import React, {useEffect} from "react";
import { Modal } from "@material-ui/core";
import { assets } from "../../constants";
import Colors from "../../constants/colors"

function diceRollHistoryRewardModal({ isOpen, onClose, reward, currentReward, item, currentItemIndex, divider }) {
  if (!isOpen) return null;
  const historyItem = [];
  const multipliers = {
    2 : 12,
    3 : 10,
    4 : 8,
    5 : 6,
    6 : 4,
    7 : 2,
    8 : 4,
    9 : 6,
    10 : 8,
    11 : 10,
    12 : 12,
}
  for(let i=0; i<item.bet_amounts.length; i++) {
    let finalAmount;
    if(i+2==parseInt(item.sum)){
        finalAmount = parseInt(item.bet_amounts[i])*multipliers[i+2];
    } else {
        finalAmount = 0;
    }
    if(item.bet_amounts[i]!='0') {historyItem.push({
        sum: i+2,
        amount: item.bet_amounts[i],
        multipliers: multipliers[i+2],
        finalAmount: finalAmount
    })}

  }
  
//   console.log(item);

  return (
    <>
        <Modal 
                onClose={onClose}
                open={isOpen}
                className={` h-[600px] w-[450px] max-[1024px]:w-[85vw] `}
        
                style={{
                  backgroundColor: "transparent",
                  margin: "auto",
                  overflow: "hidden",
                  display: "flex",
                  flexDirection: "column",
                }}
            >
            <div className=" bg-white xl:min-h-[506px] py-4 px-5 rounded-[12px] max-[1024px]:w-[85vw] max-[1024px]:h-[100%] border-2 border-black">
                <div className=" font-bold text-lg flex flex-col gap-2">
                    <div className="flex flex-row justify-between font-SatoshiBold w-full">
                        <div className="text-xl font-bold">
                            {item.amount_won==="0" ? " ": "You won 🥳" } 
                        </div>
                        <div className="mr-2">
                            You got
                        </div>
                    </div>
                    <div className="flex flex-row justify-between w-full ">
                        <div className="text-4xl text-textGreyDark font-semibold mt-4">
                            {item.amount_won === "0"?"You Lost 🫤":item.amount_won/divider + " GUI"} 
                        </div>
                        <div style={{ backgroundColor: Colors[`color${item.sum}`] }} className="rounded-[12px] flex flex-col py-2 px-1 w-[74px] items-center">
                            <div className="font-bold text-white text-xl">{item.sum}</div>
                            <div className="w-1rem"> <img className="w-full" src={assets[`dice${item.sum}`]} alt="Dice" /></div>
                        </div>
                    </div>
                    <div className="flex justify-start font-SatoshiBold text-xl">
                        Activity
                    </div>
                    <div className="flex flex-row justify-between w-full font-normal text-textGreyDark">
                        <div>
                            You Chose 
                        </div>
                        <div className="mr-1">
                            You Spent
                        </div>
                    </div>
                    
                    {/* <div className="flex flex-col py-2.5 px-2 2xl:h-[250px] font-normal h-[240px] font-SatoshiMedium text-textGreyLight overflow-y-scroll">
                        {reward.slice(1).map((it, index) => (
                            <div key={index} className="flex flex-row justify-between w-full">
                            <div className="flex flex-row gap-4">
                                <div>{it.sum}</div>
                                <div className="mr-1">{it.bet_multiplier / 100}x</div>
                            </div>
                            <div>{it.amount_won / 10**8} GUI</div>
                            </div>
                        ))}
                    </div> */}

                    {/* #58BB47 */}
                    <div className="flex flex-col gap-2 py-2.5 2xl:h-[250px] h-[240px] text-textGreyDark overflow-y-scroll hover:cursor-pointer">
                        {item.bet_type==="1"?
                            (
                                <div className={`flex flex-row justify-between w-full px-3 py-2 rounded-[12px] ${ (item.amount_won)>0? "bg-[#58BB47] bg-opacity-20 border-2 border-[#58BB47] ": " "}`}>
                                <div className="flex flex-row gap-4 items-center flex-grow">
                                    <div className="text-left w-[15px] items-center justify-center text-black font-bold">{item.side===true?"Even":"Odd"}</div>
                                    <div className=" ml-10 ">
                                        <div className={`w-10 border border-[#B4A9F5] bg-[#F7F5FF] py rounded-full `}>
                                            <p className={`text-center text-xs mx-auto text-[#B4A9F5]`}>
                                                1.5x
                                            </p>
                                        </div>
                                    </div>
                                </div>
                                <div className={`flex items-center justify-end w-1/3 font-normal ${(item.amount_won)>0?"text-[#00B061]":"text-red"}`}>
                                    <div>{item.amount_won>0?"+"+(item.total_bet_amount)/divider:"-"+(item.total_bet_amount)/divider} GUI</div>
                                </div>
                            </div>
                            )
                            :
                            (historyItem.map((it, index) => (
                            <div key={index} className={`flex flex-row justify-between w-full px-3 py-2 rounded-[12px] ${ it.finalAmount ? "bg-[#58BB47] bg-opacity-20 border-2 border-[#58BB47] ": " "}`}>
                                <div className="flex flex-row gap-4 items-center flex-grow">
                                    <div className="text-left w-[15px] items-center justify-center text-black font-bold">{it.sum}</div>
                                    <div className=" ">
                                                        <div className={`w-10 
                                                                border 
                                                                ${(it.sum===12||it.sum===2)?"border-[#FFAA05]": (it.sum===3||it.sum===11) ? "border-[#9747FF]" : (it.sum===9||it.sum===5) ? "border-[#00B061]" : (it.sum===4||it.sum===10) ? "border-[#F9379B]" : "border-textBlue" } 
                                                                bg-opacity-20
                                                                ${(it.sum===12||it.sum===2)?"gold-gradient": (it.sum===3||it.sum===11) ? "bg-[#9747FF]" : (it.sum===9||it.sum===5) ? "bg-[#00B061]" : (it.sum===4||it.sum===10) ? "bg-[#F9379B]" : "bg-textBlue" } 
                                                                py rounded-full `}>
                                                            <p className={`
                                                                    text-center 
                                                                    text-xs 
                                                                    mx-auto
                                                                    ${(it.sum===12||it.sum===2)?"text-white": (it.sum===3||it.sum===11) ? "text-[#9747FF]" : (it.sum===9||it.sum===5) ? "text-[#00B061]" : (it.sum===4||it.sum===10) ? "text-[#F9379B]" : "text-textBlue" } 
                                                                `}>
                                                                    {it.multipliers}x
                                                                </p>
                                                        </div>
                                                    </div>
                                </div>
                                <div className={`flex items-center justify-end w-1/3 font-normal ${(it.finalAmount-it.amount)>0?"text-[#00B061]":"text-red"}`}>
                                    {/* <div>{(it.finalAmount-it.amount)>0?"+"+it.finalAmount/10**8:(it.finalAmount-it.amount)/10**8} GUI</div> */}
                                    <div>{(it.finalAmount-it.amount)>0?"+"+(it.amount)/divider:(it.finalAmount-it.amount)/divider} GUI</div>
                                </div>
                            </div>
                        )))}
                    </div>
                    <div className="p-2 md:p-0">
                        <img onClick={onClose} src="/okaybutton.png" className="hover:scale-105 transition-all cursor-pointer" />
                    </div>
                    {/* <button
                        onClick={onClose}
                        className="absolute bottom-4 left-1/2 transform -translate-x-1/2 bg-black rounded-lg px-20 lg:px-40 py-4 text-2xl text-white hover:text-gray-400 border-4 border-purple hover:cursor-pointer"
                    >
                        Okay!
                    </button> */}
                    
                </div>
            </div>
        </Modal>
    
    </>
    // <div className="fixed top-0 left-0 z-50 w-full h-full bg-gray-900 bg-opacity-50 p-2 md:p-0 flex items-center justify-center">
    // {/* min-w-[21rem] */}
    //   <div className="bg-cover bg-center relative min-w-[31rem] min-h-[30rem]">
    //     {/* Background Image */}
        // <div className="absolute w-full h-full rounded-[12px] bg-white border-2 border-black ">
            // <div className="p-6 px-6 font-bold text-lg flex flex-col gap-2">
            //     <div className="flex flex-row justify-between font-SatoshiBold w-full">
            //         <div className="text-xl font-bold">
            //             {item.amount_won==="0" ? " ": "You won" } 
            //         </div>
            //         <div className="mr-1">
            //             You got
            //         </div>
            //     </div>
            //     <div className="flex flex-row justify-between w-full ">
            //         <div className="text-4xl text-textGreyDark font-semibold mt-4">
            //             {item.amount_won === "0"?"You Lost 🫤":item.amount_won/10**8 + " GUI"} 
            //         </div>
            //         <div style={{ backgroundColor: Colors[`color${item.sum}`] }} className="rounded-[12px] flex flex-col py-2 px-1 w-[74px] items-center">
            //             <div className="font-bold text-white text-xl">{item.sum}</div>
            //             <div className="w-1rem"> <img className="w-full" src={assets[`dice${item.sum}`]} alt="Dice" /></div>
            //         </div>
            //     </div>
            //     <div className="flex justify-start font-SatoshiBold text-xl">
            //         Activity
            //     </div>
            //     <div className="flex flex-row justify-between w-full font-normal text-textGreyDark">
            //         <div>
            //             You Chose 
            //         </div>
            //         <div className="mr-1">
            //             You Spent
            //         </div>
            //     </div>
                
            //     {/* <div className="flex flex-col py-2.5 px-2 2xl:h-[250px] font-normal h-[240px] font-SatoshiMedium text-textGreyLight overflow-y-scroll">
            //         {reward.slice(1).map((it, index) => (
            //             <div key={index} className="flex flex-row justify-between w-full">
            //             <div className="flex flex-row gap-4">
            //                 <div>{it.sum}</div>
            //                 <div className="mr-1">{it.bet_multiplier / 100}x</div>
            //             </div>
            //             <div>{it.amount_won / 10**8} GUI</div>
            //             </div>
            //         ))}
            //     </div> */}

            //     {/* #58BB47 */}
            //     <div className="flex flex-col gap-2 py-2.5 2xl:h-[250px] h-[240px] text-textGreyDark overflow-y-scroll hover:cursor-pointer">
            //         {item.bet_type==="1"?
            //             (
            //                 <div className={`flex flex-row justify-between w-full px-3 py-2 rounded-[12px] ${ (item.amount_won)>0? "bg-[#58BB47] bg-opacity-20 border-2 border-[#58BB47] ": " "}`}>
            //                 <div className="flex flex-row gap-4 items-center flex-grow">
            //                     <div className="text-left w-[15px] items-center justify-center text-black font-bold">{item.side===true?"Even":"Odd"}</div>
            //                     <div className=" ml-10 ">
            //                         <div className={`w-10 border border-[#B4A9F5] bg-[#F7F5FF] py rounded-full `}>
            //                             <p className={`text-center text-xs mx-auto text-[#B4A9F5]`}>
            //                                 1.5x
            //                             </p>
            //                         </div>
            //                     </div>
            //                 </div>
            //                 <div className={`flex items-center justify-end w-1/3 font-normal ${(item.amount_won)>0?"text-[#00B061]":"text-red"}`}>
            //                     <div>{item.amount_won>0?"+"+(item.total_bet_amount)/10**8:"-"+(item.total_bet_amount)/10**8} GUI</div>
            //                 </div>
            //             </div>
            //             )
            //             :
            //             (historyItem.map((it, index) => (
            //             <div key={index} className={`flex flex-row justify-between w-full px-3 py-2 rounded-[12px] ${ it.finalAmount ? "bg-[#58BB47] bg-opacity-20 border-2 border-[#58BB47] ": " "}`}>
            //                 <div className="flex flex-row gap-4 items-center flex-grow">
            //                     <div className="text-left w-[15px] items-center justify-center text-black font-bold">{it.sum}</div>
            //                     <div className=" ">
            //                                         <div className={`w-10 
            //                                                  border 
            //                                                  ${(it.sum===12||it.sum===2)?"border-[#FFAA05]": (it.sum===3||it.sum===11) ? "border-[#9747FF]" : (it.sum===9||it.sum===5) ? "border-[#00B061]" : (it.sum===4||it.sum===10) ? "border-[#F9379B]" : "border-textBlue" } 
            //                                                  bg-opacity-20
            //                                                  ${(it.sum===12||it.sum===2)?"gold-gradient": (it.sum===3||it.sum===11) ? "bg-[#9747FF]" : (it.sum===9||it.sum===5) ? "bg-[#00B061]" : (it.sum===4||it.sum===10) ? "bg-[#F9379B]" : "bg-textBlue" } 
            //                                                  py rounded-full `}>
            //                                             <p className={`
            //                                                     text-center 
            //                                                     text-xs 
            //                                                     mx-auto
            //                                                     ${(it.sum===12||it.sum===2)?"text-white": (it.sum===3||it.sum===11) ? "text-[#9747FF]" : (it.sum===9||it.sum===5) ? "text-[#00B061]" : (it.sum===4||it.sum===10) ? "text-[#F9379B]" : "text-textBlue" } 
            //                                                 `}>
            //                                                     {it.multipliers}x
            //                                                 </p>
            //                                         </div>
            //                                     </div>
            //                 </div>
            //                 <div className={`flex items-center justify-end w-1/3 font-normal ${(it.finalAmount-it.amount)>0?"text-[#00B061]":"text-red"}`}>
            //                     {/* <div>{(it.finalAmount-it.amount)>0?"+"+it.finalAmount/10**8:(it.finalAmount-it.amount)/10**8} GUI</div> */}
            //                     <div>{(it.finalAmount-it.amount)>0?"+"+(it.amount)/10**8:(it.finalAmount-it.amount)/10**8} GUI</div>
            //                 </div>
            //             </div>
            //         )))}
            //     </div>
            //     <div className="p-2 md:p-0">
            //         <img onClick={onClose} src="/okaybutton.png" className="absolute bottom-4 left-1/2 transform -translate-x-1/2 hover:scale-105 transition-all cursor-pointer" />
            //     </div>
            //     {/* <button
            //         onClick={onClose}
            //         className="absolute bottom-4 left-1/2 transform -translate-x-1/2 bg-black rounded-lg px-20 lg:px-40 py-4 text-2xl text-white hover:text-gray-400 border-4 border-purple hover:cursor-pointer"
            //     >
            //         Okay!
            //     </button> */}
                
            // </div>

        // </div>
    //   </div>
    // </div>
  );
}

export default diceRollHistoryRewardModal;
