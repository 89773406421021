import { React, useState, useEffect } from "react";
import Navbar from "./navbar/Navbar";
import chestBg from "../assets/lbChest.png";
import "swiper/css";
import "swiper/css/navigation";
import "swiper/css/pagination";
import "swiper/css/scrollbar";
import { Navigation } from "swiper/modules";
import { Swiper as SwiperComponent, SwiperSlide } from "swiper/react";
import { Autoplay, Pagination } from "swiper/modules";
import { assets } from "../constants";
import { useNavigate } from "react-router-dom";
import star from "../assets/star.svg";
import dices from "../assets/dices.png";
import raffleImage from "../assets/raffleImage2.png";
import dicesIcon from "../assets/dicesIcon.jpeg";
import wheelIcon from "../assets/wheelIcon.jpeg";
import badge from "../assets/badge.png";
import usd from "../assets/usdicon.png";
import crown from "../assets/crown.png";
import person from "../assets/person.png";
import whitebg from "../assets/whitebg.jpeg";
import axios from "axios";
import { useWallet } from "@aptos-labs/wallet-adapter-react";
import io from 'socket.io-client';

const socket = io('https://games-backend-bgdzekh0b4g0chah.westindia-01.azurewebsites.net/');

export default function LeaderBoard() {
  const navigate = useNavigate();
  const [openChooseNFT, setOpenChooseNFT] = useState(false);
  const [leaderboardItems, setLeaderboardItems] = useState([]);
  const [currentUser, setCurrentUser] = useState("");
  const [liveActivityData, setLiveActivityData] = useState([])
  const [leaderboard, setLeaderboard] = useState([]);

  const { account, connected, signAndSubmitTransaction } = useWallet();


  useEffect(() => {
    // Listen for updates from server
    // console.log("Working?")
    socket.on('live_activity', (updatedLeaderboard) => {
      // console.log(updatedLeaderboard)
      setLiveActivityData(prev=> [updatedLeaderboard,...prev]);
    });

    // Clean up on unmount
    return () => {
      socket.disconnect();
    };
  }, []);

  // setCurrentUser(account.address);

  const fetchLeaderboardData = async () => {
    try {
      const response = await axios.get(
        "https://games-backend-bgdzekh0b4g0chah.westindia-01.azurewebsites.net/leaderboard"
      );
      const data = response.data;
      // Transform the object data into an array
      if (data && typeof data === "object" && !Array.isArray(data)) {
        const transformedData = Object.values(data);
        setLeaderboardItems(transformedData[1]);
      } else {
        console.error("Unexpected data format:", data);
        setLeaderboardItems([]);
      }
    } catch (error) {
      console.error("Error fetching leaderboard data:", error);
      setLeaderboardItems([]);
    }
  };

  const fetchLiveActivityData = async () => {
    try {
      const response = await axios.get(
        "https://games-backend-bgdzekh0b4g0chah.westindia-01.azurewebsites.net/live-activity"
      );
      const data = response.data.data;
      setLiveActivityData(data);
    } catch (error) {
      console.log("Error fetching live data: ", error)
    }
  };

  const findCurUser = () => {
    if (!account || !account.address) {
      console.log("Account is not available");
      return [];
    }
    const user = account.address;
    // console.log("USER", user);

    for (let i = 0; i < leaderboardItems.length; i++) {
      if (leaderboardItems[i].player === user) {
        const rank = i + 1;
        const playerArr = [
          {
            rank: rank,
            player: "You",
            total_gui_volume: leaderboardItems[i].total_gui_volume,
            total_aptos_volume: leaderboardItems[i].total_aptos_volume,
            total_zapp_volume: leaderboardItems[i].total_zapp_volume,
            usd_vol: leaderboardItems[i].usd_vol,
            chances: leaderboardItems[i].chances,
          },
        ];
        // console.log("HI");
        return playerArr;
      }
    }
    // console.log("USER", user);
    // console.log("NO PLAYER FOUND");
    return [{
      rank: "-",
      player: "You",
      total_gui_volume: 0,
      total_aptos_volume: 0,
      total_zapp_volume: 0,
      usd_vol: 0,
      chances: 0,
    }];
  };

  const playerInfo = findCurUser();
  // console.log(playerInfo[0].player);

  useEffect(() => {
    fetchLiveActivityData();
    fetchLeaderboardData();
  }, []);

  const handleOpenChooseNFT = () => {
    setOpenChooseNFT(true);
  };

  const formatAddress = (address) => {
    if (!address) return "";
    return `${address.slice(0, 4)}...${address.slice(-4)}`;
  };

  const LeaderboardHeaders = [
    { title: "Rank no."},
    { title: "Address", img: person },
    { title: "Volume USD"},
    { title: "APTOS", img: assets.aptosLogo },
    { title: "GUI", img: assets.guiIcon },
    { title: "ZAAP", img: assets.zaap },
    { title: "Chances", img: assets.chance },
  ];


  const calculateTimeLeft = () => {
    const countDownDate = Date.UTC(2024, 6, 1, 15, 0, 0);
    const now = new Date().getTime();
    const distance = countDownDate - now;

    if (distance < 0) {
      return null;
    }

    const days = Math.floor(distance / (1000 * 60 * 60 * 24));
    const hours = Math.floor(
      (distance % (1000 * 60 * 60 * 24)) / (1000 * 60 * 60)
    );
    const minutes = Math.floor((distance % (1000 * 60 * 60)) / (1000 * 60));
    const seconds = Math.floor((distance % (1000 * 60)) / 1000);

    return {
      days,
      hours,
      minutes,
      seconds,
    };
  };

  const [timeLeft, setTimeLeft] = useState(calculateTimeLeft());

  useEffect(() => {
    const timer = setInterval(() => {
      setTimeLeft(calculateTimeLeft());
    }, 1000);

    return () => clearInterval(timer);
  }, []);

  // if (!timeLeft) {
  //   return <p id="demo">EXPIRED</p>;
  // }

  const sortedLeaderboardItems = [...leaderboardItems].sort(
    (a, b) => b.usd_vol - a.usd_vol
  );

  const gameMapping = {
    games:{
      dice_roll: "Dice Roll",
      coin_flip: "Coin Flip",
      fortune_wheel: "Fortune Wheel",
      nft_spin: "NFT Spin",
      plinko: "Plinko"
    },
    image: {
      dice_roll: assets.diceLogo,
      coin_flip: assets.coinFlipLogo,
      fortune_wheel: assets.wheelLogo,
      nft_spin: assets.nftSpinLogo,
      plinko: assets.plinkoLogo,
    },
    rewardImage: {
      "0xdcfa079344261bfde45e7f6281df091743b8d3098bf9e26e1c0212fc5b070621::zaaptos_token::ZaaptosCoin": assets.zaap,
      "0xe4ccb6d39136469f376242c31b34d10515c8eaaa38092f804db8e08a8f53c5b2::assets_v1::EchoCoin002": assets.gui,
      "0x1::aptos_coin::AptosCoin": assets.aptosLogo,
      DEFY_COINS: assets.defy1,
      RAFFLE_TICKETS: assets.raff,
    }
  };

  const getLiveGame = (game) => {
    return gameMapping.games[game] || "Error!";
  }

  const getLiveGameImage = (game) => {
    return gameMapping.image[game] || "Error!";
  }

  const getLiveRewardImage = (rewardType) => {
    return gameMapping.rewardImage[rewardType] || "no Image";
  }

  // console.log(liveActivityData)
  function timeAgo(timestamp) {
    const now = new Date();
    const time = new Date(timestamp);
    const seconds = Math.floor((now - time) / 1000);

    let interval = Math.floor(seconds / 31536000);
    if (interval > 1) {
        return `${interval} years ago`;
    }

    interval = Math.floor(seconds / 2592000);
    if (interval > 1) {
        return `${interval} months ago`;
    }

    interval = Math.floor(seconds / 86400);
    if (interval > 1) {
        return `${interval} days ago`;
    }

    interval = Math.floor(seconds / 3600);
    if (interval > 1) {
        return `${interval} hours ago`;
    }

    interval = Math.floor(seconds / 60);
    if (interval > 1) {
        return `${interval} minutes ago`;
    }

    return `${Math.floor(seconds)} seconds ago`;
}

  return (
    <div className="bg-[#F4F4FC] min-h-screen">
      <Navbar />
      
      <div className="pt-[110px] flex flex-col gap-2 lg:gap-0 lg:flex-row ">
        <div className=" lg:w-3/4 flex flex-col items-center ml-4 mr-4 lg:mr-0 lg:ml-8 min-w-[300px] lg:min-w-[648px]">
          <div className="flex h-[350px] md:h-[250px] w-full bg-gradient-to-r-banner text-white rounded-xl md:pl-8 justify-between">
            <div className="content-center leading-8 p-4">
              <p className="text-lg font-SatoshiMedium">Random Week</p>
              <p className="lg:text-5xl text-3xl font-SatoshiMedium ">30,000,000 GUI</p>
              <p className="font-SatoshiMedium">
                Will be distributed among top 15 participants
              </p>
              <div id="demo" className="mt-4 flex gap-1 items-center">
                <div className="flex-col bg-indigo-400 bg-opacity-25 rounded-xl h-20 w-[60px] lg:w-20 text-center items-center">
                  <div className="text-2xl lg:text-4xl"> {0}</div>
                  <div>Days</div>
                </div>
                :
                <div className="flex-col bg-indigo-400 bg-opacity-25 rounded-xl h-20 w-[70px] lg:w-20 text-center items-center">
                  <div className="text-2xl lg:text-4xl"> {0}</div>
                  <div>Hours</div>
                </div>
                :
                <div className="flex-col bg-indigo-400 bg-opacity-25 rounded-xl h-20 w-[70px] lg:w-20 text-center items-center">
                  <div className="text-2xl lg:text-4xl">{0}</div>
                  <div>Minutes</div>
                </div>
                :
                <div className="flex-col bg-indigo-400 bg-opacity-25 rounded-xl h-20 w-[80px] lg:w-20 text-center items-center">
                  <div className="text-2xl lg:text-4xl">{0}</div>
                  <div>Seconds</div>
                </div>
              </div>
            </div>
            <div className=" hidden md:block">
              <img
                src={chestBg}
                alt=""
                className="object-cover h-full w-full rounded-br-xl"
              />
            </div>
          </div>
        </div>

        <div className="w-full lg:w-1/4 flex flex-col mr-4 mt-4 md:mt-0 min-w-[300px] lg:min-w-[400px]">
        <div className="w-full h-[250px] px-4">
            <SwiperComponent
              slidesPerView="1"
              loop
              grabCursor={true}
              spaceBetween={25}
              autoplay={{
                delay: 2500,
                disableOnInteraction: false,
              }}
              pagination={{
                clickable: true,
              }}
              modules={[Autoplay, Navigation, Pagination]}
            >
              <SwiperSlide data-hash="One">
                <div className="w-full  rounded-2xl px-5 py-6  min-h-[250px] 2xl:min-h-[250px] relative bg-gradient-to-r-orange">
                  <div className="flex items-align">
                    <img className="ml-2" src={star} />
                    <p className="font-SatoshiBold text-2xl text-white text-left ml-3">
                      Coin Flip
                    </p>
                  </div>

                  <div className="flex items-center justify-between ">
                    <p className="font-Satoshi 2xl:text-lg text-sm text-white text-left">
                      Betting odds on Dogs vs
                      <br />
                      Cats
                    </p>
                    <img
                      className="object-contain w-1/3 ml-2"
                      src={assets.coinFlip}
                    />
                  </div>

                  <div className="cursor-pointer">
                    <div
                      onClick={() => navigate("/coin-flip")}
                      // onClick={() => toast("Coming Soon!!")}
                      className="px-6 button py-2.5 rounded-lg bg-white flex  items-center justify-center  absolute bottom-[15px] right-[20px]"
                    >
                      <p className="font-SatoshiMedium text-base text-black">
                        Participate Now
                      </p>
                    </div>
                  </div>
                </div>
              </SwiperSlide>
              <SwiperSlide data-hash="Two">
                <div className="w-full  rounded-2xl px-5 py-6 min-h-[250px] 2xl:min-h-[250px] relative bg-gradient-to-r-gold">
                  <div className="flex items-align">
                    <img className="ml-2" src={star} />
                    <p className="font-SatoshiBold text-2xl text-white text-left ml-3">
                      Fortune Wheel
                    </p>
                  </div>

                  <div className="flex items-center justify-between ">
                    <p className="font-Satoshi 2xl:text-lg -translate-y-5 text-sm text-white text-left">
                      Spin the Wheel and get a
                      <br />
                      chance to win upto 5M $GUI.
                    </p>
                    <img
                      className="object-contain w-1/3 ml-2"
                      src={assets.wheelHome}
                    />
                  </div>

                  <div className="cursor-pointer">
                    <div
                      onClick={() => navigate("/Fortune-Wheel")}
                      className="px-6 button py-2.5 rounded-lg bg-white flex  items-center justify-center  absolute bottom-[15px] right-[20px]"
                    >
                      <p className="font-SatoshiMedium text-base text-black">
                        Participate Now
                      </p>
                    </div>
                  </div>
                </div>
              </SwiperSlide>

              <SwiperSlide data-hash="Three">
                <div className="w-full  rounded-2xl px-5 py-6 min-h-[250px] 2xl:min-h-[250px] relative purple-gradient">
                  <div className="flex items-align">
                    <img className="ml-2" src={star} />
                    <p className="font-SatoshiBold text-2xl text-white text-left ml-3">
                      NFT Spin
                    </p>
                  </div>

                  <div className="flex items-center justify-between ">
                    <p className="font-Satoshi 2xl:text-lg text-sm text-white text-left">
                      Spin and win your favorite NFTs
                      <br />
                      at 0.1% of the price.
                    </p>
                    <img
                      className="object-contain w-1/3 ml-2"
                      src={raffleImage}
                    />
                  </div>

                  <div className="cursor-pointer">
                    <div
                      onClick={() => {
                        handleOpenChooseNFT();
                      }}
                      className="px-6 button py-2.5 rounded-lg bg-white flex  items-center justify-center  absolute bottom-[15px] right-[20px]"
                    >
                      <p className="font-SatoshiMedium text-base text-black">
                        Participate Now
                      </p>
                    </div>
                  </div>
                </div>
              </SwiperSlide>

              <SwiperSlide data-hash="Four">
                <div className="w-full  rounded-2xl px-5 py-6  min-h-[250px]  2xl:min-h-[250px] relative bg-gradient-to-r-blue">
                  <div className="flex items-align">
                    <img className="ml-2" src={star} />
                    <p className="font-SatoshiBold text-2xl text-white text-left ml-3">
                      Dice Roll
                    </p>
                  </div>

                  <div className="flex items-center justify-between ">
                    <p className="font-Satoshi 2xl:text-lg text-sm text-white text-left">
                      Every roll is a chance to win
                      <br />
                      in Dice Roll.
                    </p>
                    <img className="object-contain w-1/3 ml-2" src={dices} />
                  </div>

                  <div
                    onClick={() => navigate("/Dice-roll")}
                    className="px-6 button py-2.5 rounded-lg bg-white flex  items-center justify-center cursor-pointer absolute bottom-[15px] right-[20px]"
                  >
                    <p className="font-SatoshiMedium text-base text-black">
                      Participate Now
                    </p>
                  </div>
                </div>
              </SwiperSlide>
              <SwiperSlide data-hash="Five">
                <div className="w-full  rounded-2xl px-5 py-6  min-h-[250px]  2xl:min-h-[250px] relative bg-gradient-to-r-purple">
                  <div className="flex items-align">
                    <img className="ml-2" src={star} />
                    <p className="font-SatoshiBold text-2xl text-white text-left ml-3">
                      Plinko
                    </p>
                  </div>

                  <div className="flex items-center justify-between ">
                    <p className="font-Satoshi 2xl:text-lg text-sm text-white text-left">
                      Every ball will lead to a 
                      <br />
                      fortune
                    </p>
                    <img className="object-contain w-1/3 ml-2" src={assets.plinkoHome} />
                  </div>

                  <div
                    onClick={() => navigate("/Plinko")}
                    className="px-6 button py-2.5 rounded-lg bg-white flex  items-center justify-center cursor-pointer absolute bottom-[15px] right-[20px]"
                  >
                    <p className="font-SatoshiMedium text-base text-black">
                      Participate Now
                    </p>
                  </div>
                </div>
              </SwiperSlide>
            </SwiperComponent>
          </div>
        </div>
      </div>

      <div className="mt-6 flex flex-col gap-2 lg:gap-4 lg:flex-row ">
        <div className="lg:w-3/4 flex flex-col items-start ml-4 mr-4 lg:mr-0 lg:ml-8 min-w-[300px] overflow-x-scroll lg:min-w-[648px] "> 
          <p className="font-semibold text-xl mb-6">Leaderboard</p>

          <div className=" lg:w-full flex flex-col h-[530px] overflow-y-scroll">
            <div className="flex px-6 items-center w-full ">
              {LeaderboardHeaders.map((header, index) => (
                <div key={index} className={`whitespace-nowrap lg:w-[20rem] ${index===0?"w-[100px]":"w-[170px]"} `}>
                  {/* ${index===2?"w-[200px]" :"w-[170px]"} */}
                  <div className={`flex flex-row gap-2 items-center mb-2 ${index===6?"justify-center":""}`}>
                    {header.img && <img src={header.img} alt="" className="w-6 h-6" />}
                    {index === 2 ? (
                      <>
                        {header.title}
                        <img src={assets.caretDown} alt="Caret Down" className="w-4 h-4" />
                      </>
                    ) : (
                      header.title
                    )}
                  </div>
                </div>
              ))}
            </div>
            <div className="flex mt-4">
              {playerInfo.length!==0?(
                <div className="font-SatoshiMedium hover:scale-95 duration-100 border-gradient-leaderboard bg-white w-full flex px-6 py-4 items-center ">
                  <div className="whitespace-nowrap flex justify-start gap-2 items-center lg:w-[20rem] w-[100px]">
                    {playerInfo[0].rank>15?"":<img src={badge} alt="" className="size-8" />}
                    <div>{playerInfo[0].rank}</div>
                  </div>
                  <div className="whitespace-nowrap flex justify-start gap-2 items-center lg:w-[20rem] w-[170px]">
                    <div className="text-gray-600">{playerInfo[0].player}</div>
                  </div>
                  <div className="whitespace-nowrap text-gray-600 lg:w-[20rem] w-[170px] flex">
                    {/* <img src={usd} className="w-6 h-6 mr-1" /> */}
                    $ {parseFloat(playerInfo[0].usd_vol).toFixed(4)}
                  </div>
                  <div className="whitespace-nowrap text-gray-600 lg:w-[20rem] w-[170px] flex">
                    <img src={assets.aptosLogo} className="w-6 h-6 mr-1" />
                    {parseFloat(playerInfo[0].total_aptos_volume).toFixed(4)}
                  </div>
                  <div className="whitespace-nowrap text-gray-600 lg:w-[20rem] w-[170px] flex">
                    <img src={assets.guiIcon} className="w-6 h-6 mr-1" />
                    {parseFloat(playerInfo[0].total_gui_volume)}
                  </div>
                  <div className="whitespace-nowrap text-gray-600 lg:w-[20rem] w-[170px] flex">
                    <img src={assets.zaap} className="w-6 h-6 mr-1" />
                    {parseFloat(playerInfo[0].total_zapp_volume)}
                  </div>
                  <div className="whitespace-nowrap text-green lg:w-[20rem] w-[170px] flex justify-center">
                    {playerInfo[0].chances}
                  </div>
                </div>
              ) : (
                <div className="font-SatoshiMedium border-gradient-leaderboard bg-white w-full flex px-6 py-4 items-center ">
                  <div className="whitespace-nowrap flex justify-start gap-2 items-center lg:w-[20rem] w-[100px]">
                    <div>-</div>
                  </div>
                  <div className="whitespace-nowrap flex justify-start gap-2 items-center lg:w-[20rem] w-[170px]">
                    <div className="text-gray-600">0x....</div>
                  </div>
                  <div className="whitespace-nowrap text-gray-600 lg:w-[20rem] w-[170px] flex">
                    {/* <img src={usd} className="w-6 h-6 mr-1" /> */}
                    $ 0
                  </div>
                  <div className="whitespace-nowrap text-gray-600 lg:w-[20rem] w-[170px] flex">
                    <img src={assets.aptosLogo} className="w-6 h-6 mr-1" />
                    0
                  </div>
                  <div className="whitespace-nowrap text-gray-600 lg:w-[20rem] w-[170px] flex">
                    <img src={assets.guiIcon} className="w-6 h-6 mr-1" />
                    0
                  </div>
                  <div className="whitespace-nowrap text-gray-600 lg:w-[20rem] w-[170px] flex">
                    <img src={assets.zaap} className="w-6 h-6 mr-1" />
                    0
                  </div>
                  <div className="whitespace-nowrap text-green lg:w-[20rem] w-[170px] flex justify-center">
                    0
                  </div>
                </div>
              )}
            </div>
            <div>
              {leaderboardItems.length===0?(
                  <div className="flex mt-4 w-full justify-center">
                    Loading ...
                  </div>
                ):(
                  <div className="flex flex-col gap-2 mt-2 ">
                    {sortedLeaderboardItems.map((item, index) => (
                      <div key={index} className="font-SatoshiMedium hover:scale-95 duration-100 rounded-[12px] bg-white w-full flex px-6 py-4 items-center">
                        <div className="whitespace-nowrap flex justify-start gap-2 items-center lg:w-[20rem] w-[100px]">
                          {index + 1 <= 15 ? (
                            <img src={badge} alt="" className="size-8" />
                          ) : (
                            ""
                          )}
                          <div>{index + 1}</div>
                        </div>
                        <div className="text-gray-600 lg:w-[20rem] w-[170px] justify-start">
                          <div>{formatAddress(item.player)}</div>
                        </div>
                        <div className="whitespace-nowrap text-gray-600 lg:w-[20rem] w-[170px] flex">
                          {/* <img src={usd} className="w-6 h-6 mr-1" /> */}
                          $ {parseFloat(item.usd_vol).toFixed(4)}
                        </div>
                        <div className="whitespace-nowrap text-gray-600 lg:w-[20rem] w-[170px] flex">
                          <img src={assets.aptosLogo} className="w-6 h-6 mr-1" />
                          {parseFloat(item.total_aptos_volume).toFixed(5)}
                        </div>
                        <div className="whitespace-nowrap text-gray-600 lg:w-[20rem] w-[170px] flex">
                          <img src={assets.guiIcon} className="w-6 h-6 mr-1" />
                          {(item.total_gui_volume)}
                        </div>
                        <div className="whitespace-nowrap text-gray-600 lg:w-[20rem] w-[170px] flex">
                          <img src={assets.zaap} className="w-6 h-6 mr-1" />
                          {(item.total_zapp_volume)}
                        </div>
                        <div className=" text-green lg:w-[20rem] w-[170px] flex justify-center">
                          <div>{(item.chances)}</div>
                        </div>
                      </div>
                    ))}
                  </div>
                )}
            </div>
          </div>

        </div>
        <div className="w-full lg:w-1/4 flex flex-col mb-4 md:mb-0 mr-4 mt-4 md:mt-0 min-w-[300px] lg:min-w-[380px] bg-white rounded-[12px] py-2">
          <div className="flex justify-between mx-4 mt-4 items-center">
            <div className="font-semibold text-xl">
              Global Activity
            </div>
            <div className="text-gray-400 text-sm flex justify-end items-center">
              <img src={assets.participants} className="h-5 w-5 mr-1" />
              {leaderboardItems.length} Participants
            </div>
          </div>
          <div className="border-1 w-1/3 border-green my-4 mx-4"></div>
          <div className="h-[490px] overflow-y-scroll">
            {liveActivityData.map((activity, index) => (
              <div className="flex justify-between font-SatoshiMedium px-4 py-3 box-shadow-all-sides items-center m-2 rounded-[12px]">
                <div key={index} className="flex gap-2">
                  <img
                    src={getLiveGameImage(activity.game)}
                    alt=""
                    className="h-10 w-10 rounded-lg"
                  />
                  <div>
                    <div className="text-gray-400 font-SatoshiMedium text-xs">
                      {getLiveGame(activity.game)}
                    </div>
                    <div className="text-sm">{activity.player.slice(0,6)}....{activity.player.slice(-4)}</div>
                  </div>
                </div>
                <div className="flex flex-col ">
                  <div className="flex justify-end text-gray-400 text-xs font-SatoshiMedium">
                    {timeAgo(activity.transaction_timestamp)}
                  </div>
                  <div className="flex justify-end text-sm font-SatoshiMedium text-teal-600 gap-2 items-center">
                    <div>{activity.reward_type==="NFT"||activity.reward_type==="FREE_SPIN"? activity.reward_amount + "x": activity.reward_type==="0x1::aptos_coin::AptosCoin"? parseFloat(activity.reward_amount).toFixed(6) : activity.reward_amount}</div>
                    {   
                      activity.reward_type==="NFT"
                      ? 
                        activity.reward_type 
                      : 
                        activity.reward_type==="FREE_SPIN" 
                      ?
                        "Free Spin"
                      :
                        <img src={getLiveRewardImage(activity.reward_type)} alt="" className="size-6" />
                    }
                  </div>
                </div>
              </div>
            ))}
          </div>
        </div>
      </div>
      
    </div>
  );
}


            {/* <table className="min-w-full">

             ouafhoauh         <div className=" lg:w-3/4 flex flex-col items-start ml-4 mr-4 lg:mr-0 lg:ml-8 overflow-x-scroll min-w-[300px] lg:min-w-[648px] h-[550px] overflow-y-scroll">

            <thead className="">
              <tr className="text-center font-SatoshiMedium">
                {LeaderboardHeaders.map((header, index) => (
                  <th
                    key={index}
                    className="text-md font-medium text-gray-500 tracking-wider whitespace-nowrap"
                  >
                    <div className="px-6 flex justify-start gap-2 items-center mb-2">
                      <img src={header.img} alt="" className="size-6 hidden md:block" />
                      {index === 2 ? (
                        <>
                          {header.title}
                          <img src={assets.caretDown} alt="caret Down" className="size-4" />
                        </>
                      ) : (
                        header.title
                      )}
                    </div>
                  </th>
                ))}
              </tr>
            </thead>
            <tbody className="">
              {playerInfo.length !== 0 ? (
                <tr className="text-left font-SatoshiMedium border border-gradient-leaderboard bg-white rounded-lg mb-2">
                  <td className="px-6 py-4 whitespace-nowrap flex justify-start gap-2 items-center">
                    <img src={badge} alt="" className="size-8" />
                    <div>{playerInfo[0].rank}</div>
                  </td>
                  <td className="text-gray-600">
                    <div>{playerInfo[0].player}</div>
                  </td>
                  <td className="px-6 py-4 whitespace-nowrap text-gray-600">
                    {parseFloat(playerInfo[0].usd_vol).toFixed(4)}
                  </td>
                  <td className="px-6 py-4 text-gray-600">
                    {playerInfo[0].total_aptos_volume}
                  </td>
                  <td className="px-6 py-4 whitespace-nowrap flex justify-start gap-2 items-center">
                    <img src={assets.guiIcon} alt="" className="size-7" />
                    <div className="text-gray-600">
                      {playerInfo[0].total_gui_volume}
                    </div>
                  </td>
                  <td className="px-6 py-4 whitespace-nowrap text-gray-600">
                    {playerInfo[0].total_zapp_volume}
                  </td>
                  <td className="px-6 py-4 whitespace-nowrap text-teal-400 justify-center">
                    {playerInfo[0].chances}
                  </td>
                </tr>
              ) : (
                ""
              )}
              {leaderboardItems.length === 0 ? (
                <tr>
                  <td
                    colSpan={LeaderboardHeaders.length}
                    className="text-center text-gray-500"
                  >
                    No data available
                  </td>
                </tr>
              ) : (
                sortedLeaderboardItems.map((item, index) => (
                  <tr key={index} className="text-left font-SatoshiMedium bg-white rounded-lg mb-2">
                    <td className="px-6 py-4 whitespace-nowrap flex justify-start gap-2 items-center">
                      {index + 1 <= 15 ? (
                        <img src={badge} alt="" className="size-8" />
                      ) : (
                        ""
                      )}
                      <div>{index + 1}</div>
                    </td>
                    <td className="text-gray-600">
                      <div>{formatAddress(item.player)}</div>
                    </td>
                    <td className="px-6 py-4 whitespace-nowrap text-gray-600">
                      {parseFloat(item.usd_vol).toFixed(4)}
                    </td>
                    <td className="px-6 py-4 text-gray-600">
                      {item.total_aptos_volume}
                    </td>
                    <td className="px-6 py-4 whitespace-nowrap flex justify-start gap-2 items-center">
                      <img src={assets.guiIcon} alt="" className="size-7" />
                      <div className="text-gray-600">
                        {item.total_gui_volume}
                      </div>
                    </td>
                    <td className="px-6 py-4 whitespace-nowrap text-gray-600">
                      {item.total_zapp_volume}
                    </td>
                    <td className="px-6 py-4 whitespace-nowrap text-teal-400">
                      {item.chances}
                    </td>
                  </tr>
                ))
              )}
            </tbody>
          </table> */}