import "./App.css";
import "./faq.css";
import { createContext, useState } from "react";
import { BrowserRouter as Router, Routes, Route } from "react-router-dom";
import Quest from "./components/quest/Quest";
import Giveaway from "./components/giveaway/Giveaway";
import Leaderboard from "./components/leaderboard/Leaderboard";
import { AppContext } from "./contexts/AppContext";
import FAQ from "./components/faq/FAQ";
import { UserContext } from "./contexts/UserContent";
import { toast, ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import "./utils/toasts.css";
import { useScroll, useTransform } from "framer-motion";
import DiceRoll from "./components/diceroll/DiceRoll";
import NFTRaffle from "./components/nftraffle/NFTRaffle";
import FortuneWheel from "./components/fortunewheel/FortuneWheel";
import Plinko from "./components/plinko/Plinko";
import CoinFlip from "./components/coinflip/CoinFlip";
import LeaderBoard from "./components/LeaderBoard";
import PageUnavailable from "./components/PageUnaivalable";


function App() {
  const [user, setUser] = useState({
    referral: {
      code: "",
      referredWallets: [],
    },
    balance: {
      tickets: 0,
    },

    boost: {},
  });

  const [tickets, setTickets] = useState(0);
  const { scrollYProgress } = useScroll();

  const prog = useTransform(scrollYProgress, [0, 1], [1, 0]);

  return (
    <UserContext.Provider value={{ user, setUser, tickets, setTickets }}>
      <AppContext>
        <Router>
          <Routes>
            <Route exact path="/" element={<Quest />} />
            <Route path="/Giveaway" element={<Giveaway />} />
            <Route path="/Leaderboard" element={<Leaderboard />} />
            <Route path="/randomWeek" element={<LeaderBoard />} />
            <Route path="/Faq" element={<FAQ />} />
            <Route path="/Dice-roll" element={<DiceRoll />} />
            <Route path="/Nft-Spin" element={<NFTRaffle />} />
            <Route path="/Nft-Raffle" element={<NFTRaffle />} />
            <Route path="/Fortune-Wheel" element={<FortuneWheel />} />
            <Route path="/Plinko" element={<Plinko />} />
            <Route path="/coin-flip" element={<CoinFlip />} />
            <Route path="*" element={<PageUnavailable />} />

          </Routes>
        </Router>

        <ToastContainer
          draggable
          position="top-right"
          className={
            "min-[1024px]:absolute min-[1024px]:!top-[100px] xl:z-[100000000]"
          }
        />
      </AppContext>
    </UserContext.Provider>
  );
}

export default App;
