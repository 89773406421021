import Modal from "@material-ui/core/Modal";
import vector from "../../assets/Vector.png";
import "../../App.css";
import { useState } from "react";
import {  enterRaffle } from "../../utils/indexer";
import { useWallet } from "@aptos-labs/wallet-adapter-react";
import { UserContext } from "../../contexts/UserContent";
import { useContext } from "react";
import axios from "axios";
import { assets, CONST } from "../../constants";
import { fetchTickets } from "../../utils/indexer";
import {  toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import "../../utils/toasts.css";
import clsx from "clsx";


export default function EnterRaffleModal({ open, handleClose, handleOpen, state }) {


  const {signAndSubmitTransaction, account, connected, connect}  = useWallet()
  const [ticketCount, setTicketCount] = useState(0)

  const {user, setUser, tickets, setTickets} = useContext(UserContext)

  function getUser() {
    const jwt = localStorage.getItem("AVEX_DEFY_JWT_STORE");
    const config = {
      headers: {
        Authorization: jwt,
      },
    };
    if (jwt) {
      axios.get(`${CONST.baseUrl}/users`, config).then((response) => {
        setUser(response.data.data);
      });
    }
  }



  function spendTickets(){

    if(!connected){
      toast("Connect Wallet before Entering Raffle!");
      connect()
      return
    }


    enterRaffle(
      state.coinType,
      state.raffleType,
      state.onchainRaffleId,
      ticketCount,
      signAndSubmitTransaction
    ).then((response)=>{

      toast("Successfully Entered Raffle!");
      toast("It could take upto 30 seconds to reflect");


      
      fetchTickets(account?.address).then((result) => {
        //console.log(result, "Rremaining Tickets");
        setTickets(result);
        getUser()

        handleClose()
      }).catch((err)=>{
        toast("Failed to Update Raffle Tickets!");
      })

      setTimeout(()=>{
        fetchTickets(account?.address).then((result) => {
          //console.log(result, "Rremaining Tickets");
          setTickets(result);
          getUser()
  
          handleClose()
        }).catch((err)=>{
          toast("Failed to Update Raffle Tickets!");
        })
      },30000)

    }).catch((err)=>{
        toast("Failed to Enter Raffle!");
      })
  }


  return (

    <Modal
      onClose={handleClose}
      open={open}
      style={{
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
      }}
    >


  
      <div className="max-w-[50vw] mx-auto p-5 rounded-lg bg-white NFTModal ">
        <div className="flex items-center justify-between flex-wrap">
          <div className="flex items-center w-full md:w-auto md:flex-1">
            <p className="text-xl font-SatoshiBold ml-2 text-black text-center">
              {state.title}
            </p>
          </div>
          <div className="flex items-center justify-center gap-8 mt-4 md:mt-0">
            <div
              style={{ backgroundColor: "#D80202" }}
              className="px-3.5 py-1 rounded-[20px] flex items-center justify-center"
            >
              <div className="w-1.5 h-1.5 rounded-full bg-white" />
              <p className="text-left text-white text-sm font-SatoshiMedium ml-2">
                Live
              </p>
            </div>
            {user?.raffleParticipation?.length != 0 ? <div className="px-3.5 py-2 bg-bgBlue rounded-full">
              <p className="text-left text-white text-xs font-SatoshiMedium m-auto">
                Participating
              </p>
            </div>
              : <div className="px-3.5 py-2 bg-textGreyLight rounded-[20px]">
                <p className="text-left text-white text-xs font-SatoshiMedium m-auto">
                  Not Participating
                </p>
              </div>
            }
          </div>
        </div>

        <div className="flex flex-col md:flex-row p-2 ">
          <div style={{ flex: 2 }} className="p-4 h-full">
            <img
              className="w-full object-cover h-full rounded-md"
              src={state.bannerUrl}
              alt="Raffle Background"
            />
<p className="font-SatoshiMedium text-textGreyDark text-base mt-2"><b>Note:</b> As the tickets are on-chain, you will need some APT to spend it.</p>
          </div>

          <div className="flex-1 p-2">
            <div className="flex items-center justify-center gap-1 mt-2 pl-2">
              <div className="w-full items-center justify-between flex">
                <p className="font-SatoshiBold text-xl text-black">
                  Participate
                </p>
              </div>
              <img src={vector} className="w-4 h-4 object-contain ml-4" alt="Vector" />
              <div className="items-center justify-between flex">
                <p className="text-black">{state.totalParticipants}</p>
              </div>
              <div className="font-SatoshiMedium items-center justify-between flex">
                <p className="text-black font-SatoshiMedium ">Participants</p>
              </div>
            </div>

            <p className="mt-4 text-left text-black text-xs font-SatoshiMedium">
              Enter Count
            </p>

            <input
              style={{
                fontFamily: "SatoshiMedium",
              }}
              type="number"
              min={1}
              onChange={(e)=>setTicketCount(e.target.value)}
              placeholder="Enter Ticket Count"
              className="mt-2 text-black px-2 py-2 rounded-md focus:outline-none stroke-borderGrey border-2 min-h-8 w-full"
            />

            <div className="flex items-center mt-4 justify-between">
              <p className="ml-2 font-SatoshiMedium text-textGreyDark text-sm">
                You have
              </p>
              <div className="flex items-center px-2.5 py-1.5 bg-greyVeryLight rounded-full">
                <img
                  className="size-6 object-contain mr-2"
                  src={assets.blueTicket}
                  alt="Blue Ticket"
                />
                <p className="text-textGreyDark font-SatoshiMedium text-base">
                  {tickets ?? 0} Ticket
                </p>
              </div>
            </div>

            <div onClick={()=>{

              if(parseInt(tickets) < parseInt(ticketCount)) return
              spendTickets()
            }} className={clsx("py-3  rounded-lg flex  mt-4", parseInt(tickets) >= parseInt(ticketCount) ? "button bg-bgBlue cursor-pointer" : "bg-borderGrey")}>
              <p className={clsx("text-center text-white text-base font-SatoshiBold m-auto")}>
                Spend Tickets
              </p>
            </div>
            <p
            onClick={()=>{
              handleClose()
              handleOpen()
            }}
              style={{ color: "#2071EE" }}
              className="text-center text-sm font-SatoshiMedium mx-auto mt-3.5 underline cursor-pointer"
            >
              Buy Tickets ?
            </p>
          </div>
        </div>
      </div>
    </Modal>
  );
}
