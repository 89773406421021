import Modal from "@material-ui/core/Modal";
import copy from "../../../assets/copy.svg";
import profilePic from "../../../assets/profilePic.png";
import groupProfile from "../../../assets/groupProfile.svg";
import referralPoster from "../../../assets/referralPoster.png";
import referralIcon from "../../../assets/referralIcon.svg";
import yellowDiamond from "../../../assets/yellow_diamond.svg";
import xLogo from "../../../assets/xLogo.png";
import { useState } from "react";
import { useWallet } from "@aptos-labs/wallet-adapter-react";
import { UserContext } from "../../../contexts/UserContent";
import { useContext } from "react";
import { toast, ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import "../../../utils/toasts.css";
import { injectStyle } from "react-toastify/dist/inject-style";

injectStyle();

export default function ReferralModal({
  open,
  handleClose,
  handleOpenTrackReferral,
}) {
  const { account, connected } = useWallet();

  const { user, setUser } = useContext(UserContext);

  return (
    <Modal
      onClose={handleClose}
      open={open}
      style={{
        backgroundColor: "transparent",
        margin: "auto",
        color: "white",
        className: "NFTModal",
      }}
      className="h-[500px] w-[800px] max-[1024px]:w-[95%] max-[1024px]:h-[550px] position"
    >
      <div className="w-full h-full bg-white p-5 rounded-lg !outline-none ">
       <div className="w-full max-[1024px]:h-[410px] h-[410px] overflow-y-scroll">
        <div className="flex items-center justify-between">
          <div className="flex items-center">
            <img alt="" className="h-8 w-8 object-contain" src={referralIcon} />
            <p className="text-xl font-SatoshiBold ml-2 text-black text-center">
              Referral
            </p>
          </div>
   
        </div>

        <div className="flex p-2 max-[1024px]:flex-col">
          <div style={{ flex: 2 }} className="p-4">
            <img
              alt=""
              className="w-full object-contain"
              src={referralPoster}
            />
            <p className="font-SatoshiMedium text-base text-textGreyDark text-left mt-7">
            Invite your friends to the campaign and you will both get 2500 Defy coins.
            </p>
            <p className="font-SatoshiMedium text-base text-textGreyDark text-left">
            Track your referrals and convert them as successful referral. 
            </p>
            <p className="font-SatoshiMedium text-base text-textGreyDark text-left">
              Note: A referral is only successful when both the referee and the referrer earns atleast 20k Defy coins.
            </p>
          </div>

          <div className="flex-1 p-2">
            <div className="flex items-center justify-between">
              <div>
                <p className="font-SatoshiMedium text-base text-left text-black">
                  Defy Referrals
                </p>
                <p className="font-SatoshiMedium text-sm text-left text-textGreyDark mt">
                  You'll Get
                </p>
              </div>

              <div className="flex items-center py-2 px-3 bg-greyVeryLight rounded-full">
                <p className="text-black font-SatoshiBold text-base">2500</p>
                <img alt="" className="size-6 ml-2" src={yellowDiamond} />
              </div>
            </div>

            <div className="stroke-borderGrey border rounded py-1.5 px-2 mt-3">
              <p className="font-SatoshiMedium text-sm text-left text-black">
                Referral Code:
              </p>
              <div className="flex items-end justify-between">
                <p className="font-SatoshiMedium text-xs text-left text-textGreyDark mb-1">
                  {user.referral.code.split("").join(" ")}
                </p>
                <div
                  onClick={() => {
                    // Modify the toast here

                    if (connected) {
                      toast("Code Copied Successfully to your Clipboard");
                      navigator.clipboard.writeText(user.referral.code);
                    } else {
                      toast("Connect your wallet");
                    }
                  }}
                  className="flex items-center px-2 py-1 bg-greyVeryLight rounded-full mt-1 cursor-pointer"
                >
                  <p className="text-textGreyDark font-SatoshiMedium text-sm">
                    Copy
                  </p>
                  <img alt="" className="size-4 ml-2" src={copy} />
                </div>
              </div>
            </div>

            {/* <div className="bg-greyVeryLight py-4 px-3 mt-4">
              <p className="font-SatoshiMedium text-sm text-left text-black">
                Share Link to your friends
              </p>
              <div className="items-center mt-6 flex">
                <img alt="" className="size-6" src={xLogo} />
           
                <svg className="mx-3" xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none">
                  <g clip-path="url(#clip0_2697_18726)">
                    <path d="M24 12C24 5.37258 18.6274 0 12 0C5.37258 0 0 5.37258 0 12C0 18.6274 5.37258 24 12 24C18.6274 24 24 18.6274 24 12Z" fill="#419FD9" />
                    <path d="M24 12C24 5.37258 18.6274 0 12 0C5.37258 0 0 5.37258 0 12C0 18.6274 5.37258 24 12 24C18.6274 24 24 18.6274 24 12Z" fill="url(#paint0_linear_2697_18726)" />
                    <path d="M5.3937 11.7354C8.88335 10.1831 11.2103 9.15973 12.3746 8.66523C15.6989 7.25348 16.3897 7.00823 16.84 7.00008C16.939 6.99838 17.1604 7.02343 17.3038 7.14223C17.4249 7.24258 17.4582 7.37813 17.4742 7.47323C17.4901 7.56838 17.51 7.78508 17.4942 7.95438C17.314 9.88698 16.5346 14.5769 16.138 16.7415C15.9702 17.6574 15.6398 17.9645 15.3199 17.9945C14.6248 18.0598 14.0969 17.5255 13.4236 17.0748C12.37 16.3697 11.7749 15.9307 10.7522 15.2427C9.57035 14.4475 10.3365 14.0104 11.01 13.2961C11.1863 13.1092 14.2491 10.2647 14.3084 10.0067C14.3158 9.97443 14.3227 9.85413 14.2527 9.79063C14.1827 9.72713 14.0794 9.74883 14.0049 9.76608C13.8992 9.79058 12.2161 10.9264 8.95565 13.1736C8.4779 13.5086 8.0452 13.6717 7.6575 13.6632C7.2301 13.6538 6.40795 13.4165 5.79675 13.2136C5.0471 12.9648 4.45127 12.8333 4.50315 12.4107C4.53017 12.1906 4.82701 11.9655 5.3937 11.7354Z" fill="white" />
                  </g>
                  <defs>
                    <linearGradient id="paint0_linear_2697_18726" x1="12" y1="0" x2="12" y2="23.822" gradientUnits="userSpaceOnUse">
                      <stop stop-color="#2AABEE" />
                      <stop offset="1" stop-color="#229ED9" />
                    </linearGradient>
                    <clipPath id="clip0_2697_18726">
                      <rect width="24" height="24" fill="white" />
                    </clipPath>
                  </defs>
                </svg>
                <div className="w-6 h-6 p-1 bg-black  rounded-[4px]">
                  <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" viewBox="0 0 16 16" fill="none">
                    <path d="M9.41417 6.5855L6.58574 9.41393M7.05715 4.22848L7.52855 3.75707C8.8303 2.45533 10.9409 2.45532 12.2426 3.75707C13.5443 5.05882 13.5443 7.16937 12.2426 8.47112L11.7712 8.94252M4.22872 7.0569L3.75732 7.52831C2.45557 8.83006 2.45557 10.9406 3.75732 12.2424C5.05906 13.5441 7.16961 13.5441 8.47136 12.2424L8.94277 11.7709" stroke="white" stroke-width="1.33333" stroke-linecap="round" stroke-linejoin="round" />
                  </svg>
                </div>
              </div>
            </div> */}

            {/* <div className="flex items-center mt-10">
              <img
                alt=""
                className="h-5 w-5 object-contain"
                src={groupProfile}
              />
              <p className="ml-2 font-SatoshiMedium text-textGreyLight text-sm">
                234 Participants
              </p>
            </div> */}

     
          </div>
        </div>
        </div>
        <div
            onClick={()=>{

              //Modify the toast here
              if(connected){
                toast("Link Copied Successfully to your Clipboard")
                navigator.clipboard.writeText("https://waitlist.avex.id/?referralCode=" + user.referral.code)
              }else{
                toast("Connect your wallet")
              }
            }}
            className="button px-6  py-2.5 rounded-lg bg-bgBlue flex items-center justify-center mt-3 cursor-pointer xl:!w-[15rem] !w-full self-end ml-auto xl:mt-[-5rem]">
              <p className="font-SatoshiMedium text-base text-white">
                Copy Referral Link
              </p>
            </div>

            <div
              onClick={() => {
                // Modify the toast here
                if (connected) {
                  handleOpenTrackReferral();
                  handleClose();
                } else {
                  toast.error("Connect your wallet", {
                    className: "error-toast",
                  });
                }
              }}
              className="px-6 py-2.5 rounded-lg bg-black flex items-center justify-center mt-3 cursor-pointer xl:!w-[15rem] !w-full self-end ml-auto"
            >
              <p className="font-SatoshiMedium text-base text-white">
                <img
                  src={groupProfile}
                  alt="grp profile svg"
                  className="mr-2"
                />
              </p>
              Track Referrals({user.referral.referredWallets.length})
            </div>
          </div>
    </Modal>
  );
}
