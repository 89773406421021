import { assets } from "../../../constants"

export default function NFTCard({ nft, onclick }) {

    const onImageError = (e) => {
        e.target.src = assets.nftUnavailable
      }



    return (
        <div onClick={()=>onclick(nft)} className="xl:min-w-[200px] h-fit rounded-[10px] p-1.5 bg-greyVeryLight  max-w-[200px]   mx-4 mt-5  cursor-pointer min-w-[120px]  max-[1024px]:mx-2 max-[1024px]:!max-w-[45%] max-[1024px]:flex-1">
            <img onError={onImageError} className="w-full h-full rounded-lg" src={nft?.store == "0x109db6377c978b6920fcd05187c75324228a0fc36936bfa7ccef334e00bcf3b3" ? "https://tradeport.mypinata.cloud/ipfs/Qmbc9geseQBpwe8uX2tRHczrbWAchd21tqrtakjPNygqag/1110.png?pinataGatewayToken=sd9Ceh-eJIQ43PRB3JW6QGkHAr8-cxGhhjDF0Agxwd_X7N4_reLPQXZSP_vUethU&img-width=700&img-height=700&img-fit=cover&img-quality=80&img-onerror=redirect&img-fit=pad&img-format=webp" :nft?.image} />
            <p className="font-SatoshiMedium text-sm text-black text-left mt-2.5 truncate">{nft?.name}</p>
            <div className=" flex items-center justify-between">
                <p className="font-SatoshiMedium text-xs text-textGreyDark text-left">Floor Price</p>
                <p className="font-SatoshiMedium text-sm text-black text-right">{nft?.floorPrice} APT</p>
            </div>
        </div>
    )
}