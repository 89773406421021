import { ChestCard, ClaimAvexHandle, ChestModal, ProgressBar } from "./chests";
import { useEffect, useState } from "react";
import yellowDiamond from "../../assets/yellow_diamond.svg";
import bgRay from "../../assets/bgRay.png";
import Navbar from "../navbar/Navbar";
import chestAnim from "../../assets/chestAnim.gif";
import { assets } from "../../constants";
import raffleImage from "../../assets/raffleImage2.png";
import star from "../../assets/star.svg";
import nftRaffleImage2 from "../../assets/nftRaffleImage2.png"
import { TaskModal, MCQModal, Task, EmailModal } from "./tasks";
import { ReferralModal, ReferralEnterModal, WhiteListPopUp, ChestMessagePopup } from "../common/modals";
import axios from "axios";
import CONST from "../../constants/constants";
import AvexCanvas from "./3D/AvexCanvas";
import { useAuth } from "../../providers/AuthProvider";
import { useWallet } from "@aptos-labs/wallet-adapter-react";
import { useSearchParams } from "react-router-dom";
import { twitterOauthCallback } from "../../services/oauth/oauth";
import ChooseNFTModal from "./nftspin/ChooseNFTModal";
import NFTSpinModal from "./nftspin/NFTSpinModal";
import { UserContext } from "../../contexts/UserContent";
import { useContext } from "react";
import { Swiper as SwiperComponent, SwiperSlide } from "swiper/react";
import "swiper/css";
import "swiper/css/navigation";
import "swiper/css/pagination";
import "swiper/css/scrollbar";
import { Navigation } from "swiper/modules";
import TrackReferrals from "../TrackReferrals";
import dices from "../../assets/dices.png";
import { injectStyle } from "react-toastify/dist/inject-style";
import { Autoplay, Pagination } from "swiper/modules";
import { useNavigate } from "react-router-dom";
import { toast } from "react-toastify";


injectStyle();


export default function Quest() {
  const { user, setUser } = useContext(UserContext);
  const navigate = useNavigate();

  function getUser() {
    //console.log("Getting User");
    const jwt = localStorage.getItem("AVEX_DEFY_JWT_STORE");
    const config = {
      headers: {
        Authorization: jwt,
      },
    };
    if (jwt) {
      axios.get(`${CONST.baseUrl}/users`, config).then((response) => {
        setUser(response.data.data);
      });
    }
  }

  const [searchParams, setSearchParams] = useSearchParams();

  const [category, setCategory] = useState(0);
  const [chests, setChests] = useState(CONST.defaultChest);
  const [openWhiteList, setOpenWhiteList] = useState(false);

  const { isAuthenticated } = useAuth();

  const { account, connected } = useWallet();

  const [state, setState] = useState({
    task: {
      title: "",
      description: "",
      boost: {},
      reward: {
        nonFungibleAssets: [{}]
      },
      mcqQuestions: [
        {
          question: "",
          answers: [""],
        },
      ],
      faq: [{}],
    },
    chest: {
      reward: {
        nft: {},
        nonFungibleAssets: [{}]
      },
      subtexts: [],
      chestType: {},
    },
  });

  const [openChestMessage, setOpenChestMessage] = useState(false);

  useEffect(() => {



    if (!isAuthenticated || !connected) return;

    const isNew = localStorage.getItem(account?.address);
    const isNewWhiteList = localStorage.getItem(account?.address + "+boosted");
    const isNewChestPopup = localStorage.getItem(account?.address + "+chestMessage");

    if(isNewChestPopup !== "chestMessageReceived") {
      handleOpenChestMessage();
    }

    if (isNew !== "onboarded") {
      if (user?.referral?.referredBy === undefined || user?.referral?.referredBy == null)
        handleOpenReferralEnter();

    }

    if (isNewWhiteList !== "onboarded") {
      if (user.boost.isBoosted) setOpenWhiteList(true);
    }



    if (searchParams.has("referralCode")) {
      if (user?.referral?.referredBy === undefined || user?.referral?.referredBy == null)
        handleOpenReferralEnter();
      searchParams.delete("referralCode");
      setSearchParams(searchParams);

    }

    if (searchParams.has("state") && searchParams.get("state").length !== 0) {
      //console.log("Sending twitter oauth", searchParams.get("state"));
      twitterOauthCallback(account?.address, searchParams.get("state"))
        .then(() => {
          searchParams.delete("state");
          setSearchParams(searchParams);
          getUser();
        })
        .catch(() => { });
    }
  }, [isAuthenticated, connected, user]);

  const [openTicket, setOpenTicket] = useState(false);

  const handleCloseTicket = () => {
    setOpenTicket(false);
  };

  const handleOpenTicket = () => {
    setOpenTicket(true);
  };

  const [openEmail, setOpenEmail] = useState(false);

  const handleCloseEmail = () => {
    setOpenEmail(false);
  };

  const handleOpenEmail = () => {
    setOpenEmail(true);
  };

  const [openClaimHandle, setOpenClaimHandle] = useState(false);

  const handleCloseCLaimHandle = () => {
    setOpenClaimHandle(false);
  };

  const handleOpenClaimHandle = (isReady) => {
    setState((curr) => ({ ...curr, freeChest: isReady }));
    setOpenClaimHandle(true);
  };

  const handleCloseWhiteList = () => {
    localStorage.setItem(account?.address + "+boosted", "onboarded");
    setOpenWhiteList(false);
  };
  const handleCloseReferralEnter = () => {
    setOpenReferralEnter(false);
    localStorage.setItem(account?.address, "onboarded");
  };

  const handleCloseChestMessage = () => {
    localStorage.setItem(account?.address+"+chestMessage", "chestMessageReceived");
    setOpenChestMessage(false);
  }

  const [openReferralEnter, setOpenReferralEnter] = useState(false);


  const handleOpenReferralEnter = () => {
    setOpenReferralEnter(true);
  };

  const handleOpenChestMessage = () => {
    setOpenChestMessage(true);
  }

  const [openTask, setOpenTask] = useState(false);

  const handleCloseTask = () => {
    setOpenTask(false);
  };

  const handleOpenTask = (task, completed) => {
    setState((curr) => ({ ...curr, task: { ...task, completed: completed } }));
    setOpenTask(true);
  };

  const [openMCQTask, setOpenMCQTask] = useState(false);

  const handleCloseMCQTask = () => {
    setOpenMCQTask(false);
  };

  const handleOpenMCQTask = (task) => {
    setOpenMCQTask(true);
  };

  const [openChest, setOpenChest] = useState(false);

  const handleCloseChest = () => {
    setOpenChest(false);
  };

  const pagination = {
    "clickable": true,
    "renderBullet": function (index, className) {
      return '<span class=\"' + className + '\">' + (index + 1) + '</span>';
    }
  }

  const handleOpenChest = (chest) => {
    setState((curr) => ({ ...curr, chest: chest }));
    setOpenChest(true);
    // const jwt = localStorage.getItem("AVEX_DEFY_JWT_STORE");
    // //console.log(jwt,"jwwtt");
    // const config = {
    //   headers: {
    //     Authorization: jwt,
    //   },
    // };

    // if (jwt) {
    //   axios.get(`${CONST.baseUrl}/chest/${id}`, config).then((response) => {
    //     setState((curr) => ({ ...curr, chest: response.data.data }));
    //     setOpenChest(true);
    //   });
    // }
  };

  const [totalCoins, setTotalCoins] = useState(0);

  useEffect(() => {
    if (user.lastFreeChestSequence == null || user.lastFreeChestSequence == 0)
      setTotalCoins(0);
    else setTotalCoins(user.lastFreeChestSequence);
  }, [user]);

  const [openReferral, setOpenReferral] = useState(false);
  const [openTrackReferral, setOpenTrackReferral] = useState(false);

  const handleOpenTrackReferral = (id) => {
    setOpenTrackReferral(true);
  };

  const handleCloseTrackReferral = () => {
    setOpenTrackReferral(false);
  };

  const handleCloseReferral = () => {
    setOpenReferral(false);
  };

  const handleOpenReferral = (id) => {
    setOpenReferral(true);
  };

  const [openChooseNFT, setOpenChooseNFT] = useState(false);

  const handleCloseChooseNFT = () => {
    setOpenChooseNFT(false);
  };

  const handleOpenChooseNFT = () => {
    setOpenChooseNFT(true);
  };

  const [openNFT, setOpenNFT] = useState(false);

  const handleCloseNFT = () => {
    setOpenNFT(false);
  };

  const handleOpenNFT = (nft) => {
    setState((curr) => ({ ...curr, nft: nft }));
    setOpenNFT(true);
  };

  const [currentFreeChestAmt, setCurrentFreeChestAmt] = useState(0);
  const [freeChest, setFreeChest] = useState({});

  function fetchTasks() {
    const jwt = localStorage.getItem("AVEX_DEFY_JWT_STORE");
    const config = {
      headers: {
        Authorization: jwt,
      },
    };


    // if (jwt) {
    axios.get(`${CONST.baseUrl}/tasks`).then((response) => {

      let lis = []

      response.data.data.forEach((ele) => {
        if (new Date(ele.notBeforeAt) <= new Date()) {
          if(!lis.some((obj)=>obj.title == ele.title))
          lis.push(ele)
        }
      })
      // console.log(lis);
      _setTasks(lis);
    });

    axios
      .get(`${CONST.baseUrl}/chest`, config)
      .then((response) => {
        let chests = CONST.defaultChest;

        CONST.defaultChest.forEach((chest, inx) => {
          chests[inx]["slotsRemaining"] = response.data.data.find(
            (obj) => obj.chestName === chests[inx]["chestName"]
          )?.slotsRemaining;
        });





        setChests(chests);

        response.data.data.forEach((ele, inx) => {
          const lastFreeChest = user?.lastFreeChestSequence ?? 0
          if (
            !ele.chestType.isPaid &&
            ele.chestType.sequence == lastFreeChest + 1
          ) {
            setFreeChest(ele);
            setCurrentFreeChestAmt(ele.requiredTotalPointsEarned);
          } else {
          }
        });
      })
      .then(() => { })
      .catch(() => { });
    // }
  }

  useEffect(() => {
    // if (isAuthenticated && user.lastFreeChestSequence !== undefined) {
    fetchTasks();
    // }
  }, [user]);

  const [_tasks, _setTasks] = useState(CONST.defaultTask);
  // console.log(category)

  return (
    <>
      <Navbar />

      <UserContext.Consumer>
        {({ user, setUser }) => (
          <div className="main-body">
            <div className="w-11/12 mx-auto flex items-start max-[1024px]:flex-col-reverse pb-10">
              <div className="w-4/6 max-[1024px]:w-full">
                <div className="max-[1024px]:hidden w-[95vw]">
                  <p className="font-SatoshiBold 2xl:text-5xl text-2xl text-left max-[1024px]:mt-6">
                    Get Defy{" "}
                    <span className="bg-gradient-to-r from-purple to-blue text-transparent bg-clip-text">
                      NFT
                    </span>
                  </p>
                  <p className="font-SatoshiMedium 2xl:text-2xl text-base text-grey text-left mt-3">
                    Earn coins to buy chest and get{" "}
                    <span className="font-SatoshiBold text-black">
                      Defy Insiders NFT
                    </span>{" "}
                    collection and{" "}
                    <span className="font-SatoshiBold text-black">
                      early access{" "}
                    </span>
                    to app.
                  </p>
                  <div className="flex 2xl:px-2 py-1 px-1 2xl:py-1 2xl:items-center mt-8 max-w-[100vw] max-[1500px]:!max-w-[66%] max-[1500px]:overflow-x-scroll">
                    {chests.map((ele, inx) => {
                      if (!ele.chestType.isPaid) return;
                      return (
                        <ChestCard
                          onclick={handleOpenChest}
                          startColour={"64C5B2"}
                          endColour={"2F9B86"}
                          chest={ele}
                        />
                      );
                    })}
                  </div>
                </div>
                <p className="font-SatoshiBold text-2xl text-left my-8">
                  Recommended Task
                </p>
                <div className="flex justify-start align-items max-[1024px]:overflow-x-scroll">
                  <div
                    onClick={() => {
                      setCategory(0);
                    }}
                    className={
                      category == 0 ? "selected-div" : "unselected-div"
                    }
                  >
                    <p className={category == 0 ? "selected" : "unselected"}>
                      All (
                      {
                        _tasks.filter(
                          (each) =>
                            each.isActive &&
                            !user?.tasksCompleted?.some(
                              (object) => object.taskId == each.taskId
                            )
                        ).length
                      }
                      )
                    </p>
                  </div>
                  <div
                    onClick={() => {
                      setCategory(1);
                    }}
                    className={
                      category == 1 ? "selected-div" : "unselected-div"
                    }
                  >
                    <p className={category == 1 ? "selected" : "unselected"}>
                      Social (
                      {
                        _tasks.filter(
                          (each) =>
                            each.tags.includes("social") &&
                            each.isActive &&
                            !user?.tasksCompleted?.some(
                              (object) => object.taskId == each.taskId
                            )
                        ).length
                      }
                      )
                    </p>
                  </div>

                  <div
                    onClick={() => {
                      setCategory(2);
                    }}
                    className={
                      category == 2 ? "selected-div" : "unselected-div"
                    }
                  >
                    <p className={category == 2 ? "selected" : "unselected"}>
                      De-Fi (
                      {
                        _tasks.filter(
                          (each) =>
                            each.tags.includes("defi") &&
                            each.isActive &&
                            !user?.tasksCompleted?.some(
                              (object) => object.taskId == each.taskId
                            )
                        ).length
                      }
                      )
                    </p>
                  </div>

                  <div
                    onClick={() => {
                      setCategory(3);
                    }}
                    className={
                      category == 3 ? "selected-div" : "unselected-div"
                    }
                  >
                    <p className={category == 3 ? "selected" : "unselected"}>
                      Games (
                      {
                        _tasks.filter(
                          (each) =>
                            each.tags?.includes("games") &&
                            each.isActive &&
                            !user?.tasksCompleted?.some(
                              (object) => object.taskId == each.taskId
                            )
                        ).length
                      }
                      )
                    </p>
                  </div>
                  <div
                    onClick={() => {
                      setCategory(4);
                    }}
                    className={
                      category == 4 ? "selected-div" : "unselected-div"
                    }
                  >
                    <p className={category == 4 ? "selected" : "unselected"}>
                      OAT (
                      {
                        _tasks.filter(
                          (each) =>
                            each.tags?.includes("oat") &&
                            each.isActive &&
                            !user?.tasksCompleted?.some(
                              (object) => object.taskId == each.taskId
                            )
                        ).length
                      }
                      )
                    </p>
                  </div>

                  <div
                    onClick={() => {
                      setCategory(5);
                    }}
                    className={
                      category == 5 ? "selected-div" : "unselected-div"
                    }
                  >
                    <p className={category == 5 ? "selected" : "unselected"}>
                      Featured (
                      {
                        _tasks.filter(
                          (each) =>
                            each.tags.includes("featured") &&
                            each.isActive &&
                            !user?.tasksCompleted?.some(
                              (object) => object.taskId == each.taskId
                            )
                        ).length
                      }
                      )
                    </p>
                  </div>

                  <div
                    onClick={() => {
                      setCategory(6);
                    }}
                    className={
                      category == 6 ? "selected-div" : "unselected-div"
                    }
                  >
                    <p
                      className={
                        category == 6 ? "selected" : "unselected !text-green"
                      }
                    >
                      Completed (
                      {
                        _tasks.filter((each) =>
                          user?.tasksCompleted?.some(
                            (object) => object.taskId == each.taskId
                          )
                        ).length
                      }
                      )
                    </p>
                  </div>
                </div>
                <div className="h-[1px] w-full bg-textGreyLight" />

                {((category == 6 &&
                  _tasks.filter((each) =>
                    user?.tasksCompleted?.some(
                      (object) => object.taskId == each.taskId
                    )
                  ).length == 0) ||
                  (category == 0 &&
                    _tasks.filter(
                      (each) =>
                        !user?.tasksCompleted?.some(
                          (object) => object.taskId == each.taskId
                        )
                    ).length == 0) ||
                  (category !== 0 &&
                    category !== 6 &&
                    _tasks.filter(
                      (each) =>
                        each.tags.includes(
                          ["social", "defi", "games", "oat", "featured"][category - 1]
                        ) &&
                        !user?.tasksCompleted?.some(
                          (object) => object.taskId == each.taskId
                        )
                    ).length == 0)) && (
                  <div className="flex items-center justify-center w-full">
                    <p className=" mt-5 font-SatoshiBold self-center">
                      Coming Soon
                    </p>
                  </div>
                )}

                {category == 6
                  ? _tasks
                      .filter((each) =>
                        user?.tasksCompleted?.some(
                          (object) => object.taskId == each.taskId
                        )
                      )
                      .map((ele, inx) => {
                        if (ele.isActive) {
                          return (
                            <Task
                              task={ele}
                              user={user}
                              onclick={handleOpenTask}
                              completed={user?.tasksCompleted?.some(
                                (object) => object.taskId == ele.taskId
                              )}
                              walletConnected={connected}
                            />
                          );
                        }
                      })
                  : category == 0
                  ? _tasks
                      .filter(
                        (each) =>
                          !user?.tasksCompleted?.some(
                            (object) => object.taskId == each.taskId
                          )
                      )
                      // For 2x Rewards with Featured tags at top
                      .sort((a, b) => {
                        const aFeatured = a.tags.includes("featured");
                        const bFeatured = b.tags.includes("featured");
                        return aFeatured === bFeatured ? 0 : aFeatured ? -1 : 1;
                      })
                      .map((ele, inx) => {
                        if (ele.isActive) {
                          return (
                            <Task
                              task={ele}
                              user={user}
                              onclick={handleOpenTask}
                              completed={user?.tasksCompleted?.some(
                                (object) => object.taskId == ele.taskId
                              )}
                              walletConnected={connected}
                            />
                          );
                        }
                      })
                  : _tasks
                      .filter(
                        (each) =>
                          each.tags.includes(
                            ["social", "defi", "games","oat", "featured"][
                              category - 1
                            ]
                          ) &&
                          !user?.tasksCompleted?.some(
                            (object) => object.taskId == each.taskId
                          )
                      )
                      .map((ele, inx) => {
                        if (ele.isActive) {
                          return (
                            <Task
                              task={ele}
                              user={user}
                              onclick={handleOpenTask}
                              completed={user?.tasksCompleted?.some(
                                (object) => object.taskId == ele.taskId
                              )}
                              walletConnected={connected}
                            />
                          );
                        }
                      })}
              </div>

              <div className="w-2/6 flex flex-col items-end justify-center px-8 max-[1024px]:w-full max-[1024px]:flex-col max-[1024px]:px-0">
                <div className="min-[1025px]:hidden w-full">
                  <p className="font-SatoshiBold 2xl:text-5xl text-2xl text-left max-[1024px]:mt-6">
                    Get Defy{" "}
                    <span className="bg-gradient-to-r from-purple to-blue text-transparent bg-clip-text">
                      NFT
                    </span>
                  </p>
                  <p className="font-SatoshiMedium 2xl:text-2xl text-base text-grey text-left mt-3">
                    Earn coins to buy chest and get{" "}
                    <span className="font-SatoshiBold text-black">
                      Defy Insiders NFT
                    </span>{" "}
                    collection and{" "}
                    <span className="font-SatoshiBold text-black">
                      early access{" "}
                    </span>
                    to app.
                  </p>
                  <div className="flex 2xl:px-2 py-1 px-1 2xl:py-1 2xl:items-center mt-8 max-w-[100vw] overflow-x-scroll scrollbar-hide">
                    {chests.map((ele, inx) => {
                      if (!ele.chestType.isPaid) return;
                      return (
                        <ChestCard
                          onclick={handleOpenChest}
                          startColour={"64C5B2"}
                          endColour={"2F9B86"}
                          chest={ele}
                        />
                      );
                    })}
                  </div>
                </div>

                <div className="w-full">
                  <SwiperComponent
                    slidesPerView="1"
                    loop
                    grabCursor={true}
                    spaceBetween={25}
                    autoplay={{
                      delay: 2500,
                      disableOnInteraction: false,
                    }}
                    pagination={{
                      clickable: true,
                    }}
                    modules={[Autoplay, Navigation, Pagination]}
                  >
                    <SwiperSlide data-hash="One">
                      <div className="w-full  rounded-2xl px-5 py-6  mt-3 min-h-[250px] 2xl:min-h-[350px] relative bg-gradient-to-r-orange">
                        <div className="flex items-align">
                          <img className="ml-2" src={star} />
                          <p className="font-SatoshiBold text-2xl text-white text-left ml-3">
                            Coin Flip
                          </p>
                        </div>

                        <div className="flex items-center justify-between ">
                          <p className="font-Satoshi 2xl:text-lg text-sm text-white text-left">
                            Betting odds on Dogs vs
                            <br />
                            Cats
                          </p>
                          <img
                            className="object-contain w-1/3 ml-2"
                            src={assets.coinFlip}
                          />
                        </div>

                        <div className="cursor-pointer">
                          <div
                            onClick={() => navigate("/coin-flip")}
                            // onClick={() => toast("Coming Soon!!")}
                            className="px-6 button py-2.5 rounded-lg bg-white flex  items-center justify-center  absolute bottom-[15px] right-[20px]"
                          >
                            <p className="font-SatoshiMedium text-base text-black">
                              Participate Now
                            </p>
                          </div>
                        </div>
                      </div>
                    </SwiperSlide>
                    <SwiperSlide data-hash="Two">
                      <div className="w-full  rounded-2xl px-5 py-6  mt-3 min-h-[250px] 2xl:min-h-[350px] relative bg-gradient-to-r-gold">
                        <div className="flex items-align">
                          <img className="ml-2" src={star} />
                          <p className="font-SatoshiBold text-2xl text-white text-left ml-3">
                            Fortune Wheel
                          </p>
                        </div>

                        <div className="flex items-center justify-between ">
                          <p className="font-Satoshi 2xl:text-lg -translate-y-5 text-sm text-white text-left">
                            Spin the Wheel and get a
                            <br />
                            chance to win upto 5M $GUI.
                          </p>
                          <img
                            className="object-contain w-1/3 ml-2"
                            src={assets.wheelHome}
                          />
                        </div>

                        <div className="cursor-pointer">
                          <div
                            onClick={() => navigate("/Fortune-Wheel")}
                            className="px-6 button py-2.5 rounded-lg bg-white flex  items-center justify-center  absolute bottom-[15px] right-[20px]"
                          >
                            <p className="font-SatoshiMedium text-base text-black">
                              Participate Now
                            </p>
                          </div>
                        </div>
                      </div>
                    </SwiperSlide>

                    <SwiperSlide data-hash="Three">
                      <div className="w-full  rounded-2xl px-5 py-6  mt-3 min-h-[250px] 2xl:min-h-[350px] relative purple-gradient">
                        <div className="flex items-align">
                          <img className="ml-2" src={star} />
                          <p className="font-SatoshiBold text-2xl text-white text-left ml-3">
                            NFT Spin
                          </p>
                        </div>

                        <div className="flex items-center justify-between ">
                          <p className="font-Satoshi 2xl:text-lg text-sm text-white text-left">
                            Spin and win your favorite NFTs 
                            <br />
                            at 0.1% of the price.
                          </p>
                          <img
                            className="object-contain w-1/3 ml-2"
                            src={raffleImage}
                          />
                        </div>

                        <div className="cursor-pointer">
                          <div
                            onClick={() => {
                              handleOpenChooseNFT();
                            }}
                            className="px-6 button py-2.5 rounded-lg bg-white flex  items-center justify-center  absolute bottom-[15px] right-[20px]"
                          >
                            <p className="font-SatoshiMedium text-base text-black">
                              Participate Now
                            </p>
                          </div>
                        </div>
                      </div>
                    </SwiperSlide>

                    <SwiperSlide data-hash="Four">
                      <div className="w-full  rounded-2xl px-5 py-6  mt-3 min-h-[250px]  2xl:min-h-[350px] relative bg-gradient-to-r-blue">
                        <div className="flex items-align">
                          <img className="ml-2" src={star} />
                          <p className="font-SatoshiBold text-2xl text-white text-left ml-3">
                            Dice Roll
                          </p>
                        </div>

                        <div className="flex items-center justify-between ">
                          <p className="font-Satoshi 2xl:text-lg text-sm text-white text-left">
                            Every roll is a chance to win
                            <br />
                            in Dice Roll.
                          </p>
                          <img
                            className="object-contain w-1/3 ml-2"
                            src={dices}
                          />
                        </div>

                        <div
                          onClick={() => navigate("/Dice-roll")}
                          className="px-6 button py-2.5 rounded-lg bg-white flex  items-center justify-center cursor-pointer absolute bottom-[15px] right-[20px]"
                        >
                          <p className="font-SatoshiMedium text-base text-black">
                            Participate Now
                          </p>
                        </div>
                      </div>
                    </SwiperSlide>
                    <SwiperSlide data-hash="Five">
                      <div className="w-full  rounded-2xl px-5 py-6  mt-3 min-h-[250px] 2xl:min-h-[350px] relative bg-gradient-to-r-purple">
                        <div className="flex items-align">
                          <img className="ml-2" src={star} />
                          <p className="font-SatoshiBold text-2xl text-white text-left ml-3">
                            Plinko
                          </p>
                        </div>

                        <div className="flex items-center justify-between mt-5">
                          <p className="font-Satoshi 2xl:text-lg text-sm text-white text-left ">
                            Every ball will lead to a 
                            <br />
                            fortune
                          </p>
                          <img
                            className="object-contain w-1/3 ml-2"
                            src={assets.plinkoHome}
                          />
                        </div>

                        <div className="cursor-pointer">
                          <div
                            onClick={() => navigate("/Plinko")}
                            // onClick={() => toast("Coming Soon!!")}
                            className="px-6 button py-2.5 rounded-lg bg-white flex  items-center justify-center  absolute bottom-[15px] right-[20px]"
                          >
                            <p className="font-SatoshiMedium text-base text-black">
                              Participate Now
                            </p>
                          </div>
                        </div>
                      </div>
                    </SwiperSlide>
                  </SwiperComponent>
                </div>
                <div
                  className="w-full h-auto rounded-2xl bg-greyVeryLight stroke-borderGrey border-l  px-6 py-4 mt-6"
                  style={{
                    backgroundImage: `url(${bgRay})`,
                    backgroundPosition: "center",
                    backgroundSize: "cover",
                  }}
                >
                  <div>
                    <div className="flex justify-between">
                      <p className="font-SatoshiBold text-xl">Free Rewards</p>
                      <p className="font-SatoshiMedium text-sm text-grey">
                        Remaining
                      </p>
                    </div>
                    <div className="flex justify-between items-end mt-[-4px]">
                      <p className="font-SatoshiMedium text-xs text-grey">
                        Collect Coins to Open Free Chest
                      </p>

                      <div className="flex">
                        <p className="font-SatoshiMedium text-base">
                          {currentFreeChestAmt -
                            user.balance.totalPointsEarned <
                          0
                            ? "Claim Now"
                            : parseInt(
                                currentFreeChestAmt -
                                  user.balance.totalPointsEarned
                              ) || "10,000"}
                        </p>
                        <img className="ml-2" src={yellowDiamond} />
                      </div>
                    </div>
                    <div
                      onClick={() => {
                        if (totalCoins == 0) {
                          handleOpenClaimHandle(
                            user.balance.totalPointsEarned -
                              currentFreeChestAmt >=
                              0
                          );
                        } else if (
                          user.balance.totalPointsEarned -
                            currentFreeChestAmt >=
                          0
                        ) {
                          handleOpenChest(freeChest);
                        }
                      }}
                      className="cursor-pointer"
                    >
                      {totalCoins == 0 ? (
                        <AvexCanvas total={currentFreeChestAmt} />
                      ) : (
                        <img className="w-10/12 m-auto" src={chestAnim} />
                      )}
                    </div>
                    <ProgressBar
                      total={currentFreeChestAmt}
                      current={user.balance.totalPointsEarned}
                    />
                  </div>
                </div>

                <div className="w-full bg-bgBlue rounded-2xl 2xl:py-6 p-4 my-5">
                  <div className="flex items-align justify-between">
                    <div className="flex items-align">
                      <p className="font-SatoshiBold 2xl:text-2xl text-lg text-white text-left">
                        Get 2500
                      </p>
                      <img className="ml-2" src={yellowDiamond} />
                    </div>
                  </div>

                  <div className="flex items-center justify-between mt-2">
                    <p className="font-Satoshi 2xl:text-xl text-base text-white text-left">
                      for every successful
                      <br />
                      referral.
                    </p>

                    <div
                      onClick={handleOpenReferral}
                      className="2xl:px-4 2xl:py-2 px-2.5 py-1.5 rounded-xl bg-black flex  items-center justify-center cursor-pointer button"
                    >
                      <p className="font-SatoshiMedium 2xl:text-lg text-base text-white">
                        Refer now
                      </p>
                    </div>
                  </div>
                </div>
              </div>
            </div>

            <WhiteListPopUp
              open={openWhiteList}
              handleClose={handleCloseWhiteList}
            />
            <TaskModal
              state={state}
              open={openTask}
              handleClose={handleCloseTask}
              openMCQ={handleOpenMCQTask}
              openEmail={handleOpenEmail}
            />

            <ClaimAvexHandle
              open={openClaimHandle}
              handleClose={handleCloseCLaimHandle}
              state={state}
            />
            <MCQModal
              state={state}
              open={openMCQTask}
              handleClose={handleCloseMCQTask}
            />

            <ChestModal
              open={openChest}
              handleClose={handleCloseChest}
              state={state}
            />
            <ReferralModal
              open={openReferral}
              handleClose={handleCloseReferral}
              handleOpenTrackReferral={handleOpenTrackReferral}
            />
            <EmailModal open={openEmail} onClose={handleCloseEmail} />
            <ChooseNFTModal
              open={openChooseNFT}
              handleClose={handleCloseChooseNFT}
              handleOpenNFT={handleOpenNFT}
            />
            <ReferralEnterModal
              open={openReferralEnter}
              handleClose={handleCloseReferralEnter}
              openReferralModal={handleOpenReferral}
            />

            <NFTSpinModal
              state={state}
              open={openNFT}
              handleClose={handleCloseNFT}
            />

            <TrackReferrals
              open={openTrackReferral}
              handleClose={handleCloseTrackReferral}
            />

            <ChestMessagePopup
              open={openChestMessage}
              handleClose={handleCloseChestMessage}
            />
          </div>
        )}
      </UserContext.Consumer>
    </>
  );
}