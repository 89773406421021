import React from "react";
import { useState, useEffect, useContext } from "react";
import axios from "axios";
import Navbar from "../navbar/Navbar";
import clsx from "clsx";
import { assets, CONST } from "../../constants";
import "./coin-animation.css";
import { fetchCoinConfig, handlePlayCoin, fetchDefyRewards, fetchGUIRewards, fetchFOMORewards, fetchAptosRewards } from "../../utils/indexer"
import { useWallet } from "@aptos-labs/wallet-adapter-react";
import { Aptos, AptosConfig, Network } from "@aptos-labs/ts-sdk";
import { UserContext } from "../../contexts/UserContent";
import { toast } from "react-toastify";
import { sign } from "@aptos-labs/ts-sdk";

const aptosConfig = new AptosConfig({
  network: Network.MAINNET,
});
const aptos = new Aptos(aptosConfig);

const CoinFlip = () => {
  const { account, connected, signAndSubmitTransaction } = useWallet();
  const { user, setUser } = useContext(UserContext);
  const [coinConfig, setCoinConfig] = useState({
    active: false
})

const supportedCoins = [
  {
      "coin_address" : "0xe4ccb6d39136469f376242c31b34d10515c8eaaa38092f804db8e08a8f53c5b2::assets_v1::EchoCoin002",
      "decimals" : 6
  },
{
      "coin_address" : "0xdcfa079344261bfde45e7f6281df091743b8d3098bf9e26e1c0212fc5b070621::zaaptos_token::ZaaptosCoin",
      "decimals" : 8
  }
]

const supportedAptosCoin = [
  {
      "coin_address" : "0x1::aptos_coin::AptosCoin",
      "decimals" : 8
  },
{
      "coin_address" : "0x1::aptos_coin::AptosCoin",
      "decimals" : 8
  }
]


  const [totalCost, setTotalCost] = useState(0);
  const [isFlipped, setIsFlipped] = useState(false);
  const [flipResult, setFlipResult] = useState("");
  const [isTossing, setIsTossing] = useState(false);
  const [coinImageFront, setCoinImageFront] = useState(assets.guiIcon);
  const [coinImageBack, setCoinImageBack] = useState(assets.zaap);
  const [side, setSide] = useState(0);
  const [isDisabled, setIsDisabled] = useState(false)
  const [divider, setDivider] = useState(supportedCoins[0].decimals)
  const [coinType, setCoinType] = useState("meme")

  const minAptos = 0.1;
  const maxAptos = 10;
  const minZaap = 10000;
  const maxZaap = 100000;
  const minGui = 100000;
  const maxGui = 1000000;



  const events = [
    {
      status: "lost",
      title: "You Lost 🫤",
      base: "GUI",
      img: assets.coin,
      points: "500",
      pointimg: assets.yellowDiamond,
    },
    {
      status: "win",
      title: "You Won 🥳",
      base: "GUI",
      img: assets.coin,
      points: "250000",
      pointimg: assets.guiIcon1,
    },
    {
      status: "win",
      title: "You Won ",
      base: "GUI",
      img: assets.coin,
      points: "250000",
      pointimg: assets.guiIcon1,
    },
    {
      status: "win",
      title: "You Won 🥳",
      base: "GUI",
      img: assets.coin,
      points: "250000",
      pointimg: assets.guiIcon1,
    },
  ];

  const coin = [
    {
      image: assets.coin,
      title: "GUI",
      points: 5000,
    },
    {
      image: assets.coin,
      title: "GUI",
      points: 2500,
    },
    {
      image: assets.coin,
      title: "GUI",
      points: 5000,
    },
    {
      image: assets.coin,
      title: "GUI",
      points: 5000,
    },
    {
      image: assets.coin,
      title: "GUI",
      points: 5000,
    },
    {
      image: assets.coin,
      title: "GUI",
      points: 5000,
    },
    {
      image: assets.coin,
      title: "GUI",
      points: 5000,
    },
    {
      image: assets.coin,
      title: "GUI",
      points: 5000,
    },
  ];

  const multiplier = [
    {
      title: "GUI - The Dog",
      title2:"Heads",
      probability: "probability: 1/2",
      value: "2x",
    },
    {
      title: "ZAAP - The Cat",
      title2:"Tails",
      probability: "probability: 1/2",
      value: "2x",
    },
    {
      title: "House Edge",
      title2: "House Edge",
      probability: "",
      value: "10%",
    },
  ];

  const Chances = ["x1", "x2", "x3", "x4", "x5", "x6", "x7", "x8"];
  const [selectedChance, setSelectedChance] = useState(Chances[0]);
  const [playerRewards, setPlayerRewards] = useState([{
    amount_won: "0",
                bet_amount: "0",
                bet_multiplier_denominator: "0",
                bet_multiplier_numerator: "0",
                defy_coins_won: "0",
                heads_coin: "0",
                is_winner: "0",
                outcome_side: "0",
                player: "0",
                selected_side: "0",
                tales_coin: "0"
  }])
  const [historyRewards, setHistoryRewards] = useState([])


  useEffect(() => {
    fetchCoinConfig(coinType)
    .then((res) => {
        setCoinConfig(res)
        // console.log(res)
    }).catch(() => { })

  }, [connected, coinType])

// console.log(coinConfig)
const [finalChance, setFinalChance] = useState(1);

function getUser() {
  const jwt = localStorage.getItem("AVEX_DEFY_JWT_STORE");
  const config = {
      headers: {
          Authorization: jwt,
      },
  };
  if (jwt) {
      axios.get(`${CONST.baseUrl}/users`, config).then((response) => {
          setUser(response.data.data);
      });
  }
}

  const displayedCoins = coin.slice(
    0,
    parseInt(selectedChance.replace("x", ""), 10)
  );

  const handleChanceChange = (e) => {
    setIsDisabled(false)
    const newSelectedChance = e.target.value;
    // console.log(newSelectedChance);
    setSelectedChance(newSelectedChance);
    const numericChance = parseInt(newSelectedChance.replace("x", ""), 10);
    const cost = numericChance * inputValue;
    setFinalChance(numericChance)
    setTotalCost(finalChance*inputValue);
  };

  const handleFirstTokenSelect = () => {
    setIsDisabled(false);
    setSide(0);
    if(coinType==="meme") {
      setCoinImageFront(assets.guiIcon);
      setCoinImageBack(assets.zaap);
      setInputValue(minGui)
      setDivider(supportedCoins[0].decimals)
    } else {
      setCoinImageFront(assets.aptosHeads);
      setCoinImageBack(assets.aptosTails);
      setInputValue(minAptos)
      setDivider(supportedAptosCoin[0].decimals)
    }
  }
  const handleSecondTokenSelect = () => {
    setIsDisabled(false);
    setSide(1);
    if(coinType==="meme") {
      setCoinImageFront(assets.zaap);
      setCoinImageBack(assets.guiIcon);
      setInputValue(minZaap)
      setDivider(supportedCoins[1].decimals)
    } else {
      setCoinImageFront(assets.aptosTails);
      setCoinImageBack(assets.aptosHeads);
      setInputValue(minAptos)
      setDivider(supportedAptosCoin[0].decimals)
    }
  }

  // const getInitialMinBet = () => {
  //   let minBet;
  //   if (side === 0) {
  //     minBet = coinConfig.min_bet_amount_heads;
  //   } else {
  //     minBet = coinConfig.min_bet_amount_tails;
  //   }
  //   return minBet;
  // };
  // console.log(coinConfig.min_bet_amount_heads);

  
  const [inputValue, setInputValue] = useState(100000);
  
  const handleInputChange = (event) => {
    setIsDisabled(false)
    setInputValue(event.target.value);
  };

  const tossCoin = async () => {
    if (!coinConfig.active) {
      toast("The Game is not On yet")
      return
    }

    if (!connected) {
      toast("Connect your Wallet to proceed")
      return
    }

    // console.log(inputValue*finalChance,coinConfig.min_bet_amount_heads ,side);

    if(coinType==="meme") {
      if(inputValue<minGui&&side===0) {
        toast("Amount cannot be less than "+(minGui).toLocaleString()+" $GUI per flip")
        return
      }
      if(inputValue<minZaap&&side===1) {
        toast("Amount cannot be less than "+(minZaap).toLocaleString()+" ZAAP per flip")
        return
      }
      if(inputValue>maxGui&&side===0) {
        toast("Amount cannot be more than "+(maxGui).toLocaleString()+" $GUI per flip")
        return
      }
      if(inputValue>maxZaap&&side===1) {
        toast("Amount cannot be more than "+(maxZaap).toLocaleString()+" ZAAP per flip")
        return
      }
    } else {
      if(inputValue<minAptos) {
        toast("Amount cannot be less than "+(minAptos).toLocaleString()+" $GUI per flip")
        return
      }
      if(inputValue>maxAptos) {
        toast("Amount cannot be more than "+(maxAptos).toLocaleString()+" ZAAP per flip")
        return
      }
    }

    
    try{
      const numericChance = parseInt(selectedChance.replace("x", ""), 10);
      const amount = inputValue*(10**divider)
      const response = await handlePlayCoin(coinType, side, amount, numericChance, signAndSubmitTransaction);
      // await new Promise(resolve => setTimeout(resolve, 1000));
      if(response.success===true) {
        setIsDisabled(true)
        setPlayerRewards(response.data);
      
        // setPlayerRewards(response.data);
      // toast(response[0].is_winner?"You Won":"You lost")
        setTimeout(() => {
          setHistoryRewards(prev => [...prev,...response.data])
          }, 4000); 
        
        setTimeout(() => {
          setIsTossing(true);
          setIsFlipped(true);
          setTimeout(() => {
          // const newFrontImage = playerRewards[0].outcome_side==="0"? assets.coin : assets.fomo;
          // const newBackImage = playerRewards[0].outcome_side==="0"? assets.fomo : assets.coin;
          
          //   setCoinImageFront(newFrontImage);
          //   setCoinImageBack(newBackImage);
            setIsFlipped(false);
            setIsTossing(false);
          }, 2500);
        });
    } else {
      setIsDisabled(false)
    }
      
    }
    catch(error) {
      console.error("Error during coin toss:", error);
    }
  };

  const [guiTotal, setGuiTotal] = useState(0)
  const [fomoTotal, setFomoTotal] = useState(0)
  const [defyTotal, setDefyTotal] = useState(0)
  const [aptosTotal, setAptosTotal] = useState(0)

  const handleClaim = async () => {
    const moduleAddress = process.env.REACT_APP_MODULE_ADDR;

    const payload = {
      data: {
        function: `${moduleAddress}::coin_flip::claim`,
        typeArguments: [
          supportedCoins[0].coin_address,
          supportedCoins[1].coin_address,
          supportedAptosCoin[0].coin_address,
          "0x1::string::String",
          "0x1::string::String",
        ],
        functionArguments: [
          3
        ],
        "type": "entry_function_payload"
      },
    };
    try {
      const response = await signAndSubmitTransaction(payload);
      await aptos.waitForTransaction({ transactionHash: response.hash });
      toast("Successfully claimed");
      
    } catch (error) {
      console.error(error);
    }
  };

  useEffect(()=>{
    fetchGUIRewards(account?.address)
    .then((res) => {
      setTimeout(()=>{setGuiTotal(res.rewards_balance.value/10**supportedCoins[0].decimals)},3900);
      // setUserRewards(prevRewards => [...prevRewards, res])
      // console.log(res);
      })
      .catch((error) => {
        console.log("error");
      });
  }, [connected, isTossing, user, account])

  useEffect(()=>{
    fetchFOMORewards(account?.address)
    .then((res) => {
      setTimeout(()=>{setFomoTotal(res.rewards_balance.value/10**supportedCoins[1].decimals)},3900);
      // setUserRewards(prevRewards => [...prevRewards, res])
      // console.log(res);
      })
      .catch((error) => {
        console.log("error");
      });
  }, [connected, isTossing, user, account])

  useEffect(()=>{
    fetchDefyRewards(account?.address)
    .then((res) => {
      setTimeout(()=>{setDefyTotal(res.rewards_balance)},3900);
      // setUserRewards(prevRewards => [...prevRewards, res])
      // console.log(res);
      })
      .catch((error) => {
        console.log("error");
      });
  }, [connected, isTossing, user, account])
  
  useEffect(()=>{
    fetchAptosRewards(account?.address)
    .then((res) => {
      setTimeout(()=>{setAptosTotal(res.rewards_balance.value/10**supportedAptosCoin[0].decimals)},3900);
      })
      .catch((error) => {
        console.log("error");
      });
  }, [connected, isTossing, user, account])

  // console.log(defyTotal);
//FOMO 120k GUI 100k

  const handleOnClickPlus = () => {
    if(coinType==="meme") {
      if(inputValue>=maxGui&&side===0) {
        toast("Amount cannot be more than "+(maxGui).toLocaleString()+" $GUI per flip")
        return
      }
      if(inputValue>=maxZaap&&side===1) {
        toast("Amount cannot be more than "+(maxZaap).toLocaleString()+" ZAAP per flip")
        return
      }
      setIsDisabled(false)
      setInputValue((prevValue) => {
        const currentValue = parseFloat(prevValue);
        const validValue = isNaN(currentValue) ? 0 : currentValue;
        const newValue = validValue + (side===0?100000:10000);
        const roundedValue = Math.round(newValue * 10) / 10;
        return roundedValue;
      });
    } else {
      if(inputValue>=maxAptos) {
        toast("Amount cannot be more than "+(maxAptos).toLocaleString()+" APT per flip")
        return
      }
      setIsDisabled(false)
      setInputValue((prevValue) => {
        const currentValue = parseFloat(prevValue);
        const validValue = isNaN(currentValue) ? 0 : currentValue;
        const newValue = validValue + 0.1;
        const roundedValue = Math.round(newValue * 10) / 10;
        return roundedValue;
      });
    }

  };
  // console.log(coinConfig.max_bet_amount_heads)

  const handleOnClickMinus = () => {
    if(coinType==="meme") {
      if(inputValue<=minGui&&side===0) {
        toast("Amount cannot be less than "+(minGui).toLocaleString()+" $GUI per flip")
        return
      }
      if(inputValue<=minZaap&&side===1) {
        toast("Amount cannot be less than "+(minZaap).toLocaleString()+" ZAAP per flip")
        return
      }
      setIsDisabled(false)
      setInputValue((prevValue) => {
        const currentValue = parseFloat(prevValue);
        const validValue = isNaN(currentValue) ? 0 : currentValue;
        const newValue = validValue - (side===0?100000:10000);
        const roundedValue = Math.round(newValue * 10) / 10;
        return (newValue < 0 ? validValue : roundedValue);
      });
    } else {
      if(inputValue<=minAptos) {
        toast("Amount cannot be less than "+(minAptos).toLocaleString()+" APT per flip")
        return
      }
      setIsDisabled(false)
      setInputValue((prevValue) => {
        const currentValue = parseFloat(prevValue);
        const validValue = isNaN(currentValue) ? 0 : currentValue;
        const newValue = validValue - 0.1;
        const roundedValue = Math.round(newValue * 10) / 10;
        return (newValue < 0 ? validValue : roundedValue);
      });
    }
  };

  const handleFlip = (inx) => {
    setTimeout(() => {
      setIsTossing(true);
      setIsFlipped(true);
      setTimeout(() => {
      // const newFrontImage = playerRewards[0].outcome_side==="0"? assets.coin : assets.fomo;
      // const newBackImage = playerRewards[0].outcome_side==="0"? assets.fomo : assets.coin;
      
      //   setCoinImageFront(newFrontImage);
      //   setCoinImageBack(newBackImage);
        setIsFlipped(false);
        setIsTossing(false);
      }, 2500);
    });
    
  }

  const [won, setWon] = useState(0)

  useEffect(() => {
    let coin1Count = 0;
    let coin2Count = 0;

    for (let i = 0; i < playerRewards.length; i++) {
      if (playerRewards[i].outcome_side == "0" ) coin1Count++;
      else coin2Count++;
    }
    
    if(coin1Count>=coin2Count && side=="0") setWon(1);
    else if(coin2Count>=coin1Count && side!="0") setWon(1);
    else setWon(0)

  }, [playerRewards]);

  const [volumeData, setVolumeData] = useState({ 
    value1: {
      gui_usd_volume:0,
      gui_volume: 0
    }, 
    value2: {
      zapp_usd_volume: 0,
      zapp_volume: 0
    } 
  });
  let value1Percentage = 50
  let value2Percentage = 50

  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await axios.get('https://games-backend-bgdzekh0b4g0chah.westindia-01.azurewebsites.net/coin-flip-volume'); // Replace with your API endpoint
        const gui = response.data.data.gui;
        const zaap = response.data.data.zaap;
        setVolumeData({ value1: gui, value2: zaap });
      } catch (error) {
        console.error('Error fetching data:', error);
      }
    };
    fetchData();

    const interval = setInterval(fetchData, 5000);
    return () => clearInterval(interval);
  }, [connected]);

  const total = volumeData.value1.gui_usd_volume + volumeData.value2.zapp_usd_volume;

  if (total > 0) {
    value1Percentage = ((volumeData.value1.gui_usd_volume / total) * 100).toLocaleString();
    value2Percentage = ((volumeData.value2.zapp_usd_volume / total) * 100).toLocaleString();
  }

  const handleMemeCoinSelect = () => {
    setSide(0)
    setIsDisabled(false)
    setCoinImageFront(assets.guiIcon)
    setCoinImageBack(assets.zaap)
    setCoinType("meme")
    setInputValue(minGui)
    setDivider(supportedCoins[0].decimals)
  }

  const handleAptosCoinSelect = () => {
    setSide(0)
    setIsDisabled(false)
    setCoinImageFront(assets.aptosHeads)
    setCoinImageBack(assets.aptosTails)
    setCoinType("aptos")
    setInputValue(minAptos)
    setDivider(supportedAptosCoin[0].decimals)
  }

  return (
    <>
      <Navbar />
      <div className="pt-[110px]">
        <div className="flex item-start w-full lg:pt-5 xl:items-start px-20 pb-10 mt-[-2.99vh] max-[1024px]:flex-col max-[1024px]:px-4">
          {/*left content*/}
          <div
            style={{ flex: 1 }}
            className=" max-[1024px]:flex max-[1024px]:flex-col"
          >
            <p className="font-SatoshiMedium 2xl:text-5xl text-3xl text-left mt-4">
                            Coin
                            <span className="bg-gradient-to-r from-purple to-blue text-transparent bg-clip-text">
                                {" "}
                                Flip
                            </span>
                        </p>
            <p className="sm:w-[290px] 2xl:text-lg 2xl:font-normal text-sm mt-4 font-medium font-SatoshiMedium text-textGreyDark text-left max-[1024px]:hidden">
            Coin Flip is a thrilling game where players test their luck with a simple coin toss. Inspired by tradition, players customize bets on heads or tails, blending risk and reward. Each flip builds anticipation as players aim to double their wager. Whether new or seasoned, Coin Flip offers straightforward excitement for all.
            </p>
            {/*Note section*/}
            <div className="mt-6 flex flex-col w-[295px] justify-between p-1 max-[1024px]:items-center max-[1024px]:w-full">
              {/* <p className="text-base 2xl:text-lg font-bold font-SatoshiMedium text-black text-left">
                Rewards Earned
              </p> */}
              {/* h-[45px] */}
              <div className="flex flex-row gap-2 rounded-md px-2 items-center mt-3 2xl:w-[310px] w-full">
                <p
                  readonly
                  type="number"
                  className=" px-1 py-1 rounded-md focus:outline-none s min-h-9 w-full text-textGreyDark font-SatoshiMedium flex flex-row gap-2"
                >
                  Rewards Earned
                </p>
                {/* <img
                  className="h-8 w-8 pb-0.5 rounded-full"
                  src={assets.guiIcon}
                /> */}
                <button 
                  className="h-8 w-[74px] rounded-md bg-[#1C1C1C] py-1 px-4"
                  onClick={handleClaim}
                >
                  <p className="font-Satoshi text-base font-medium text-white">
                    Claim
                  </p>
                </button>
              </div>
              <div className="mt-2 w-full flex flex-col gap-3 2xl:w-[310px] bg-greyVeryLight rounded-xl p-2.5 overflow-y-scroll hover:cursor-pointer">
                      <div className="w-full min-h-[60px] bg-[#FFFFFF] rounded-xl flex flex-row items-center transform duration-300 ease-in-out hover:scale-95">
                        <div className="h-12 w-12 rounded bg-[#f4f4f6] flex justify-center items-center ml-1.5">
                          <img className="w-10 h-10" src={assets.gui} />
                        </div>
                        <div className="ml-2 flex flex-col gap-1">
                          <p className="font-satoshi font-medium text-xs text-textGreyDark">
                            You Won!
                          </p>
                          <p
                            className={clsx(
                              "font-satoshi font-medium text-xs",
                              guiTotal > 0 ? "text-green" : ""
                            )}
                          >
                            {guiTotal} GUI
                          </p>
                        </div>
                      </div>
                      <div className="w-full min-h-[60px] bg-[#FFFFFF] rounded-xl flex flex-row items-center transform duration-300 ease-in-out hover:scale-95">
                        <div className="h-12 w-12 rounded bg-[#f4f4f6] flex justify-center items-center ml-1.5">
                          <img className="w-10 h-10" src={assets.zaap} />
                        </div>
                        <div className="ml-2 flex flex-col gap-1">
                          <p className="font-satoshi font-medium text-xs text-textGreyDark">
                            You Won!
                          </p>
                          <p
                            className={clsx(
                              "font-satoshi font-medium text-xs",
                              fomoTotal > 0 ? "text-green" : ""
                            )}
                          >
                            {fomoTotal} ZAAP
                          </p>
                        </div>
                      </div>
                      <div className="w-full min-h-[60px] bg-[#FFFFFF] rounded-xl flex flex-row items-center transform duration-300 ease-in-out hover:scale-95">
                        <div className="h-12 w-12 rounded bg-[#f4f4f6] flex justify-center items-center ml-1.5">
                          <img className="w-10 h-10" src={assets.aptosLogo} />
                        </div>
                        <div className="ml-2 flex flex-col gap-1">
                          <p className="font-satoshi font-medium text-xs text-textGreyDark">
                            You Won!
                          </p>
                          <p
                            className={clsx(
                              "font-satoshi font-medium text-xs",
                              aptosTotal > 0 ? "text-green" : ""
                            )}
                          >
                            {aptosTotal} APT
                          </p>
                        </div>
                      </div>
                      <div className="w-full min-h-[60px] bg-[#FFFFFF] rounded-xl flex flex-row items-center transform duration-300 ease-in-out hover:scale-95">
                        <div className="h-12 w-12 rounded bg-[#f4f4f6] flex justify-center items-center ml-1.5">
                          <img className="w-10 h-10" src={assets.defy1} />
                        </div>
                        <div className="ml-2 flex flex-col gap-1">
                          <p className="font-satoshi font-medium text-xs text-textGreyDark">
                            You Won!
                          </p>
                          <p
                            className={clsx(
                              "font-satoshi font-medium text-xs",
                              defyTotal > 0 ? "text-green" : ""
                            )}
                          >
                            {defyTotal} DEFY Coins
                          </p>
                        </div>
                      </div>
              </div>
            </div>
          </div>

          {/*Middle content*/}
          <div
            style={{ flex: 2 }}
            className="md:pr-10 pr-4 max-[1024px]:px-2 md:ml-4 lg:mt-5 "
          >
            {/*  gap-2 my-0 sm:my-7 */}
            <div className=" my-4 md:my-6 md:h-[400px] justify-center content-center ">
            <span className={`flex flex-row justify-center items-center text-lg ${!isTossing&&isDisabled?(won?"text-green":"text-red"):""}`}>{!isTossing&&isDisabled?coinType==="meme"?side?"The Cats":"The Dogs":"You ":""} {!isTossing&&isDisabled?(won?"Won 🎉":"Lose 😢"):""}</span>

              {/* <div className={`grid grid-cols-${getGridColumns()}`}> */}
              {!isDisabled ? (
                <div className={`flex gap-2 my-4 flex-wrap justify-evenly`}>
                {displayedCoins?.map((item, index) => (
                  <div
                    key={index}
                    className="flex flex-col sm:min-w-[150px] min-w-[100px] justify-center items-center gap-2 my-0 sm:my-7"
                  >
                    <div
                      className={`coin ${isFlipped ? "flip" : ""} hover:cursor-pointer`}
                      id="coin"
                      onClick={handleFlip}
                    >
                      <div className="side front">
                        {coinImageFront && (
                          <img src={coinImageFront} alt="front" />
                        )}
                      </div>
                      <div className="side back">
                        {coinImageBack && (
                          <img src={coinImageBack} alt="back" />
                        )}
                      </div>
                    </div>
                      <div className="">
                        <p
                          className={clsx(
                            "font-Satoshi font-bold max-[490px]:text-sm text-center whitespace-nowrap",
                            // item.points > 2500 ? "text-green" : "text-red"
                          )}
                        >
                          {inputValue} <span>{coinType==="aptos"?"APT":side===0?"GUI":"ZAAP"}</span>
                        </p>
                      </div>
                  </div>
                ))}
              </div>
              ):(
              <div className={`flex gap-2 flex-wrap justify-evenly`}>
                {playerRewards?.map((item, index) => (
                  <div
                    key={index}
                    className="flex flex-col w-[150px] justify-center items-center gap-2 my-0 sm:my-7"
                  >
                    <div
                      className={`coin ${isFlipped ? "flip" : ""}`}
                      id="coin"
                      onClick={handleFlip}
                    >
                      <div className="side front" >
                        {coinImageFront && (
                          // <img src={coinImageFront} alt="GUI" />
                          <img src={isTossing?coinImageFront:(item.heads_coin===supportedCoins[0].coin_address?(item.outcome_side==="0"?assets.guiIcon:assets.zaap):(item.outcome_side==="0"?assets.aptosHeads:assets.aptosTails))} alt="GUI" />
                        )}
                      </div>
                      <div className="side back">
                        {coinImageBack && (
                          <img src={isTossing?coinImageBack:(item.heads_coin===supportedCoins[0].coin_address?(item.outcome_side==="0"?assets.zaap:assets.guiIcon):(item.outcome_side==="0"?assets.aptosTails:assets.aptosHeads))} alt="FOMO" />
                        )}
                      </div>
                    </div>
                    {!isTossing && (
                      <div className="">
                        <p className="font-semibold text-center font-Satoshi max-[490px]:text-sm">
                          {coinType==="meme"?(item.outcome_side==="0"?"GUI":"ZAAP"):item.outcome_side==="0"?"Heads":"Tails"}
                        </p>
                        <p
                          className={clsx(
                            "font-Satoshi font-bold max-[490px]:text-sm text-center whitespace-nowrap",
                            item.is_winner ? "text-green" : "text-red"
                          )}
                        >
                          {item.amount_won} <span>{coinType==="meme"?(item.selected_side==="0"?"GUI":"ZAAP"):"APT"}</span>
                        </p>
                      </div>
                    )}
                  </div>
                ))}
              </div>
              )}
            </div>

            <div className="items-end justify-end pr-0.5 flex gap-4">
              <button 
                className={`flex justify-center items-center rounded-lg px-4 py-2 duration-150 transition ease-in-out shadow-sm ${coinType==="meme"&&!isTossing ? "bg-[#4A87F2] text-white hover:bg-[#3a6fcc]" : "bg-gray-200 text-black hover:bg-gray-300"}`}
                onClick={handleMemeCoinSelect}
                disabled={isTossing}
              >
                Meme Coins
              </button>
              <button 
                className={`flex justify-center items-center rounded-lg px-4 py-2 duration-150 transition ease-in-out shadow-sm ${coinType==="aptos"&&!isTossing ? "bg-[#4A87F2] text-white hover:bg-[#3a6fcc]" : "bg-gray-200 text-black hover:bg-gray-300"}`}
                onClick={handleAptosCoinSelect}
                disabled={isTossing}
              >
                Aptos
              </button>
            </div>

            <div>
              <div className="  flex flex-col items-center max-[490px]:items-start gap-6 rounded-xl mt-3 p-0.5 border-[1px] border-solid shadow-[0_1px_20px_-10px_rgba(0,0,0,0.3)]">
                <div className="mt-[10px] flex flex-row justify-between px-1 w-full max-[490px]:flex-col">
                  <div className="w-1/2 flex flex-col items-start px-3">
                    <p className="text-left mb-1  text-sm font-SatoshiMedium">
                      Sides
                    </p>
                    <div className="w-full flex flex-row items-center md:gap-3 max-[490px]:gap-20 mr-2 max-[490px]:mb-3 duration-100 transform">
                      <button
                        className={`flex flex-row items-center gap-2 px-2 min-w-[100px] transition-all duration-100 rounded-[12px] ${side ? "text-textGreyDark font-Satoshi" : "border-2 h-[45px] border-neutral-300 "}`}
                        onClick={handleFirstTokenSelect}
                        disabled={isTossing}
                      >
                        {coinType==="meme"
                          ?
                          <>
                            <img src={assets.guiIcon} className="w-[35px] h-[35px]" />
                            <p>GUI</p>                        
                          </>
                          :
                          <>
                            <img src={assets.aptosHeads} className="w-[35px] h-[35px]" />
                            <p>Heads</p>                        
                          </>
                        }
                      </button>
                      <button
                        className={`flex flex-row items-center gap-2 px-2 min-w-[100px] transition-all duration-100 rounded-[12px] ${!side ? "text-textGreyDark font-Satoshi" : "border-2 h-[45px] border-neutral-300 "}`}
                        onClick={handleSecondTokenSelect}
                        disabled={isTossing}
                      >
                        {coinType==="meme"
                          ?
                          <>
                            <img src={assets.zaap} className="w-[35px] h-[35px]" />
                            <p className="">ZAAP</p>                       
                          </>
                          :
                          <>
                            <img src={assets.aptosTails} className="w-[35px] h-[35px]" />
                            <p>Tails</p>                        
                          </>
                        }                        
                      </button>
                    </div>
                  </div>
                  <div className="items-center justify-evenly flex flex-row max-[490px]:justify-start ml-2">
                    <div className="flex flex-col items-start min-w-[200px] w-[330px] min-h-[50px]">
                      <p className="text-left  text-sm font-SatoshiMedium">
                        Bid
                      </p>
                      <div className="w-full flex flex-row bg-greyVeryLight rounded-md items-center justify-between h-[45px]">
                        <input
                          type="number" // Use text type to avoid default number input behavior 
                          style={{
                            fontFamily: "SatoshiMedium", 
                          }}
                          required
                          autoFocus
                          min={0}
                          max={10000000000}
                          onChange={handleInputChange}
                          value={inputValue}
                          placeholder={`${"Enter Amount"}`}
                          className="px-2 py-1 rounded-md items-center flex justify-between bg-greyVeryLight focus:outline-none w-full text-textGreyDark"
                          disabled={isTossing}
                        >
                          
                        </input>
                        {
                            coinType==="meme"
                          ?
                            <img
                              className="h-8 w-8 mr-2 rounded-full"
                              src={side?assets.zaap:assets.guiIcon}
                            />
                          :
                            <img
                              className="h-8 w-8 mr-2 rounded-full"
                              src={assets.aptosLogo}
                            />
                        }
                        
                        <div className="flex items-center mr-1">
                          <button
                              className={`px-2 hover:bg-neutral-200 rounded-full`}
                              onClick={handleOnClickPlus}
                              disabled={isTossing}
                          >
                          +
                          </button>
                          <div className="w-[0.5px] h-6 bg-gray-500 mx-1"></div>
                          <button
                              className={`px-2 hover:bg-neutral-200 rounded-full`}
                              onClick={handleOnClickMinus}
                              disabled={isTossing}
                          >
                          -
                          </button>
                      </div>                          
                      </div>
                    </div>
                    <div className="min-h-[50px] w-[81px] flex flex-col items-start mx-2 md:ml-8">
                      <p className="font-SatoshiMedium text-sm">Chances</p>
                      <select
                        className="h-[45px] w-[81px] rounded-md pl-4 text-left bg-greyVeryLight text-textGreyDark focus:outline-none"
                        style={{ fontFamily: "SatoshiMedium" }}
                        value={selectedChance}
                        onChange={handleChanceChange}
                        disabled={isTossing}
                      >
                        {Chances.map((item) => (
                          <option key={item} value={item}>
                            {item}
                          </option>
                        ))}
                      </select>
                    </div>
                  </div>
                </div>

                <div className="flex flex-row gap-3 w-full mb-3 lg:mt-3">
                  <div className=" w-max flex flex-col items-start ml-2">
                    <p className="pl-2 text-sm font-SatoshiMedium">
                      Total Cost
                    </p>
                    <div className="flex  rounded-md items-center mr-3">
                      <p
                        readonly
                        type="number"
                        className="mt-1 px-2 py-1 rounded-md focus:outline-none s  min-h-9 w-full text-textGreyDark font-SatoshiMedium"
                      >
                        {coinType==="meme"?(inputValue*finalChance):parseFloat(inputValue*finalChance).toFixed(1)}
                      </p>
                      {
                            coinType==="meme"
                          ?
                            <img
                              className="h-8 w-8 mr-2 rounded-full"
                              src={side?assets.zaap:assets.guiIcon}
                            />
                          :
                            <img
                              className="h-8 w-8 mr-2 rounded-full"
                              src={assets.aptosLogo}
                            />
                        }
                    </div>
                  </div>

                    <button
                      className={`flex-1 h-[45px] button flex py-2 ${isTossing?"bg-neutral-400":"bg-bgBlue"} rounded-lg cursor-pointer mx-3 mt-3 spin-now-button `}
                      id="toss-button"
                      onClick={tossCoin}
                      disabled={isTossing}
                    >
                      <p className="text-center items-center mx-auto text-white text-base font-SatoshiBold my-auto">
                        Flip Now
                      </p>
                  </button>
                </div>
              </div>
            </div>
          </div>

          {/*Last content*/}
          <div className="w-full" style={{ flex: 1 }}>
            <div>
              <div className="h-[52px] rounded-t-lg bg-greyVeryLight flex flex-row items-center justify-between p-2 mt-3 ">
                  <div className="flex flex-row items-center">
                    <img className="w-5 h-5" src={assets.diceIcon} />
                    <p className="pl-1 font-Satoshi ">Volume</p>
                  </div>
                  {/* <div className="text-right text-sm text-textGreyDark cursor-pointer font-Satoshi rounded-full h-[28px] px-2 bg-[#cfd2d8] flex items-center justify-center">
                    {parseFloat(volumeData.value1.gui_usd_volume + volumeData.value2.zapp_usd_volume).toFixed(2)}$
                  </div> */}
                  <div className="h-[28px] border-[#4A87F2] border border-solid flex items-center justify-center rounded-full px-4 py-1 bg-[#d2e1fc]">
                      <p className="text-sm text-[#4A87F2] font-Satoshi">
                      $ {parseFloat(volumeData.value1.gui_usd_volume + volumeData.value2.zapp_usd_volume).toFixed(2)}
                      </p>
                  </div>
                </div>
                <div className="w-full border border-solid flex flex-col px-1 py-[6px]">
                    <div className="w-full max-w-xl mx-auto p-2">

                      <div className="flex items-center gap-1  text-sm font-Satoshi ">
                        <div
                          className="bg-green h-8 rounded-lg justify-center items-center flex text-white"
                          style={{ width: `${value1Percentage}%` }}
                        >
                          {parseFloat(value1Percentage).toFixed(2)}%
                        </div>
                        <div
                          className="bg-yellow-500 h-8 rounded-lg flex justify-center items-center text-white"
                          style={{ width: `${value2Percentage}%` }}
                        >
                          {parseFloat(value2Percentage).toFixed(2)}%
                        </div>
                      </div>

                      <div className="flex items-center gap-1 mt-2 font-Satoshi text-sm text-textGreyDark">
                          <div
                            className="justify-start items-center flex"
                            style={{ width: `${value1Percentage}%` }}
                          >
                            GUI
                          </div>
                          <div className="flex justify-center items-center  font-bold ">
                            Coin
                          </div>
                          <div
                            className="flex justify-end items-center"
                            style={{ width: `${value2Percentage}%` }}
                          >
                            ZAAP
                          </div>
                      </div>

                      <div className="flex items-center gap-1 mt-2 font-Satoshi text-sm text-textGreyDark">
                          <div
                            className="justify-start items-center flex"
                            style={{ width: `${value1Percentage}%` }}
                          >
                            ${(parseFloat(volumeData.value1.gui_usd_volume).toFixed(2)).toLocaleString()}
                            
                          </div>
                          <div className="flex justify-center items-center font-bold">
                            Vol
                          </div>
                          <div
                            className="flex justify-end items-center"
                            style={{ width: `${value2Percentage}%` }}
                          >
                            ${(parseFloat(volumeData.value2.zapp_usd_volume).toFixed(2)).toLocaleString()}
                          </div>
                      </div>

                      <div className="flex justify-between gap-1 mt-2 font-Satoshi text-sm text-textGreyDark">
                          <div
                            className="justify-start items-center flex"
                            style={{ width: `${value1Percentage}%` }}
                          >
                            {(volumeData.value1.gui_volume).toLocaleString()}
                            <img src={assets.guiIcon} alt="GUI" className="h-5 w-5 ml-1"/>
                          </div>
                          <div
                            className="flex justify-end items-center"
                            style={{ width: `${value2Percentage}%` }}
                          >
                            {(volumeData.value2.zapp_volume).toLocaleString()}
                            <img src={assets.zaap} alt="GUI" className="h-5 w-5 ml-1"/>
                          </div>
                      </div>

                      

                    </div>
                </div>

              <div className="h-[52px] rounded-t-lg bg-greyVeryLight flex flex-row items-center justify-between p-2 mt-3 ">
                <div className="flex flex-row items-center">
                  <img className="w-5 h-5" src={assets.coinToss} />
                  <p className="pl-1 font-Satoshi ">Chances</p>
                </div>
                <p className="text-right text-sm text-textGreyDark cursor-pointer font-Satoshi rounded-full h-[28px] w-[90px] bg-[#cfd2d8] flex items-center justify-center">
                  X Multiplier
                </p>
              </div>
              <div className="w-full border border-solid flex flex-col px-1 py-[6px]">
                {multiplier.map((item) => (
                  <div className="w-full h-[48px] py-[6px] flex flex-row items-center justify-between px-3">
                    <div>
                      <p className="font-Satoshi text-sm text-textGreyDark">
                        {coinType==="meme"?item.title:item.title2}
                      </p>
                      <p className="font-Satoshi text-xs text-textGreyLight">
                        {item.probability}
                      </p>
                    </div>
                    <div className="w-14 h-[28px] border-[#2071EE] border border-solid flex items-center justify-center rounded-[10px] px-4 py-1 bg-[#D2E3FC]">
                      <p className="text-sm text-[#2071EE] font-Satoshi">
                        {item.value}
                      </p>
                    </div>
                  </div>
                ))}
              </div>
            </div>
            <p className="text-base 2xl:text-lg font-bold font-SatoshiMedium text-black max-[1024px]:hidden text-left py-2 ">
              Session Activity
            </p>
            <div className=" h-[400px] flex flex-col gap-3  bg-greyVeryLight rounded-xl p-2.5 overflow-y-scroll">
              {historyRewards.length!==0?historyRewards.slice().reverse().map((item, inx) => (
                <div key={inx} className="w-full h-[60px] bg-[#FFFFFF] rounded-xl flex flex-row items-center justify-between p-2  hover:scale-95 transform duration-300">
                  <div className="flex flex-row">
                    <div className=" w-11 h-11 rounded bg-[#f4f4f6] flex justify-center items-center ">
                      <img className="w-fit h-fit" src={item.heads_coin===supportedCoins[0].coin_address?(item.outcome_side==="0"?assets.guiIcon:assets.zaap):(item.outcome_side==="0"?assets.aptosHeads:assets.aptosTails)} />
                    </div>
                    <div className="ml-2 flex flex-col gap-1">
                      <p
                        className={clsx(
                          "font-satoshi font-medium text-sm",
                          item.is_winner ? "text-green" : "text-red"
                        )}
                      >
                        {item.is_winner?"You Won 🥳":"You Lost 🫤"}
                      </p>
                      <p className="font-satoshi font-medium text-sm text-[#121212]">
                      {/* {coinType==="meme"?(item.outcome_side==="0"?"GUI":"ZAAP"):item.outcome_side==="0"?"Heads":"Tails"} */}
                      {item.heads_coin===supportedCoins[0].coin_address?(item.outcome_side==="0"?"GUI":"ZAAP"):item.outcome_side==="0"?"Heads":"Tails"}
                        {/* {item.outcome_side==="0"?"GUI":"ZAAP"} */}
                      </p>
                    </div>
                  </div>
                  <div className="flex flex-row items-center justify-center gap-0.5">
                    <p
                      className={clsx(
                        "font-Inter font-semibold text-sm ",
                        item.status === "win"
                          ? "text-green"
                          : "text-textGreyDark"
                      )}
                    >
                      {item.is_winner?item.amount_won:item.defy_coins_won}
                    </p>
                    <img className="w-10 h-10 ml-2" src={item.is_winner?coinType==="meme"?item.outcome_side==="0"?assets.gui:assets.zaap:assets.aptosLogo:assets.defy1} />
                  </div>
                </div>
              )):
              <p className="font-SatoshiMedium text-md text-textGreyDark mx-auto text-center mt-[70px] max-[1200px]:mt-[40px]">
                <span className="text-[60px]">😢</span><br />
                <br />
                "No more Rewards, play to get more"
              </p>
            }
            </div>
          </div>
          
        </div>
      </div>
    </>
  );
};

export default CoinFlip;
