import React, { memo, useState, useRef, useEffect, useContext } from "react";
import Modal from "@material-ui/core/Modal";
import clsx from "clsx";
import { debounce } from "lodash";
import NFTCard from "./NFTCard";
import SoldOutNftCard from "./SoldOutNftCard";
import meltingFace from "../../../assets/MeltingFace.png";
import aptosCoins from "../../../assets/aptosCoin.png";
import raffleTicket from "../../../assets/raffleTicket.png";
import bettingEffect from "../../../assets/betting-effect.mp3";
import { fetchTickets, fetchNftV1, fetchNftV2, fetchSoldoutNfts } from "../../../utils/indexer";
import { useWallet } from "@aptos-labs/wallet-adapter-react";
import { Aptos, AptosConfig, Network } from "@aptos-labs/ts-sdk";
import { UserContext } from "../../../contexts/UserContent";
import beat from "../../../assets/Atchi thooku beat.mp3";

import hourglass from "../../../assets/hourglass.png";

const aptosConfig = new AptosConfig({
  network: Network.MAINNET,
});
const aptos = new Aptos(aptosConfig);

export default memo(function ChooseNFTModal({
  open,
  handleClose,
  handleOpenNFT,
}) {
  
  const [nfts, setNfts] = useState([]);
  const [nftsV2, setNftsV2] = useState([]);
  const [rewards, setRewards] = useState({});
  const [rewardsFetched, setRewardsFetched] = useState(false);

  const [rewardNft, setRewardNft] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const [rewardNftV2, setRewardNftV2] = useState([]);
  const [showRewardsModal, setShowRewardsModal] = useState(false);
  const [showNoRewardsModal, setShowNoRewardsModal] = useState(false);
  const [activeTab, setActiveTab] = useState("Live");

  const { user, setUser, tickets, setTickets } = useContext(UserContext);
  const { connect, account, signAndSubmitTransaction, connected } = useWallet();
  const [displayText, setDisplayText] = useState(
    "Your Favorite NFT at a fraction of the price"
  );
  
  const [betX, setBetX] = useState(0);
  const swiperRef = useRef(null);
  
  const [sound, setSound] = useState(true);
  const [slides, setSlides] = useState(5);

  const audio = useRef(new Audio());
  const audio2 = useRef(new Audio());

  const [soldOutNfts, setSoldOutNFTS] = useState([])

  const fetchNfts = async () => {
    const moduleAddress = process.env.REACT_APP_MODULE_ADDR;
     let nfts = await fetchNftV1(moduleAddress);
     nfts = nfts.map((ele) => ({ ...ele, version: 1, versionText: "NFT V1" }));
     setNfts(nfts);
     let nftsV2 = await fetchNftV2(moduleAddress);
     nftsV2 = nftsV2.map((ele) => ({
       ...ele,
       version: 2,
       versionText: "NFT V2",
     }));
     setNftsV2(nftsV2);
  };

  const handleSoldoutNfts = async() => {
    const moduleAddress = process.env.REACT_APP_MODULE_ADDR;
    let soldOutNfts = await fetchSoldoutNfts(moduleAddress);
    setSoldOutNFTS(soldOutNfts);
  }

  const getRewards = async () => {
    setIsLoading(true);
    try {
      if (!account?.address) {
        console.error("Account address is null or undefined");
        setRewards({
          apt: 0,
          raffle_ticket: 0,
        });
        setIsLoading(false);
        return;
      }

      const rewardsResource = await aptos.getAccountResource({
        accountAddress: process.env.REACT_APP_RESOURCE_ADDR,
        resourceType: `${process.env.REACT_APP_MODULE_ADDR}::nft_lottery::Rewards`,
      });
      if (rewardsResource) {
        const handle = rewardsResource.rewards.handle;
        const data = {
          key_type: "address",
          key: account.address, // Ensure this is not null
          value_type: `${process.env.REACT_APP_MODULE_ADDR}::nft_lottery::Reward`,
        };

        console.log("Fetching rewards with data:", { handle, data });

        let reward = await aptos.getTableItem({ handle, data });
        console.log("Rewards fetched:", reward);

        setRewards({
          apt: reward?.apt?.value ?? 0,
          raffle_ticket: reward?.raffle_ticket ?? 0,
        });
      } else {
        setRewards({
          apt: 0,
          raffle_ticket: 0,
        });
      }
    } catch (e) {
      console.error("Error fetching rewards:", e);
    }
    setIsLoading(false);
  };

  const debouncedGetRewards = debounce(getRewards, 500);



  useEffect(() => {
    fetchNfts();
    handleSoldoutNfts();
    debouncedGetRewards();
  }, [open]);

  const handleCloseRewardsModal = () => {
    setRewardsFetched(false);
    setShowRewardsModal(false);
  };

  const RewardsModal = ({ open, handleClose, rewards, handlePlay }) => {
    useEffect(() => {
      if (open && !rewardsFetched) {
        debouncedGetRewards();
        setRewardsFetched(true);
      }
    }, [open, rewardsFetched]);
    return (
      <Modal
        open={open}
        onClose={handleClose}
        style={{
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
        }}
      >
        <div
          style={{
            backgroundColor: "white",
            padding: "20px",
            borderRadius: "10px",
            textAlign: "center",
            maxWidth: "400px",
            width: "100%",
          }}
        >
          <h2
            style={{
              fontSize: "20px",
              fontWeight: "bold",
              marginBottom: "20px",
            }}
          >
            Your Pending Rewards
          </h2>
          <div
            style={{
              display: "flex",
              justifyContent: "space-around",
              marginBottom: "20px",
            }}
          >
            <div
              style={{
                textAlign: "center",
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
              }}
            >
              <div
                style={{
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                }}
              >
                <div style={{ margin: "0 20px", textAlign: "center" }}>
                  <div
                    style={{
                      width: "60px",
                      height: "60px",
                      backgroundColor: "#f5f5f5",
                      display: "flex",
                      alignItems: "center",
                      justifyContent: "center",
                      borderRadius: "10px",
                      marginBottom: "10px",
                      marginLeft: "20px",
                    }}
                  >
                    <img
                      className="h-28 w-28"
                      src={raffleTicket}
                      alt="Raffle Ticket"
                      style={{ width: "30px", height: "30px" }}
                    />
                  </div>
                  <p style={{ marginLeft: "10px" }}>Raffle Ticket</p>
                  <p style={{ marginLeft: "10px" }}>x{rewards.raffle_ticket}</p>
                </div>
                <div
                  style={{
                    margin: "0 20px",
                    textAlign: "center",
                  }}
                >
                  <div
                    style={{
                      width: "60px",
                      height: "60px",
                      backgroundColor: "#f5f5f5",
                      display: "flex",
                      alignItems: "center",
                      justifyContent: "center",
                      borderRadius: "10px",
                      marginBottom: "10px",
                      marginLeft: "35px",
                    }}
                  >
                    <img
                      className="h-28 w-28"
                      src={aptosCoins}
                      alt="Aptos Coin"
                      style={{ width: "30px", height: "30px" }}
                    />
                  </div>
                  <p style={{ margin: 0 }}>Aptos Coin</p>
                  <p style={{ margin: 0 }}>
                    {Number(rewards.apt / 100000000 || 0).toFixed(8)} APT
                  </p>
                </div>
              </div>
            </div>
          </div>
          <button
            onClick={handlePlay}
            style={{
              backgroundColor: "#2563eb",
              color: "white",
              padding: "10px 20px",
              borderRadius: "5px",
              border: "none",
              cursor: "pointer",
              fontSize: "16px",
              width: "100%",
            }}
          >
            Claim Rewards
          </button>
        </div>
      </Modal>
    );
  };

  const NoRewardsModal = ({ open, handleClose }) => {
    return (
      <Modal
        open={open}
        onClose={handleClose}
        style={{
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
        }}
      >
        <div
          style={{
            backgroundColor: "white",
            padding: "20px",
            borderRadius: "10px",
            textAlign: "center",
            maxWidth: "400px",
            width: "100%",
          }}
        >
          <h2
            style={{
              fontSize: "20px",
              fontWeight: "bold",
              marginBottom: "20px",
            }}
          >
            No Rewards Available
          </h2>
          <div
            className="flex justify-center items-center"
            style={{ fontSize: "120px", marginBottom: "20px" }}
          >
            <img className="h-28 w-28 " src={meltingFace} alt="melting face" />
          </div>
          <button
            onClick={handleClose}
            style={{
              backgroundColor: "#2563eb",
              color: "white",
              padding: "10px 20px",
              borderRadius: "5px",
              border: "none",
              cursor: "pointer",
              fontSize: "16px",
              width: "100%",
            }}
          >
            Play to Get Rewards
          </button>
        </div>
      </Modal>
    );
  };

  useEffect(() => {
    if (sound) {
      audio.current = new Audio(beat);
      audio2.current = new Audio(bettingEffect);
    } else {
      audio.current = new Audio();
      audio2.current = new Audio();
    }
  }, [sound]);

  useEffect(() => {
    setSlidesPerview();
    window.addEventListener("resize", setSlidesPerview);
    return () => {
      window.removeEventListener("resize", setSlidesPerview);
    };
  }, []);

  const setSlidesPerview = () => {
    setSlides(window.innerWidth <= 720 ? 1 : window.innerWidth > 720 ? 5 : 3);
  };

  const handleClaim = async () => {
    if (rewards.apt === 0 && rewards.raffle_ticket === 0) {
      setShowNoRewardsModal(true);
    } else {
      setShowRewardsModal(true);
    }
  };

  const handlePlay = async () => {
    const moduleAddress = process.env.REACT_APP_MODULE_ADDR;

    const transaction = {
      data: {
        function: `${moduleAddress}::nft_lottery::claim`,
        functionArguments: [],
      },
    };
    try {
      const response = await signAndSubmitTransaction(transaction);
      await aptos.waitForTransaction({ transactionHash: response.hash });
      fetchTickets(account?.address).then((result) => {
        setTickets(result);
      });
      setRewards({ apt: 0, raffle_ticket: 0 }); // Update the state to reflect that rewards have been claimed
      debouncedGetRewards();
    } catch (error) {
      console.error(error);
    }
  };

  return (
    <div>
      <Modal
        onClose={handleClose}
        open={open}
        style={{
          backgroundColor: "transparent",
          height: "80vh",
          margin: "auto",
          outlineWidth: 0,
          outline: "none",
          color: "white",
        }}
        outline="none"
        className="max-[1024px]:w-[95vw] w-[80vw]"
      >
        <div className="w-full h-full py-6 bg-white px-6 max-[767px]:!max-w-[100vw] rounded-lg flex flex-col Modal">
          <div className="flex flex-col sm:flex-row sm:items-center sm:justify-between w-full">
            <div style={{ flex: 3 }}>
              <p className="font-SatoshiBold text-base text-black text-left">
                Choose NFT to Raffle
              </p>
              <p className="font-SatoshiMedium text-sm text-textGreyDark text-left">
                Select NFT to Raffle and win other exciting Prizes
              </p>
            </div>
            <button
              onClick={handleClaim}
              className={clsx(
                "px-6 py-2.5 rounded-lg flex items-center justify-center",
                "bg-black cursor-pointer" // Default black background
              )}
            >
              <p className="font-SatoshiMedium text-base text-white">
                Claim Rewards
              </p>
            </button>
          </div>

          <div className="mt-6 flex items-center justify-between">
            <div className="flex">
              <button
                className={clsx("px-4 py-2 border-rounded rounded-md", {
                  "bg-black text-white": activeTab === "Live",
                  "bg-white text-black": activeTab !== "Live",

                })}
                onClick={() => setActiveTab("Live")}
              >
                Live
              </button>
              {/* <button
                className={clsx("px-4 py-2 border-rounded rounded-md", {
                  "bg-black text-white": activeTab === "Previous",
                  "bg-white text-black": activeTab !== "Previous",
                })}
                onClick={() => setActiveTab("Previous")}
              >
                Previous NFTs
              </button> */}

            </div>
          </div>
          <div
            className="border-t border-gray-400"
            style={{ borderTopWidth: "3px" }}
          ></div>

          {activeTab === "Live" ? (
            (nfts.length === 0 && nftsV2.length == 0) ? (
              <div className="flex flex-col items-center justify-center flex-1 mt-2">

                <img
                  src={hourglass}
                  alt="Hourglass"
                  className="h-12 w-12 mb-4"
                />

                <p className="font-SatoshiMedium text-lg text-gray-600">
                  All NFTs are gone 😢, Spin would be live again soon!
                </p>
              </div>
            ) : (
              <div
                style={{
                  flexWrap: "wrap",
                }}
                className="w-full h-full flex overflow-y-scroll justify-start flex-1 mt-2"
              >
                {[...nfts, ...nftsV2]
                  .sort((b, a) => a.floorPrice - b.floorPrice)
                  .map((ele, inx) => {
                    return (
                      <NFTCard nft={ele} key={inx} onclick={handleOpenNFT} />
                    );
                  })}
                {soldOutNfts && soldOutNfts.map((item, inx) => {
                  return (
                    <SoldOutNftCard nft={item} key={inx} />
                  );
                })}
              </div>
            )
          ) : (
            <div
              style={{
                flexWrap: "wrap",
              }}
              className="w-full h-full flex flex-col overflow-y-scroll flex-1 mt-2"
            >
              <div
                style={{
                  flexWrap: "wrap",
                }}
                className="w-full h-full flex overflow-y-scroll flex-1"
              >
                <NFTCard />
                <NFTCard />
                <NFTCard />
                <NFTCard />
                <NFTCard />
                <NFTCard />
              </div>
            </div>
          )}
        </div>
      </Modal>

      <RewardsModal
        open={showRewardsModal}
        handleClose={handleCloseRewardsModal}
        rewards={rewards}
        rewardNft={rewardNft}
        rewardNftV2={rewardNftV2}
        handlePlay={handlePlay}
      />

      <NoRewardsModal
        open={showNoRewardsModal} // New modal to show when no rewards are available
        handleClose={() => setShowNoRewardsModal(false)}
      />
    </div>
  );
});