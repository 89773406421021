
export default function MarqueeItem({nft, isNft}){

    const isNFT = isNft ?? false

    return(
        isNFT ? 
        
        <div className="relative w-[120px] h-[150px] min-[1800px]:w-[170px] min-[1800px]:h-[200px] overflow-hidden rounded mx-auto">
            <img
                src={nft.store == "0x109db6377c978b6920fcd05187c75324228a0fc36936bfa7ccef334e00bcf3b3" ? "https://tradeport.mypinata.cloud/ipfs/Qmbc9geseQBpwe8uX2tRHczrbWAchd21tqrtakjPNygqag/1110.png?pinataGatewayToken=sd9Ceh-eJIQ43PRB3JW6QGkHAr8-cxGhhjDF0Agxwd_X7N4_reLPQXZSP_vUethU&img-width=700&img-height=700&img-fit=cover&img-quality=80&img-onerror=redirect&img-fit=pad&img-format=webp" :nft.image}
                style={{
                    width: '100%', height: '100%',
                    backgroundPosition: 'center',
                    backgroundSize: 'cover',
                    backgroundRepeat: 'no-repeat',
                    position: 'absolute',
                    filter: 'blur(25px)',
                    zIndex: 0,
                    top: 0,
                    left: 0,
                    transform: 'scale(1)',
                }}>
            </img>
            <div
                className="w-[120px] h-[150px] flex items-center justify-center flex-col absolute top-1/2 left-1/2 transform -translate-x-1/2 -translate-y-1/2 z-2">
                <img className="w-[80px] h-[80px] object-cover rounded" src={nft.image} />
                <p className="text-[10px] text-white text-center font-SatoshiBold mt-1.5" >{nft.name}</p>
                <p className="text-[10px] text-greyVeryLight mt-0.5 text-center font-SatoshiMedium">{nft.versionText}</p>
            </div>
        </div> :

<div className="relative w-[120px] h-[150px] min-[1800px]:w-[170px] min-[1800px]:h-[200px] overflow-hidden rounded self-center mx-auto">
<img
    src={nft.image}
    style={{
        width: '100%', height: '100%',
        backgroundPosition: 'center',
        backgroundSize: 'cover',
        backgroundRepeat: 'no-repeat',
        position: 'absolute',
        zIndex: 0,
        top: 0,
        left: 0,
        transform: 'scale(1)',

    }}>
</img>
</div>

    )
}