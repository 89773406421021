import { MartianWallet } from "@martianwallet/aptos-wallet-adapter";
import { PetraWallet } from "petra-plugin-wallet-adapter";
import { PontemWallet } from "@pontem/wallet-adapter-plugin";
import { RiseWallet } from "@rise-wallet/wallet-adapter";
import { OKXWallet } from "@okwallet/aptos-wallet-adapter";
import { AptosWalletAdapterProvider } from "@aptos-labs/wallet-adapter-react";
import {
  AutoConnectProvider,
  useAutoConnect,
} from "../providers/AutoConnectProvider";
import { AlertProvider, useAlert } from "../providers/AlertProvider";
import { AuthProvider, useAuth } from "../providers/AuthProvider";

const WalletContextProvider = ({ children }) => {
  const { autoConnect } = useAutoConnect();
  const { setErrorAlertMessage } = useAlert();

  const wallets = [
    new PetraWallet(),
    // new PontemWallet(),
    new RiseWallet(),
    new OKXWallet(),
    new MartianWallet(),
  ];

  return (
    <AptosWalletAdapterProvider
      plugins={wallets}
      autoConnect={autoConnect}
      onError={(error) => {
        //console.log("Wallet Adapter Error", error);
        setErrorAlertMessage(error);
      }}
    >
      {children}
    </AptosWalletAdapterProvider>
  );
};

export const AppContext = ({ children }) => {
  return (
    <AutoConnectProvider>
      <AlertProvider>
        <WalletContextProvider>
          <AuthProvider>{children}</AuthProvider>
        </WalletContextProvider>
      </AlertProvider>
    </AutoConnectProvider>
  );
};