import React from "react";
import freechestbg from "../../../assets/freechestbglarge.png";
import freechest from "../../../assets/freechest.png";

function FreeChestRewardsModel({ isOpen, onClose }) {
  if (!isOpen) return null;

  return (
    <div className="fixed top-0 left-0 w-full h-full bg-gray-900 bg-opacity-50 flex items-center justify-center">
      <div className="bg-cover bg-center bg-no-repeat relative w-96 lg:w-[45rem] min-w-[21rem] min-h-[40rem]">
        {/* Background Image */}
        <img
          src={freechestbg}
          alt="Background"
          className="absolute inset-0 w-full h-full object-cover rounded-lg"
        />

        {/* Content of your modal */}
        <div className="absolute inset-0 p-5 flex flex-col justify-center items-center text-center">
          <button
            onClick={onClose}
            className="absolute top-3 right-3 text-3xl text-gray-500 hover:text-gray-700 align-top"
          >
            &times;
          </button>
          <div className="flex flex-col gap-8 items-center">
            <p className="text-3xl font-bold text-white">
              Free Chest Rewards
            </p>
            <img src={freechest} className="h-[160px] my-8" />
            <div className="mt-5 flex flex-col gap-8 items-center">
              <p className="text-3xl font-bold text-white">2x Raffle Ticket</p>
              <button
                className={`w-40 p-[0.15rem] text-white bg-[#2071EE] rounded-lg`}
              >
                Claim
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default FreeChestRewardsModel;
