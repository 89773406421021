import React from 'react';
import Navbar from './navbar/Navbar';
import { assets } from '../constants';

const PageUnavailable = () => {
  return (
    <div className='bg-[#F4F4FC]'>
        <Navbar />
        <div className="flex flex-col items-center justify-center min-h-screen font-SatoshiMedium text-gray-800">
            <div className="mb-2">
              <img 
                src={assets.fof} 
                alt="Not Found Illustration" 
                className="lg:h-[30rem] h-[20rem] w-[30rem]"
              />
            </div>
            <h1 className="lg:text-4xl text-2xl font-bold mb-4">Oops! Page Not Found</h1>
            <p className="lg:text-lg text-sm mb-6">We couldn't find the page you were looking for</p>
            <a href="/" className="px-6 py-3 bg-black text-white rounded-full shadow-lg hover:bg-opacity-80 hover:cursor-pointer transition transform hover:scale-105 ease-in-out duration-200">
                Go Back To Quest
            </a>
        </div>
    </div>
  );
}

export default PageUnavailable;
