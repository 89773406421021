import Modal from "@material-ui/core/Modal";

export default function WhiteListPopUp({ open, handleClose }) {
    

    return (
        <Modal
            onClose={handleClose}
            open={open}
            style={{
                backgroundColor: "transparent",
                margin: "auto",
                color: "white",
            }}
            className="w-[420px] !outline-none max-[1024px]:w-[90vw] h-[420px] !outline-none max-[1024px]:h-[450px]"
        >
            <div className="w-full h-full bg-white p-5 rounded-lg max-[1024px]:w-[90vw]   flex flex-col items-center">
            <p className="text-black font-SatoshiBold text-xl max-[1024px]:text-lg"> Yayyy!🎉  You have been Whitelisted  </p>
            <img className="mt-6" src="/light.png" />
            <p className="text-black font-SatoshiMedium  text-center"> You have been <b>specially invited</b> by Maharsi Patel. This gives you 2x coins on all activities. Enjoy the campaign and thanks for joining! </p>
            <div className="flex gap-3 mt-4">
            <input type="checkbox" className="accent-[#F4F4F6] scale-125" />
            <h2 className="text-[#646D80] font-SatoshiMedium text-base max-[1024px]:text-sm"> Keep this a secret between us and continue </h2>
            </div>
            <img onClick={handleClose} src="/okaybutton.png" className="mt-5 hover:scale-105 transition-all cursor-pointer" />
            </div>

        </Modal>
    )
}