import { useEffect, useState, useRef } from "react";
import axios from "axios";
import Navbar from "../navbar/Navbar";
import { assets, CONST } from "../../constants";
import { useWallet } from "@aptos-labs/wallet-adapter-react";
import { UserContext } from "../../contexts/UserContent";
import { useContext } from "react";
import clsx from "clsx";
import { toast } from "react-toastify";
import Dice from 'react-dice-roll';
import { fetchDiceConfig, handlePlayDiceRoll, fetchDiceRewards } from "../../utils/indexer";
import { Aptos, AptosConfig, Network } from "@aptos-labs/ts-sdk";
import Select from 'react-select'
import Colors from "../../constants/colors";
import DiceRollRewardModal from "./diceRollRewardModal";
import DiceRollHistoryRewardModal from "./diceRollHistoryRewardModal";

const aptosConfig = new AptosConfig({
  network: Network.MAINNET,
});
const aptos = new Aptos(aptosConfig);



export default function DiceRoll() {

    const { account, connected, signAndSubmitTransaction } = useWallet();
    const { user, setUser } = useContext(UserContext);
    // console.log(user.referral.code)
    const referralCode = user.referral.code

    const [diceValues, setDiceValues] = useState({})
    const [selected, setSelected] = useState({})

    const DiceNumbers = [1, 2, 3, 4, 5, 6];
    const [diceConfig, setDiceConfig] = useState({
        active: false
    })
    const divider = 10**6;
    const inputRef = useRef();
    const [inputFocused, setInputFocused] = useState(false);

    const [chance, setChance] = useState(1)

    function getUser() {
        const jwt = localStorage.getItem("AVEX_DEFY_JWT_STORE");
        const config = {
            headers: {
                Authorization: jwt,
            },
        };
        if (jwt) {
            axios.get(`${CONST.baseUrl}/users`, config).then((response) => {
                setUser(response.data.data);
            });
        }
    }

    const [rewards, setRewards] = useState({})

    useEffect(() => {
        fetchDiceConfig().then((res) => {
            setDiceConfig(res)
        }).catch(() => { })

    }, [])

    const [diceRewardsModal, setDiceRewardsModal] = useState(false);
    const [diceHistoryRewardsModal, setDiceHistoryRewardsModal] = useState(false);
    const [amountWonList, setAmountWonList] = useState([]);
    const [previousAmountWon, setPreviousAmountWon] = useState(0);
    const [lastAmountWon, setLastAmountWon] = useState(0);
    const [historyItem,setHistoryItem] = useState();
    const [currentItemIndex, setCurrentItemIndex] = useState();
    const [isDisabled, setIsDisabled] = useState(false);
    const [inputValue, setInputValue] = useState("");
    const [inputDisabled, setInputDisabled] = useState(true);

    useEffect(() => {
        if (inputDisabled === false) {
            let anySelectedTrue = Object.values(selected).some(value => value === true);
            let hasInvalidValue = false;
            let final = true;
            Object.keys(selected).forEach(key => {
                if (selected[key] && (diceValues[key] == "0" || isNaN(parseFloat(diceValues[key])))) {
                    hasInvalidValue = true;
                }
            });
            
            // let final = false
            if(hasInvalidValue===false && anySelectedTrue===true) {final = false} else{ final=true}
            setIsDisabled(final);
        } else {
            let anySelectedTrue = Object.values(selected).some(value => value === true);
            let final = true
            let hasInvalidValue = false
            if(inputValue=="0"||isNaN(inputValue)||inputValue===''||inputValue==='0.') hasInvalidValue = true; 
            if(hasInvalidValue===false && anySelectedTrue===true) {final = false} else{ final=true}
            setIsDisabled(final);
        }
    }, [diceValues, selected, inputDisabled, connected, inputValue]);
    
    
    
    const handleOpenDiceRollRewardModal = () => {
        setTimeout(() => {
            setDiceRewardsModal(true);
        }, 1800);
    }
    
    const handleOpenDiceRollHistoryRewardModal = (item, index) => {
        setDiceHistoryRewardsModal(true);
        setHistoryItem(item);
        setCurrentItemIndex(index)
    }
    const [userRewards,setUserRewards] = useState([1])
    useEffect(() => {
        fetchDiceRewards(account?.address).then((res) => {
            setRewards(res);

            const currentRewardsBalance = res?.rewards_balance?.value;
            const difference = currentRewardsBalance - previousAmountWon;
            setPreviousAmountWon(currentRewardsBalance);
            const newAmountWonList = [...amountWonList, difference].filter(num => !isNaN(num));
            setAmountWonList(newAmountWonList);
            const sum = newAmountWonList.reduce((accumulator, currentValue) => accumulator + currentValue, 0);


            setLastAmountWon(difference);
        }).catch(() => {
            //console.log("avlodha");
        })
    }, [connected, userRewards])

    const Chances = ['1','2','3','4','5','6','7','8','9','10']

    function timeout(delay) {
        return new Promise((res) => setTimeout(res, delay));
      }

    const diceRef = useRef(null);
    const diceRef2 = useRef(null);

    const [total, setTotal] = useState(0)
    
    let globalEventData = { events: [] };

    const handleSetUserRewards = (item) => {
        setUserRewards(prevArray => {
            const newArray = [...prevArray];
            newArray.push(item);
            return newArray;
        })
    }
    let bet_type = (selected['0'] === true || selected['1'] === true) ? 1 : 0;
    let side = (selected['0'] === true) ? true : false;
    let Bet_amount_even_odd = 0;

    
    

    const onRoll = () => {

        if (isDisabled) return;
        if (!diceConfig.active) {
            toast("The Game is not On yet")
            return
        }


        if (!connected) {
            toast("Connect your Wallet to proceed")
            return
        }


        const selectedDice = Object.keys(selected)
            .filter(key => selected[key] === true)
            .map(key => key);

        if (selectedDice.length === 0) {
            toast("Pleae select dice before proceeding")
            return
        }

        let total = 0

        let values = ["0", "0", "0", "0", "0", "0", "0", "0", "0", "0", "0"]
        let trueCount = 0;

        Object.keys(selected).forEach(key => {
            if (selected[key] === true) {
                trueCount++;
            }
        });
        let value = inputValue/trueCount
        
        if(inputDisabled === true ) {
            selectedDice.forEach((ele, inx) => {    
                total = inputValue; //total value in the input box

                values[parseInt(ele) - 2] = parseInt(value * (divider));
                if (selected['0'] === true || selected['1'] === true) {
                    Bet_amount_even_odd = parseInt(value*(divider))
                } else {
                    Bet_amount_even_odd = 0
                    // inputDisabled(false)
                }
            })
        } else {
            selectedDice.forEach((ele, inx) => {
                total += parseFloat(diceValues[ele])
                values[parseInt(ele) - 2] = parseInt(diceValues[ele] * (divider)) 
                        
                if (selected['0'] === true || selected['1'] === true) {
                    Bet_amount_even_odd = parseInt(diceValues[ele]*divider);
                } else {
                    Bet_amount_even_odd = 0;
                }

            })
        }
        
            // inputDisabled === true ? 
                
            //     selectedDice.forEach((ele, inx) => {    
            //         total = inputValue; //total value in the input box

            //         values[parseInt(ele) - 2] = (value * (10 ** 8));
            //     })
            //     : 
            //     ""

        // console.log(total, diceConfig.max_bet_amount / (divider))
        if (total < (diceConfig.min_bet_amount / (divider))) {
            toast("Amount cannot be less than " +(diceConfig.min_bet_amount/divider).toLocaleString()+" $GUI per roll")
            return
        }

        if (total > (diceConfig.max_bet_amount / (divider))) {
            toast("Amount cannot be more than "+(diceConfig.max_bet_amount/divider).toLocaleString()+" $GUI per roll")
            return
        }
        handlePlayDiceRoll(values, bet_type, side, Bet_amount_even_odd, signAndSubmitTransaction, chance).then( (response) => {
            fetchDiceRewards(account?.address)
            getUser()
          
            let amountWon = 0
            
            response.events.map(async (event) => {
                const moduleAddress =
                    process.env.REACT_APP_MODULE_ADDR;

                if (
                    event.type.includes("::dice_roll::PlayEvent") 
                ) { 
                    
                    diceRef.current?.rollDice(event.data.dice_one_value);
                    diceRef2.current?.rollDice(event.data.dice_two_value);
                    await timeout(1500);
                    amountWon += event.data.amountWon;
                    handleSetUserRewards(event.data)              
                }
            })
            
            // toast("You won!!");
        }).then(()=>{
            handleOpenDiceRollRewardModal()
        }).catch(() => {
            toast("Transaction Canceled")
        })
    };

    const [state, setState] = useState({
        totalParticipants: 0,
        bannerUrl: assets.coinRaffleBg,
        reward: "",
        task: {
            title: "",
            description: "",
            boost: {},
            mcqQuestions: [
                {
                    question: "",
                    answers: [""],
                },
            ],
            faq: [{}],
        },
        chest: {
            reward: {},
            chestType: {},
        },
    });

    const [openTicket, setOpenTicket] = useState(false);
    const [activityLog, setActivityLog] = useState([])

    const handleCloseTicket = () => {
        setOpenTicket(false);
    };

    const handleOpenTicket = () => {
        setOpenTicket(true);
    };

    const [show, setShow] = useState([]);

    const [openEnterRaffle, setOpenEnterRaffle] = useState(false);

    const handleCloseEnterRaffle = () => {
        setOpenEnterRaffle(false);
    };

    const handleOpenEnterRaffle = () => {
        setOpenEnterRaffle(true);
    };

    function formatDate(dateString) {
        const options = { day: "numeric", month: "long", year: "numeric" };
        return new Date(dateString).toLocaleDateString("en-GB", options);
    }

    const [winnersTabIndex, setWinnersTabIndex] = useState(0);

    const [timer, setTimer] = useState("0:0:0");

    useEffect(() => {
        const interval = setInterval(() => {
            const now = new Date();
            const distance = new Date(state.expiresAt) - now;

            if (distance < 0) {
                clearInterval(interval);
                setTimer("0:0:0");
                return;
            }

            const days = Math.floor(distance / (1000 * 60 * 60 * 24));
            const hours = Math.floor(
                (distance % (1000 * 60 * 60 * 24)) / (1000 * 60 * 60)
            );
            const minutes = Math.floor((distance % (1000 * 60 * 60)) / (1000 * 60));

            setTimer(`${days}: ${hours}: ${minutes}`);
        }, 1000);

        return () => clearInterval(interval);
    }, [state.notBeforeAt]);

    const getRaffle = () => {
        const jwt = localStorage.getItem("AVEX_DEFY_JWT_STORE");
        const config = {
            headers: {
                Authorization: jwt,
            },
        };

        // if (jwt) {
        axios.get(`${CONST.baseUrl}/raffle`).then((response) => {
            const result = response.data.data;
            let upcoming = [];
            let past = [];
            let active = {};
            result.forEach((ele, inx) => {
                if (ele.isLive) active = ele;
                else if (ele.expiresAt == null) upcoming.push(ele);
                else if (new Date(ele.expiresAt) - new Date() > 0) upcoming.push(ele);
                else if (new Date(ele.expiresAt) - new Date() <= 0) past.push(ele);
            });

            setState((curr) => ({
                ...curr,
                upcoming: upcoming,
                past: past,
                ...active,
                totalParticipants: Object.keys(active['participants'] ?? {})?.length ?? 0,
                totalTickets: active['totalTicketsInvested'],
            }));
            setShow(upcoming);
            setActivityLog(active?.participants[account?.address] ?? [])

            const moduleAddress = process.env.REACT_APP_MODULE_ADDR;

            // getParticipants(active);
        }).catch(() => { });
        // }
    };

    useEffect(() => {
        getRaffle();
    }, [connected, openEnterRaffle]);


    useEffect(() => {

        let total = 0

        Object.keys(selected).map((key) => {
            if (selected[key] == true) {
                total += parseFloat(diceValues[key]) ?? 0
            }
        })
        setTotal(chance*total)
    }, [diceValues,chance])
    
    const handleClaim = async () => {
        const moduleAddress = process.env.REACT_APP_MODULE_ADDR;

    
        const transaction = {
          data: {
            function: `${moduleAddress}::dice_roll::claim`,
            typeArguments: [
                "0xe4ccb6d39136469f376242c31b34d10515c8eaaa38092f804db8e08a8f53c5b2::assets_v1::EchoCoin002",
                "0x1::string::String",
                "0x1::string::String",
                "0x1::string::String",
                "0x1::string::String"
            ],
            functionArguments: [
                1
            ]
          }
        }
        try {
          const response = await signAndSubmitTransaction(transaction);
          await aptos.waitForTransaction({ transactionHash: response.hash });
            toast("Successfully claimed")
            fetchDiceRewards().then(()=>{}).catch(()=>{})

        } catch (error) {
          console.error(error);
          toast("Something went wrong 💀")
        }
      }


      function toggleSelected(i) {
        let total = 0;

        const diceSumCombinations = ['2', '3', '4', '5', '6', '7', '8', '9', '10', '11', '12'];
        if ((i === '0' && selected['1'] === true) || (i === '1' && selected['0'] === true)) {
            toast("You cannot select both odd and even together");
            return;
        }

        if ((i === '0' || i === '1') && Object.keys(selected).some(key => diceSumCombinations.includes(key) && selected[key] === true)) {
            toast("You cannot select both even/odd and dice sum  together");
            return;
        }
    
        if (diceSumCombinations.includes(i) && (selected['0'] === true || selected['1'] === true)) {
            toast("You cannot select both even/odd and dice sum together");
            return;
        }
    
        if (selected[i] === true) {
            setSelected(curr => ({ ...curr, [i]: !curr[i] ?? true }));
        } else {
            Object.keys(selected).forEach((key) => {
                if (selected[key] === true) {
                    total += 1;
                }
            });
    
            if (total >= 4) {
                toast("You can select a maximum of 4 dice combinations");
                return;
            }
    
            setSelected(curr => ({ ...curr, [i]: !curr[i] ?? true }));
        }
    }
    

    const handleSplitEqual = () => {
        // if (inputRef.current) {
        //     inputRef.current.focus();
        // }
        setInputFocused(true);
        setInputDisabled(true);
        // setIsDisabled(!Object.values(selected).some(value => value === true));
    }
    const handleCustomPress = () => {
        setInputDisabled(false)
    }
    
    
    
    
    const handleInputChange = (e) => {
        const value = e.target.value;
        const parsedValue = parseFloat(value);
    
        if (!isNaN(parsedValue) && parsedValue !== 0) {
          setInputValue((parsedValue.toFixed(4)).toString()); 
          setTotal(parsedValue);
        } else {
          setInputValue(""); 
      }; 
    }

    // console.log(selected);
    
    const handleOnClickPlus = () => {
        if(!Object.values(selected).some(value => value === true)) {
            toast("Please choose dice sum first")
        }
        setInputValue((prevValue) => {
          const currentValue = parseFloat(prevValue);
          const validValue = isNaN(currentValue) ? 0 : currentValue;
          const newValue = validValue + 50000;
          const roundedValue = Math.round(newValue * 10) / 10;
          return roundedValue;
        });
      };
    
      const handleOnClickMinus = () => {
        if(!Object.values(selected).some(value => value === true)) {
            toast("Please choose dice sum first")
        }
        setInputValue((prevValue) => {
          const currentValue = parseFloat(prevValue);
          const validValue = isNaN(currentValue) ? 0 : currentValue;
          const newValue = validValue - 50000;
          const roundedValue = Math.round(newValue * 10) / 10;
          return (newValue < 0 ? validValue : roundedValue);
        });
      };

      const handleAmountChange = (e) => {
        if(!Object.values(selected).some(value => value === true)) {
            toast("Please choose dice sum first")
        }
        const value = e.target.value;
        if (!isNaN(value) && value >= 0) {
          setInputValue(value);
        }
      };

      const multipliers = {
        0: 1.5,
        1: 1.5,
        2 : 12,
        3 : 10,
        4 : 8,
        5 : 6,
        6 : 4,
        7 : 2,
        8 : 4,
        9 : 6,
        10 : 8,
        11 : 10,
        12 : 12,
    }

    const findMultiplier = (multiplier) => {
        return multipliers[multiplier];
    }

    return (
        <>
            <Navbar />
            <div className="main-body">
            {diceRewardsModal && (
                <DiceRollRewardModal 
                    isOpen={diceRewardsModal}
                    onClose={()=>setDiceRewardsModal(false)}
                    reward={userRewards}
                    currentReward={userRewards[userRewards.length-1]}
                    // totalAmount={total}
                    chances={chance}
                    divider = {divider}
                    referral = {referralCode}
                />
            )}
            {diceHistoryRewardsModal && (
                <DiceRollHistoryRewardModal 
                    isOpen={diceHistoryRewardsModal}
                    onClose={()=>setDiceHistoryRewardsModal(false)}
                    reward={userRewards}
                    currentReward={lastAmountWon}
                    item={historyItem}
                    currentItemIndex={currentItemIndex}
                    divider={divider}
                />
            )}
                <div className="flex items-start w-full lg:items-center xl:items-start px-20 pb-10 mt-[-2.66vh] max-[1024px]:flex-col max-[1024px]:px-4">
                    <div style={{ flex: 1 }} className="w-full">

                        <p className="font-SatoshiMedium 2xl:text-5xl text-3xl text-left mt-4">
                            Try
                            <span className="bg-gradient-to-r from-purple to-blue text-transparent bg-clip-text">
                                {" "}
                                Dice Roll
                            </span>
                        </p>
                        <p className="font-SatoshiMedium 2xl:text-xl text-sm text-textGreyDark text-left mt-6 max-[1024px]:hidden">
                        Get ready for thrilling excitement and the chance to win big! Two dice will be rolled, and your mission is to predict the sum of the two dice. If your prediction is correct, you'll win big! If not, no worries! you'll still receive Defy Coins. Dive into the action, test your luck with Aptos Roll. Play now and experience the exhilarating thrill of Dice Roll!                        </p>
                        <div className="mt-10 mb-2 font-SatoshiMedium font-bold ">Rewards Earned</div>
                        <div className="flex items-center justify-between border border-textDarkGray rounded-lg py-2 px-3 w- ">
                            <p className="text-left text-textGreyDark text-base font-SatoshiBold">
                                {(rewards?.rewards_balance?.value==undefined || rewards?.rewards_balance?.value == 0 || !connected)  ? "0 GUI" : rewards?.rewards_balance?.value/divider + " GUI" }
                            </p>
                            <div className="flex flex-row items-center">
                                <img className="h-8 w-8 mr-2 rounded-full" src={assets.guiIcon} alt="gui" />
                                <div onClick={() => {
                                    if (rewards?.rewards_balance?.value === 0 || rewards?.rewards_balance?.value === undefined ) return
                                    handleClaim()
                                }} className={clsx(" ml-auto px-6  py-2.5 rounded-lg  flex  items-center justify-center  max-[1200px]:w-full", (rewards?.rewards_balance?.value  == 0 || rewards?.rewards_balance?.value  == undefined || !connected) ? "bg-borderGrey" : "bg-black cursor-pointer")}>
                                    <p className="font-SatoshiMedium text-base text-white">
                                        Claim
                                    </p>
                                </div>
                            </div>
                            
                        </div>
                            {/* max-[1024px]:hidden */}
                        <div className="rounded-xl w-full bg-greyVeryLight py-2.5 px-2 2xl:h-[300px] h-[240px] mt-4 overflow-y-scroll">
                            {userRewards.length === 1 ? 
                                <p className="font-SatoshiMedium text-base text-textGreyDark mx-auto text-center mt-[70px] max-[1200px]:mt-[40px]">
                                    <span className="text-[72px]">😢</span><br />
                                    <br />
                                    No new rewards
                                </p> 
                            : 
                            (
                                <div>
                                    <div className="flex flex-col gap-2 ">
                                        {userRewards.slice(1).reverse().map((item, index) => (
                                            <button key={index} className="flex flex-row bg-white rounded-[12px] relative p-2 transition duration-300 ease-in-out transform hover:scale-90" onClick={() => handleOpenDiceRollHistoryRewardModal(item, index)}>
                                                <div style={{ backgroundColor: Colors[`color${item.sum}`] }} className="rounded-[12px] flex flex-col py-2 px-1 w-[70px] items-center">
                                                    <div className="font-bold text-white text-sm">{item.sum}</div>
                                                    <div className="w-1rem"> <img className="w-full" src={assets[`dice${item.sum}`]} alt="Dice" /></div>
                                                </div>
                                                <div className="flex flex-col items-start">
                                                    <div className="ml-2 text-textGreyDark">
                                                        {item.amount_won === "0" ? "You Lost 🫤" : "You Won 🥳"}
                                                    </div>
                                                    <div className={`flex flex-row font-bold mt-4 `}>
                                                        <p className={`text-base ml-2 ${item.amount_won === "0" ? "text-red" : ""}`}>
                                                            {parseFloat(((item.total_bet_amount/divider)).toFixed(4))}
                                                        </p>
                                                        <p className={`text-base ml-1 ${item.amount_won === "0" ? "text-red" : ""}`}>GUI</p>
                                                        {item.amount_won !== "0" && (
                                                             <span className="flex flex-row justify-center items-center">
                                                                <p className="mx-4"> <img className="w-3 h-2 " src={assets.rightArrow} alt="RightArrow" /> </p>
                                                                <p className={`${item.amount_won === "0" ? "text-red" : "text-green"}`} > {item.amount_won / divider} GUI</p>
                                                            </span>  
                                                        )}

                                                        
                                                                          
                                                        
                                                    </div>
                                                </div>
                                                <div className="absolute right-2 ">
                                                    {item.bet_type === '1' ? 
                                                        (
                                                            <div className={`w-10 border border-[#B4A9F5] bg-[#F7F5FF] py rounded-full `}>
                                                                <p className={`text-center text-xs mx-auto text-[#B4A9F5]`}>
                                                                    1.5x
                                                                </p>
                                                            </div>
                                                        ) : (
                                                            <div className={`w-10 
                                                                        border 
                                                                        ${(item.sum==="12"||item.sum==="2")?"border-[#FFAA05]": (item.sum==="3"||item.sum==="11") ? "border-[#9747FF]" : (item.sum==="9"||item.sum==="5") ? "border-[#00B061]" : (item.sum==="4"||item.sum==="10") ? "border-[#F9379B]" : "border-textBlue" } 
                                                                        bg-opacity-20
                                                                        ${(item.sum==="12"||item.sum==="2")?"gold-gradient": (item.sum==="3"||item.sum==="11") ? "bg-[#9747FF]" : (item.sum==="9"||item.sum==="5") ? "bg-[#00B061]" : (item.sum==="4"||item.sum==="10") ? "bg-[#F9379B]" : "bg-textBlue" } 
                                                                        py rounded-full `}>
                                                                <p className={`
                                                                        text-center 
                                                                        text-xs 
                                                                        mx-auto
                                                                        ${(item.sum==="12"||item.sum==="2")?"text-white": (item.sum==="3"||item.sum==="11") ? "text-[#9747FF]" : (item.sum==="9"||item.sum==="5") ? "text-[#00B061]" : (item.sum==="4"||item.sum==="10") ? "text-[#F9379B]" : "text-textBlue" }
                                                                    `}>
                                                                        {item.bet_multiplier/100}x
                                                                    </p>
                                                            </div>
                                                        )
                                                    }
                                                    
                                                </div>
                                                
                                            </button>
                                        ))}
                                    </div>
                                    
                                </div>
                            )
                                
                            }
                        </div>
                    </div>

                    <div className="px-10 max-[1024px]:px-2" style={{ flex: 2 }}>
                        <div className="w-full 2xl:h-[450px] h-[320px] max-[1024px]:h-[200px] rounded-[20px] mt-8 relative z-0">
                            <img
                                className="flex flex-col items-center justify-center object-cover rounded-[20px] w-full h-full"
                                src={assets.diceRollBg}
                                alt="Background"
                            ></img>

                            <div className="absolute top-1/2 left-1/2 -translate-x-1/2  -translate-y-1/2  flex z-0">
                                <Dice ref={diceRef} size={72} />
                                <div className="w-3"></div>
                                <Dice ref={diceRef2} size={72} />

                            </div>
                        </div>

                        {/* <div className="flex flex-row justify-end gap-4 p-2">
                            <button 
                                className={`flex justify-center items-center rounded-lg px-4 py-2 duration-150 transition ease-in-out shadow-sm ${inputDisabled ? "bg-[#4A87F2] text-white hover:bg-[#3a6fcc]" : "bg-gray-200 text-black hover:bg-gray-300"}`}
                                onClick={handleSplitEqual}
                            >
                                Split Equally
                            </button>
                            <button 
                                className={`flex justify-center items-center rounded-lg px-4 py-2 duration-150 transition ease-in-out shadow-sm ${inputDisabled ? "bg-gray-200 text-black hover:bg-gray-300" : "bg-[#4A87F2] text-white hover:bg-[#3a6fcc]"}`} 
                                onClick={handleCustomPress}
                            >
                                Custom
                            </button>
                        </div> */}


                        <div className="flex justify-between px-6 mt-4 font-SatoshiMedium">
                            <div className="font-semibold">Your Bets</div>
                            <div className="font-semibold">{
                                !isDisabled ? " ":"No Bets Selected"   
                            }</div>
                        </div>
                        <div className="flex w-full flex-wrap lg:justify-evenly justify-center gap-4 lg:mb-8 lg:mt-2 mb-4 mt-4">
                            {Object.keys(selected).map((ele, inx) => {
                                // console.log(ele)
                                if (selected[ele] !== true) return
                                
                                return (
                                    // <div className="rounded-[10px] bg-white custom-shadow flex-1 p-3 mt-3 mx-2 min-w-[200px] md:min-w-[45%]">
                                    //     <p className="font-SatoshiMedium text-xs text-left">Dice Sum {ele==="0" ? "Even" : ele==="1" ? "Odd" : ele}</p>
                                    //     <div className={` min-h-8 mt-2 flex ${!inputDisabled?"bg-greyVeryLight":" bg-neutral-200 text-white"} rounded-md items-center`}>
                                    //         <input
                                    //             style={{
                                    //                 fontFamily: "SatoshiMedium",
                                    //             }}
                                    //             type="number"
                                    //             min={0}
                                    //             onChange={(e) => setDiceValues(curr => ({ ...curr, [ele]: e.target.value }))}
                                    //             // onChange={handleDiceRollValue}
                                    //             value={diceValues[ele] === undefined || diceValues[ele] === '' ? '' : inputDisabled?inputValue/Object.values(selected).filter(value => value === true).length : diceValues[ele]}
                                    //             placeholder={inputDisabled?`${inputValue/Object.values(selected).filter(value => value === true).length}`:"Enter Amount"}
                                    //             className={`remove-arrow text-black px-2 py-2 rounded-md ${!inputDisabled?"bg-greyVeryLight":" bg-neutral-200 "} focus:outline-none s  min-h-7 w-full`}
                                    //             disabled={inputDisabled}
                                    //         />
                                    //         <img className="h-8 w-8 mr-2 rounded-full" src={assets.guiIcon} alt="gui" />

                                    //             <div  className="flex items-center duration-500 ease-in-out transition">
                                    //                 {!inputDisabled ? <>
                                    //                 <button
                                    //                     className={`px-2 ${!inputDisabled?"hover:bg-neutral-200":""} rounded-full`}
                                    //                     onClick={(e) => setDiceValues(curr => {
                                    //                         const currentValue = parseFloat(curr[ele]);
                                    //                         const validValue = isNaN(currentValue) ? 0 : currentValue;
                                    //                         const newValue = validValue + 50000;
                                    //                         const roundedValue = Math.round(newValue * 10) / 10;
                                    //                         return { ...curr, [ele]: roundedValue.toString() };
                                    //                     })}
                                    //                     disabled={inputDisabled}
                                    //                 >
                                    //                     +
                                    //                 </button>
                                    //                 <div className={`w-[0.5px] h-6  mx-1 ${!inputDisabled?"bg-gray-300":"bg-white"}`}></div>
                                    //                 <button
                                    //                     className={`px-2 mr-1 ${!inputDisabled?"hover:bg-neutral-200":""} rounded-full`}
                                    //                     onClick={(e) => setDiceValues(curr => {
                                    //                         const currentValue = parseFloat(curr[ele]);
                                    //                         const validValue = isNaN(currentValue) ? 0 : currentValue;
                                    //                         const newValue = validValue - 50000;
                                    //                         const roundedValue = Math.round(newValue * 10) / 10;
                                    //                         const finalValue = Math.max(0, roundedValue);
                                    //                         return { ...curr, [ele]: finalValue.toString() };
                                    //                     })}
                                    //                     disabled={inputDisabled}
                                    //                 >
                                    //                     -
                                    //                 </button>
                                    //                 </>
                                    //                 :
                                    //                 ""
                                    //                 }

                                    //             </div>                                         
                                    //     </div>
                                    // </div>

                                    <div>
                                        <div
                                            // onClick={() => { toggleSelected('4') }}
                                            className={clsx("sm:w-1/2 md:w-1/3 mt-5")}>
                                            <div className={`rounded-[12px] bg-white p-1 mx-auto cursor-pointer border border-textBlue ${ele==="0"||ele==="1"?"w-[200px] py-2":"w-[100px] h-[84px]"}`} >

                                                <div className="flex items-center justify-evenly px-1 ">
                                                    <p className="text-left font-SatoshiBold text-base">{ele==="0"?"EVEN SUM":ele==="1"?"ODD SUM":ele}</p>
                                                    <div className={`w-10 
                                                                border 
                                                                ${(findMultiplier(ele)===1.5) ? "border-[#B4A9F5]": (findMultiplier(ele)===12)?"border-[#FFAA05]": (findMultiplier(ele)===10) ? "border-[#9747FF]" : (findMultiplier(ele)===6) ? "border-[#00B061]" : (findMultiplier(ele)===8) ? "border-[#F9379B]" : (findMultiplier(ele)===2) ? "border-[#6F6F6F]": "border-textBlue" } 
                                                                bg-opacity-20
                                                                ${(findMultiplier(ele)===1.5) ? "bg-[#B4A9F5]": (findMultiplier(ele)===12)?"gold-gradient": (findMultiplier(ele)===10) ? "bg-[#9747FF]" : (findMultiplier(ele)===6) ? "bg-[#00B061]" : (findMultiplier(ele)===8) ? "bg-[#F9379B]" : (findMultiplier(ele)===2) ? "bg-[#6F6F6F]": "bg-textBlue" } 
                                                                py rounded-full `}>
                                                            <p className={`
                                                                    text-center 
                                                                    text-xs 
                                                                    mx-auto
                                                                    ${(findMultiplier(ele)===1.5) ? "text-[#B4A9F5]": (findMultiplier(ele)===12)?"text-white": (findMultiplier(ele)===10) ? "text-[#9747FF]" : (findMultiplier(ele)===6) ? "text-[#00B061]" : (findMultiplier(ele)===8) ? "text-[#F9379B]" : (findMultiplier(ele)===2) ? "text-[#6F6F6F]": "text-textBlue" } 
                                                                `}>
                                                                    {findMultiplier(ele)}x
                                                                </p>
                                                        </div>
                                                    {/* <div className="w-10 bg-[#FDB2D8] border border-[#F9379B] py rounded-full ml-1">
                                                        <p className="text-center text-xs mx-auto text-[#F9379B]">{findMultiplier(ele)}x</p>
                                                    </div> */}
                                                </div>
                                                    {ele==0 ? "" : ele==1 ? "" : <img className="w-full" src={`${assets[`dice${ele}`]}`} alt="dice4" />}
                                                
                                            </div>
                                        </div>
                                        <div className="w-full items-center justify-center flex mt-2 font-SatoshiMedium text-textGreyLight text-lg">
                                            {(inputValue/Object.values(selected).filter(value => value === true).length).toFixed(2)}
                                        </div>
                                    </div>
                                )
                            })}


                        </div>


                        <div className="items-end flex flex-wrap sm:flex-none sm:flex-nowrap mr-2 sm:mr-0 justify-between">

                            <div className="sm:w-2/5 w-full ml-2">
                                <p className="text-left text-sm font-SatoshiMedium">
                                    {inputDisabled?"Enter ":"Dice Sum"} Amount
                                </p>
                                <div className="flex bg-greyVeryLight rounded-md items-center">
                                    {inputDisabled===true||(selected[0]===true&&selected[1]===true) ? 
                                        <div className="flex items-center justify-end w-full">
                                            <input
                                                ref={inputRef}
                                                style={{
                                                fontFamily: "SatoshiMedium",
                                                }}
                                                className="my-1 px-2 py-1 rounded-md bg-greyVeryLight min-h-9 w-full focus:outline-none text-black transform duration-300"
                                                value={inputValue}
                                                onChange={handleAmountChange}
                                                autoFocus={inputFocused}
                                                onFocus={() => setInputFocused(false)}
                                                placeholder="Enter Amount"
                                                // type="number"
                                            />
                                            <img className="h-8 w-8 mr-2 rounded-full" src={assets.guiIcon} alt="gui" />
                                            <div className="flex items-center mr-1">
                                                <button
                                                    className={`px-2 ${!isDisabled ? "hover:bg-neutral-200" : ""} rounded-full`}
                                                    onClick={handleOnClickPlus}
                                                >
                                                +
                                                </button>
                                                <div className="w-[0.5px] h-6 bg-gray-500 mx-1"></div>
                                                <button
                                                    className={`px-2 ${!isDisabled ? "hover:bg-neutral-200" : ""} rounded-full`}
                                                    onClick={handleOnClickMinus}
                                                >
                                                -
                                                </button>
                                            </div>
                                        </div>
                                        :
                                        <div className="flex items-center justify-end w-full">
                                            <p
                                                readOnly
                                                style={{
                                                    fontFamily: "SatoshiMedium",
                                                }}
                                                type="number"
                                                className="my-1 px-2 py-1 items-center flex rounded-md bg-greyVeryLight focus:outline-none min-h-9 w-full text-black transform duration-300"
                                                >
                                                {!total || isNaN(total)
                                                    ? "0" : isDisabled ? inputValue
                                                    : parseFloat(total.toFixed(4))/chance}

                                            </p>
                                            <img className="h-8 w-8 mr-2 rounded-full" src={assets.guiIcon} alt="gui" />
                                        </div>
                                    }

                                
                                </div>
                            </div>

                            {/* <div className="min-h-[60px] w-fit flex flex-col items-center ml-2"> */}
                            <div className="min-h-[60px] sm:w-fit w-full flex flex-col text-start sm:items-center ml-2 sm:mt-0 mt-2">
                                <p className="font-SatoshiMedium text-sm">Chances</p>
                                <select
                                value={chance}
                                onChange={e => setChance(e.target.value)} 
                                className="h-[45px] sm:w-15 w-full px-2 rounded-md text-start sm:text-center bg-greyVeryLight text-textGreyDark focus:outline-none" style={{fontFamily: "SatoshiMedium"}}>
                                    {Chances.map((item)=>(
                                            <option>{item}</option>
                                    ))}
                                {/* {console.log(chance)} */}
                                </select>
                            </div>
                            
                            <div className="w-full mt-2 sm:mt-0 sm:w-1/2 ml-2 sm:mx-2">
                                <p className="font-SatoshiMedium text-sm">Total Amount: {inputDisabled? parseFloat((inputValue*chance).toFixed(4)):parseFloat(total.toFixed(4))} </p>
                                <div
                                    onClick={() => !isDisabled && onRoll()}
                                    className={`flex-1 button flex h-[45px] rounded-lg cursor-pointer  ${isDisabled ? 'bg-gray-400 cursor-not-allowed' : 'bg-bgBlue'}`}
                                    style={{ pointerEvents: isDisabled ? 'none' : 'auto' }}
                                >
                                    <p className="text-center mx-auto text-white text-base font-SatoshiBold m-auto">
                                        Roll Now
                                    </p>
                                </div>
                            </div>

                        </div>
                    </div>



                    <div className="max-[1024px]:w-full max-[1024px]:mt-4" style={{ flex: 1 }}>
                        
                        <p className="font-SatoshiBold text-base text-black text-left py-2 md:py-0 2xl:text-lg ml-2 ">
                            Select Chances
                        </p>
                        <div className="w-full rounded-[12px] bg-[#F5F5F5] px-3 pb-3 flex items-center justify-center md:items-start md:justify-start flex-wrap">
                            <div className="flex flex-col w-full">
                                <p className="font-SatoshiMedium text-base text-textGreyDark text-left py-1 2xl:text-md px-3 w-full">
                                    Select Odd Even
                                </p>
                                <div className="flex flex-row w-full gap-2 justify-evenly">
                                    <div
                                        onClick={() => { toggleSelected('0') }}
                                        className={clsx(" my-2")}>
                                        <div className={clsx("rounded-lg bg-white py-2 px-1 mx-auto cursor-pointer", selected['0'] && "border border-textBlue")}>
                                            <div className="flex items-center justify-between px-1">
                                                <p className="xl:text-left whitespace-nowrap font-SatoshiLight text-base">Even Sum</p>
                                                <div className="w-10 bg-[#F7F5FF] border border-[#B4A9F5] py rounded-full ml-1">
                                                    <p className="text-center text-xs mx-auto text-[#B4A9F5]">1.5x</p>
                                                </div>
                                            </div>
                                        </div>
                                    </div>

                                    <div
                                        onClick={() => { toggleSelected('1') }}
                                        className={clsx("my-2")}>
                                        <div className={clsx("rounded-lg bg-white py-2 px-1 mx-auto cursor-pointer", selected['1'] && "border border-textBlue")}>

                                            <div className="flex items-center justify-between px-1">
                                                <p className="text-left whitespace-nowrap font-SatoshiLight text-base">Odd Sum</p>
                                                <div className="w-10 bg-[#F7F5FF] border border-[#B4A9F5] py rounded-full ml-1">
                                                    <p className="text-center text-xs mx-auto text-[#B4A9F5]">1.5x</p>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    
                                </div>
                            </div>
                            <div className="flex flex-row w-full items-center justify-center mt-1">
                                <hr className="w-1/3 border-t-1 border-textGreyDark"/>
                                <span className="mx-1 text-textGreyDark text-xs">OR</span>
                                <hr className="w-1/3 border-t-1 border-textGreyDark"/>
                            </div>
                            
                            <div className="flex flex-col w-full">
                                <p className="font-SatoshiMedium text-base px-3 text-textGreyDark text-left 2xl:text-md">
                                    Select Sum
                                </p>
                            </div>
                            

                            <div
                                onClick={() => { toggleSelected('2') }}
                                className={clsx("sm:w-1/2 md:w-1/3 mt-5")}>
                                <div className={clsx("rounded bg-white p-1 w-[70px] h-[64px] mx-auto cursor-pointer", selected['2'] && "border border-textBlue")}>
                                    <div className="flex items-center justify-between px-1">
                                        <p className="text-left font-SatoshiBold text-base">2</p>
                                        <div className="w-10 gold-gradient border border-[#FFAA05] py rounded-full ml-1">
                                            <p className="text-center text-xs mx-auto text-white">12x</p>
                                        </div>
                                    </div>
                                    <img className="w-full" src={assets.dice2} alt="dice2" />

                                    

                                </div>
                            </div>

                            <div
                                onClick={() => { toggleSelected('3') }}
                                className={clsx("sm:w-1/2 md:w-1/3 mt-5")}>
                                <div className={clsx("rounded bg-white p-1 w-[70px] h-[64px] mx-auto cursor-pointer", selected['3'] && "border border-textBlue")}>

                                    <div className="flex items-center justify-between px-1">
                                        <p className="text-left font-SatoshiBold text-base">3</p>
                                        <div className="w-10 bg-[#B4A9F5] border border-[#9747FF] py rounded-full ml-1">
                                            <p className="text-center text-xs mx-auto text-[#9747FF]">10x</p>
                                        </div>
                                    </div>
                                    <img className="w-full" src={assets.dice3} alt="dice3" />

                                </div>
                            </div>

                            <div
                                onClick={() => { toggleSelected('4') }}
                                className={clsx("sm:w-1/2 md:w-1/3 mt-5")}>
                                <div className={clsx("rounded bg-white p-1 w-[70px] h-[64px] mx-auto cursor-pointer", selected['4'] && "border border-textBlue")}>

                                    <div className="flex items-center justify-between px-1 ">
                                        <p className="text-left font-SatoshiBold text-base">4</p>
                                        <div className="w-10 bg-[#FDB2D8] border border-[#F9379B] py rounded-full ml-1">
                                            <p className="text-center text-xs mx-auto text-[#F9379B]">8x</p>
                                        </div>
                                    </div>
                                    <img className="w-full" src={assets.dice4} alt="dice4" />

                                </div>
                            </div>

                            <div
                                onClick={() => { toggleSelected('5') }}
                                className={clsx("sm:w-1/2 md:w-1/3 mt-5")}>
                                <div className={clsx("rounded bg-white p-1 w-[70px] h-[64px] mx-auto cursor-pointer", selected['5'] && "border border-textBlue")}>

                                    <div className="flex items-center justify-between px-1 ">
                                        <p className="text-left font-SatoshiBold text-base">5</p>
                                        <div className="w-10 bg-[#ADE6CC] border border-[#00B061] py rounded-full ml-1">
                                            <p className="text-center text-xs mx-auto text-[#00B061]">6x</p>
                                        </div>
                                    </div>
                                    <img className="w-full" src={assets.dice5} alt="dice5" />

                                </div>
                            </div>

                            <div
                                onClick={() => { toggleSelected('6') }}
                                className={clsx("sm:w-1/2 md:w-1/3 mt-5")}>
                                <div className={clsx("rounded bg-white p-1 w-[70px] h-[64px] mx-auto cursor-pointer", selected['6'] && "border border-textBlue")}>

                                    <div className="flex items-center justify-between px-1 ">
                                        <p className="text-left font-SatoshiBold text-base">6</p>
                                        <div className="w-10 bg-[#D2E3FC] border border-textBlue py rounded-full ml-1">
                                            <p className="text-center text-xs mx-auto text-textBlue">4x</p>
                                        </div>
                                    </div>
                                    <img className="w-full" src={assets.dice6} alt="dice6" />

                                </div>
                            </div>

                            <div
                                onClick={() => { toggleSelected('7') }}
                                className={clsx("sm:w-1/2 md:w-1/3 mt-5")}>
                                <div className={clsx("rounded bg-white p-1 w-[70px] h-[64px] mx-auto cursor-pointer", selected['7'] && "border border-textBlue")}>

                                    <div className="flex items-center justify-between px-1 ">
                                        <p className="text-left font-SatoshiBold text-base">7</p>
                                        <div className="w-10 bg-[#DFDFDF] border border-[#6F6F6F] py rounded-full ml-1">
                                            <p className="text-center text-xs mx-auto text-[#6F6F6F]">2x</p>
                                        </div>
                                    </div>
                                    <img className="w-full" src={assets.dice7} alt="dice7" />

                                </div>
                            </div>


                            <div
                                onClick={() => { toggleSelected('8') }}
                                className={clsx("sm:w-1/2 md:w-1/3 mt-5")}>
                                <div className={clsx("rounded bg-white p-1 w-[70px] h-[64px] mx-auto cursor-pointer", selected['8'] && "border border-textBlue")}>

                                    <div className="flex items-center justify-between px-1 ">
                                        <p className="text-left font-SatoshiBold text-base">8</p>
                                        <div className="w-10 bg-[#D2E3FC] border border-textBlue py rounded-full ml-1">
                                            <p className="text-center text-xs mx-auto text-textBlue">4x</p>
                                        </div>
                                    </div>
                                    <img className="w-full" src={assets.dice8} alt="dice8" />

                                </div>
                            </div>


                            <div
                                onClick={() => { toggleSelected('9') }}
                                className={clsx("sm:w-1/2 md:w-1/3 mt-5")}>
                                <div className={clsx("rounded bg-white p-1 w-[70px] h-[64px] mx-auto cursor-pointer", selected['9'] && "border border-textBlue")}>

                                    <div className="flex items-center justify-between px-1 ">
                                        <p className="text-left font-SatoshiBold text-base">9</p>
                                        <div className="w-10 bg-[#ADE6CC] border border-[#00B061] py rounded-full ml-1">
                                            <p className="text-center text-xs mx-auto text-[#00B061]">6x</p>
                                        </div>
                                    </div>
                                    <img className="w-full" src={assets.dice9} alt="dice9" />

                                </div>
                            </div>

                            <div
                                onClick={() => { toggleSelected('10') }}
                                className={clsx("sm:w-1/2 md:w-1/3 mt-5")}>
                                <div className={clsx("rounded bg-white p-1 w-[70px] h-[64px] mx-auto cursor-pointer", selected['10'] && "border border-textBlue")}>

                                    <div className="flex items-center justify-between px-1 ">
                                        <p className="text-left font-SatoshiBold text-base">10</p>
                                        <div className="w-10 bg-[#FDB2D8] border border-[#F9379B] py rounded-full ml-1">
                                            <p className="text-center text-xs mx-auto text-[#F9379B]">8x</p>
                                        </div>
                                    </div>
                                    <img className="w-full" src={assets.dice10} alt="dice10" />

                                </div>
                            </div>

                            <div
                                onClick={() => { toggleSelected('11') }}
                                className={clsx("sm:w-1/2 md:w-1/3 mt-5")}>
                                <div className={clsx("rounded bg-white p-1 w-[70px] h-[64px] mx-auto cursor-pointer", selected['11'] && "border border-textBlue")}>

                                    <div className="flex items-center justify-between px-1 ">
                                        <p className="text-left font-SatoshiBold text-base">11</p>
                                        <div className="w-10 bg-[#B4A9F5] border border-[#9747FF] py rounded-full ml-1">
                                            <p className="text-center text-xs mx-auto text-[#9747FF]">10x</p>
                                        </div>
                                    </div>
                                    <img className="w-full" src={assets.dice11} alt="dice11" />

                                </div>
                            </div>


                            <div
                                onClick={() => { toggleSelected('12') }}
                                className={clsx("sm:w-1/2 md:w-1/3 mt-5")}>
                                <div className={clsx("rounded bg-white p-1 w-[70px] h-[64px] mx-auto cursor-pointer", selected['12'] && "border border-textBlue")}>

                                    <div className="flex items-center justify-between px-1 ">
                                        <p className="text-left font-SatoshiBold text-base">12</p>
                                        <div className="w-10 gold-gradient border border-[#FFAA05] py rounded-full ml-1">
                                            <p className="text-center text-xs mx-auto text-white">12x</p>
                                        </div>
                                    </div>
                                    <img className="w-full" src={assets.dice12} alt="dice12" />

                                </div>
                            </div>


                        </div>

                        <div className="relative">

                            <div className="w-full rounded-tl-lg rounded-tr-lg bg-greyVeryLight flex items-center justify-between px-2 py-3 mt-2">
                                <div
                                    className="flex items-center justify-center"
                                >
                                    <img className="w-5 h-5 object-contain" src={assets.diceIcon} />
                                    <p className="text-left text-black text-base font-SatoshiMedium ml-2">
                                        Sum of Dice
                                    </p>
                                </div>

                                <div
                                    className="px-2.5 py-1 rounded-[20px] flex items-center justify-center bg-borderGrey"
                                >
                                    <p className="text-left text-textGreyDark text-sm font-SatoshiMedium">
                                        X Multiplier
                                    </p>
                                </div>

                            </div>
                            <div className="w-full px-2 pb-3 border-b border-l border-r stroke-borderGrey">
                                <div className="flex items-center justify-between mt-3">
                                    <div >
                                        <p className="text-left font-SatoshiMedium text-sm text-textGreyDark">
                                            Sum of Even & Odd
                                        </p>
                                        <p className="text-left font-SatoshiMedium text-xs text-textGreyLight">
                                            Probability: 1/2
                                        </p>
                                    </div>
                                    <div className="w-[3.4rem] py-1 rounded-xl border border-[#B4A9F5] bg-[#F7F5FF]">
                                        <p className="font-SatoshiMedium text-center text-sm text-[#B4A9F5]">1.5x</p>
                                    </div>
                                    
                                </div>

                                <div className="flex items-center justify-between mt-3">
                                    <div >
                                        <p className="text-left font-SatoshiMedium text-sm text-textGreyDark">
                                            Dice Sum 2 & 12
                                        </p>
                                        <p className="text-left font-SatoshiMedium text-xs text-textGreyLight">
                                            Probability: 1/36
                                        </p>
                                    </div>
                                    <div className="w-[3.4rem] py-1 rounded-xl border border-[#FFAA05] gold-gradient">
                                        <p className="font-SatoshiMedium text-center text-sm text-white">12x</p>
                                    </div>
                                    
                                </div>

                                <div className="flex items-center justify-between mt-3">
                                    <div>
                                        <p className="text-left font-SatoshiMedium text-sm text-textGreyDark">
                                            Dice Sum 3 & 11
                                        </p>
                                        <p className="text-left font-SatoshiMedium text-xs text-textGreyLight">
                                            Probability: 2/36
                                        </p>
                                    </div>

                                    <div className="w-[3.4rem] py-1 rounded-xl border border-[#9747FF] bg-[#B4A9F5]">
                                        <p className="font-SatoshiMedium text-center text-sm text-[#9747FF]">10x</p>
                                    </div>
                                </div>

                                <div className="flex items-center justify-between mt-3">
                                    <div>
                                        <p className="text-left font-SatoshiMedium text-sm text-textGreyDark">
                                            Dice Sum 4 & 10
                                        </p>
                                        <p className="text-left font-SatoshiMedium text-xs text-textGreyLight">
                                            Probability: 3/36
                                        </p>
                                    </div>

                                    <div className="w-[3.4rem] py-1 rounded-xl border border-[#F9379B] bg-[#FDB2D8]">
                                        <p className="font-SatoshiMedium text-center text-sm text-[#F9379B]">8x</p>
                                    </div>
                                </div>

                                <div className="flex items-center justify-between mt-3">
                                    <div>
                                        <p className="text-left font-SatoshiMedium text-sm text-textGreyDark">
                                            Dice Sum 5 & 9
                                        </p>
                                        <p className="text-left font-SatoshiMedium text-xs text-textGreyLight">
                                            Probability: 4/36
                                        </p>
                                    </div>

                                    <div className="w-[3.4rem] py-1 rounded-xl border border-[#00B061] bg-[#ADE6CC]">
                                        <p className="font-SatoshiMedium text-center text-sm text-[#00B061]">6x</p>
                                    </div>
                                </div>

                                <div className="flex items-center justify-between mt-3">
                                    <div>
                                        <p className="text-left font-SatoshiMedium text-sm text-textGreyDark">
                                            Dice Sum 6 & 8
                                        </p>
                                        <p className="text-left font-SatoshiMedium text-xs text-textGreyLight">
                                            Probability: 5/36
                                        </p>
                                    </div>

                                    <div className="w-[3.4rem] py-1 rounded-xl border border-textBlue bg-lightBlue">
                                        <p className="font-SatoshiMedium text-center text-sm text-textBlue">4x</p>
                                    </div>
                                </div>

                                <div className="flex items-center justify-between mt-3">
                                    <div>
                                        <p className="text-left font-SatoshiMedium text-sm text-textGreyDark">
                                            Dice Sum 7
                                        </p>
                                        <p className="text-left font-SatoshiMedium text-xs text-textGreyLight">
                                            Probability: 6/36
                                        </p>
                                    </div>
                                    <div className="w-[3.4rem] py-1 rounded-xl border border-[#6F6F6F] bg-[#DFDFDF]">
                                        <p className="font-SatoshiMedium text-center text-sm text-[#6F6F6F]">2x</p>
                                    </div>
                                </div>

                            </div>
                        </div>

                    </div>
                </div>


            </div>
        </>

    );
}