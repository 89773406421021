import { useEffect, useState } from "react";
import ActivityLogItem from "./ActivityLogItem";
import EnterRaffleModal from "./EnterRaffleModal";
import TicketModal from "../common/modals/TicketModal";
import axios from "axios";
import Navbar from "../navbar/Navbar";
import { assets, CONST } from "../../constants";
import { useWallet } from "@aptos-labs/wallet-adapter-react";
import { UserContext } from "../../contexts/UserContent";
import { useContext } from "react";



export default function Giveaway() {




  const { account, connected } = useWallet();

  const [state, setState] = useState({
    totalParticipants: 0,
    bannerUrl: assets.coinRaffleBg,
    reward: "",
    task: {
      title: "",
      description: "",
      boost: {},
      mcqQuestions: [
        {
          question: "",
          answers: [""],
        },
      ],
      faq: [{}],
    },
    chest: {
      reward: {},
      chestType: {},
    },
  });

  const [openTicket, setOpenTicket] = useState(false);
  const { user, setUser } = useContext(UserContext);
  const [activityLog, setActivityLog] = useState([])

  const handleCloseTicket = () => {
    setOpenTicket(false);
  };

  const handleOpenTicket = () => {
    setOpenTicket(true);
  };

  const [show, setShow] = useState([]);

  const [openEnterRaffle, setOpenEnterRaffle] = useState(false);

  const handleCloseEnterRaffle = () => {
    setOpenEnterRaffle(false);
  };

  const handleOpenEnterRaffle = () => {
    setOpenEnterRaffle(true);
  };

  function formatDate(dateString) {
    const options = { day: "numeric", month: "long", year: "numeric" };
    return new Date(dateString).toLocaleDateString("en-GB", options);
  }

  const [winnersTabIndex, setWinnersTabIndex] = useState(0);

  const [timer, setTimer] = useState("0:0:0");

  useEffect(() => {
    const interval = setInterval(() => {
      const now = new Date();
      const distance = new Date(state.expiresAt) - now;

      if (distance < 0) {
        clearInterval(interval);
        setTimer("0:0:0");
        return;
      }

      const days = Math.floor(distance / (1000 * 60 * 60 * 24));
      const hours = Math.floor(
        (distance % (1000 * 60 * 60 * 24)) / (1000 * 60 * 60)
      );
      const minutes = Math.floor((distance % (1000 * 60 * 60)) / (1000 * 60));

      setTimer(`${days}: ${hours}: ${minutes}`);
    }, 1000);

    return () => clearInterval(interval);
  }, [state.notBeforeAt]);

  const getRaffle = () => {
    const jwt = localStorage.getItem("AVEX_DEFY_JWT_STORE");
    const config = {
      headers: {
        Authorization: jwt,
      },
    };

    // if (jwt) {
    axios.get(`${CONST.baseUrl}/raffle`).then((response) => {
      const result = response.data.data;
      let upcoming = [];
      let past = [];
      let active = {};
      result.forEach((ele, inx) => {
        if (ele.isLive) active = ele;
        else if (ele.expiresAt == null) upcoming.push(ele);
        else if (new Date(ele.expiresAt) - new Date() > 0) upcoming.push(ele);
        else if (new Date(ele.expiresAt) - new Date() <= 0) past.push(ele);
      });

      setState((curr) => ({
        ...curr,
        upcoming: upcoming,
        past: past,
        ...active,
        totalParticipants: Object.keys(active['participants'] ?? {})?.length ?? 0,
        totalTickets: active['totalTicketsInvested'],
      }));
      setShow(upcoming);
      setActivityLog(active?.participants[account?.address] ?? [])

      const moduleAddress = process.env.REACT_APP_MODULE_ADDR;

      // getParticipants(active);
    }).catch(() => { });
    // }
  };

  useEffect(() => {
    getRaffle();
  }, [connected, openEnterRaffle]);

  


  return (
    <>
          <Navbar />
    <div className="main-body">

      <div className="flex items-start w-full lg:items-center xl:items-start px-20 pb-10 mt-[-2.66vh] max-[1024px]:flex-col max-[1024px]:px-4">
        <div style={{ flex: 1 }}>
          <p className="font-SatoshiMedium 2xl:text-2xl text-base text-textGreyDark text-left mt-[5px]">
            #1
          </p>
          <p className="font-SatoshiMedium 2xl:text-5xl text-3xl text-left mt-4">
            Giveaway
            <span className="bg-gradient-to-r from-purple to-blue text-transparent bg-clip-text">
              {" "}
              Raffle
            </span>
          </p>
          <p className="font-SatoshiMedium 2xl:text-xl text-sm text-textGreyDark text-left mt-6 max-[1024px]:hidden">
            Participate in the giveaway by spending your tickets. The lucky
            participants gets a chance to win a prize from the total pool reward
            pool. This whole process in transparent and made on-chain. The
            tickets you use in the current raffle will not be applicable for the
            upcoming raffles. For more info, read the FAQ’s
          </p>

          <div className="flex items-center justify-between mt-14 max-[1024px]:hidden">
            <p className="text-left text-black text-base font-SatoshiBold">
              Activity Log
            </p>
            {user?.raffleParticipation?.length !== 0 ? <div className="px-3.5 py-2 bg-bgBlue rounded-full">
              <p className="text-left text-white text-xs font-SatoshiMedium m-auto">
                Participating
              </p>
            </div>
              : <div className="px-3.5 py-2 bg-textGreyLight rounded-[20px]">
                <p className="text-left text-white text-xs font-SatoshiMedium m-auto">
                  Not Participating
                </p>
              </div>
            }
          </div>

          <div className="rounded-xl w-full bg-greyVeryLight py-2.5 px-2 2xl:h-[300px] h-[240px] mt-4 max-[1024px]:hidden overflow-y-scroll">
            {user?.raffleParticipation?.map((ele, inx) => {
              if (ele.coinType !== state.coinType
                || ele.raffleType !== state.raffleType
                || ele.onchainRaffleId !== state.onchainRaffleId
              )
                return
              return (
                <ActivityLogItem ele={ele} />
              )
            })
            }
          </div>
        </div>

        <div className="px-10 max-[1024px]:px-2" style={{ flex: 2 }}>
          <div className="flex justify-between items-start pt-5">
            <div>
              <p className="font-SatoshiBold 2xl:text-4xl text-2xl text-left text-black">
                {state.title}
              </p>
              <p className="font-SatoshiMedium 2xl:text-lg text-[10px] text-left text-textGreyDark mt-4">
                POOL OF
                <br />{" "}
                <span className="text-green 2xl:text-2xl text-xl">
                  {state.reward}
                </span>
              </p>
            </div>

            <div>
              <p className="font-SatoshiMedium 2xl:text-2xl text-base text-right text-textGreyDark">
                Winners Draw in
              </p>
              <div className="flex items-center ">
                <div className="mr-5 ">
                  <p className="text-xl 2xl:text-2xl font-SatoshiBold text-center text-black">
                    {timer.split(":")[0]}
                  </p>
                  <p className="text-xs 2xl:text-lg font-SatoshiMedium text-center text-textGreyDark">
                    Days
                  </p>
                </div>
                <div className="mr-5">
                  <p className="text-xl 2xl:text-2xl font-SatoshiBold text-center text-black">
                    {timer.split(":")[1]}
                  </p>
                  <p className="text-xs 2xl:text-lg font-SatoshiMedium text-center text-textGreyDark">
                    Hours
                  </p>
                </div>
                <div>
                  <p className="text-xl 2xl:text-2xl font-SatoshiBold text-center text-black">
                    {timer.split(":")[2]}
                  </p>
                  <p className="text-xs 2xl:text-lg  font-SatoshiMedium text-center text-textGreyDark">
                    Minutes
                  </p>
                </div>
              </div>
            </div>
          </div>
          <img
            className="w-full 2xl:h-[450px] h-[320px] max-[1024px]:h-[200px] rounded-[20px] flex flex-col items-center justify-center mt-8"
            src={state.bannerUrl}
          ></img>

          <div className="flex items-center justify-between mt-3">
            <p className="font-SatoshiMedium text-sm 2xl:text-2xl text-left text-textGreyDark max-[1024px]:hidden">
              You Entered <span className="text-textBlue"> {user?.raffleParticipation?.reduce((accumulator, ele) => {
                if (ele.coinType === state.coinType && ele.raffleType === state.raffleType && ele.onchainRaffleId === state.onchainRaffleId) {
                  return accumulator + ele['ticketsInvested'];
                }
                return accumulator;
              }, 0)}</span> Raffle Tickets
            </p>
            <div className="flex items-center ">
              <img className="w-5 h-5 object-contain mr-2" src={assets.groupProfile} />
              <p className="font-SatoshiMedium 2xl:text-xl text-sm text-right text-textGreyDark">
                {state.totalTickets}+ Raffle Tickets Entered
              </p>
            </div>
          </div>
          <p className="font-SatoshiMedium text-sm 2xl:text-2xl text-left text-textGreyDark min-[1024px]:hidden">
            You Entered <span className="text-textBlue"> {user?.raffleParticipation?.reduce((accumulator, ele) => {
              if (ele.coinType === state.coinType && ele.raffleType === state.raffleType && ele.onchainRaffleId === state.onchainRaffleId) {
                return accumulator + ele['ticketsInvested'];
              }
              return accumulator;
            }, 0)}</span> Raffle Tickets
          </p>

          <div className=""></div>

          <div className="flex items-center justify-between mt-8">
            <div
              onClick={handleOpenTicket}
              className="flex-1 button flex py-3 stroke-borderGrey border rounded-lg cursor-pointer mx-2"
            >
              <p className="text-center text-textGreyDark text-base font-SatoshiBold m-auto">
                Buy Tickets
              </p>
            </div>
            <div
              onClick={handleOpenEnterRaffle}
              className="flex-1 button flex py-3 bg-bgBlue rounded-lg cursor-pointer mx-2"
            >
              <p className="text-center mx-auto text-white text-base font-SatoshiBold m-auto">
                Enter Raffle
              </p>
            </div>
          </div>
        </div>

        <div className="flex items-center justify-between mt-14 min-[1024px]:hidden w-full">
          <p className="text-left text-black text-base font-SatoshiBold">
            Activity Log
          </p>
          {activityLog?.length !== 0 ? <div className="px-3.5 py-2 bg-bgBlue rounded-full">
            <p className="text-left text-white text-xs font-SatoshiMedium m-auto">
              Participating
            </p>
          </div>
            : <div className="px-3.5 py-2 bg-textGreyLight rounded-[20px]">
              <p className="text-left text-white text-xs font-SatoshiMedium m-auto">
                Not Participating
              </p>
            </div>
          }
        </div>

        <div className="rounded-xl w-full bg-greyVeryLight py-2.5 px-2 2xl:h-[300px] h-[240px] mt-4 min-[1024px]:hidden overflow-y-scroll">
          {activityLog.map((ele, inx) => {
            if (ele.coinType !== state.coinType || ele.raffleType !== state.raffleType || ele.onchainRaffleId !== state.onchainRaffleId) return

            return (
              <ActivityLogItem ele={ele} />
            )
          })
          }
        </div>

        <div className="max-[1024px]:w-full max-[1024px]:mt-4" style={{ flex: 1 }}>
          <p className="font-SatoshiBold text-xl text-black text-left 2xl:text-4xl ">
            Events
          </p>
          <div className="w-full rounded-xl bg-greyVeryLight py-4 px-5 mt-4 2xl:min-h-[700px] h-[500px]">
            <div
              style={{ backgroundColor: "#E1E4EA" }}
              className="rounded-full w-full p-1.5 flex justify-between items-center"
            >
              <div
                onClick={() => {
                  setShow(state.upcoming);
                  setWinnersTabIndex(0);
                }}
                className={
                  winnersTabIndex == 0
                    ? "RaffleWinnersSelectedDiv py-2"
                    : "RaffleWinnersUnselectedDiv py-2"
                }
              >
                <p
                  className={
                    winnersTabIndex == 0
                      ? "RaffleWinnersSelected m-auto text-center 2xl:text-xl"
                      : "RaffleWinnersUnselected text-center 2xl:text-xl"
                  }
                >
                  Upcoming
                </p>
              </div>
              <div
                onClick={() => {
                  setShow(state.past);
                  setWinnersTabIndex(1);
                }}
                className={
                  winnersTabIndex == 1
                    ? "RaffleWinnersSelectedDiv py-2"
                    : "RaffleWinnersUnselectedDiv py-2"
                }
              >
                <p
                  className={
                    winnersTabIndex == 1
                      ? "RaffleWinnersUnselected text-center 2xl:text-xl"
                      : "RaffleWinnersUnselected text-center 2xl:text-xl"
                  }
                >
                  Previous
                </p>
              </div>
            </div>
            <div className="mt-3">
              {show && show.map((ele, inx) => {
                return (
                  <div className="w-full flex items-end justify-between mt-6">
                    <div className="flex items-center">
                      <img
                        className="w-10 h-10 object-cover rounded"
                        src={ele.bannerUrl}
                      />
                      <div className="ml-2">
                        <p className="font-SatoshiMedium text-sm text-left 2xl:text-xl">
                          {ele.title}
                        </p>
                        <p className="font-SatoshiMedium text-[10px] 2xl:text-base text-left text-textGreyDark mt-1">
                          from {formatDate(new Date(ele.notBeforeAt))}{" "}
                        </p>
                      </div>
                    </div>
                    <p className="font-SatoshiMedium text-xs 2xl:text-base"></p>
                  </div>
                );
              })}
            </div>
          </div>
        </div>
      </div>


      <TicketModal
        open={openTicket}
        handleClose={handleCloseTicket}
        state={user}
      />
      <EnterRaffleModal
        open={openEnterRaffle}
        handleClose={handleCloseEnterRaffle}
        state={state}
        handleOpen={handleOpenTicket}
      />
    </div>
    </>

  );
}
