import axios from "axios";
import CONST from "../../constants/constants";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import "../../utils/toasts.css";

export const discordOauth = async (taskid, address) => {
  //console.log("discord oauth");
  window.location.href = `${CONST.baseUrl}/auth/discord?walletAddress=${address}`;
};

export const twitterOauth = async (taskid, address) => {
  //console.log("Twitter oauth");
  window.location.href = `${CONST.baseUrl}/auth/twitter`;
};

export const twitterOauthCallback = async (address, state) => {
  axios
    .get(
      `${CONST.baseUrl}/auth/twitter/client/callback?walletAddress=${address}&userId=${state}`
    )
    .then(() => {
      // getUser()
      toast("Twitter Verification successful!");
    })
    .catch(() => {
      toast("Twitter Verification Unsuccessful!");
    });
};

export const googleOauth = async (taskid, address) => {
  //console.log("Google oauth");
  window.location.href = `${CONST.baseUrl}/auth/google?walletAddress=${address}`;
};
