class FaqData {
  constructor(question, answer) {
    this.question = question;
    this.answer = answer;
  }
}
var data = [
  [
    new FaqData(
      "What is Defy",
      "Defy is a crypto superapp that allows you to access all of defi products in Aptos within one app. It also allows you to trade with friends and track their profiles while rewarding you for every interaction. For more info read here.".replace("read", "<a href='https://medium.com/@maharsi/defy-complete-product-overview-7786fb8bed59' style='color: blue; text-decoration: underline;' target='_blank'>read</a>")
    ),
    new FaqData(
      "What is the Defy Waitlist campaign?",
      "The Defy waitlist campaign is an arcade full of games, on-chain and social quests. The best ones will get access to the Defy app earlier than the others. Everyone participating in the campaign has a chance to win meme coins, NFTs. Defy coins and more. For more info read here. For more info read here.".replace("read", "<a href='https://medium.com/@maharsi/incentivized-waitlist-campaign-defy-e1767ed81793' style='color: blue; text-decoration: underline;' target='_blank'>read</a>")
    ),
    new FaqData(
      "How Does The Program work?",
      "Users need to collect as many coins as possible. These coins can be used to buy raffle tickets and the limited chests. The remaining coins in the campaign will be compensated to the Defy application on launch. For more info read here.".replace("read", "<a href='https://medium.com/@maharsi/incentivized-waitlist-campaign-defy-e1767ed81793' style='color: blue; text-decoration: underline;' target='_blank'>read</a>")
      ),
      new FaqData(
        "How do I get Defy coins?",
        "You can get Defy coins by completing the quests and playing games.<br/>Note: Coins will only be credited once you have registered your wallet with Defy waitlist.",
      ),
    new FaqData(
      "What are the chests?",
      "The chests contain the Defy Insiders NFT collection and raffle tickets. The Defy Insiders NFT is your early access to the Defy app and also has various in-app benefits. The raffle tickets allow you to participate in the weekly GUI giveaway contest. Read here for more info.".replace("Read", "<a href='https://medium.com/@maharsi/incentivized-waitlist-campaign-defy-e1767ed81793' style='color: blue; text-decoration: underline;' target='_blank'>Read</a>")
    ),
    new FaqData(
      "How can I sign up for the waitlist program?",
      "Signing up for the Defy campaign is absolutely free and users only require any one of the  Aptos wallets to get started. "
    ),
    new FaqData(
      "How can I contact support?",
      "You can ask any queries related to the waitlist program on our <a href='https://discord.gg/hKFj7xFDRr' style='color: blue; text-decoration: underline;' target='_blank'>Discord</a> and <a href='https://t.me/avexdefy/602' style='color: blue; text-decoration: underline;' target='_blank'>Telegram</a> channels or contact one of our team members on <a href='https://twitter.com/defyapp_' style='color: blue; text-decoration: underline;' target='_blank'>X</a>."
    ),
    new FaqData(
      "What is .avex?",
      ".avex is a naming service provided by the Avex Id team. All users who engage with the waitlist can get their .avex names for free by acquiring 10,000 Defy coins."
    ),
  ],
  [
    new FaqData("What is the Raffle Giveaway?", "The raffle giveaway is a lottery-like system where users need to deposit raffle tickets in order to participate in the free giveaway contest worth $10,000+ token rewards. These contests will be live weekly and the time-period will reduce if we manage to get more partnerships."),
    new FaqData("How does it work?",
     "Users need to spend their raffle tickets to take part in the giveaway. The draw will take place once the timer ends. The prizes will be directly distributed to the winners of the raffle by the end of the giveaway."),
    new FaqData("Is this legit and transparent?", 
    "The raffle tickets and the random generator are maintained on-chain. These are also made public to make things transparent and trustworthy."),
    new FaqData("How do I get raffle tickets?",
     "Raffle tickets can either be bought with coins or unlocking chests. Moreover you can get a chance to earn raffle tickets through daily rewards, free chests, by completing quests and more. Always have an eye on featured tasks and our partners."),
  ],
  [
    new FaqData("What is NFT Spin?",
     "It is a fun way to win your favourite NFT for 0.1% of its floor price."),
    new FaqData("How does it work?", "Users can select their favourite NFT, choose their upside potential and get ready to spin by paying the game fees. Users can get a chance to win their NFT, $APT tokens, Raffle Tickets and Defy Coins."),
    new FaqData("What are the odds of winning?",
     "The winning odds can be set at 0.1 - 0.5% according to your own risk-reward measurements. You can see more details on the respective NFT’s."),
    new FaqData("How many NFT collections will be launched?",
     "Currently we have partnered with more than 8 collections. These collections will be released gradually over time. You can follow our social handles to stay updated.".replace("handles", "<a href='https://twitter.com/defyapp_' style='color: blue; text-decoration: underline;' target='_blank'>handles</a>")),
  ],
  [
    new FaqData("What is Defy Insider NFT?","The Defy Insider NFTs are a premium collection of 3D based-avatar NFTs. These NFT’s represent your true physical self and have a limited supply of just 1750 pieces. The collection has 4 rarities of NFT’s - Mythic, Legendary, Epic and Rare. For more info read here.".replace("read", "<a href='https://medium.com/@maharsi/the-defy-nft-collections-1bf8ab0c755b' style='color: blue; text-decoration: underline;' target='_blank'>read</a>")),
    new FaqData("What are the perks of Insider NFT?","The holders of Defy Insiders NFT get early access to the Defy app and also get other long-term in-app benefits like free premium features, fee discounts, free transactions and many others. For more info read here.".replace("read", "<a href='https://medium.com/@maharsi/the-defy-nft-collections-1bf8ab0c755b' style='color: blue; text-decoration: underline;' target='_blank'>read</a>")),
    new FaqData("How are the Defy Insiders NFT made?","The Defy Insiders NFT are made using the Aptos Digital Asset Standard. This makes the NFT’s totally composable, scalable and secure. This unlocks a lot of features that can be launched within this collection. For more info read here.".replace("read", "<a href='https://medium.com/@maharsi/the-defy-nft-collections-1bf8ab0c755b' style='color: blue; text-decoration: underline;' target='_blank'>read</a>")),
  ],
];

export default data;
