import React, { useState, useEffect } from 'react';
import progressbarend from '../../../assets/chestMedal.png';
import { motion } from 'framer-motion';
import yellowDiamond from "../../../assets/yellow_diamond.svg"

const ProgressBar = ({ total, current }) => {
  const [progress, setProgress] = useState(0);

  useEffect(() => {
    // Calculate progress percentage
    let updatedProgress = (current / total) * 100;
    if(updatedProgress > 100) updatedProgress = 100
    setProgress(updatedProgress);
  }, [current, total]);

  return (
    <div className="flex justify-between items-center w-full bg-white h-6 px-1 rounded relative">
      <motion.div
        animate={{
          left: `${progress}%`,
        }}
        style={{
          top: -40,
        }}
        className="absolute bg-textBlue py-1.5 px-2.5 rounded-full transform -translate-x-[45%] w-fit"
      >
        <div className='flex items-center justify-center px-1'>
        <p  className="font-SatoshiBold text-[14px] text-white  text-center mr-1">
         {current ?? 0}
        </p>
        <img className='object-contain w-4 h-4 ml-0.5' src={yellowDiamond}/>
        </div>
        
        <div className="arrow-down-marquee absolute bottom-0 left-1/2 transform translate-y-3/4 -translate-x-1/2"></div>
      </motion.div>
      <div className=" flex rounded items-center bg-yellow-400 h-4" style={{ width: `${progress}%` }}>
        <div style={{ flex: '1 0 auto' }}></div>
        {/* <div className=" rounded-full bg-bgBlue text-white text-sm w-20  h-6 font-bold pl-2">{current}</div> */}
      </div>
      <div className='align-middle items-end relative mr-[-20px]'>
        <img src={progressbarend} alt="" className=' object-contain h-[64px]' />
        <p className='text-textBlue font-SatoshiBold text-sm text-center absolute top-[18px] left-1/2 transform -translate-x-1/2'>{total.toString().substring(0, 2)}k</p>

      </div>
    </div>
  );
};

export default ProgressBar;
