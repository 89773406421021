import React, { Component, useState, useEffect } from "react";
import Navbar from "../navbar/Navbar";
import { toast } from "react-toastify";
import { useWallet } from "@aptos-labs/wallet-adapter-react";
import EnterFortuneWheel from "./EnterFortuneWheel";
import { assets } from "../../constants";
import clsx from "clsx";
import { Aptos, AptosConfig, Network } from "@aptos-labs/ts-sdk";
import { title } from "process";
import { color } from "framer-motion";
import "./fortuneWheel.css";
import GUIimage from "../../assets/GUIimage.png";
import guiBG from "../../assets/GUIbgNew.png";
import diamond from "../../assets/diamondyellow.jpeg";
import coinBG from "../../assets/CoinBG.png";
import NFTgift from "../../assets/NFTgift.png";
import nftBG from "../../assets/nftBG.png";
import raffleBG from "../../assets/raffleBg.png";
import fortuneWheel from "../../assets/fortunewheelOuter.png";
import {
  fetchWheelConfig,
  wheelPlaying,
  fetchWheelReward,
  fetchPlayerWheelRewards,
} from "../../utils/indexer";
import { sign } from "@aptos-labs/ts-sdk";
import { getSuggestedQuery } from "@testing-library/react";

const aptosConfig = new AptosConfig({
  network: Network.MAINNET,
});
const aptos = new Aptos(aptosConfig);

const FortuneWheel = () => {
  const { account, connected, signAndSubmitTransaction } = useWallet();
  const [selectedChances, setSelectedChances] = useState(1);
  const [totalCost, setTotalCost] = useState(100000);
  const [winningSegment, setWinningSegment] = useState(null);
  const Chances = ["X1", "X2", "X3", "X4", "X5", "X6", "X7", "X8", "X9", "X10"];
  const [rewards, setRewards] = useState({});
  // const [rewardTier, setRewardTier] = useState(1);
  const [wheelConfig, setWheelConfig] = useState({
    active: false,
  });

  const divisor = 1000000

  const segments = [
    { id: 2, title: "1M GUI", image: GUIimage, background: guiBG },
    { id: 6, title: "5000 Defy Coins", image: diamond, background: coinBG },
    { id: 1, title: "1x NFT", image: NFTgift, background: nftBG },
    { id: 4, title: "20K GUI", image: GUIimage, background: guiBG },
    { id: 9, title: "100 Defy Coins", image: diamond, background: coinBG },
    {
      id: 11,
      title: "5 Raffle Tickets",
      image: assets.blueTicket,
      background: raffleBG,
    },
    { id: 3, title: "100K GUI", image: GUIimage, background: guiBG },
    { id: 8, title: "500 Defy Coins", image: diamond, background: coinBG },
    {
      id: 10,
      title: "10 Raffle Tickets",
      image: assets.blueTicket,
      background: raffleBG,
    },
    { id: 5, title: "7.5K GUI", image: GUIimage, background: guiBG },
    { id: 7, title: "25000 Defy Coins", image: diamond, background: coinBG },
    {
      id: 12,
      title: "2 Raffle Tickets",
      image: assets.blueTicket,
      background: raffleBG,
    },
  ];

  const TiersToWheelAngles = {
    3: 0,
    0: 1,
    5: 2,
    1: 3,
    11: 4,
    6: 5,
    4: 6,
    9: 7,
    7: 8,
    2: 9,
    10: 10,
    8: 11,
}
  
  const events = [
    {
      status: "lost",
      title: "You Lost 🫤",
      reward: "2000 GUI",
      image: assets.guiIcon1,
    },
    {
      status: "won",
      title: "You Won 🥳",
      reward: "250 Defy Coins",
      image: assets.yellowDiamond,
    },
    {
      status: "lost",
      title: "You Lost",
      reward: "1x Raffle Ticket",
      image: assets.blueTicket,
    },
    {
      status: "won",
      title: "You Won",
      reward: "1x NFT",
      image: assets.reward,
    },
  ];

  const prizes = [
    {
      title: "Free NFT",
      percent: "1%",
      value: "NFT",
    },
    {
      title: "1M GUI Tokens",
      percent: "0.1%",
      value: "Token",
    },
    {
      title: "100k GUI Tokens",
      percent: "5%",
      value: "Token",
    },

    {
      title: "20k GUI Tokens",
      percent: "15%",
      value: "Token",
    },

    {
      title: "7.5k GUI Tokens",
      percent: "25%",
      value: "Token",
    },

    {
      title: "10 Raffle Tickets",
      percent: "3x",
      value: "Ticket",
    },

    {
      title: "5 Raffle Tickets",
      percent: "3x",
      value: "Ticket",
    },

    {
      title: "2 Raffle Tickets",
      percent: "3x",
      value: "Ticket",
    },

    {
      title: "5k Defy Coins",
      percent: "72x",
      value: "coin",
    },

    {
      title: "2.5k Defy Coins",
      percent: "72x",
      value: "coin",
    },

    {
      title: "500 Defy Coins",
      percent: "72x",
      value: "coin",
    },

    {
      title: "100 Defy Coins",
      percent: "72x",
      value: "coin",
    },
  ];
  let previousEndDegree = [0];
  const [newEndDegree, setNewEndDegree] = useState(0)
  const [degree, setDegree] = useState(360*5)
  const [disabled, setDisabled] = useState(false)
  const [playerRewards, setPlayerRewards] = useState([{
    player: "0",
    reward_amount: "0",
    reward_tier: "0",
    reward_type: "0",
    timestamp: "0",
  }])

  const [historyRewards, setHistoryRewards] = useState([])

  // console.log(historyRewards);
  // let playerRewards;

  useEffect(() => {
    fetchWheelConfig()
      .then((res) => {
        setWheelConfig(res);
      })
      .catch(() => {});
  }, [connected]);

  // const onSpin = () => {
  //   if (!wheelConfig.active) {
  //     toast("The Game has not started yet");
  //     return;
  //   }

  //   if (!connected) {
  //     toast("Connect your Wallet to proceed");
  //     return;
  //   }

  //   function timeout(delay) {
  //     return new Promise((res) => setTimeout(res, delay));
  //   }
  //   // setTimeout(() => {}, 3000);
  //   wheelPlaying(selectedChances, signAndSubmitTransaction)
  //     .then((response) => {
  //       // console.log(response);
  //       // const rewardAmount = response[0].reward_amount;
  //       // playerRewards.push(rewardAmount);
        

  //       const rewardTier = TiersToWheelAngles[parseInt(response[0].reward_tier)];
  //       const resp = TiersToWheelAngles[parseInt(response)]
  //       console.log("reward= "+ resp);
        
  //       fetchWheelReward(account?.address);

  //         const segmentAngle = 360 / segments.length;
  //         const targetDegree = rewardTier * segmentAngle;
  //         // console.log("sdadasd ", targetDegree, rewardTier);
  //         const randomFullRotations = 360*5
  //         setDegree(prevEndDegree => prevEndDegree + randomFullRotations)
  //         setNewEndDegree(degree + targetDegree);
          

  //         // playerRewards(resp[0])
  //         console.log("reward= "+playerRewards);
  //         handleOpenSpin();
  //         // const playerRewardsTier = TiersToWheelAngles[parseInt(response[0].reward_amount)] 
  //         // console.log("reward = ", response[0].reward_amount);
          
  //         // console.log("rewardList = ", playerRewards);
  //     })
  //     .catch(() => {});
  // };

  const onSpin = async () => {
    
    if (!wheelConfig.active) {
      toast("The Game has not started yet");
      return;
    }
  
    if (!connected) {
      toast("Connect your Wallet to proceed");
      return;
    }

    if(wheelConfig.active) wheelConfig.spin_fee = wheelConfig.spin_fee/10**6
  
    try {
      const response = await wheelPlaying(selectedChances, signAndSubmitTransaction);
      setPlayerRewards(response);
      setTimeout(() => {
      setHistoryRewards(prev => [...prev,...response])
      }, 4000); 
      const rewardTier = TiersToWheelAngles[parseInt(response[0].reward_tier)];
      const segmentAngle = 360 / segments.length;
      const targetDegree = rewardTier * segmentAngle;
      const randomFullRotations = 360 * 5;
  
      fetchWheelReward(account?.address);
  
      setDegree(prevEndDegree => prevEndDegree + randomFullRotations);
      setNewEndDegree(degree + targetDegree);
      
      // setPlayerRewards(response)
      // console.log("Rewards: " + playerRewards[0].reward_amount);
      handleOpenSpin();
    } catch (error) {
      console.error("Error during wheel spin:", error);
    }
  };
  // console.log("Rewards= " + playerRewards);
  function timeout(delay) {
    return new Promise(res => setTimeout(res, delay));
  }
  
  
  const [allRewards, setAllRewards] = useState({
    nft_v1:[],
    nft_v2:[],
    raffle_ticket:"0",
    waitlist_coins:"0"
  });
  const [guiRewards, setGuiRewards] = useState({coin:{value:0}});

  useEffect(() => {
    fetchWheelReward(account?.address)
    .then((res) => {
      setTimeout(()=>{setGuiRewards(res)},3900);
      // setUserRewards(prevRewards => [...prevRewards, res])
      // console.log(res);
      })
      .catch((error) => {
        console.log("error");
      });
  }, [connected, degree]);

  useEffect(() => {
    fetchPlayerWheelRewards(account?.address)
    .then((res) => {
      setTimeout(()=>{setAllRewards(res)},3900);
      // setUserRewards(prevRewards => [...prevRewards, res])
      // console.log(res);
      })
      .catch((error) => {
        console.log("error");
      });
  }, [connected, degree]);

  // console.log(guiRewards, allRewards)

  const handleChancesChange = (event) => {
    const value = event.target.value.replace("X", "");
    const numericValue = parseInt(value, 10);
    if (!isNaN(numericValue)) {
      setSelectedChances(numericValue);
    }
  };

  useEffect(() => {
    const calculatedCost = selectedChances * 100000;
    if (!isNaN(calculatedCost)) {
      setTotalCost(calculatedCost);
    }
  }, [selectedChances]);

  const [state, setState] = useState({});
  const [openEnterRaffle, setOpenEnterRaffle] = useState(false);

  const handleCloseEnterRaffle = () => {
    setOpenEnterRaffle(false);
  };

  const handleOpenSpin = () => {
    setTimeout(() => {
      setOpenEnterRaffle(true);
    }, 4000);
  };

  const claimWheelRewards = async () => {
    const moduleAddress = process.env.REACT_APP_MODULE_ADDR;

    const payload = {
      data: {
        function: `${moduleAddress}::wheel::claim`,
        typeArguments: [
          "0xe4ccb6d39136469f376242c31b34d10515c8eaaa38092f804db8e08a8f53c5b2::assets_v1::EchoCoin002",
          "0x1::string::String",
          "0x1::string::String",
          "0x1::string::String",
          // "0x1::string::String",
        ],
        functionArguments: [
          1
        ],
        "type": "entry_function_payload"
      },
    };
    try {
      const response = await signAndSubmitTransaction(payload);
      await aptos.waitForTransaction({ transactionHash: response.hash });
      toast("Successfully claimed");
      fetchWheelReward(account?.address)
        .then(() => {})
        .catch(() => {});
    } catch (error) {
      console.error(error);
    }
  };

  // console.log(wheelConfig);

  let before = [];
  let after = [];
  // console.log(playerRewards);
  for (let i = 0; i < playerRewards.length; i++) {
    // console.log(playerRewards[i]?.reward_type);
    if (playerRewards[i]?.reward_type.includes("-")) {
      // console.log(playerRewards[i]?.reward_type);
      const response = separateString(playerRewards[i]?.reward_type);
      before.push(response[0])
      after.push(response[1])
    }
  }

  function separateString(str) {
    const index = str.indexOf('-');
    if (index !== -1) {
        const part1 = str.substring(0, index);
        const part2 = str.substring(index + 1);
        return [part1, part2];
    } else {
        return ["NFT", 'https://demofree.sirv.com/nope-not-here.jpgnd'];
    }
  }


  // useEffect(()=>{
  //   if(allRewards.nft_v1.length===0&&allRewards.nft_v2.length===0&&allRewards.raffle_ticket=="0"&&allRewards.waitlist_coins=="0"&&guiRewards.coin.value==0)
  //     setDisabled(true)
  //   else setDisabled(false)
  // },[connected, account, guiRewards, allRewards ])
  // const handleDisabled = () => {
  //   if(allRewards.nft_v1.length===0&&allRewards.nft_v2.length===0&&allRewards.raffle_ticket==="0"&&allRewards.waitlist_coins==="0"&&guiRewards.coin.value==0)
  //     setDisabled(true)
  //   else setDisabled(false)
  //   } 

  console.log(historyRewards)

  return (
    <>
      <Navbar />
      <div className="pt-[110px]">
        <div className="flex item-start w-full lg:pt-5 xl:items-start px-20 pb-10 mt-[-2.99vh] max-[1024px]:flex-col max-[1024px]:px-4">
          {/*left content*/}
          <div style={{ flex: 1 }}>
          <p className="font-SatoshiMedium 2xl:text-5xl text-3xl text-left mt-4">
                            Fortune
                            <span className="bg-gradient-to-r from-purple to-blue text-transparent bg-clip-text">
                                {" "}
                                Wheel
                            </span>
                        </p>
            <p className="sm:w-[290px] 2xl:text-base 2xl:font-normal text-sm mt-4 font-medium font-SatoshiMedium text-textGreyDark text-left max-[1024px]:hidden">
              The Fortune Wheel game is a popular entertainment game that involves a spinning wheel divided into various segments. Each segment is marked with different prizes, amounts of money, or other rewards. The basic premise is simple: a participant spins the wheel, and wherever the wheel stops, the corresponding segment determines the participant's prize. Play now and win the Jackpot.
            </p>
            {/*Note section*/}
            <div className="mt-12 flex flex-row lg:w-[320px] 2xl:w-[310px] justify-between p-1">
              <p className="text-base 2xl:text-lg font-bold font-SatoshiMedium text-black text-left">
                 Claimable Rewards
                {/* {playerRewards[0]?.reward_tier} {playerRewards[0]?.reward_amount} */}
              </p>
              <button
                className={`text-right text-sm text-white font-Satoshi rounded-full h-[29px] w-[90px] ${disabled?"bg-neutral-300":"bg-[#252525]"} flex items-center justify-center`}
                onClick={claimWheelRewards}
                disabled={disabled}
              >
                Claim 
              </button>
            </div>
            <div className="mt-3 h-[365px] flex flex-col gap-3 2xl:w-[310px] bg-greyVeryLight rounded-xl p-2.5 overflow-y-scroll hover:cursor-pointer">
                {
                  allRewards.nft_v1.length>=0 && (
                    allRewards.nft_v1.map((item,inx) => (
                      <div className="w-full min-h-[60px] bg-[#FFFFFF] rounded-xl flex flex-row items-center transform duration-300 ease-in-out hover:scale-95">
                        <div className="h-12 w-12 rounded bg-[#f4f4f6] flex justify-center items-center ml-1.5">
                          <img className="w-10 h-10" src={item.image_url} />
                        </div>
                        <div className="ml-2 flex flex-col gap-1">
                          <p className="font-satoshi font-medium text-xs text-textGreyDark">
                            {/* {item} */}
                          </p>
                          <p
                            className={clsx(
                              "font-satoshi font-medium text-xs",
                              // item.status === "won" ? "text-green" : "text-red"
                            )}
                          >
                            {item.token_name}
                          </p>
                        </div>
                      </div>
                    ))                   
                  )
                }
                {
                  allRewards.nft_v2.length>=0 && (
                    allRewards.nft_v2.map((item,inx) => (
                      <div className="w-full min-h-[60px] bg-[#FFFFFF] rounded-xl flex flex-row items-center transform duration-300 ease-in-out hover:scale-95">
                        <div className="h-12 w-12 rounded bg-[#f4f4f6] flex justify-center items-center ml-1.5">
                          <img className="w-10 h-10" src={item.image_url} />
                        </div>
                        <div className="ml-2 flex flex-col gap-1">
                          <p className="font-satoshi font-medium text-xs text-textGreyDark">
                            {/* {item} */}
                          </p>
                          <p
                            className={clsx(
                              "font-satoshi font-medium text-xs",
                              // item.status === "won" ? "text-green" : "text-red"
                            )}
                          >
                            {item.token_name}
                          </p>
                        </div>
                      </div>
                    ))                   
                  )
                }
                <div className="w-full min-h-[60px] bg-[#FFFFFF] rounded-xl flex flex-row items-center transform duration-300 ease-in-out hover:scale-95">
                  <div className="h-12 w-12 rounded bg-[#f4f4f6] flex justify-center items-center ml-1.5">
                    <img className="w-8 h-6" src={assets.blueTicket} />
                  </div>
                  <div className="ml-2 flex flex-col gap-1">
                    <p className="font-satoshi font-medium text-xs text-textGreyDark">
                            You Won!
                    </p>
                    <p
                      className={clsx(
                      "font-satoshi font-medium text-xs",
                      // item.status === "won" ? "text-green" : "text-red"
                      )}
                    >
                      {allRewards.raffle_ticket}x Raffle Tickets
                    </p>
                  </div>
                </div>
                <div className="w-full min-h-[60px] bg-[#FFFFFF] rounded-xl flex flex-row items-center transform duration-300 ease-in-out hover:scale-95">
                  <div className="h-12 w-12 rounded bg-[#f4f4f6] flex justify-center items-center ml-1.5">
                    <img className="w-6 h-10" src={assets.coinAnim} />
                  </div>
                  <div className="ml-2 flex flex-col gap-1">
                    <p className="font-satoshi font-medium text-xs text-textGreyDark">
                            You Won!
                    </p>
                    <p
                      className={clsx(
                      "font-satoshi font-medium text-xs",
                      // item.status === "won" ? "text-green" : "text-red"
                      )}
                    >
                      {allRewards.waitlist_coins}x Defy Coins
                    </p>
                  </div>
                </div>                  
                <div className="w-full min-h-[60px] bg-[#FFFFFF] rounded-xl flex flex-row items-center transform duration-300 ease-in-out hover:scale-95">
                  <div className="h-12 w-12 rounded bg-[#f4f4f6] flex justify-center items-center ml-1.5">
                    <img className="w-10 h-10" src={assets.guiIcon1} />
                  </div>
                  <div className="ml-2 flex flex-col gap-1">
                    <p className="font-satoshi font-medium text-xs text-textGreyDark">
                      {/* {item} */}
                    </p>
                    <p
                      className={clsx(
                        "font-satoshi font-medium text-xs",
                        // item.status === "won" ? "text-green" : "text-red"
                      )}
                    >
                      {(guiRewards.coin.value / divisor).toFixed(2)} GUI
                    </p>
                  </div>
                </div>
            </div>
          </div>
          {/*Middle part*/}

          <div
            className="px-10 max-[1024px]:px-2  2xl:mt-5"
            style={{ flex: 2 }}
          >
            <div className="flex justify-center my-5 relative">
  {/* First image */}
              <img
                src={fortuneWheel}
                alt=""
                className="relative"
                style={{ 
                  width: "80%", 
                  // transform: `rotate(-${degree}deg)`,
                  // transition: 'transform 4s cubic-bezier(0.440, -0.205, 0.000, 1)',
                }}
              />
              <div 
                className={`
                  absolute mt-[2%] transform -translate-x-1/2 -translate-y-1/2 w-[75%]
                `}
                style={{
                  transform: `rotate(${newEndDegree}deg)`, // Rotate to newEndDegree in one direction
                  transition: 'transform 4s cubic-bezier(0.440, -0.205, 0.000, 1)',
                }}
        
              >
                <img src={assets.wheel} className="" alt="" />
              </div>
              
              {/* Third image on top of the second image */}
              <div className="absolute top-1/2 left-1/2 transform -translate-x-1/2 -translate-y-1/2 w-[12%]">
                <img src={assets.marker} className="" alt="" />
              </div>
            </div>

            {/* <fieldset className="ui-wheel-of-fortune font-SatoshiMedium md:my-5 xl:my-5 2xl:my-6">
                <ul className="">
                  {segments.map((segment, index) => (
                    <li
                      key={index}
                      style={{
                        backgroundImage: `url(${segment.background})`,
                      }}
                    >
                      <div style={{ transform: "rotate(-90deg)" }}>
                        <div className="text-md font-semibold">
                          {segment.title.split(" ").map((text, i) => (
                            <p key={i} className="text-center">
                              {text}
                            </p>
                          ))}
                        </div>
                        <div className="flex justify-center items-center">
                          <img
                            src={segment.image}
                            alt={segment.title}
                            className="size-8 flex justify-center"
                          />
                        </div>
                      </div>
                    </li>
                  ))}
                </ul>
                <button type="button"></button>
              </fieldset> */}
              
            <div className="w-full items-center justify-center">
              <div className=" flex flex-row items-center justify-center ml-2">
                <p className="pl-3 pb-1 pr-3 text-sm font-SatoshiMedium">
                  Cost Per Spin:
                </p>
                <div className="flex rounded-md items-center justify-center mr-3">
                  <p
                    readonly
                    type="number"
                    className=" px-2 py-1 rounded-md focus:outline-none s  min-h-9 w-full text-textGreyDark font-SatoshiMedium"
                  >
                    100K
                  </p>
                  <img
                    className="h-8 w-8 pb-1 rounded-full"
                    src={assets.guiIcon}
                  />
                </div>
              </div>
            </div>
            <div className=" h-[84px] flex items-center justify-evenly rounded-xl mt-2 border-[1px] border-solid shadow-[0_1px_20px_-10px_rgba(0,0,0,0.3)]">
              <div className="min-h-[60px] w-[81px] flex flex-col items-start ml-2">
                <p className="font-SatoshiMedium text-sm">Chances</p>
                <select
                  className="h-[45px] w-[81px] rounded-md pl-4 text-left bg-greyVeryLight text-textGreyDark focus:outline-none"
                  style={{ fontFamily: "SatoshiMedium" }}
                  onChange={handleChancesChange}
                >
                  {Chances.map((item) => (
                    <option key={item} value={item}>
                      {item}
                    </option>
                  ))}
                </select>
              </div>

              <div className=" w-max flex flex-col items-start ml-2">
                <p className="pl-2 text-sm font-SatoshiMedium">Total Cost</p>
                <div className="flex  rounded-md items-center mr-3">
                  <p
                    readonly
                    type="number"
                    className="mt-2 px-2 py-1 rounded-md focus:outline-none s  min-h-9 w-full text-textGreyDark font-SatoshiMedium"
                  >
                    {totalCost}
                  </p>
                  <img
                    className="h-8 w-8 ml-2 rounded-full"
                    src={assets.guiIcon}
                  />
                </div>
              </div>

              <div
                className="flex-1 h-[45px] button flex py-3 bg-bgBlue rounded-lg cursor-pointer mx-3 mt-4 spin-now-button"
                onClick={onSpin}
                id="spin-button"
              >
                <p className="text-center mx-auto text-sm text-white lg:text-base font-SatoshiBold m-auto">
                  Spin Now
                </p>
              </div>
            </div>
          </div>
          {/*Last part*/}
          <div style={{ flex: 1.05 }}>
            <p className="text-base 2xl:text-lg font-bold font-SatoshiMedium text-black text-left m-2">
              Spin History
            </p>

            <div className="h-[52px] rounded-t-lg bg-greyVeryLight flex flex-row items-center justify-between p-2">
              <div className="flex flex-row items-center">
                <img className="w-5 h-5" src={assets.nftSpin} />
                <p className="pl-1 font-Satoshi ">Your Reward</p>
              </div>
              <p className="text-right font-Satoshi ">
                Reward Type
              </p>
            </div>
            {/* <div className="w-full h-[595px] border border-solid flex flex-col pt-1">
              {prizes.map((element) => (
                <div className="w-full h-[47px] flex flex-row items-center justify-between px-3">
                  <p className="font-Satoshi text-sm text-textGreyDark">
                    {element.title}
                  </p>
                  <p

                    className={clsx(`w-10 h-[18px] border border-solid flex items-center justify-center rounded-full text-xs  font-Satoshi bg-white cursor-default
                        ${
                          element.value === "NFT"
                            ? "pink-button text-[#f9379b] border border-[#F9379B]"
                            : ""
                        }
                        ${
                          element.value === "Token"
                            ? "green-gradient text-white border border-[#E1AB79]"
                            : ""
                        }
                        ${
                          element.value === "Ticket"
                            ? "blue-button border border-[#4A87F2] text-[#4A87F2]"
                            : ""
                        }
                        ${
                          element.value === "coin"
                            ? "gold-button border border-[#FFAA05] text-[#FFAA05]"
                            : ""
                        }`)}
                  >
                    {element.percent}
                  </p>
                </div>
              ))}
            </div> */}
            <div className="w-full h-[595px] border border-solid flex flex-col pt-2 gap-2 bg-neutral-100 overflow-y-scroll">
      
                {historyRewards.length!==0?historyRewards.slice().reverse().map((item, index) => (
                  <div key={index} className="h-[47px] flex flex-row items-center justify-between px-3 py-8 bg-white mx-2 rounded-[12px] hover:scale-95 duration-300 transform">
                    <p className="font-SatoshiMedium font-semiBold text-md text-green">
                      {item?.reward_tier === "0" ? before.pop() : 
                      item?.reward_tier > 0 && item?.reward_tier < 5 ? (item?.reward_amount / divisor).toFixed(2) + " GUI" : 
                      item?.reward_tier > 4 && item?.reward_tier < 9 ? item?.reward_amount + " Defy Coins" : 
                      item?.reward_amount + " Raffle Tickets"}
                    </p>
                    <img 
                      src={item?.reward_tier === "0" ? after.pop() : 
                          item?.reward_tier > 0 && item?.reward_tier < 5 ? assets.gui : 
                          item?.reward_tier > 4 && item?.reward_tier < 9 ? assets.defy1 : 
                          assets.raff} 
                      className="w-[55px] h-[55px] p-1"
                      alt="reward icon"
                    />
                  </div>
                )):
                <p className="font-SatoshiMedium text-base text-textGreyDark mx-auto text-center mt-[70px] max-[1200px]:mt-[40px]">
                  <span className="text-[72px]">😢</span><br />
                  <br />
                   "No more Rewards, play to get more"
                </p>
                  }
            </div>
          </div>
        </div>
        <EnterFortuneWheel
          open={openEnterRaffle}
          handleClose={handleCloseEnterRaffle}
          state={state}
          allRewards={allRewards}
          guiRewards={guiRewards}
          playerRewards={playerRewards}
        />
      </div>
    </>
  );
};

export default FortuneWheel;