import React, { useEffect, useState } from "react";
import modalImage from "../../../assets/emailmodal.png";
import loader from "../../../assets/loader-email.gif";
import defylogo from "../../../assets/Defy_logo.svg";
import OtpInput from "react-otp-input";
import clsx from "clsx";
import CONST from "../../../constants/constants";
import { Modal } from "@material-ui/core";
import axios from "axios";
import { toast } from "react-toastify";
import { UserContext } from "../../../contexts/UserContent";
import { useContext } from "react";

const EmailModal = ({ open, onClose }) => {
  const [email, setEmail] = useState("");
  const [verified, setVerified] = useState(false);
  const [otp, setOtp] = useState("");
  const [claimed, setClaimed] = useState(0);
  const [isValid, setValid] = useState(0);
  const [resendTimer, setResendTimer] = useState(30);
  const [resendDisabled, setResendDisabled] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const {user, setUser} = useContext(UserContext)



  function getUser() {
    //console.log("Getting User");
    const jwt = localStorage.getItem("AVEX_DEFY_JWT_STORE");
    const config = {
      headers: {
        Authorization: jwt,
      },
    };
    if (jwt) {
      axios.get(`${CONST.baseUrl}/users`, config).then((response) => {
        setUser(response.data.data);
        //console.log(response.data.data.points);
      });
    }
  }

  

  const handleVerify = () => {
    sendMail(email);
  };

  const startResendCountdown = async () => {
    await sendMail(email);
    setResendDisabled(true);
    let timer = setInterval(() => {
      setResendTimer((prevTimer) => prevTimer - 1);
    }, 1000);

    setTimeout(() => {
      clearInterval(timer);
      setIsLoading(true);
      setResendTimer(30);
      setResendDisabled(false);
    }, 30000);
  };

  useEffect(() => {
    if (resendTimer === 0) {
      setResendDisabled(false);
    }
  }, [resendTimer]);

  async function sendMail(email) {
    if (!/^[^\s@]+@[^\s@]+\.[^\s@]+$/.test(email.trim())) {
      //console.log("Fail");
    }

    const jwt = localStorage.getItem("AVEX_DEFY_JWT_STORE");
    const config = {
      headers: {
        Authorization: jwt,
      },
    };

    if (jwt) {
      setIsLoading(true);

      //console.log("sending");
      setValid(1);
      axios
        .request({
          url: `${CONST.baseUrl}/users/email`,
          method: "post",
          maxBodyLength: Infinity,
          headers: config.headers,
          data: {
            email: email,
          },
        })
        .then((response) => {
          setIsLoading(false);
          setVerified(true);
          toast("Verification mail sent!");
        })
        .catch(() => {
          setIsLoading(false);

          toast("Unable to send Verification mail!");
        });
    }
  }

  function verifyOTP(otp) {
    if (otp.length !== 6) return;

    const jwt = localStorage.getItem("AVEX_DEFY_JWT_STORE");
    const config = {
      headers: {
        Authorization: jwt,
      },
    };

    if (jwt) {
      //console.log("verifying");
      setOtp("")
      setIsLoading(true);

      axios
        .request({
          url: `${CONST.baseUrl}/users/email/verify`,
          method: "post",
          maxBodyLength: Infinity,
          headers: config.headers,
          data: {
            code: otp,
          },
        })
        .then((response) => {
          //console.log(response.statusText);
          setIsLoading(false);
          getUser()
          toast("Verification of mail successful!");
          setTimeout(() => {
            onClose()
          }, 1000);
        })
        .catch(() => {
          setIsLoading(false);
          setTimeout(() => {
            onClose()
          }, 1000);
          toast("Verification of mail unsuccessful");
        });
    }
  }

  useEffect(() => {
    verifyOTP(otp);
  }, [otp]);

  useEffect(() => {
    if (open) {
      setOtp("");
      setVerified(false);
      setValid(0);
      setEmail("");
    }
  }, [open]);

  if (!open) return;

  return (
    <Modal
    onClose={onClose}
    open={open}
    className="h-[600px] w-[800px] max-[768px]:h-[75vh] max-[768px]:w-[95vw] flex "
    style={{
      backgroundColor: "transparent",
      margin: "auto",
      overflow: "hidden",
      display: "flex",
      flexDirection: "column",
      // TODO: ADDED IN MERGE
  }}
>
<div className="w-full flex flex-col bg-white p-5 rounded-lg max-[768px]:h-[100%] max-[768px]:overflow-y-auto  !outline-none flex">
    
        <span
          className="absolute top-0 right-0 m-4 cursor-pointer text-lg text-white hover:text-gray-700"
          onClick={onClose}
        >
          &times;
        </span>
        <div className="flex items-center mb-2">
          <img src={defylogo} />
          <h2 className="text-xl font-SatoshiBold mb-2">Enter your Mail ID</h2>
        </div>
        {/* Add your email form or any other content */}
        <div className="md:flex md:flex-row ">
          {/* first container (info) */}
          <div className="w-full md:columns-md me-6">
            <img className="rounded" src={modalImage} alt="" />

            <p className="hidden md:block mt-4">
              Description : <br />
              Claim 500 coins by verifying your mail ID. This will keep you
              updated on all the new quests, rewards and upcoming updates. We
              promise not to spam your inbox. We hate spam as much as you do.
            </p>
          </div>
          {/* second container (verification) */}
          <div className="columns-2xl">
            {!verified ? (
              <>
                <h3 className="mt-10 md:mt-0 text-lg font-bold text-slate-500">
                  Enter Your Mail ID
                </h3>
                <input
                  className="w-3/4 block mx-auto md:w-full mt-3 p-2 border-2 border-slate-300 rounded-full !outline-none"
                  type="text"
                  onChange={(e) => {
                    setEmail(e.target.value);
                    if (e.target.value.trim() === "") {
                      setValid(0);
                    } else if (
                      !/^[^\s@]+@[^\s@]+\.[^\s@]+$/.test(e.target.value.trim())
                    ) {
                      //console.log("Fail");
                      setValid(-1);
                    } else {
                      setValid(1);
                    }
                  }}
                ></input>
                <div
                  className={clsx(
                    "w-1/2 mx-auto md:w-29 py-1 px-3 flex items-center rounded-[20px] mt-4 self-center justify-center ",
                    isValid == -1 && "bg-[#FFEBCC]",
                    isValid == 0 && "bg-[#FFB9B9]",
                    isValid == 1 && "bg-[#DEF1DA]"
                  )}
                >
                  <h1
                    className={clsx(
                      "text-nowrap text-sm font-SatoshiMedium",
                      isValid == -1 && "text-[#FF9D00]",
                      isValid == 0 && "text-[#FF6767]",
                      isValid == 1 && "text-[#58BB47]"
                    )}
                  >
                    {isValid == -1 && "🫤 Not a Valid Email"}
                    {isValid == 0 && "🤔 Enter an Email"}
                    {isValid == 1 && "😁 Email is Okay"}
                  </h1>
                </div>
                <div className="flex items-start mt-40 gap-1">
                  {/* <div className="flex items-center justify-center">
                    <img src={vector} className="w-1/7" alt="Vector" />
                  </div>
                  <div>
                    <p className=" font-semibold text-md text-slate-400 mb-2">
                      {claimed} claimed
                    </p>
                  </div> */}
                </div>

                {isLoading ? (
                  <div className="flex justify-center items-center">
                    <img src={loader} alt="Loading..." className="w-8 h-8" />
                  </div>
                ) : (
                  <button
                    disabled={isValid !== 1}
                    className={clsx("w-full  font-bold px-6 py-3 text-white bg-[#4A87F2] hover:bg-blue-700 border rounded-lg ", isValid == 1 ? "button" : "")}
                    onClick={handleVerify}
                  >
                    <p>Verify</p>
                  </button>
                )}
              </>
            ) : (
              !isLoading ? <>
                <h3 className="text-lg font-bold text-slate-500">Enter OTP</h3>
                <OtpInput
                  value={otp}
                  onChange={setOtp}
                  numInputs={6}
                  inputType="tel"
                  renderSeparator={<span></span>}
                  renderInput={(props) => <input {...props} />}
                  inputStyle="otp-input"
                />
                {/* please use blue instead of neutral for the resend and verify button. Am not able to do it on my system */}
                <div className="flex items-center justify-between">
                  <button
                    className={`font-medium text-sm mt-3 px-3 py-1 rounded-full ${
                      resendDisabled
                        ? "bg-gray-300 text-gray-600"
                        : "bg-[#4A87F2] text-white"
                    }`}
                    onClick={startResendCountdown}
                    disabled={resendDisabled}
                  >
                    Resend OTP
                  </button>
                  <div
                    className={`mt-3 ${
                      resendDisabled ? "bg-orange-200" : ""
                    } px-3 py-1 rounded-3xl text-sm text-orange-500`}
                  >
                    {resendDisabled && `${resendTimer}s`}
                  </div>
                </div>
              </> :  <div className="flex justify-center items-center w-full h-full">
                    <img src={loader} alt="Loading..." className="w-8 h-8" />
                  </div>
            )}
          </div>
      </div>
      </div>
    </Modal>
  );
};

export default EmailModal;