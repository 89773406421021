import { createContext, useCallback, useContext, useState } from "react";
import { ErrorAlert, SuccessAlert } from "../components/Alert";
import { isAptosNetwork } from "@aptos-labs/wallet-adapter-core";

export const AlertContext = createContext(undefined);

export function useAlert() {
  const context = useContext(AlertContext);
  if (!context)
    throw new Error("useAlert must be used within an AlertProvider");
  return context;
}

export const AlertProvider = ({ children }) => {
  const [successAlertMessage, setSuccessAlertMessage] = useState(null);
  const [errorAlertMessage, setErrorAlertMessage] = useState(null);

  const setSuccessAlertHash = useCallback((hash, network) => {
    if (isAptosNetwork(network)) {
      const explorerLink = `https://explorer.aptoslabs.com/txn/${hash}${
        network?.name ? `?network=${network.name}` : ""
      }`;
      return setSuccessAlertMessage(
        <>
          View on Explorer:{" "}
          <a
            className="underline"
            target="_blank"
            href={explorerLink}
            rel={"noreferrer"}
          >
            {explorerLink}
          </a>
        </>
      );
    }

    return setSuccessAlertMessage(<>Transaction Hash: {hash}</>);
  }, []);

  return (
    <AlertContext.Provider
      value={{
        setSuccessAlertHash,
        setSuccessAlertMessage,
        setErrorAlertMessage,
      }}
    >
      {successAlertMessage && (
        <SuccessAlert
          text={successAlertMessage}
          setText={setSuccessAlertMessage}
        />
      )}
      {errorAlertMessage && (
        <ErrorAlert text={errorAlertMessage} setText={setErrorAlertMessage} />
      )}
      {children}
    </AlertContext.Provider>
  );
};