import React, { useState,useEffect, useRef } from "react";
import Navbar from "../navbar/Navbar";
import { assets } from "../../constants";

const NFTRaffle = () => {
  const [tickets, setTickets] = useState(3);
  const [selectedTab, setSelectedTab] = useState("upcoming");
  const [ticketCount, setTicketCount] = useState(3);

  const [state,setState]=useState('')
  const events = [
    {
      title: "Scary draw",
      date: "from April 22, 2024",
      price: "$450",
      img: assets.ScaryDraw,
    },
    {
      title: "Christmas 2.1",
      date: "from April 22, 2024",
      price: "$450",
      img: assets.Christmas,
    },
    {
      title: "Scary draw",
      date: "from April 22, 2024",
      price: "$450",
      img: assets.ScaryDraw,
    },
    {
      title: "Christmas 2.1",
      date: "from April 22, 2024",
      price: "$450",
      img: assets.Christmas,
    },
    {
      title: "Scary draw",
      date: "from April 22, 2024",
      price: "$450",
      img: assets.ScaryDraw,
    },
  ];
  

  const [timer, setTimer] = useState("0:0:0");

  let interval = useRef();
  const startTimer= (() => {
    const countdown = new Date('june 2, 2024 00:00').getTime();

    const interval = setInterval(() => {
      const now = new Date();
      const distance =countdown - now;

      const days = Math.floor(distance / (1000 * 60 * 60 * 24));
      const hours = Math.floor(
        (distance % (1000 * 60 * 60 * 24)) / (1000 * 60 * 60)
      );
      const minutes = Math.floor((distance % (1000 * 60 * 60)) / (1000 * 60));

      if (distance < 0) {
        clearInterval(interval);
        setTimer("0:0:0");
        return;
      }else{
        setTimer(`${days}: ${hours}: ${minutes}`);
      }
    }, 1000);
  });

  useEffect(() => {
    startTimer();
    return () => {
      clearInterval(interval.current);
    };
  })

  return (
    <>
      <Navbar />
      <div className="main-body">
        <div className="flex items-start w-full lg:pt-5 xl:items-start px-20 pb-10 mt-[-2.66vh] max-[1024px]:flex-col max-[1024px]:px-4">
          {/* Left content*/}
          <div style={{flex:1}}>
            <p className="font-SatoshiMedium 2xl:text-2xl text-base text-textGreyDark text-left mt-[5px]">
              #31
            </p>
            <p className="font-SatoshiMedium 2xl:text-5xl text-3xl mt-4 text-left">
              NFT 
              <span className=" text-[#8058ff]">
              {" "}
              Raffle</span>
            </p>
            <p className="sm:w-[290px] 2xl:text-xl text-sm mt-6 font-SatoshiMedium text-textGreyDark text-left max-[1024px]:hidden">
              Get ready for a thrilling chance to jump the line! Our raffle
              feature offers an exciting opportunity for lucky participants
              to secure their spot on the Defy app waitlist. Simply enter
              the raffle, and if fortune smiles upon you, you'll be granted
              early access to the innovative Defy platform. Enter the raffle
              now and defy expectations!
            </p>
            {/*Rewards section*/}
            <div className="mt-12 flex flex-row w-[295px] justify-between p-1  max-[1024px]:hidden">
              <p className="text-base font-bold font-SatoshiMedium text-black text-left">
                Rewards</p>
              <p className="text-right  text-sm text-white cursor-pointer font-Satoshi rounded-full h-[29px] w-[90px] bg-[#F0B90B] flex items-center justify-center">
                Won 5 NFTs
              </p>
            </div>
            <div className="h-[169px] sm:w-[295px] 2xl:w-[310px] bg-greyVeryLight mt-3 rounded-xl p-2.5 max-[1024px]:hidden overflow-y-scroll">
              <div className="w-full h-[60px] bg-[#FFFFFF] rounded-xl flex items-center justify-between">
                <div className="ml-2 flex flex-row gap-3 text-textGreyDark">
                  <img src={assets.MoneyMan} className="h-12 w-12"/>
                  <div className="flex flex-col text-sm">
                    <p>Money Man</p>
                    <p>#26127</p>
                  </div>
                </div>
                <div className="mr-2 bg-black h-[25px] w-[64px] rounded-md flex justify-center items-center">
                  <p className="text-white text-sm">Claim</p>
                </div>
              </div>
            </div>
          </div>
          
          {/*Middle part*/}
          <div className="px-10 max-[1024px]:px-2  2xl:mt-5" style={{flex:2}}>
          <div className="flex justify-between items-start pt-10">
            <div>
              <p className="font-SatoshiBold 2xl:text-4xl text-3xl text-left text-black">
                <span className="font-SatoshiMedium 2xl:text-2xl text-lg text-textGreyDark text-left mr-3">#1</span>
                $GUI Giveaway
              </p>
              <p className="font-SatoshiMedium 2xl:text-lg text-[10px] text-left text-textGreyDark mt-4">
                RAFFLE FOR
                <br />{" "}
                <span className="text-[#00b061] 2xl:text-2xl text-xl">
                  {""}
                  5 NFTs
                </span>
              </p>
            </div>

            <div>
              <p className="font-SatoshiMedium 2xl:text-2xl text-base text-right text-textGreyDark">
                Draw Starts in
              </p>
              <div className="flex items-center ">
                <div className="mr-5 ">
                  <p className="text-xl 2xl:text-2xl font-SatoshiBold text-center text-black">
                    {timer.split(":")[0]}
                  </p>
                  <p className="text-xs 2xl:text-lg font-SatoshiMedium text-center text-textGreyDark">
                    Days
                  </p>
                </div>
                <div className="mr-5">
                  <p className="text-xl 2xl:text-2xl font-SatoshiBold text-center text-black">
                    {timer.split(":")[1]}
                  </p>
                  <p className="text-xs 2xl:text-lg font-SatoshiMedium text-center text-textGreyDark">
                    Hours
                  </p>
                </div>
                <div>
                  <p className="text-xl 2xl:text-2xl font-SatoshiBold text-center text-black">
                    {timer.split(":")[2]}
                  </p>
                  <p className="text-xs 2xl:text-lg  font-SatoshiMedium text-center text-textGreyDark">
                    Minutes
                  </p>
                </div>
              </div>
            </div>
          </div>
            <img className="w-full xl:h-[240px] h-[320px] lg:h-[196px] max-[1024px]:h-[200px] rounded-[20px] flex flex-col items-center justify-center my-6" 
            src={assets.NftBanner}/>
          {/*priority task*/}
          <div className="bg-[#F9F9F9] p-3 rounded-lg w-full h-[180px] mx-auto my-2">
                  <p className="text-sm font-Satoshi font-bold mb-1">Priority Task</p>
                  <div className="flex flex-col space-y-2">
                    <div className="flex items-center justify-between p-1 bg-greyVeryLight rounded mb-1">
                      <div className="flex items-center space-x-4">
                        <img src={assets.xLogo} alt="X-icon" className="w-10 h-10" />
                        <div className="font-Satoshi flex-col pr-2">
                          <p className=" font-bold text-base text-black ">
                            Follow Aptos Monkey on Twitter
                          </p>
                          <p className="font-medium text-[#656565] text-sm">
                            Follow @netzfamily on twitter and get chance to win
                            NFT
                          </p>
                        </div>
                      </div>
                      <button className="w-[67px] h-[28px] bg-black text-white rounded-full text-base pb-1 font-medium ">
                        Follow
                      </button>
                    </div>
                    <div className="flex items-center justify-between p-1 bg-greyVeryLight rounded">
                      <div className="flex items-center space-x-4">
                        <img src={assets.Discord} alt="Discord" className="w-10 h-10" />
                        <div className="font-Satoshi flex-col">
                          <p className=" font-bold text-base text-black">
                            Join Aptosmonkey Discord
                          </p>
                          <p className="font-medium text-[#656565] text-sm">
                            Join and verify on Aptosmonkey discord
                          </p>
                        </div>
                      </div>
                      <button className="w-[67px] h-[28px] bg-black text-white rounded-full text-base pb-1 font-medium ">
                        Join
                      </button>
                    </div>
                  </div>
          </div>
          {/*Buttons*/}
          <div className="w-full h-[117px] flex flex-col items-center mx-auto p-3">
            <div className="flex flex-row items-center justify-between w-full">
              <input className="w-[151px] h-[17px] font-inter text-[#BDBDBD] font-normal text-sm outline-none"
              type="text" placeholder="Enter Ticket Count"/>
              <div className="w-[105px] h-[28px] bg-greyVeryLight rounded-full flex flex-row justify-center items-center">
                <img className="w-[16.67px] h-[15px] m-2" src={assets.blueTicket}/>
                <p className="font-satoshi font-medium text-sm text-textGreyDark"> {ticketCount} Tickets</p>
              </div>
            </div>
      
            <div className="w-full flex flex-row gap-3 items-center mt-2">
              <div className="w-full h-[44px] rounded-lg border-[1px] border-[#CFD2D8] flex items-center justify-center cursor-pointer">
                <p className="font-inter font-semibold text-base text-textGreyDark">
                  Enter Tickets</p>
              </div>
              <div className="w-full h-[44px] rounded-lg border-[1px] bg-[#CFD2D8] flex items-center justify-center cursor-pointer">
                <p className="font-inter font-bold text-base text-white">
                  Spend Tickets</p>
              </div>
            </div>
            <div className="h-5 mx-3 mt-2 w-full flex justify-center items-center">
              <p className="font-Inter font-normal text-xs text-[#7B7B7B]">
                Need more raffle tickets? Buy here  
               <span className="font-inter font-normal text-xs text-[#232323] underline cursor-pointer pl-1">
                 Tickets!</span></p>
            </div>
          </div>
          </div>
          {/*Last part*/}
          
          <div className="max-[1024px]:w-full max-[1024px]:mt-4" style={{ flex:1}}>
              <p className="font-SatoshiBold text-xl text-black text-left p-6">
                Events
              </p>
              <div className="w-full h-[475px] bg-greyVeryLight p-2 rounded-xl mb-2">
                <div className="flex flex-row items-center justify-between w-full h-[45px] rounded-full bg-[#E1E4EA]">
                <button
                    className={`flex-1 h-[37px] text-sm font-medium ml-1 item-center rounded-full pb-1  ${
                      selectedTab === "upcoming"
                        ? "bg-white text-black"
                        : "text-[#979DAA]"
                    }`}
                    onClick={() => setSelectedTab("upcoming")}
                  >
                    <span>Upcoming</span>
                  </button>
                  <button
                    className={`flex-1 h-[37px] text-sm font-medium mr-1 item-center rounded-full pb-1 ${
                      selectedTab === "previous"
                        ? "bg-white text-black"
                        : "text-[#979DAA]"
                    }`}
                    onClick={() => setSelectedTab("previous")}
                  >
                    <span>Previous</span>
                  </button>
                </div>
                <div className="w-full h-full mt-4">
                {events.map((event) => (
                  <div
                    className="flex items-center justify-between mb-2 p-1 rounded-lg"
                  >
                    <div className="flex items-center">
                      <img
                        src={event.img}
                        alt={event.title}
                        className="w-[42px] h-[42px] mr-2 object-cover rounded-lg"
                      />
                      <div>
                        <p className="font-Satoshi font-medium text-black text-sm">{event.title}</p>
                        <p className="font-Satoshi text-[10px] text-textGreyDark font-medium">{event.date}</p>
                      </div>
                    </div>
                    <p className="font-Satoshi font-medium text-xs text-[#000000]">{event.price}</p>
                  </div>
                ))}
                </div>
              </div>
            </div>
        </div>
      </div>
    </>
  );
};

export default NFTRaffle;
