import yellowDiamond from "../../../assets/yellow_diamond.svg";
import clsx from "clsx";
import { toast, ToastContainer } from "react-toastify";
import { useWallet } from "@aptos-labs/wallet-adapter-react";

 const Task = ({ onclick, task, completed ,walletConnected, user}) => {


  const {connected, connect} = useWallet()


  if(new Date(task.notBeforeAt) > new Date())
    return


  return (
    <div
      onClick={() => {
        if (!connected) {
          toast("Wallet is not connected!");
          connect();
          return;
        }

        // if(task.taskType !== "connection" && !(user?.connections?.some((object)=> object?.provider == task.platform))){
        //   toast(`Please Verify ${task.platform} before proceeding further`)
        //   return
        // }

        if (new Date(task.expiresAt) <= new Date()) return;
        onclick(task, completed);
      }}
      className="mt-5 flex flex-col items-end cursor-pointer"
    >
      {task.boost.isBoosted && (
        <div className=" w-40 trapezoid rounded-tr-md">
          <p className="text-white font-SatoshiMedium text-[18px] m-auto pl-3">
            {task.boost.multiplier}x Rewards
          </p>
        </div>
      )}
      <div
        className={clsx(
          "w-full h-[64px] rounded-md  flex align-items justify-between p-2 task-holder  relative ",
          task.boost.isBoosted ? "bg-textBlue20" : "bg-greyVeryLight"
        )}
      >
        {task.boost.isBoosted && (
          <div className="w-full rounded-tl-md bg-bgBlue h-1 absolute top-0 left-0"></div>
        )}{" "}
        <div
          className={clsx("flex items-align", task.boost.isBoosted && "mt-1")}
        >
          <div className="flex overflow-hidden w-[56px] ">
            <img
              className="rounded-md object-cover h-full w-fit task-logo task-to-bottom"
              src={task.iconUrl}
            />
            <img
              className="rounded-md object-cover h-full w-fit ml-2 task-logo mt-[30px] task-to-top"
              src={task.iconUrl}
            />
          </div>
          <div className="ml-1 flex items-center justify-center ml-4">
            <p className="text-left font-SatoshiBold text-lg max-[600px]:text-base">
              {task.title}
            </p>
            {/* <p className="text-left font-SatoshiMedium text-base max-[600px]:text-sm">
               {task.description} 
            </p> */}
          </div>
        </div>
        <div
          className={clsx(
            "px-2 rounded-full bg-white flex items-center justify-center max-h-10 min-w-[120px] ",
            task.boost.isBoosted && "mt-1"
          )}
        >
          <p className="font-SatoshiBold text-base text-textGreyDark">
            {task.taskType == "onchain" ||
            task.taskType == "balance_check_coin" ||
            task.taskType == "balance_check_nft" ||
            task.taskType == "wapal_nft_activity_mint" ||
            task.taskType == "wapal_nft_activity_buy" ||
            task.taskType == "tradeport_nft_activity_mint" ||
            task.taskType == "tradeport_nft_activity_buy" ||
            task.taskType == "gui" ||
            task.taskType == "donk" || task.taskType == "zaaptos" || task.taskType == "cellana" || task.taskType == "aries_check"
              ? "Unlimited"
              : task.reward.points}
          </p>
          <img className="h-5 w-5 ml-2 max-[1024px]:ml-1" src={yellowDiamond} />
        </div>
      </div>
    </div>
  );
};

export default Task