import { useState, useEffect } from "react";
import { assets } from "../../../constants";
import axios from "axios";
import { fetchSlotsRemaningMythicChest, fetchUserClaimedOrNotMythicChest } from "../../../utils/indexer";
export default function ChestCard({
  startColour,
  endColour,
  image,
  onclick,
  data,
  chest,
}) {
  const [timer, setTimer] = useState("");
  // console.log(chest);
  useEffect(() => {
    const interval = setInterval(() => {
      const now = new Date();
      const distance = new Date(chest.notBeforeAt) - now;

      if (distance < 0) {
        clearInterval(interval);
        setTimer("opened");
        return;
      }

      const days = Math.floor(distance / (1000 * 60 * 60 * 24));
      const hours = Math.floor(
        (distance % (1000 * 60 * 60 * 24)) / (1000 * 60 * 60)
      );
      const minutes = Math.floor((distance % (1000 * 60 * 60)) / (1000 * 60));

      setTimer(`${days}d : ${hours}h : ${minutes}m`);
    }, 1000);

    return () => clearInterval(interval);
  }, [chest.notBeforeAt]);

  const [slotsRemainingState, setSlotsRemainingState] = useState(0);

  useEffect(() => {
    const updateSlotsRemaining = async () => {
      try {
        const slots = await fetchSlotsRemaningMythicChest();
        setSlotsRemainingState(slots);
      } catch (error) {
        console.error('Error fetching slots remaining:', error);
      }
    };

    updateSlotsRemaining(); // Initial fetch when component mounts

    const intervalId = setInterval(updateSlotsRemaining, 5000); // Update every 5 seconds

    // Cleanup interval on component unmount
    return () => clearInterval(intervalId);
  }, []); // Empty dependency array to ensure it only runs on mount


  

  return (
    <div
      onClick={() => {
        if (chest.type == "default") return;
        onclick(chest);
      }}
      className="h-[230px] min-[2100px]:h-[300px] min-[2100px]:w-[320px] !min-w-[220px] rounded-xl custom-shadow p-2.5 chest-card mr-4 cursor-pointer"
    >
      <div
        style={{
          backgroundImage: `url(${chest.bannerUrl})`,
          backgroundPosition: "center",
          backgroundSize: "cover",
        }}
        className="rounded-md w-full min-[2100px]:h-[170px] h-[130px] flex align-center justify-center blinking-div"
      >
        {/* <img className="h-[100px] w-[100px] m-auto object-contain" src={ele.iconUrl} alt='chest'/> */}
      </div>

      <div className="flex justify-between mt-2">
        <p className="font-SatoshiBold min-[2100px]:text-lg text-sm">
          {chest.chestName}
        </p>
        <div className="flex items-center">
          <p className="font-SatoshiMedium min-[2100px]:text-lg text-xs">
            {chest.price}
          </p>
          <img className="ml-1" src={assets.yellowDiamond} alt="yellowDiamond" />
        </div>
      </div>

      {chest.chestType.isPaid && (
        <div className="flex justify-between mt-1.5">
          <p className="font-SatoshiMedium min-[2100px]:text-lg text-xs text-grey">
            Slots
          </p>
          <div className="flex align-items">
            <p className="font-SatoshiMedium min-[2100px]:text-lg text-xs">
            {chest.chestName === "Mythic Chest" ? (
                          slotsRemainingState
                        ) : (
                          chest.slotsRemaining
                        )}
                        /
                        {/* Render total slots based on chest name */}
                        {(() => {
                          switch (chest.chestName) {
                            case "Mythic Chest":
                              return 100;
                            case "Legendary Chest":
                              return 175;
                            case "Epic Chest":
                              return 375;
                            case "Rare Chest":
                              return 700;
                            default:
                              return chest.totalSlots ?? 0;
                          }
                        })()}{" "}
            </p>
          </div>
        </div>
      )}

      {timer !== "opened" && (
        <div className="flex justify-between mt-1.5">
          <p className="font-SatoshiMedium min-[2100px]:text-lg text-xs text-grey">
            Opens in
          </p>

          <div className="flex align-items">
            <p className="font-SatoshiMedium min-[2100px]:text-lg text-xs text-textBlue">
              {timer}
            </p>
          </div>
        </div>
      )}
    </div>
  );
}
