import Modal from "@material-ui/core/Modal";
import blueTicket from "../../../assets/blueTicket.png"
import coinAnim from "../../../assets/coinAnim.gif"
import OtpInput from 'react-otp-input';
import { useState, useEffect } from "react";
import axios from "axios";
import CONST from "../../../constants/constants";
import { useWallet } from "@aptos-labs/wallet-adapter-react";
import clsx from "clsx";
import { useSearchParams } from "react-router-dom";
import { toast } from "react-toastify";

export default function ReferralEnterModal({ open, handleClose, openReferralModal }) {

    const [searchParams, setSearchParams] = useSearchParams();

    const [otp, setOtp] = useState(searchParams.get("referralCode") ?? '');
    const {
        account,
    } = useWallet();

    const handleAddReferral = (id) => {

        const jwt = localStorage.getItem("AVEX_DEFY_JWT_STORE");
        const config = {
            headers: {
                Authorization: jwt,
            },
        };


        if (jwt) {
            axios.get(`${CONST.baseUrl}/referral/add?referralCode=${id}`, config).then((response) => {
                localStorage.setItem(account?.address, "onboarded");
                setOtp("")
                if(response.data.success){
                    toast("Referral Succesfull")
                }else{
                    toast("Referral UnSuccesfull")

                }
                handleClose()
                
            }).catch(()=>{
                toast("Referral UnSuccesfull")

            });
        }
    };





    return (
        <Modal
            onClose={handleClose}
            open={open}
            style={{
                backgroundColor: "transparent",
                height: 420,
                width: 420,
                margin: "auto",
                color: "white",
                className: "NFTModal"
            }}
        >
            <div className="w-full h-full bg-white p-5 rounded-lg NFTModal">
                <p className="text-xl font-SatoshiBold mx-auto text-black text-center">Enter your referral code.</p>
                <img className="w-1/4 my-2 mx-auto" src={coinAnim} />

                <OtpInput

                    value={otp}
                    onChange={setOtp}
                    numInputs={6}
                    className="mx-auto"
                    renderSeparator={<span className="">-</span>}
                    containerStyle={{
                        alignItems: 'center',
                        justifyContent: 'center'
                    }}
                    inputStyle={{
                        borderWidth: 1,
                        borderRadius: 8,
                        backgroundColor: 'rgba(217,217,217,0.2)',
                        borderColor: 'rbga(0,0,0,0.1)',
                        width: 40,
                        color:'black',
                        fontFamily:'SatoshiMedium',
                        height: 40
                    }}
                    renderInput={(props) => <input {...props} />}
                />

                <p className="text-center text-base font-SatoshiMedium text-black mt-6">
                    You and your friend will get 2,500 coins each once you both earn 20,000 Defy Coins.
                </p>

                <p onClick={()=>{
                    openReferralModal()
                    handleClose()
                }} className="text-center text-sm font-SatoshiMedium text-textBlue mt-4 cursor-pointer">
                    Share your referral code
                </p>

                <div className="flex items-center justify-betweem">
                    <div
                        onClick={() => {
                            handleClose()
                        }}
                        className="px-6 flex-1 py-2.5 rounded-lg bg-black flex items-center justify-center mt-3 mx-2 cursor-pointer">
                        <p className="font-SatoshiMedium text-base text-white">Skip Now</p>
                    </div>

                    <div
                    
                        onClick={() => {
                            if (otp.length == 6) {
                                handleAddReferral(otp)

                            }
                        }}
                        className={clsx("px-6 flex-1  py-2.5 rounded-lg bg-bgBlue flex items-center justify-center mt-3 mx-2 cursor-pointer", otp.length !== 6 && "is-disabled")}>
                        <p className="font-SatoshiMedium text-base text-white">Okay!</p>
                    </div>

                </div>
            </div>

        </Modal>
    )
}