import Modal from "@material-ui/core/Modal";
import { assets } from "../../../constants";
import React, { useState, useEffect } from "react";

export default function ChestMessagePopup({ open, handleClose }) {

    const [timeRemaining, setTimeRemaining] = useState("");

    useEffect(() => {
        const targetDate = new Date(Date.UTC(2024, 8, 12, 12, 0, 0)); // Date.UTC(year, month-1, day, hour, minute, second)
        // const targetDate = new Date(Date.UTC(2024, 6, 16, 12, 0, 0)); //to test
        const updateCountdown = () => {
            const now = new Date();
            const difference = targetDate - now;

            if (difference > 0) {
                const days = Math.floor(difference / (1000 * 60 * 60 * 24));
                const hours = Math.floor((difference % (1000 * 60 * 60 * 24)) / (1000 * 60 * 60));
                const minutes = Math.floor((difference % (1000 * 60 * 60)) / (1000 * 60));
                // const seconds = Math.floor((difference % (1000 * 60)) / 1000);

                setTimeRemaining(`${days}d ${hours}h ${minutes}m`);
            } else {
                setTimeRemaining("The chest is now open!");
            }
        };

        const intervalId = setInterval(updateCountdown, 1000);

        return () => clearInterval(intervalId); // Clear interval on component unmount
    }, []);
    
    return (
        <Modal
        onClose={handleClose}
        open={open}
        style={{
            backgroundColor: "transparent",
            margin: "auto",
            color: "white",
        }}
        className="w-[780px] max-[1024px]:w-[90vw] h-[500px] !outline-none max-[1024px]:h-[530px]"
    >
        <div 
            className={`text-textGreyDark w-full h-full xl:p-8 p-5 rounded-lg max-[1024px]:w-[90vw] flex flex-col !outline-none bg-cover bg-center bg-no-repeat relative`}
        >
            <img
                src={assets.chestMessageBG}
                alt="Background"
                className="absolute inset-0 w-full h-full object-cover rounded-lg"
            />
            <button 
                onClick={handleClose}
                className="absolute text-white md:-top-12 top-1 right-1 md:right-2 rounded-full p-2 transition duration-300"
            >
                <img src={assets.cross} className="h-5 w-5" />
            </button>
            <div className="flex flex-col justify-between h-full md:mt-0 mt-4">
                <div className=" font-semibold text-xl max-[1024px]:text-lg z-10 flex justify-between gap-2"> 
                    <div>The Defy Insiders</div> 
                    <div className="">Opens in: {timeRemaining} </div>
                </div>
                <div className=" text-lg z-10 xl:mb-4 justify-center text-center flex">
                    {timeRemaining==="The chest is now open!"?"THE MYTHIC CHEST IS NOW OPEN!!" : "The Mythic Chest now opens on 24th July."}
                    <br />
                    The Diamond Insider NFT awaits! Dont miss this event as there are only 100 spots.
                </div>
            </div>
        </div>
    </Modal>

    )
}