import aptosLogo from "../assets/aptosLogo.svg"
import carousel1 from "../assets/carousel1.png"
import carousel2 from "../assets/carousel2.png"
import nftUnavailable from "../assets/nftUnavailable.png"
import blueTicket from "../assets/blueTicket.png"
import coinRaffleBg from "../assets/coinRaffleBg.jpg"
import groupProfile from "../assets/groupProfile.svg"
import search from "../assets/search.svg"
import close from "../assets/close.svg"
import guiIcon from "../assets/guiIcon.jpeg"
import celebrationBg from "../assets/celebrationBg.png"
import coinAnim from "../assets/coinAnim.gif"
import yellowDiamond from "../assets/yellow_diamond.svg"
import defy from "../assets/Defy_logo.svg"
import caretDown from "../assets/caretDown.svg"
import caretDownInactive from "../assets/caretDownInactive.svg"
import chest from "../assets/chest.svg"
import menu from "../assets/menu.svg"
import coinRaffle from "../assets/coinRaffle.svg"
import nftSpin from "../assets/nftSpin.svg"
import treasure from "../assets/treasure.svg"
import chestModalBannerPaid from "../assets/chestModalBannerPaid.png"
import chestModalBanner from "../assets/chestModalBanner.png"
import diceRollBg from "../assets/diceRollBg.png"
import dice1n2 from "../assets/dice1n2.png"
import diceIcon from "../assets/diceIcon.png"
// import dice1 from "../assets/dice1.png"
import dice2 from "../assets/dice2.png"
import dice3 from "../assets/dice3.png"
import dice4 from "../assets/dice4.png"
import dice5 from "../assets/dice5.png"
import dice6 from "../assets/dice6.png"
import dice7 from "../assets/dice7.png"
import dice8 from "../assets/dice8.png"
import dice9 from "../assets/dice9.png"
import dice10 from "../assets/dice10.png"
import dice11 from "../assets/dice11.png"
import dice12 from "../assets/dice12.png"
import rightArrow from "../assets/rightArrow.png"
import defyImg from "../assets/defyImg.png"
import dice from "../assets/diceIcon.png"

import MoneyMan from "../assets/MoneyMan.png";
import NftBanner from "../assets/nftbanner.png";
import ScaryDraw from "../assets/ScaryDraw.png";
import Christmas from "../assets/Christmas.png";
import Discord from "../assets/discordLogo.png";
import xLogo from "../assets/xLogo.png";
import reward from "../assets/reward.png";
import fortuneWheel from "../assets/fortunewheelOuter.png";

import coin from '../assets/coin.png';
import fomo from '../assets/fomo.png'
import defy1 from "../assets/defy.png"
import gui from "../assets/gui.png"
import fomoCoin from "../assets/fomoCoin.png"
import wheel from "../assets/Wheel.png"
import marker from '../assets/marker.png'
import raff from "../assets/raff.png"
import guiIcon1 from "../assets/guiIcon1.png"
import wheelHome from "../assets/wheelHome.png"
import coinToss from "../assets/coinToss.png"
import zaap from "../assets/zaap icon.png"
import coinFlip from "../assets/coinFlip.png"
import coinFlipLogo from "../assets/Games/Coin Flip Logo.png"
import wheelLogo from "../assets/Games/wHEEL logo.png"
import diceLogo from "../assets/Games/Dice logo.png"
import chance from "../assets/chance.png"
import participants from "../assets/participants.png"
import nftSpinLogo from "../assets/Games/NFT Spin logo.png"
import plinkoLogo from "../assets/Games/plinko logo.png"
import fof from "../assets/404.png"
import plinkoHome from "../assets/plinkoHome.png"
import aptosHeads from "../assets/aptosHeads.png"
import aptosTails from "../assets/aptosTails.png"
import chestMessageBG from "../assets/chestMessageBG.png"
import cross from "../assets/cross.png"
import checklist from "../assets/checklist.png"
import line from "../assets/line.png"
import emailImage from "../assets/emailImage.png"
import avexChest from "../assets/avexChest.png"



export default {
   avexChest,
   emailImage,
   line,
   checklist,
   cross,
   chestMessageBG,
   aptosHeads,
   aptosTails,
   plinkoHome,
   fof,
   plinkoLogo,
   nftSpinLogo,
   participants,
   chance,
   coinFlipLogo,
   wheelLogo,
   diceLogo,
   coinFlip,
   zaap,
   coinToss,
   fomoCoin,
   gui,
   defy1,
   wheelHome,
   dice,
   defyImg,
   aptosLogo,
   dice1n2,
   carousel1,
   diceIcon,
   carousel2,
   chestModalBanner,
   diceRollBg,
   chestModalBannerPaid,
   nftUnavailable,
   blueTicket,
   guiIcon,
   treasure,
   celebrationBg,
   coinRaffleBg,
   groupProfile,
   close,
   search,
   coinAnim,
   yellowDiamond,
   chest,
   coinRaffle,
   menu,
   nftSpin,
   defy,
   caretDown,
   caretDownInactive,
   dice2,
   dice3,
   dice4,
   dice5,
   dice6,
   dice7,
   dice8,
   dice9,
   dice10,
   dice11,
   dice12,
   rightArrow,
   MoneyMan,
   NftBanner,
   Christmas,
   ScaryDraw,
   Discord,
   xLogo,
   reward,
   guiIcon1,
   fortuneWheel,
   coin,
   fomo,
   gui,
   defy1,
   raff,
   marker,
   wheel,
}