import React, { useState } from 'react'
import { Modal } from '@material-ui/core';
import { assets } from "../../constants";

function DiceRollRewardModal({ isOpen, onClose, reward, currentReward, chances, divider, referral }) {
    const [viewMoreIsOpen, setViewMoreIsOpen] = useState(false);
    
    if (!isOpen) return null;
    
    let totalWonList = [];
    let totalLostList = [];
    let totalDiceValueList = [];
    let currentRoundsData = []
    

    const handleOnClick = () => {
        setViewMoreIsOpen(prevState => !prevState);
        // console.log("clicked");
    }

    for(let i=reward.length-1; i>reward.length-chances-1;i--){
      totalWonList.push((reward[i].amount_won)/divider)
      totalLostList.push(parseFloat(reward[i].defy_coins_won))
      totalDiceValueList.push((reward[i].sum))
      currentRoundsData.push(reward[i])
    }

    let nonZeroBetAmountsKeys = [];
    let nonZeroBetAmountList = [];
    
    for (let i = 0; i < currentReward.bet_amounts.length; i++) {
        if (currentReward.bet_amounts[i] !== "0") {
            nonZeroBetAmountsKeys.push((parseInt(i) + 2).toString());
            nonZeroBetAmountList.push(currentReward.bet_amounts[i] / divider);
        }
    }
    // console.log(referral);

    let totalWonAmount = totalWonList.reduce((accumulator, currentValue) => accumulator + currentValue, 0);
    let totalLostAmount = totalLostList.reduce((accumulator, currentValue) => accumulator + currentValue, 0);

    const currentItem = [];
    const multipliers = {
        2 : 12,
        3 : 10,
        4 : 8,
        5 : 6,
        6 : 4,
        7 : 2,
        8 : 4,
        9 : 6,
        10 : 8,
        11 : 10,
        12 : 12,
    }
    for(let i=0; i<currentReward.bet_amounts.length; i++) {
        let finalAmount;
        if(i+2==parseInt(currentReward.sum)){
            finalAmount = parseInt(currentReward.bet_amounts[i])*multipliers[i+2];
        } else {
            finalAmount = 0;
        }
        if(currentReward.bet_amounts[i]!='0') {currentItem.push({
            sum: i+2,
            amount: currentReward.bet_amounts[i],
            multipliers: multipliers[i+2],
            finalAmount: finalAmount
        })}
    }
    for(let i=0; i<currentItem.length;i++){
        
    }


    if (!isOpen) return null;
    return (
        <>
            <Modal 
                onClose={onClose}
                open={isOpen}
                className={` ${viewMoreIsOpen===true?"h-[700px]":"h-[600px]" } w-[450px] max-[1024px]:w-[85vw] `}
        
                style={{
                  backgroundColor: "transparent",
                  margin: "auto",
                  overflow: "hidden",
                  display: "flex",
                  flexDirection: "column",
                }}
            >
                <div className=" bg-white xl:min-h-[506px] py-5 px-6 rounded-[12px] max-[1024px]:w-[85vw] max-[1024px]:h-[100%] border-black border-2">
                    <div className="flex justify-center">
                        <h1 className="text-lg font-bold mb-2">{chances>"1"? "Your Rewards! 🎉" : currentReward.amount_won>0? "Congratulations, You Won! 🎉" : "You Lost, Roll Again! 😢" }</h1>
                    </div>
                    <div className="mt-6 flex justify-center">
                    {chances > 1 && totalWonAmount>0
                    ? (
                        <div className='flex flex-row'>
                            <img src={assets.guiIcon} className="h-14 w-14 object-cover rounded" />
                            <img src={assets.defyImg} className="h-16 w-16 object-cover rounded -translate-y-1" />
                        </div>
                    ) : 
                    currentReward.amount_won > 0 ? (
                        <img src={assets.guiIcon} className="h-14 w-14 object-cover rounded" />
                    ) : (
                        <img src={assets.defyImg} className="h-16 w-16 object-cover rounded" />
                    )}

                        
                    </div>
                    <div className={`mt-6 flex justify-center text-3xl font-bold text-center ${chances>"1"? "text-green" :currentReward.amount_won>0?"text-green":""}`}>
                        { chances>"1"? (totalWonAmount!==0?(parseFloat(totalWonAmount.toFixed(4)) + " GUI & "):"") + totalLostAmount + " DEFY COINS" : currentReward.amount_won>0? parseFloat(totalWonAmount.toFixed(4)) + " GUI" : parseFloat(totalLostAmount) + " DEFY COINS" }
                    </div>
                    <div className='flex flex-col mt-10'>
                        <div className="flex items-center justify-between">
                            <h4 className="text-lg text-textGreyDark">You bet on:</h4>
                            <h4 className='text-lg font-semibold'>
                                {currentReward.bet_type==="1"?"Dice Sum: "+(currentReward.side?"EVEN":"ODD"): "Dice Sum: "+ nonZeroBetAmountsKeys.join(", ")}
                            </h4>
                        </div>
                        <div className="flex items-start justify-between">
                            <h4 className="text-lg text-textGreyDark">You bid:</h4>
                            <h4 className='text-lg font-semibold'>{(currentReward.total_bet_amount/divider)*chances} GUI</h4>
                        </div>
                        <div className="flex items-start justify-between">
                            <h4 className="text-lg text-textGreyDark">Dice Value:</h4>
                            <h4 className='text-lg font-semibold'>{chances>0?totalDiceValueList.join(", "):currentReward.sum}</h4>
                        </div>

                        <div className='justify-end flex'>
                        {chances>"1" && 
                                <button 
                                    className='text-md text-[#2071EE] hover:bg-neutral-100 ease-in-out duration-150 translate-x-2 rounded-lg px-2 py-1'
                                    onClick={()=>handleOnClick()}
                                >
                                    View More
                                </button> 
                        } 
                        </div>
                        
                        {viewMoreIsOpen && (
                            <div className='rounded-xl w-full bg-greyVeryLight flex-col gap-2 py-2.5 px-2 2xl:h-[100px] h-[150px] overflow-y-scroll'>
                                <div className='justify-between flex'>
                                    <div className='text-textGreyDark'>Your Bets: </div>
                                    {currentReward.bet_type==="0"?
                                        <div className='  lg:w-[260px] flex flex-wrap gap-1 md:gap-2 justify-end'>
                                            {currentItem.map((ele, inx) => (
                                                <div key={inx} className='flex flex-row flex-wrap font-semibold'>
                                                    {ele.sum} - {parseFloat((ele.amount / divider).toFixed(4))} <img src={assets.guiIcon} className="h-6 w-6 ml-1 object-cover rounded" />
                                                </div>
                                            ))}
                                        </div>
                                    :
                                        
                                        <div className='flex flex-wrap gap-1 md:gap-2 justify-between font-semibold'>
                                            {currentReward.side?"Even - " : "Odd - "} {parseFloat((currentReward.total_bet_amount/divider).toFixed(4))} <img src={assets.guiIcon} className="h-6 w-6 object-cover rounded" />
                                        </div>
                                        
                                    }
                                </div>
                                <div>
                                    {currentRoundsData.map((ele,inx) => (
                                        <div key={inx} className='justify-start flex flex-col gap-1'>
                                            <div className='mt-2 font-semibold'>
                                                Round {inx+1}
                                            </div>
                                            <div className={`flex justify-between `}>
                                                <div className='text-textGreyDark '>Result:</div>
                                                <div className={`text-green font-semibold`}>{ele.amount_won>0?parseFloat(((ele.amount_won)/divider).toFixed(4)) + " GUI":parseFloat((ele.defy_coins_won))+" Defy coins"} </div>
                                            </div>
                                            <div className='flex justify-between'>
                                                <div className='text-textGreyDark '>Dice Value:</div>
                                                <div className='font-semibold'>{ele.sum}</div>
                                            </div>
                                            
                                        </div>
                                    ))}
                                    
                                </div>
                                
                            </div>
                        
                        )}
                        

                    </div>
                    <div className='mt-6 justify-center align-center text-center'>
                        <a 
                            className='text-[#2071EE] mr-1' 
                            href={`https://twitter.com/intent/tweet?text=I%20am%20playing%20%22Dice%20Roll%22%20by%20%40defyapp_%20and%20its%20super%20cool.%0A%0AIt%20is%20built%20using%20the%20%40Aptos%20Randomness%20API%20to%20ensure%20transparency%20and%20fairness.%F0%9F%8E%B2%0A%0AGo%20test%20your%20luck%20and%20have%20fun%20https%3A%2F%2Fwaitlist.avex.id%2F%3FreferralCode%3D${referral}`}
                            target='_blank'
                        >
                                Share
                        </a>
                        with friends and get 2500 Defy coins on every successful referral.
                    </div>
                    <button 
                        className='mt-6 justify-center flex align-center text-center bg-[#2071EE] w-full font-bold py-4 text-white rounded-lg transform ease-in-out duration-150 hover:scale-90'
                        onClick={onClose}
                    >
                        Done
                    </button>
                </div>
            </Modal>
        </>
    )
}

export default DiceRollRewardModal