import React, { useEffect, useState } from "react";
import xLogo from "../../assets/xLogo.png";
import axios from "axios";
import CONST from "../../constants/constants";
import { UserContext } from "../../contexts/UserContent";
import { useContext } from "react";
import discordLogo from "../../assets/discordLogo.png";
import telegramLogo from "../../assets/telegramLogo.png";
import { useWallet } from "@aptos-labs/wallet-adapter-react";
import clsx from "clsx";
import Navbar from "../navbar/Navbar";

const LeaderboardEntry = ({
  rank,
  address,
  coins,
  tickets,
  filter,
  socials,
  isUser = false,
}) => (
  <div className={`${isUser ? "rainbow px-0.5" : "px-0.5"}  py-0.5 md:mb-2 min-[1024px]:rounded-[12px] rounded-[8px]`}>
    <div
      className={`${isUser && " "
        } xl:!px-3  md:grid md:grid-cols-4 md:lg:grid-cols-5 font-SatoshiMedium gap-4 md:gap-4 md:items-center  md:justify-between  lg:p-4 min-[1024px]:rounded-[10px] rounded-[6px] min-[1024px]:pl-0 pl-2 flex justify-between bg-white`}
    >
      <div className="font-bold text-black">{rank}</div>
      <div className="text-black lg:hidden">
        {address.substring(0, 4) + "...." + address.slice(-2)}
      </div>
      <div className="text-black hidden lg:block">{address}</div>
      <div className={clsx("text-black font-bold flex items-center w-1/3 lg:gap-1", filter == 1 && "hidden lg:flex")}>
        {coins}
      </div>
      <div className={clsx("text-black font-bold flex items-center w-1/3 lg:gap-1", filter == 0 && "hidden lg:flex ")}>
        {tickets ?? 0}
      </div>

      <div className=" hidden lg:flex">
        <a
          target="_blank"
          rel="noopener noreferrer"
          className="text-gray-600 hover:text-gray-800 mx-1 flex items-center"
        >
          {socials["twitter"] && (
            <a
              className="mr-2 cursor-pointer"
              href={"https://twitter.com/" + socials["twitter"]}
              target="_blank"
            >
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width="24"
                height="24"
                viewBox="0 0 24 24"
                fill="none"
              >
                <g clip-path="url(#clip0_2416_8706)">
                  <mask
                    id="mask0_2416_8706"
                    styles="mask-type:luminance"
                    maskUnits="userSpaceOnUse"
                    x="0"
                    y="0"
                    width="24"
                    height="24"
                  >
                    <path d="M24 0H0V24H24V0Z" fill="white" />
                  </mask>
                  <g mask="url(#mask0_2416_8706)">
                    <path
                      d="M21.1875 0H2.8125C1.2592 0 0 1.2592 0 2.8125V21.1875C0 22.7408 1.2592 24 2.8125 24H21.1875C22.7408 24 24 22.7408 24 21.1875V2.8125C24 1.2592 22.7408 0 21.1875 0Z"
                      fill="black"
                    />
                    <path
                      d="M16.6829 4.6875H19.1639L13.7437 10.8825L20.1202 19.3125H15.1274L11.2169 14.1998L6.74241 19.3125H4.25993L10.0574 12.6863L3.94043 4.6875H9.05991L12.5947 9.36075L16.6829 4.6875ZM15.8122 17.8275H17.1869L8.31291 6.0945H6.83766L15.8122 17.8275Z"
                      fill="white"
                    />
                  </g>
                </g>
                <defs>
                  <clipPath id="clip0_2416_8706">
                    <rect width="24" height="24" fill="white" />
                  </clipPath>
                </defs>
              </svg>
            </a>
          )}
          {/* {socials["discord"] && (
            <a
              className="mr-0.5 cursor-pointer"
              href={"https://discord.com/" + socials["discord"]}
              target="_blank"
            >
              <svg
                className="mr-2"
                xmlns="http://www.w3.org/2000/svg"
                width="24"
                height="24"
                viewBox="0 0 24 24"
                fill="none"
              >
                <g clip-path="url(#clip0_2416_8713)">
                  <path
                    d="M24 12C24 5.37258 18.6274 0 12 0C5.37258 0 0 5.37258 0 12C0 18.6274 5.37258 24 12 24C18.6274 24 24 18.6274 24 12Z"
                    fill="#7289DA"
                  />
                  <path
                    d="M18.0184 7.88986C16.9175 7.00911 15.5963 6.56876 14.2018 6.49536L13.9817 6.71556C15.2294 7.00911 16.3303 7.59626 17.3578 8.40361C16.1101 7.74306 14.7156 7.30271 13.2477 7.15591C12.8074 7.08251 12.4403 7.08251 12 7.08251C11.5596 7.08251 11.1927 7.08251 10.7523 7.15591C9.2844 7.30271 7.8899 7.74306 6.6422 8.40361C7.6697 7.59626 8.77065 7.00911 10.0183 6.71556L9.79815 6.49536C8.40365 6.56876 7.08255 7.00911 5.98165 7.88986C4.73394 10.2385 4.07339 12.8807 4 15.5963C5.1009 16.7706 6.6422 17.5046 8.2569 17.5046C8.2569 17.5046 8.77065 16.9174 9.1376 16.4036C8.1835 16.1835 7.30275 15.6697 6.7156 14.8624C7.22935 15.1559 7.7431 15.4495 8.2569 15.6697C8.91745 15.9633 9.578 16.1101 10.2386 16.2569C10.8257 16.3302 11.4128 16.4036 12 16.4036C12.5871 16.4036 13.1743 16.3302 13.7614 16.2569C14.422 16.1101 15.0826 15.9633 15.7431 15.6697C16.2569 15.4495 16.7707 15.1559 17.2844 14.8624C16.6973 15.6697 15.8165 16.1835 14.8624 16.4036C15.2294 16.9174 15.7431 17.5046 15.7431 17.5046C17.3578 17.5046 18.8991 16.7706 20 15.5963C19.9266 12.8807 19.2661 10.2385 18.0184 7.88986ZM9.578 14.2752C8.84405 14.2752 8.1835 13.6146 8.1835 12.8073C8.1835 12 8.84405 11.3394 9.578 11.3394C10.312 11.3394 10.9725 12 10.9725 12.8073C10.9725 13.6146 10.312 14.2752 9.578 14.2752ZM14.422 14.2752C13.6881 14.2752 13.0275 13.6146 13.0275 12.8073C13.0275 12 13.6881 11.3394 14.422 11.3394C15.1559 11.3394 15.8165 12 15.8165 12.8073C15.8165 13.6146 15.1559 14.2752 14.422 14.2752Z"
                    fill="white"
                  />
                </g>
                <defs>
                  <clipPath id="clip0_2416_8713">
                    <rect width="24" height="24" fill="white" />
                  </clipPath>
                </defs>
              </svg>
            </a>
          )} */}

          {socials["telegram"] && (
            <a
              className="mr-2 cursor-pointer"
              href={"https://t.me/" + socials["telegram"]}
              target="_blank"
            >
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width="24"
                height="24"
                viewBox="0 0 24 24"
                fill="none"
              >
                <g clip-path="url(#clip0_2416_8716)">
                  <path
                    d="M24 12C24 5.37258 18.6274 0 12 0C5.37258 0 0 5.37258 0 12C0 18.6274 5.37258 24 12 24C18.6274 24 24 18.6274 24 12Z"
                    fill="#419FD9"
                  />
                  <path
                    d="M24 12C24 5.37258 18.6274 0 12 0C5.37258 0 0 5.37258 0 12C0 18.6274 5.37258 24 12 24C18.6274 24 24 18.6274 24 12Z"
                    fill="url(#paint0_linear_2416_8716)"
                  />
                  <path
                    d="M5.3937 11.7354C8.88335 10.1831 11.2103 9.15973 12.3746 8.66523C15.6989 7.25348 16.3897 7.00823 16.84 7.00008C16.939 6.99838 17.1604 7.02343 17.3038 7.14223C17.4249 7.24258 17.4582 7.37813 17.4742 7.47323C17.4901 7.56838 17.51 7.78508 17.4942 7.95438C17.314 9.88698 16.5346 14.5769 16.138 16.7415C15.9702 17.6574 15.6398 17.9645 15.3199 17.9945C14.6248 18.0598 14.0969 17.5255 13.4236 17.0748C12.37 16.3697 11.7749 15.9307 10.7522 15.2427C9.57035 14.4475 10.3365 14.0104 11.01 13.2961C11.1863 13.1092 14.2491 10.2647 14.3084 10.0067C14.3158 9.97443 14.3227 9.85413 14.2527 9.79063C14.1827 9.72713 14.0794 9.74883 14.0049 9.76608C13.8992 9.79058 12.2161 10.9264 8.95565 13.1736C8.4779 13.5086 8.0452 13.6717 7.6575 13.6632C7.2301 13.6538 6.40795 13.4165 5.79675 13.2136C5.0471 12.9648 4.45127 12.8333 4.50315 12.4107C4.53017 12.1906 4.82701 11.9655 5.3937 11.7354Z"
                    fill="white"
                  />
                </g>
                <defs>
                  <linearGradient
                    id="paint0_linear_2416_8716"
                    x1="12"
                    y1="0"
                    x2="12"
                    y2="23.822"
                    gradientUnits="userSpaceOnUse"
                  >
                    <stop stop-color="#2AABEE" />
                    <stop offset="1" stop-color="#229ED9" />
                  </linearGradient>
                  <clipPath id="clip0_2416_8716">
                    <rect width="24" height="24" fill="white" />
                  </clipPath>
                </defs>
              </svg>
            </a>
          )}
          {Object.keys(socials).length == 0 && (
            <div className="flex gap-2 items-center">
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width="18"
                height="18"
                viewBox="0 0 18 18"
                fill="none"
              >
                <path
                  d="M14.625 5.62494H12.375V3.93744C12.375 3.04233 12.0194 2.18389 11.3865 1.55095C10.7536 0.918018 9.89511 0.562439 9 0.562439C8.10489 0.562439 7.24645 0.918018 6.61351 1.55095C5.98058 2.18389 5.625 3.04233 5.625 3.93744V5.62494H3.375C3.07663 5.62494 2.79048 5.74347 2.5795 5.95444C2.36853 6.16542 2.25 6.45157 2.25 6.74994V14.6249C2.25 14.9233 2.36853 15.2095 2.5795 15.4204C2.79048 15.6314 3.07663 15.7499 3.375 15.7499H14.625C14.9234 15.7499 15.2095 15.6314 15.4205 15.4204C15.6315 15.2095 15.75 14.9233 15.75 14.6249V6.74994C15.75 6.45157 15.6315 6.16542 15.4205 5.95444C15.2095 5.74347 14.9234 5.62494 14.625 5.62494ZM6.75 3.93744C6.75 3.3407 6.98705 2.76841 7.40901 2.34645C7.83097 1.92449 8.40326 1.68744 9 1.68744C9.59674 1.68744 10.169 1.92449 10.591 2.34645C11.0129 2.76841 11.25 3.3407 11.25 3.93744V5.62494H6.75V3.93744ZM14.625 14.6249H3.375V6.74994H14.625V14.6249Z"
                  fill="#646D80"
                />
              </svg>
              <h1 className="text-base text-[#646D80] font-Satoshi">
                {" "}
                Private{" "}
              </h1>
            </div>
          )}
        </a>
      </div>
    </div>
  </div>
);


const LeaderboardList = ({ rank, entries, filter, setFilter }) => {
  const { user } = useContext(UserContext);
  const { connected, account } = useWallet();

  const sortedEntries = [...entries].slice(0, 200);
  const [myRankVal, setMyRankVal] = useState(0);


  const findMyRank = () => {
    for (let i = 0; i < sortedEntries.length; i++) {
      // console.log(i,sortedEntries[sortedEntries.length-1].totalPointsEarned, user?.walletAddress)
      if (user?.walletAddress == sortedEntries[i]?.walletAddress) {
        
        setMyRankVal(i);
        return;
      }
    }
    // If wallet address not found in the list, set rank to -1 or a specific value
    setMyRankVal(0);
  };

  useEffect(() => {
    findMyRank();
  }, [user, sortedEntries]);

  return (
    <div className="bg-white 2xl:h-[47rem] rounded-b-lg md:rounded-r-lg shadow-md p-6 ">
      <div
        className="flex items-center justify-between mb-4"
        styles={{ gap: "36px" }}
      >
        <div className="flex flex-col items-start">
          <span className="text-gray-600 font-bold 2xl:text-xl">
            YOUR RANK:
          </span>
          <div className="text-gray-800 text-2xl font-bold">#{myRankVal}</div>
        </div>
        <div className="flex flex-col items-start">
          <span className="font-SatoshiBold text-[#646D80] text-xs">
            SORT BY:
          </span>
          <div className="flex mt-1">
            <button onClick={() => setFilter(0)} className={clsx("  font-bold flex gap-1 items-center py-2 px-4 rounded-full mr-2", filter == 0 ? "bg-[#2071EE] text-white" : "bg-white border border-black")}>
              <h1> Coins </h1>
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width="20"
                height="21"
                viewBox="0 0 20 21"
                fill="none"
              >
                <path
                  d="M10.0332 18.5C5.61493 18.5 2.0332 14.9183 2.0332 10.5C2.0332 6.08173 5.61493 2.5 10.0332 2.5C14.4514 2.5 18.0332 6.08173 18.0332 10.5C18.0332 14.9183 14.4514 18.5 10.0332 18.5ZM8.0332 8.10001L6.0332 10.1L10.0326 14.1L14.0332 10.1L12.0332 8.10001H8.0332Z"
                  fill="#F0B90B"
                />
              </svg>
            </button>
            <button onClick={() => setFilter(1)} className={clsx(" border flex gap-1 items-center   py-2 px-4 rounded-full", filter == 1 ? "bg-[#2071EE] text-white" : "bg-white border-black")}>
              <h1> Tickets </h1>
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width="20"
                height="21"
                viewBox="0 0 20 21"
                fill="none"
              >
                <path
                  d="M11.6709 2.99976V17.9998H2.50423C2.044 17.9998 1.6709 17.6266 1.6709 17.1665V12.5831C2.82149 12.5831 3.75423 11.6504 3.75423 10.4998C3.75423 9.34921 2.82149 8.41642 1.6709 8.41642V3.83309C1.6709 3.37286 2.044 2.99976 2.50423 2.99976H11.6709ZM13.3376 2.99976H17.5042C17.9645 2.99976 18.3376 3.37286 18.3376 3.83309V8.41642C17.187 8.41642 16.2542 9.34921 16.2542 10.4998C16.2542 11.6504 17.187 12.5831 18.3376 12.5831V17.1665C18.3376 17.6266 17.9645 17.9998 17.5042 17.9998H13.3376V2.99976Z"
                  fill="#4A87F2"
                />
              </svg>
            </button>
          </div>
        </div>
      </div>
      <div className="md:grid md:grid-cols-5 md:gap-20 flex justify-between md:space-x-2 lg:gap-0  bg-white md:p-4 mb-2 rounded-md text-gray-600">
        <div className="text-center md:text-start">Rank</div>
        <div className="flex-1 text-center md:text-start">Address</div>
        <div className={clsx("flex-1 text-center md:text-start", filter == 1 && "max-[1024px]:hidden")}>
          <div className={clsx("flex gap-1 items-center lg:flex",)}>
            <h1> Coins Earned</h1>
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width="20"
              height="21"
              viewBox="0 0 20 21"
              fill="none"
            >
              <path
                d="M10.0332 18.5C5.61493 18.5 2.0332 14.9183 2.0332 10.5C2.0332 6.08173 5.61493 2.5 10.0332 2.5C14.4514 2.5 18.0332 6.08173 18.0332 10.5C18.0332 14.9183 14.4514 18.5 10.0332 18.5ZM8.0332 8.10001L6.0332 10.1L10.0326 14.1L14.0332 10.1L12.0332 8.10001H8.0332Z"
                fill="#F0B90B"
              />
            </svg>
          </div>
        </div>
        <div className={clsx("lg:block max-[1024px]:flex-1", filter == 0 && "hidden")}>Tickets</div>

        <div className="hidden lg:block">Socials</div>
      </div>
      <div style={{ height: 'calc(100vh - 450px)' }} className="overflow-y-scroll">


        {sortedEntries && filter == 0 && user && [sortedEntries.sort((b, a) => a.totalPointsEarned - b.totalPointsEarned)[rank - 1]].slice(0, 100).map((entry, inx) => {




          if (entry == undefined) return null
          if (entry?.walletAddress !== account?.address) return null

          let _socials = {}
          user?.connections?.forEach((ele) => {
            _socials[ele.provider] = ele.username
          })


          return (
            <LeaderboardEntry
              isUser={true}
              rank={rank}
              filter={filter}

              address={
                entry.avexName ??
                entry.walletAddress.substring(0, 5) +
                ".." +
                entry.walletAddress.slice(-5)
              }
              coins={entry.totalPointsEarned}
              tickets={entry.totalTicketsEarned}
              socials={user.isSocialsEnabled ? _socials : {}}
            />



          )
        })
        }

        {sortedEntries && filter == 1 && user && [sortedEntries.sort((b, a) => a.totalTicketsEarned - b.totalTicketsEarned)[rank - 1]].slice(0,100).map((entry, inx) => {




          if (entry == undefined) return null
          if (entry?.walletAddress !== account?.address) return null

          let _socials = {}
          user?.connections?.forEach((ele) => {
            _socials[ele.provider] = ele.username
          })


          return (
            <LeaderboardEntry
              isUser={true}
              rank={rank}
              filter={filter}

              address={
                entry.walletAddress.substring(0, 5) +
                ".." +
                entry.walletAddress.slice(-5)
              }
              coins={entry.totalPointsEarned}
              tickets={entry.totalTicketsEarned}
              socials={user.isSocialsEnabled ? _socials : {}}
            />



          )
        })
        }
        {
          filter == 0 && sortedEntries.sort((b, a) => a.totalPointsEarned - b.totalPointsEarned).slice(0,100).map((entry, index) => {

            if (index == rank - 1) return

            return (
              <LeaderboardEntry
                key={index}
                filter={filter}

                rank={index + 1}
                address={
                  entry.avexName ??
                  entry.walletAddress.substring(0, 5) +
                  ".." +
                  entry.walletAddress.slice(-5)
                }
                coins={entry.totalPointsEarned}
                tickets={entry.totalTicketsEarned}
                socials={
                  index !== (filter == 0 ? rank.pointsRank : rank.ticketsRank) || !connected
                    ? entry.isSocialsEnabled
                      ? entry.socials
                      : {}
                    : user.isSocialsEnabled
                      ? entry.socials
                      : {}
                }
              />
            );
          })}





        {
          filter == 1 && sortedEntries.sort((b, a) => a.totalTicketsEarned - b.totalTicketsEarned).slice(0,100).map((entry, index) => {

            if (index == rank - 1) return

            return (
              <LeaderboardEntry
                key={index}

                rank={index + 1}
                filter={filter}
                address={
                  entry.avexName ??

                  entry.walletAddress.substring(0, 5) +
                  ".." +
                  entry.walletAddress.slice(-5)
                }
                coins={entry.totalPointsEarned}
                tickets={entry.totalTicketsEarned}
                socials={
                  index !== (filter == 0 ? rank.pointsRank : rank.ticketsRank) || !connected
                    ? entry.isSocialsEnabled
                      ? entry.socials
                      : {}
                    : user.isSocialsEnabled
                      ? entry.socials
                      : {}
                }
              />
            );
          })}
      </div>
    </div>
  );
};

const Leaderboard = () => {
  const [userRank, setUserRank] = useState({
    pointsRank: 0,
    ticketsRank: 0,
  }
  );

  const [filter, setFilter] = useState(0)

  const { user, setUser } = useContext(UserContext);

  const [leaderboardEntries, setLeaderboardEntries] = useState([

    {
      walletAddress: "xxx...xxxx",
      walletAddress: "xxx...xxxx",
      walletAddress: "xxx...xxxx",
      walletAddress: "xxx...xxxx",
      totalPointsEarned: 118232,
      tickets: 2,
      socials: [
        { icon: "twitter", url: "#" },
        { icon: "telegram", url: "#" },
      ],
    },
  ]);

  const { connected } = useWallet();

  function getUser() {
    //console.log("Getting User");
    const jwt = localStorage.getItem("AVEX_DEFY_JWT_STORE");
    const config = {
      headers: {
        Authorization: jwt,
      },
    };
    if (jwt) {
      axios.get(`${CONST.baseUrl}/users`, config).then((response) => {
        setUser(response.data.data);
      });
    }
  }

  function switchSocial() {
    if (!connected) {
      //Connect Wallet Toast Should Come for SUNIL
      return;
    }

    const jwt = localStorage.getItem("AVEX_DEFY_JWT_STORE");
    const config = {
      headers: {
        Authorization: jwt,
      },
    };

    const isEnable = !user.isSocialsEnabled;
    setUser((curr) => ({ ...curr, isSocialsEnabled: isEnable }));

    if (jwt) {
      axios
        .put(
          `${CONST.baseUrl}/users/enable-socials?status=${isEnable ? "enable" : "disable"
          }`,
          config
        )
        .then((response) => {
          getUser();
        })
        .catch(() => { });
    }
  }

  function fetchLeaderBoards() {
    const jwt = localStorage.getItem("AVEX_DEFY_JWT_STORE");
    const config = {
      headers: {
        Authorization: jwt,
      },
    };
    // if (jwt) {
    axios
      .get(`${CONST.baseUrl}/users/leaderboard`)
      .then((response) => {
        setLeaderboardEntries(response.data.data.leaderboard);
      });
    // }
  }

  function fetchUserRank() {
    const jwt = localStorage.getItem("AVEX_DEFY_JWT_STORE");
    const config = {
      headers: {
        Authorization: jwt,
      },
    };
    if (jwt) {
      axios
        .get(`${CONST.baseUrl}/users/leaderboard/user`, config)
        .then((response) => {
          setUserRank(response.data.data);
        }).catch(() => { });
    }
  }

  useEffect(() => {
    if (window.innerWidth > 1024) {
      document.body.style.overflow = "hidden";
      return () => {
        document.body.style.overflow = "scroll";
      };
    }
  }, []);


  useEffect(() => {
    fetchUserRank();
    fetchLeaderBoards();
  }, []);

  return (
    <>
      <Navbar />
      <div
        className="w-full h-full overflow-y-scroll main-body"
        style={{
          overflow: "hidden",
          backgroundImage: "linear-gradient(to right, #D3E1FB, #DfC9FE)",
        }}
      >
        <div className="rounded border"></div>
        <div className="flex flex-col md:flex-row justify-between py-14">
          <div className="w-full px-5 md:w-2/3 md:ml-6">
            <h2 className="text-xl md:w-1/5 md:text-center rounded-t-lg p-3 2xl:text-2xl font-Satoshi bg-white font-bold">
              Leaderboard
            </h2>
            <LeaderboardList filter={filter} setFilter={setFilter} rank={filter == 0 ? userRank.pointsRank : userRank.ticketsRank} entries={leaderboardEntries} />
          </div>
          <div className="lg:w-1/3 px-5 lg:ml-2 lg:mr-6 justify-end xl:!mt-[50px]">
            <div className="bg-white 2xl:min-h-[11rem] mt-4 md:mt-0 rounded-lg z-0  md:ml-2 shadow-md p-4 pt-4" >
              <div className="flex z-0 items-center justify-between border-b border-purple-300 pb-2 ">
                <h3 className="text-black font-semibold font-SatoshiBold ">
                  Enable Socials
                </h3>
                <div
                  className={`relative inline-block z-0 w-10 mr-2 align-middle select-none transition md:scale-125 duration-200 ease-in ${user.isSocialsEnabled ? "bg-bgBlue" : "bg-[#E9E9EA]"
                    } rounded-full`}
                  onClick={switchSocial}
                >
                  <span className="sr-only">Enable Socials</span>
                  <div
                    className={`w-5 z-0 h-5 bg-white rounded-full shadow-md transform transition ease-in-out duration-200 ${user.isSocialsEnabled ? "translate-x-5" : "translate-x-0"
                      }`}
                  />
                </div>
              </div>
              {!user.isSocialsEnabled ? (
                <p className="text-gray-500 2xl:text-base text-sm mt-2 p-8">
                  This lets other users to see your socials link. Disabling it
                  will show your socials as private.
                </p>
              ) : (
                <div className="py-2">
                  {user?.connections.map((social) => {

                    if (social.provider == "email" || social.provider == "discord") return

                    return (
                      <div className="items-center justify-start flex mt-2.5">
                        <a className="curser-pointer">
                          <img
                            className="w-[30px] h-[30px] object-contain rounded-lg"
                            src={
                              social.provider == "twitter"
                                ? xLogo
                                : social.provider == "telegram"
                                  ? telegramLogo
                                  : discordLogo
                            }
                          />
                        </a>
                        <p className="font-SatoshiBold text-black text-lg ml-2 text-left">
                          {social.username}
                        </p>
                      </div>
                    );
                  })}
                </div>
              )}
            </div>
            {/* <RaffleDashboard /> */}
          </div>
        </div>
      </div>
    </>
  );
};

export default Leaderboard;
