import Modal from "@material-ui/core/Modal";
import "../../App.css";
import { useState } from "react";
import { UserContext } from "../../contexts/UserContent";
import { useContext } from "react";
import axios from "axios";
import { assets, CONST } from "../../constants";
import "react-toastify/dist/ReactToastify.css";
import "../../utils/toasts.css";



export default function EnterFortuneWheel({ open, handleClose, handleOpen, state, allRewards, guiRewards, playerRewards }) {

  const {user, setUser, tickets, setTickets} = useContext(UserContext)
  
  const divisor = 1000000.0;

  function getUser() {
    const jwt = localStorage.getItem("AVEX_DEFY_JWT_STORE");
    const config = {
      headers: {
        Authorization: jwt,
      },
    };
    if (jwt) {
      axios.get(`${CONST.baseUrl}/users`, config).then((response) => {
        setUser(response.data.data);
      });
    }
  }
  const referralCode = user.referral.code

  let before = [];
  let after = [];
  // console.log(playerRewards);
  for (let i = 0; i < playerRewards.length; i++) {
    // console.log(playerRewards[i]?.reward_type);
    if (playerRewards[i]?.reward_type.includes("-")) {
      // console.log(playerRewards[i]?.reward_type);
      const response = separateString(playerRewards[i]?.reward_type);
      before.push(response[0])
      after.push(response[1])
    }
  }

  function separateString(str) {
    const index = str.indexOf('-');
    if (index !== -1) {
        const part1 = str.substring(0, index);
        const part2 = str.substring(index + 1);
        return [part1, part2];
    } else {
        return ["NFT", 'https://demofree.sirv.com/nope-not-here.jpgnd'];
    }
  }

  // console.log(before, after)

  return (

    <Modal
      onClose={handleClose}
      open={open}
      style={{
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
      }}
    >


  
      <div className="max-w-[50vw] lg:w-[500px] mx-auto p-5 rounded-xl focus:outline-none bg-white FortuneModal overflow-y-scroll border-2 border-black max-h-[80%] md:h-auto ">
        <div className="w-full md:h-full flex flex-col justify-center items-center">
        <p className="font-satoshi font-bold text-xl mt-6 cursor-default">
          Congratulations you Won 🥳!
        </p>
        <div className="flex flex-row gap-4 flex-wrap items-center justify-center mt-6  ">
            {playerRewards?.map((item, index) => (
              <div className="flex flex-col items-center gap-3 w-[100px] " key={index}>
                <img
                  src={
                    item?.reward_tier === "0"
                      ? after.pop()
                      : item?.reward_tier > 0 && item?.reward_tier < 5
                      ? assets.gui
                      : item?.reward_tier > 4 && item?.reward_tier < 9
                      ? assets.defy1
                      : assets.raff
                  }
                  className="w-[40px] h-[40px]"
                />
                <p className="font-Satoshi font-medium text-sm lg:text-md text-textGreyDark cursor-default whitespace-nowrap ">
                  {item?.reward_tier === "0"
                    ? before.pop()
                    : item?.reward_tier > 0 && item?.reward_tier < 5
                    ? (item?.reward_amount / divisor).toFixed(2) + " GUI"
                    : item?.reward_tier > 4 && item?.reward_tier < 9
                    ? item?.reward_amount + " Defy Coins"
                    : item?.reward_amount + " Raffle Tickets"}
                </p>
              </div>
            ))}
          </div>

        
        {/* <div className="h-fit w-fit lg:mx-5  flex text-center items-center justify-center mt-6 mb-5"> */}
        <div className='mt-6 mb-4 justify-center align-center text-center'>
                        <a 
                            className='text-[#2071EE] mr-1' 
                            href={`https://x.com/intent/tweet?text=The%20Fortune%20Wheel%20by%20%40defyapp_%20is%20so%20fun%20and%20has%20so%20many%20exciting%20prizes%20to%20win%21%20%0A%0AIt%20is%20made%20using%20the%20%40Aptos%20Randomness%20API.%F0%9F%8E%B2%0A%0APlay%20now%20and%20get%20exciting%20rewards%20at%20https%3A%2F%2Fwaitlist.avex.id%2F%3FreferralCode%3D${referralCode}`}
                            target='_blank'
                        >
                                Share
                        </a>
                        this exciting moment with your friends and get 2500 Defy coins on every successful referral!
                    </div>
        {/* </div> */}

        <div className="p-2 md:p-0">
          <img onClick={handleClose} src="/okaybutton.png" className="hover:scale-105 transition-all cursor-pointer" />
        </div>

        </div>
      </div>
    </Modal>
  );
}
