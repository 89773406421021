import React, { Suspense, useEffect, useState } from "react";
import { Canvas } from "@react-three/fiber";
import { OrbitControls, Preload, useGLTF } from "@react-three/drei";
import * as THREE from 'three'
import { useFrame } from "@react-three/fiber";
import CanvasLoader from "./CanvasLoader";


const Avex = ({ isMobile, total }) => {

  let Avex = useGLTF("./AvexModel/avex.gltf");

  let mixer
  useEffect(() => {
    if (Avex.animations.length) {
      mixer = new THREE.AnimationMixer(Avex.scene);
      Avex.animations.forEach(clip => {
        const action = mixer.clipAction(clip)
        action.play();
      });
    }
  })

  useFrame((state, delta) => {
    mixer?.update(delta)
  })

  return (
    <mesh>
      <hemisphereLight intensity={5} groundColor='black' />
      <spotLight
        position={[0, 0, 0]}
        angle={12}
        penumbra={1}
        intensity={1}
        castShadow
        shadow-mapSize={1024}
      />
      <primitive
        object={Avex.scene}
        scale={isMobile ? 4 : 4
        }
        position={isMobile ? [-4, 0, 0] : [-4, 0, 0]}
        rotation={[0, 0, 0]}

      />
    </mesh>
  );
};


const AvexCanvas = ({total}) => {



  const [isMobile, setIsMobile] = useState(false);

  useEffect(() => {
    const mediaQuery = window.matchMedia("(max-width: 500px)");
    setIsMobile(mediaQuery.matches);
    const handleMediaQueryChange = (event) => {
      setIsMobile(event.matches);
    };

    mediaQuery.addEventListener("change", handleMediaQueryChange);

    return () => {
      mediaQuery.removeEventListener("change", handleMediaQueryChange);
    };
  }, []);

  return (
    <div>
      <div className={'modelbackgroud'} style={{
        width: "100%", height: "100%",
        position: 'relative',
        backgroundSize: 'cover',
        backgroundPosition: 'center center',
        backgroundRepeat: 'no-repeat',

      }}>

        <Canvas style={{ height: 300 }}
          frameloop='demand'
          shadows
          dpr={[1, 2]}
          camera={{ position: [26, 3, 5], fov: 25 }}
          gl={{ preserveDrawingBuffer: true }}
        >

          <Suspense fallback={<CanvasLoader />}>
            <OrbitControls
              autoRotate={true}
              enableZoom={false}
              maxPolarAngle={Math.PI / 2}
              minPolarAngle={Math.PI / 2}
            />
            <Avex total={total} isMobile={isMobile} />

          </Suspense>

          <Preload all />
        </Canvas>
      </div>

    </div>
  );
};

export default AvexCanvas;