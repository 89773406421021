import React, { useState } from "react";
import image from "../../assets/arrow-circle.svg.png";
import data, { FaqData } from "../../data/faq_data.js"; 
import Navbar from "../navbar/Navbar.js";


function FAQ() {
  const [openAnswer, setOpenAnswer] = useState(null);
  const [index, setIndex] = useState(0);

  const toggleAnswer = (id) => {
    setOpenAnswer(openAnswer === id ? null : id);
  };




  return (
    <>
    <Navbar/>
    <div className="main-body">
      <div className="faq-section-2">
        <div className="faq-container">
          <div className="faq-menu-wrapper">
            <div className="faq-menu-title">
              <div className="faq-menu-title-label">
                <span className="accent-color">
                  <strong className="strongg">F</strong>
                </span>
                <span className="faq-sticky-title-hidden-mobile">
                  <strong className="strongg">requently</strong>
                </span>
                <strong> </strong>
                <span className="accent-color">
                  <strong>A</strong>
                </span>
                <span className="faq-sticky-title-hidden-mobile">
                  <strong>sked</strong>
                </span>
                <strong> </strong>
                <span className="accent-color">
                  <strong>Q</strong>
                </span>
                <span className="faq-sticky-title-hidden-mobile">
                  <strong>uestions</strong>
                </span>
              </div>
            </div>
            <div className="question-cat">
              {/* Categories */}
              <div className="faq-menu">
                <a
                  href="#"
                  className={`faq-menu-item w-inline-block ${index === 0 ? "w--current" : ""
                    }`}
                  onClick={() => setIndex(0)}
                >
                  <div className="faq-menu-item-label">General</div>
                </a>
              </div>
              <div className="faq-menu">
                <a
                  href="#"
                  className={`faq-menu-item w-inline-block ${index === 1 ? "w--current" : ""
                    }`}
                  onClick={() => setIndex(1)}
                >
                  <div className="faq-menu-item-label">Giveaway</div>
                </a>
              </div>
              <div className="faq-menu">
                <a
                  href="#"
                  className={`faq-menu-item w-inline-block ${index === 2 ? "w--current" : ""
                    }`}
                  onClick={() => setIndex(2)}
                >
                  <div className="faq-menu-item-label">NFT Raffle</div>
                </a>
              </div>
              <div className="faq-menu">
                <a
                  href="#"
                  className={`faq-menu-item w-inline-block ${index === 3 ? "w--current" : ""
                    }`}
                  onClick={() => setIndex(3)}
                >
                  <div className="faq-menu-item-label">Insider NFT</div>
                </a>
              </div>
            </div>
          </div>
          <div className="faq-groups-wrapper">
            <div className="faq-group-row">
              {/* Map over FAQ items for the selected category */}
              {data[index].map((faq, faqIndex) => (
                <div
                  className="faq-item-wrapper"
                  key={faqIndex}
                  id={`faq-row-${faqIndex + 1}`}
                  onClick={() => toggleAnswer(`faq-row-${faqIndex + 1}`)}
                >
                  <div className="faq-question">
                    <div className="faq-question-label">{faq.question}</div>
                    <img
                      src={image}
                      loading="lazy"
                      alt=""
                      className={`faq-arrow ${openAnswer === `faq-row-${faqIndex + 1}` ? "rotate" : ""}`}
                    />
                  </div>
                  <div
                    id={`faq-item-${faqIndex + 1}`}
                    className={`faq-answer ${openAnswer === `faq-row-${faqIndex + 1}` ? "open fade-in" : ""}`}
                  >
                    {/* Render answer content with clickable links */}
                    <p className="faq-answer-p" dangerouslySetInnerHTML={{ __html: faq.answer }}></p>
                  </div>
                </div>
              ))}

            </div>
          </div>
        </div>
      </div>



    </div>
    </>

  );
}

export default FAQ;
