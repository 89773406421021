import Modal from "@material-ui/core/Modal";
import twitterConnectPoster from "../../../assets/twitterConnectPoster.png";
import profilePic from "../../../assets/profilePic.png";
import coinAnim from "../../../assets/coinAnim.gif";
import groupProfile from "../../../assets/groupProfile.svg";
import { useWallet } from "@aptos-labs/wallet-adapter-react";
import axios from "axios";
import CONST from "../../../constants/constants";
import mcqIcon from "../../../assets/mcqIcon.png";
import { useContext, useEffect, useState } from "react";
import {
  discordOauth,
  twitterOauth,
  googleOauth,
} from "../../../services/oauth/oauth";
import book from "../../../assets/book.png";
import leftArrowCircle from "../../../assets/leftArrowCircle.png";
import rightArrowCircle from "../../../assets/rightArrowCircle.png";
import redCross from "../../../assets/redCross.svg";
import redLink from "../../../assets/redLink.svg";
import greenLink from "../../../assets/greenLink.svg";
import greenTick from "../../../assets/greenTick.svg";
import purpleLightning from "../../../assets/purpleLightning.svg";
import { UserContext } from "../../../contexts/UserContent";
import { fetchTickets } from "../../../utils/indexer";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import "../../../utils/toasts.css";
import clsx from "clsx";


 const MCQModal = ({ open, handleClose, state }) => {

  const {  account } = useWallet();

  const { user, setUser, tickets, setTickets } = useContext(UserContext);

  function getUser() {
    //console.log("Getting User");
    const jwt = localStorage.getItem("AVEX_DEFY_JWT_STORE");
    const config = {
      headers: {
        Authorization: jwt,
      },
    };
    if (jwt) {
      axios.get(`${CONST.baseUrl}/users`, config).then((response) => {
        setUser(response.data.data);
      });
    }
  }

  const refresh = () => {
    setSubmitted(false);
    setChoices({});
    setCurrentQ(0);
  };

  const [submitted, setSubmitted] = useState(false);

  const options = ["a) ", "b) ", "c) ", "d) "];
  const [result, setResult] = useState(false);

  const nextQuestion = () => {
    if (choices[currentQ] == undefined) return;

    if (currentQ + 1 == state.task.mcqQuestions.length) {
      const jwt = localStorage.getItem("AVEX_DEFY_JWT_STORE");
      const config = {
        headers: {
          Authorization: jwt,
        },
      };

      let apiArr = [];
      for (let i = 0; i < state.task.mcqQuestions.length; i++) {
        apiArr.push(choices[i]);
      }

      if (jwt) {
        axios
          .post(
            `${CONST.baseUrl}/tasks/verify?taskId=${state.task["taskId"]}`,
            {
              answers: apiArr,
            },
            config
          )
          .then((response) => {
            setSubmitted(true);
            setResult(response.data.data);
            if (response.data.data) {
              toast("Congrats! Task succesfully verified!");
              getUser();
              fetchTickets(account?.address).then((result) => {
                setTickets(result);
              });
            } else {
              toast("Get a Perfect score to complete the task");
            }
          })
          .catch(() => {
            toast("Task not verified!");
          });
      }

      return;
    }
    setCurrentQ((curr) => curr + 1);
  };

  const [choices, setChoices] = useState({});

  const previousQuestion = () => {
    if (currentQ == 0) return;
    setCurrentQ((curr) => curr - 1);
  };

  useEffect(() => {
    if (open) refresh();
  }, [open]);

  const [currentQ, setCurrentQ] = useState(0);

  return (
    state.task.mcqQuestions && (
      <Modal
        onClose={handleClose}
        open={open}
        style={{
          backgroundColor: "#FFF",
          margin: "auto",
          color: "white",
          className: "NFTModal",
          height: submitted ? 700 : 550,
        }}
        className="h-[550px] w-[800px] max-[1024px]:w-[95%]"
      >
        <div className="w-full h-full bg-white p-5 rounded-lg MCQModal">
          <div className="flex items-center justify-between">
            <div className="flex space-x-2 md:space-x-0 items-center">
              <img className="h-8 w-8 object-contain" src={mcqIcon} />
              <div>
                <p className=" text-sm md:text-xl font-SatoshiBold ml-2 text-black md:text-left">
                  {state.task.title}
                </p>
                <p className="text-xs md:text-sm font-SatoshiMedium ml-2 text-textGreyDark md:text-left">
                  {state.task.title}
                </p>
              </div>
            </div>
            <div className="sm:flex sm:space-x-2 sm:items-center ">
              <p className="text-xs md:text-base font-SatoshiMedium text-textGreyDark text-center">
                Answered
              </p>
              {state.task.mcqQuestions && (
                <p className="text-sm font-SatoshiMedium  text-textBlue text-center">
                  {currentQ + 1}/{state.task.mcqQuestions.length}
                </p>
              )}
            </div>
          </div>

          {submitted ? (
            <div className="flex p-2 max-[1024px]:flex-col">
              <div style={{ flex: 2 }} className="p-4">
                <img
                  className="w-full max-h-[220px] rounded-lg object-cover"
                  src={state.task.additionalImageUrl}
                />
                <div style={{ maxHeight: 300 }} className="overflow-y-scroll">
                  {state.task.mcqQuestions.map((q, currentQ) => {
                    return (
                      <div key={currentQ} className="mt-2">
                        <p className="font-SatoshiMedium text-sm text-black text-left my-4">
                          {currentQ + 1}.{" "}
                          {state.task.mcqQuestions[currentQ].question}
                        </p>

                        {state.task.mcqQuestions[currentQ].answers.map(
                          (ele, inx) => {
                            return (
                              <div>
                                <div className="flex justify-start items-center mt-2 ml-4">
                                  <input
                                    type="radio"
                                    id={inx}
                                    value={inx}
                                    checked={choices[currentQ] == inx}
                                  />

                                  <p className=" ml-4 font-SatoshiMedium text-sm text-textGreyDark text-left">
                                    {options[inx]}
                                    {ele}
                                  </p>
                                </div>
                              </div>
                            );
                          }
                        )}
                        {choices[currentQ] !==
                          state.task.mcqQuestions[currentQ].correctAnswer ? (
                          <div
                            style={{ backgroundColor: "rgba(224,100,67,0.2)" }}
                            className="w-full h-12 rounded-md flex items-center justify-between mt-2 p-3"
                          >
                            <div className="items-center flex">
                              <img
                                className="object-contain w-5 h-5"
                                src={redCross}
                              />
                              <p className="ml-2 font-SatoshiMedium text-base text-red">
                                Wrong Answer
                              </p>
                            </div>
                            <div
                              onClick={() =>
                                window.open(
                                  state.task.mcqQuestions[currentQ]
                                    .referenceMaterialUrl,
                                  "_blank"
                                )
                              }
                              className="items-center flex  cursor-pointer"
                            >
                              <p className="mr-2 font-SatoshiMedium text-base text-red">
                                Blog Link
                              </p>
                              <img
                                className="object-contain w-5 h-5"
                                src={redLink}
                              />
                            </div>
                          </div>
                        ) : (
                          <div
                            style={{ backgroundColor: "rgba(88,187,71,0.2)" }}
                            className="w-full h-12 rounded-md flex items-center justify-between mt-2 p-3"
                          >
                            <div className="items-center flex">
                              <img
                                className="object-contain w-5 h-5"
                                src={greenTick}
                              />
                              <p className="ml-2 font-SatoshiMedium text-base text-[#00B061]">
                                Correct Answer
                              </p>
                            </div>
                            <div
                              onClick={() =>
                                window.open(
                                  state.task.mcqQuestions[currentQ]
                                    .referenceMaterialUrl,
                                  "_blank"
                                )
                              }
                              className="items-center flex cursor-pointer"
                            >
                              <p className="mr-2 font-SatoshiMedium text-base text-[#00B061]">
                                Learn More
                              </p>
                              <img
                                className="object-contain w-5 h-5 "
                                src={greenLink}
                              />
                            </div>
                          </div>
                        )}
                      </div>
                    );
                  })}
                </div>

                <div className="flex flex-col md:flex-row md:items-center md:justify-between md:mt-6 ">
                  <div className="flex py-3 md:py-0 justify-center md:justify-start items-center flex-1">
                    <img
                      className="object- contain w-5 md:w-6 h-5 md:h-6"
                      src={book}
                    />
                    <a
                      href={
                        state.task.links[0]
                      }
                      className="underline text-textBlue font-SatoshiMedium text-sm md:text-base ml-3 cursor-pointer"
                    >
                      Read Material
                    </a>
                  </div>

                  <div
                    onClick={result ? handleClose : refresh}
                    className="md:px-20 px-5 py-2 md:py-2.5 rounded-lg bg-bgBlue items-center justify-center flex cursor-pointer"
                  >
                    <p className="font-SatoshiMedium text-base text-white mr-2">
                      {result ? "Claim Rewards" : "Try Again"}
                    </p>
                    <img
                      className="object-contain w-4 h-4"
                      src={rightArrowCircle}
                    />
                  </div>
                </div>
              </div>
            </div>
          ) : (
            <div className="flex md:p-2 flex-col md:flex-row h-full">
              <div
                style={{ flex: 2 }}
                className="flex flex-col justify-between p-4 "
              >
                <div>
                  <img
                    className="w-full max-h-[220px] rounded-lg object-cover"
                    src={state.task.additionalImageUrl}
                  />
                  <p className="font-SatoshiMedium text-sm text-black text-left my-4">
                    {currentQ + 1}. {state.task.mcqQuestions[currentQ].question}
                  </p>
                  <div className="">
                    {state.task.mcqQuestions[currentQ].answers.map(
                      (ele, inx) => {
                        return (
                          <div
                            key={inx}
                            className="flex justify-start items-center mt-2 ml-4"
                          >
                            <input
                              type="radio"
                              id={inx}
                              value={inx}
                              checked={choices[currentQ] == inx}
                              onChange={() =>
                                setChoices((curr) => ({
                                  ...curr,
                                  [currentQ]: inx,
                                }))
                              }
                            />

                            <p className=" ml-4 font-SatoshiMedium text-sm text-textGreyDark text-left">
                              {options[inx]}
                              {ele}
                            </p>
                          </div>
                        );
                      }
                    )}
                  </div>
                </div>
                <div className="flex flex-col md:flex-row md:items-center md:justify-between md:mt-6 mb-4">
                  <div className="flex py-3 md:py-0 justify-center md:justify-start items-center flex-1">
                    <img
                      className="object- contain w-5 md:w-6 h-5 md:h-6"
                      src={book}
                    />
                    <a
                      href={
                        state?.task?.links && state.task.links[0]
                      }
                      target="_blank"
                      className="underline text-textBlue font-SatoshiMedium text-sm md:text-base ml-3"
                    >
                      Read Material
                    </a>
                  </div>
                  <div className="flex flex-1 justify-between items-center">
                    <div
                      onClick={previousQuestion}
                      className="flex items-center cursor-pointer py-2.5"
                    >
                      <img
                        className="object-contain w-4 h-4 mr-2"
                        src={leftArrowCircle}
                      />
                      <p className="font-SatoshiMedium text-xs md:text-base text-textBlue">
                        Previous
                      </p>
                    </div>
                    <div
                      onClick={nextQuestion}
                      className="md:px-20 px-5 button py-2 md:py-2.5 rounded-lg bg-bgBlue items-center justify-center flex cursor-pointer"
                    >
                      {state.task.mcqQuestions && (
                        <p className="font-SatoshiMedium text-base text-white mr-2">
                          {currentQ + 1 == state.task.mcqQuestions.length
                            ? "Submit"
                            : "Next"}
                        </p>
                      )}{" "}
                      <img
                        className="object-contain w-4 h-4"
                        src={rightArrowCircle}
                      />
                    </div>
                  </div>
                </div>
              </div>
            </div>
          )}
        </div>
      </Modal>
    )
  );
};

export default MCQModal