import { createContext, useContext, useEffect, useState } from "react";
import { useWallet } from "@aptos-labs/wallet-adapter-react";
import axios from "axios";
import CONST from "../constants/constants";
import {Buffer} from 'buffer';
import { UserContext } from "../contexts/UserContent";

export const AuthContext = createContext(undefined);

export function useAuth() {
  const context = useContext(AuthContext);
  if (!context) throw new Error("useAuth must be used within an AuthProvider");
  return context;
}

function parseJwt(token) {
  var base64Url = token.split('.')[1];
  var base64 = base64Url.replace(/-/g, '+').replace(/_/g, '/');
  var jsonPayload = decodeURIComponent(window.atob(base64).split('').map(function (c) {
    return '%' + ('00' + c.charCodeAt(0).toString(16)).slice(-2);
  }).join(''));

  return JSON.parse(jsonPayload);
}

export const AuthProvider = ({ children }) => {


  async function getUser() {
    //console.log("Getting User");
    const jwt = localStorage.getItem("AVEX_DEFY_JWT_STORE");
    const config = {
      headers: {
        Authorization: jwt,
      },
    };
    if (jwt) {
      axios.get(`${CONST.baseUrl}/users`, config).then((response) => {
        setUser(response.data.data);
        //console.log(response.data.data.points);
      });
    }
  }

  const { account, network, connected, isLoading, signMessage, wallet } = useWallet();
  const [isAuthenticated, setIsAuthenticated] = useState(false);

  const getNonce = async () => {
    try {
      const res = await axios.post(`${CONST.baseUrl}/users/nonce`, {
        walletAddress: account.address,
        publicKey: account.publicKey,
      });
      return res.data.data.nonce;
    } catch (error) {
      //console.log("Unable to fetch nonce", error);
    }
  };

  const authenticate = async () => {


    try {
      const nonce = await getNonce();
      let _data = null
      if(wallet?.name == "Pontem"){
        //console.log("Pontemmmmm");
        _data = await window.pontem.signMessage({
          message: `Approve Signature on Avex Defy`,
          nonce: nonce,
          address: account.address,
          chainId: network.chainId,
        });

        //console.log(_data, "_data");
      }else{
        _data = await signMessage({
          message: `Approve Signature on Avex Defy`,
          nonce: nonce,
          address: account.address,
          chainId: network.chainId,
        });
      }
      

      let signature = ""
      if(_data.signature){
        signature = _data.signature
      }else{
        signature =  Buffer.from(_data.result.signature).toString('hex')
      }
      

      const res = await axios.post(`${CONST.baseUrl}/users/login`, {
        signature: signature,
        walletAddress: account.address,
      });


      const jwt = res.data.token;
      //console.log(parseJwt(jwt));

      localStorage.setItem("AVEX_DEFY_JWT_STORE", jwt);
      axios.defaults.headers.common["authorization"] = jwt;
      await getUser()
      setIsAuthenticated(true);
    } catch (error) {
      //console.log("Unable to sign message", error);
    }
  };
  const verifyJwt = async () => {
    try {
      const res = await axios.get(`${CONST.baseUrl}/users`, {
        headers: { Authorization: localStorage.getItem("AVEX_DEFY_JWT_STORE") },
      });
      if (res.status === 200) {
        return true;
      }
      return false;
    } catch (error) {
      //console.log("Unable to verify", error);
      return false;
    }
  };

  const authFlow = async () => {

    if (account) {

      const jwt =  localStorage.getItem("AVEX_DEFY_JWT_STORE");

      if (jwt && parseJwt(jwt).walletAddress === account?.address) {

        const verified = await verifyJwt();
        //console.log(verified, "Verified Status");
        if (verified) {
          axios.defaults.headers.common["Authorization"] = jwt;
          await getUser()
          setIsAuthenticated(true);
        } else {
          // localStorage.removeItem("AVEX_DEFY_JWT_STORE");
          authenticate();
        }
      } else {
        setIsAuthenticated(false)
        //console.log("Changed Account");
        //console.log(jwt);
        // //console.log(parseJwt(jwt).walletAddress === account?.address);
        authenticate();
      }
    }
  };

  


  useEffect(() => {
    if (!isLoading) {
      if (connected) {

        authFlow();

      } else {
        // localStorage.removeItem("AVEX_DEFY_JWT_STORE");
      }
    }
  }, [isLoading, connected, account]);

  const {user, setUser} = useContext(UserContext)


  // useEffect(()=>{
  //   if(isAuthenticated){
  //     //console.log("Is Auth tp True");
  //     getUser()}
  // },[isAuthenticated])

  return (
    <AuthContext.Provider value={{ isAuthenticated }}>
      {children}
    </AuthContext.Provider>
  );
};