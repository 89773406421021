import { useAuth } from "../../providers/AuthProvider";
import { useWallet } from "@aptos-labs/wallet-adapter-react";
import { fetchTickets } from "../../utils/indexer";
import "@aptos-labs/wallet-adapter-ant-design/dist/index.css";
import { useState } from "react";
import DailyRewardsModal from "./DailyRewardsModal";
import { motion } from "framer-motion";
import clsx from "clsx";
import { useEffect } from "react";
import { WalletConnector } from "@aptos-labs/wallet-adapter-mui-design";
import TicketModal from "../common/modals/TicketModal";
import CoinModal from "../common/modals/CoinModal";
import ChooseNFTModal from "../quest/nftspin/ChooseNFTModal";
import NFTBetModal from "../quest/nftspin/NFTSpinModal";
import { UserContext } from "../../contexts/UserContent";
import { useContext } from "react";
import axios from "axios";
import { assets, CONST } from "../../constants";
import { toast } from "react-toastify";
import { useNavigate } from "react-router-dom";

export default function Navbar({}) {
  const [isModalOpen, setModalOpen] = useState(false);
  const [showNavbar, setShowNavbar] = useState(false);
  const [dropdownVisible, setDropdownVisible] = useState(false);
  const [dropdownVisibleGiveaway, setDropdownVisibleGiveaway] = useState(false);
  const [dropdownLeaderboardVisible, setDropdownLeaderboardVisible] =
    useState(false);
  const { connected, disconnect, account } = useWallet();
  const { isAuthenticated } = useAuth();
  const [caretColor, setCaretColor] = useState(false);
  const [coins, setCoins] = useState(0);

  const navigate = useNavigate();

  const [openTicket, setOpenTicket] = useState(false);

  const { user, setUser, tickets, setTickets } = useContext(UserContext);

  useEffect(() => {
    setCoins(user.balance.points);

    const interval = setInterval(() => {
      setCoins(user.balance.points);
    }, 10000);

    return () => clearInterval(interval);
  }, [connected, user]);

  const handleCloseTicket = () => {
    setOpenTicket(false);
  };

  const handleOpenTicket = () => {
    setOpenTicket(true);
  };

  function getUser() {
    //console.log("Getting User");
    const jwt = localStorage.getItem("AVEX_DEFY_JWT_STORE");
    const config = {
      headers: {
        Authorization: jwt,
      },
    };
    if (jwt) {
      axios.get(`${CONST.baseUrl}/users`, config).then((response) => {
        setUser(response.data.data);
      });
    }
  }

  useEffect(() => {
    if (isAuthenticated && connected) {
      fetchTickets(account?.address).then((result) => {
        setTickets(result);
      });
      // getUser();
    }
  }, [isAuthenticated, account]);

  const handleShowRaffle = () => {
    setDropdownVisible(!dropdownVisible);
    setDropdownVisibleGiveaway(false);
    setDropdownLeaderboardVisible(false);
  };
  const handleLeaderboard = () => {
    setDropdownLeaderboardVisible(!dropdownLeaderboardVisible);
    setDropdownVisibleGiveaway(false);
    setDropdownVisible(false);
  };
  const handleShowGiveaway = () => {
    setDropdownVisibleGiveaway(!dropdownVisibleGiveaway);
    setDropdownVisible(false);
    setDropdownLeaderboardVisible(false);
  };
  const handleShowNavbar = () => {
    setShowNavbar(!showNavbar);
  };

  const [openCoin, setOpenCoin] = useState(false);

  const handleCloseCoin = () => {
    setOpenCoin(false);
  };

  const handleOpenCoin = () => {
    setOpenCoin(true);
  };

  const [openChooseNFT, setOpenChooseNFT] = useState(false);

  const handleCloseChooseNFT = () => {
    setOpenChooseNFT(false);
  };

  const handleOpenChooseNFT = () => {
    setOpenChooseNFT(true);
  };

  const [state, setState] = useState({
    task: {
      title: "",
      description: "",
      boost: {},
      mcqQuestions: [
        {
          question: "",
          answers: [""],
        },
      ],
      faq: [{}],
    },
    chest: {
      reward: {},
      chestType: {},
    },
  });

  const [openNFT, setOpenNFT] = useState(false);

  const handleCloseNFT = () => {
    setOpenNFT(false);
  };

  const handleOpenNFT = (nft) => {
    setState((curr) => ({ ...curr, nft: nft }));
    setOpenNFT(true);
  };

  return (
    <nav className=" lg:w-11/12 2xl:w-9/12 z-50 h-16 rounded-[60px] px-6 py-4 lg:py-2 bg-[#ffffff99] backdrop-blur mx-auto mt-4 mb-16 !fixed max-[1024px]:w-11/12 left-1/2 -translate-x-1/2">
      <ul className="flex justify-between align-center ">
        <ul
          className="flex align-center items-center hover:cursor-pointer"
          onClick={() => navigate("/")}
        >
          <img className="mb-1" src={assets.defy} />
          <li>
            <a className="pl-2 xl:pl-4 font-SatoshiBold text-2xl">Defy</a>
          </li>
        </ul>
        <div className="menu-icon" onClick={handleShowNavbar}>
          <img
            src={assets.menu}
            style={{ width: 30, height: 30, objectFit: "contain" }}
          />
        </div>
        <ul
          className={`flex gap-2 items-center xl:gap-0 nav-elements navbar ${
            showNavbar && "active"
          }`}
        >
          <li>
            <a
              onClick={() => navigate("/")}
              className={clsx(
                "font-SatoshiMedium text-base mr-2 xl:mr-6 cursor-pointer text-[#656565]  hover:text-black",
                showNavbar && "fade-in"
              )}
            >
              Quest
            </a>
          </li>

          <li className="flex flex-col items-center mr-2 xl:mr-4 gap-0">
            <div className="relative">
              <div className="gap-1 items-center cursor-pointer hover-menu max-[1024px]:w-fit mx-auto">
                <button
                  onMouseOver={() => setCaretColor(true)}
                  onMouseOut={() => setCaretColor(false)}
                  onClick={handleShowGiveaway}
                  className="flex dropdown text-[#656565] hover:text-black"
                >
                  <a className="font-SatoshiMedium text-base mr-1 ">Giveaway</a>
                  <motion.img
                    animate={{
                      rotate: dropdownVisibleGiveaway ? 180 : 0,
                    }}
                    transition={{
                      type: "spring",
                      stiffness: 500,
                      damping: 30,
                    }}
                    src={
                      caretColor ? assets.caretDown : assets.caretDownInactive
                    }
                    className="mt-1"
                  ></motion.img>
                </button>
              </div>
              {dropdownVisibleGiveaway && (
                <div className=" max-[1024px]:hidden hover:flex cursor-pointer lg:absolute lg:top-[2rem] bg-[#ffffff99] rounded-lg backdrop-blur flex flex-col gap-5 w-[150px] py-2 px-1 justify-center items-start bg-opacity-20">
                  {" "}
                  {/* h-[130px] */}
                  <a
                    onClick={() => navigate("/Giveaway")}
                    className="font-SatoshiMedium flex gap-1 items-center cursor-pointer text-[#656565] hover:text-black"
                  >
                    <img
                      className="object-contain w-[24px]"
                      src={assets.coinRaffle}
                    />
                    Coin Giveaway
                  </a>
                  <a
                    onClick={() => {
                      toast("Coming Soon");
                    }}
                    className=" font-SatoshiMedium flex gap-1 items-center cursor-pointer text-[#656565] hover:text-black"
                  >
                    <img
                      className="object-contain w-[24px]"
                      src={assets.coinRaffle}
                    />
                    NFT Giveaway
                  </a>
                </div>
              )}
              {dropdownVisibleGiveaway && (
                <div className="border-1 border-black text-black mt-2 lg:hidden bg-[#f4f4fc6f] hover:flex  cursor-pointer rounded-[12px] backdrop-blur flex flex-col gap-3 items-start justify-start min-w-[170px] px-3 py-3">
                  <a
                    onClick={() => {
                      toast("Coming Soon");
                      // handleOpenChooseNFT();
                    }}
                    className=" font-SatoshiMedium flex gap-1 items-center cursor-pointer "
                  >
                    <img
                      className="object-contain w-[24px]"
                      src={assets.coinRaffle}
                    />
                    NFT Giveaway
                  </a>
                  <a
                    onClick={() => navigate("/Giveaway")}
                    className="font-SatoshiMedium flex gap-1 items-center"
                  >
                    <img
                      className="object-contain w-[24px]"
                      src={assets.coinRaffle}
                    />
                    Coin Giveaway
                  </a>
                </div>
              )}
            </div>
          </li>

          <li className="flex flex-col items-center mr-2 xl:mr-4 gap-1">
            <div className="relative  ">
              <div className="gap-1 items-center cursor-pointer hover-menu max-[1024px]:w-fit mx-auto">
                <button
                  onMouseOver={() => setCaretColor(true)}
                  onMouseOut={() => setCaretColor(false)}
                  onClick={handleShowRaffle}
                  className="flex dropdown text-[#656565] hover:text-black"
                >
                  <a className="font-SatoshiMedium text-base mr-1 ">Games</a>
                  <motion.img
                    animate={{
                      rotate: dropdownVisible ? 180 : 0,
                    }}
                    transition={{
                      type: "spring",
                      stiffness: 500,
                      damping: 30,
                    }}
                    src={
                      caretColor ? assets.caretDown : assets.caretDownInactive
                    }
                    className="mt-1 "
                  ></motion.img>
                </button>
              </div>
              {dropdownVisible && (
                <div className=" max-[1024px]:hidden hover:flex cursor-pointer lg:absolute lg:top-[2rem] bg-[#ffffff99] rounded-lg backdrop-blur flex flex-col gap-5 w-[180px] py-2 px-1 justify-start items-start">
                  <a
                    onClick={() => {
                      // toast("Coming Soon");
                      handleOpenChooseNFT();
                    }}
                    className="font-SatoshiMedium flex gap-1 items-center cursor-pointer text-[#656565] hover:text-black"
                  >
                    <img
                      className="object-contain w-[24px]"
                      src={assets.nftSpin}
                    />
                    NFT Spin
                  </a>
                  <a
                    onClick={() => navigate("/Fortune-Wheel")}
                    className=" font-SatoshiMedium flex gap-1 items-center cursor-pointer text-[#656565] hover:text-black"
                  >
                    <img
                      className="object-contain w-[24px]"
                      src={assets.nftSpin}
                    />
                    Fortune Wheel
                  </a>
                  
                  <a
                    onClick={() => navigate("/coin-flip")}
                    // onClick={()=>{toast("Coming Soon!!")}}
                    className=" font-SatoshiMedium flex gap-1 items-center cursor-pointer text-[#656565] hover:text-black"
                  >
                    <img
                      className="object-contain w-[24px]"
                      src={assets.coinToss}
                    />
                    Coin Flip
                  </a>
                  <a
                    onClick={() => navigate("/Plinko")}
                    // onClick={()=>{toast("Coming Soon!!")}}
                    className=" font-SatoshiMedium flex gap-1 items-center cursor-pointer text-[#656565] hover:text-black"
                  >
                    <img
                      className="object-contain w-[24px]"
                      src={assets.coinToss}
                    />
                    Plinko
                  </a>
                  <a
                    onClick={() => navigate("/Dice-roll")}
                    className=" font-SatoshiMedium flex gap-1 items-center cursor-pointer text-[#656565] hover:text-black"
                  >
                    <img
                      className="object-contain w-[24px]"
                      src={assets.dice}
                    />
                    Dice Roll
                  </a>
                  
                </div>
              )}
              {dropdownVisible && (
                <div className=" border-1 border-black text-black lg:hidden mt-2 bg-[#f4f4fc6f] hover:flex  cursor-pointer rounded-[12px] backdrop-blur flex flex-col gap-3 items-start justify-start min-w-[170px] px-3 py-3">
                  <a
                    onClick={() => {
                      // toast("Coming Soon");
                      handleOpenChooseNFT();
                    }}
                    className="text-black font-SatoshiMedium flex gap-1 items-center cursor-pointer "
                  >
                    <img
                      className="object-contain w-[24px]"
                      src={assets.nftSpin}
                    />
                    NFT Spin
                  </a>
                  <a
                    onClick={() => navigate("/Fortune-Wheel")}
                    className="text-black font-SatoshiMedium flex gap-1 items-center"
                  >
                    <img
                      className="object-contain w-[24px]"
                      src={assets.nftSpin}
                    />
                    Fortune Wheel
                  </a>
                  <a
                    // onClick={()=>{toast("Coming Soon!!")}}
                    onClick={() => navigate("/coin-flip")}
                    className="text-black font-SatoshiMedium flex gap-1 items-center"
                  >
                    <img
                      className="object-contain w-[24px]"
                      src={assets.coinToss}
                    />
                    Coin Flip
                  </a>

                  <a
                    // onClick={()=>{toast("Coming Soon!!")}}
                    onClick={() => navigate("/Plinko")}
                    className="text-black font-SatoshiMedium flex gap-1 items-center"
                  >
                    <img
                      className="object-contain w-[24px]"
                      src={assets.coinToss}
                    />
                    Plinko
                  </a>
                  
                  <a
                    onClick={() => navigate("/dice-roll")}
                    className="text-black font-SatoshiMedium flex gap-1 items-center"
                  >
                    <img
                      className="object-contain w-[24px]"
                      src={assets.dice}
                    />
                    Dice Roll
                  </a>
                  
                </div>
              )}
            </div>
          </li>
          <li className="flex flex-col items-center mr-2 xl:mr-4 gap-1">
            <div className="relative  ">
              <div className="gap-1 items-center cursor-pointer hover-menu max-[1024px]:w-fit mx-auto">
                <button
                  onMouseOver={() => setCaretColor(true)}
                  onMouseOut={() => setCaretColor(false)}
                  onClick={handleLeaderboard}
                  className="flex dropdown text-[#656565] hover:text-black"
                >
                  <a className="font-SatoshiMedium text-base mr-1 ">
                    Leaderboard
                  </a>
                  <motion.img
                    animate={{
                      rotate: dropdownLeaderboardVisible ? 180 : 0,
                    }}
                    transition={{
                      type: "spring",
                      stiffness: 500,
                      damping: 30,
                    }}
                    src={
                      caretColor ? assets.caretDown : assets.caretDownInactive
                    }
                    className="mt-1 "
                  ></motion.img>
                </button>
              </div>
              {dropdownLeaderboardVisible && (
                <div className=" max-[1024px]:hidden hover:flex cursor-pointer lg:absolute lg:top-[2rem]  bg-[#ffffff99] rounded-lg backdrop-blur flex flex-col gap-5 w-[180px] py-2 px-1 justify-start items-start">
                  <a
                    onClick={() => navigate("/randomWeek")}
                    className="font-SatoshiMedium text-base mr-2 xl:mr-5 cursor-pointer text-[#656565] hover:text-black"
                  >
                    Random Week
                  </a>
                  <a
                    onClick={() => navigate("/Leaderboard")}
                    className="font-SatoshiMedium text-base mr-2 xl:mr-5 cursor-pointer text-[#656565] hover:text-black"
                  >
                    Leaderboard
                  </a>
                </div>
              )}
              {dropdownLeaderboardVisible && (
                <div className="border-1 border-black text-black mt-2 lg:hidden bg-[#f4f4fc6f] hover:flex cursor-pointer rounded-[12px] backdrop-blur flex flex-col gap-3 items-start justify-start min-w-[170px] px-3 py-3">
                  <a
                    onClick={() => navigate("/randomWeek")}
                    className="text-black font-SatoshiMedium flex gap-1 items-center"
                  >
                    Random Week
                  </a>
                  <a
                    onClick={() => navigate("/Leaderboard")}
                    className="text-black font-SatoshiMedium flex gap-1 items-center"
                  >
                    Leaderboard
                  </a>
                </div>
              )}
            </div>
          </li>
          <li>
            <a
              onClick={() => navigate("/Faq")}
              // target="_blank"
              to={{ pathname: "/faq" }}
              className="font-SatoshiMedium text-base mr-2 xl:mr-5 cursor-pointer text-[#656565] hover:text-black"
            >
              FAQ
            </a>
          </li>
          <li>
            <div className="flex flex-1 mr-0.5 cursor-pointer">
              <p
                onClick={() => setModalOpen(true)}
                className="font-SatoshiMedium text-base mr-1 whitespace-nowrap text-[#656565] hover:text-black"
              >
                Daily
              </p>
              <img alt="" className="h-6 w-6" src={assets.chest} />
            </div>
          </li>
          <div
            className="bg-divider h-2/3 lg:mx-2 -translate-y-1 max-[1024px]:hidden"
            style={{ width: 2 }}
          ></div>
          <li className="cursor-pointer">
            <div
              onClick={handleOpenCoin}
              className="py-2 rounded-full bg-greyVeryLight flex max-h-10 cursor-pointer px-4 xl:mr-5 items-center"
            >
              <img
                alt=""
                className="h-5 w-5 mr-1.5"
                src={assets.yellowDiamond}
              />
              <p className="font-SatoshiMedium text-sm text-nowrap  text-[#656565] hover:text-black">
                {coins} Coins
              </p>
            </div>
          </li>
          <li>
            <div
              onClick={handleOpenTicket}
              className="py-2 rounded-full bg-greyVeryLight flex max-h-10 cursor-pointer px-4 xl:mr-5 items-center"
            >
              <img alt="" className="h-5 w-5 mr-1.5" src={assets.blueTicket} />
              <p className="font-SatoshiMedium text-sm text-nowrap text-[#656565] hover:text-black">
                {tickets ?? 0} Tickets
              </p>
            </div>
          </li>
          <li>
            <div
              className={clsx(
                "p-1 rounded-full ",
                user.boost.isBoosted && "rainbow"
              )}
            >
              <WalletConnector />
            </div>
          </li>
        </ul>
      </ul>
      <DailyRewardsModal
        isOpen={isModalOpen}
        user={user}
        onClose={() => setModalOpen(false)}
      />
      <TicketModal
        open={openTicket}
        handleClose={handleCloseTicket}
        state={user}
      />
      <CoinModal open={openCoin} handleClose={handleCloseCoin} state={user} />
      <ChooseNFTModal
        open={openChooseNFT}
        handleClose={handleCloseChooseNFT}
        handleOpenNFT={handleOpenNFT}
      />
      <NFTBetModal state={state} open={openNFT} handleClose={handleCloseNFT} />
    </nav>
  );
}
