import React, { useState } from "react";
import Modal from "@material-ui/core/Modal";
import { useWallet } from "@aptos-labs/wallet-adapter-react";
import { useContext } from "react";
import { UserContext } from "../contexts/UserContent";
import "../utils/toasts.css";
import { injectStyle } from "react-toastify/dist/inject-style";
import coin from "../assets/yellow_diamond.svg";
import { toast } from "react-toastify";

import blueTicket from "../assets/blue_ticket.svg";
import clsx from "clsx";

injectStyle();

export default function TrackReferrals({ open, handleClose }) {
  const { account, connected } = useWallet();
  const { user } = useContext(UserContext);
  const [category, setCategory] = useState(0);

  const referralsData =
    category === 0 ? user.successfulReferrals : user.unsuccessfulReferrals;

  return (
    <Modal
      onClose={handleClose}
      open={open}
      style={{
        backgroundColor: "transparent",
        margin: "auto",
        color: "white",
        className: "NFTModal",
      }}
      className="h-[400px] w-[800px] max-[768px]:!h-[90vh] max-[768px]:w-[95vw] "
    >
      <div
        className="w-full h-full flex flex-col bg-white py-5 px-5 xl:px-3 rounded-lg  !outline-none "
        style={{ fontSize: "1.2rem" }}
      >
        <div className="flex justify-start align-items max-[1024px]:overflow-x-scroll">
          <div
            onClick={() => setCategory(0)}
            className={category === 0 ? "selected-div" : "unselected-div"}
          >
            <p className={category === 0 ? "selected" : "unselected"}>
              Successful ({user.referral.referredWallets.filter((ele) => ele.referralBonusReceived == true).length})
            </p>
          </div>
          <div
            onClick={() => setCategory(1)}
            className={category === 1 ? "selected-div" : "unselected-div"}
          >
            <p className={category === 1 ? "selected" : "unselected"}>
              Unsuccessful ({user.referral.referredWallets.filter((ele) => ele.referralBonusReceived == false).length})
            </p>
          </div>
        </div>
        <div className="h-[1px] w-full bg-textGreyLight" />
        <p className="my-3 text-gray-400 font-SathoshiMedium">
          {category === 0
            ? "The following are your successful referrals. Check their ranks and socials."
            : "The following are your unsuccessful referrals. Check their ranks and socials."}
        </p>
        <div className="overflow-y-scroll">
          <table className="w-full text-black">
            <thead>
              <tr>
                <th className="font-normal text-gray-600">Address</th>
                <th className="font-normal text-gray-600">Coins</th>
                <th className="font-normal text-gray-600">Raffle Tickets</th>
                <th className="font-normal text-gray-600">Socials</th>
              </tr>
            </thead>
            {[...user.referral.referredWallets].map((ele, inx) => {
              if (category == 0 && !ele.referralBonusReceived) return
              if (category == 1 && ele.referralBonusReceived) return
              return (
                <tbody>
                  <td className="text-center">{ele.walletAddress.substring(0, 2)}..{ele.walletAddress.slice(-2)}</td>
                  <td className="text-center">
                    <div className="flex items-center justify-center font-SathoshiMedium">
                      {ele.totalPointsEarned ?? 0} <img src={coin} className="h-4 w-4 ml-3" />
                    </div>
                  </td>
                  <td className="text-center flex items-center justify-center">
                    <div className="flex items-center justify-center font-SathoshiMedium">
                      {ele.totalTicketsEarned ?? 0} <img src={blueTicket} className="h-4 w-4 ml-3" />
                    </div>
                  </td>
                  <td className="text-center">
                    <div className="">
                      <a
                        target="_blank"
                        rel="noopener noreferrer"
                        className="text-gray-600 hover:text-gray-800 mx-1 font-SathoshiMedium flex items-center justify-center"
                      >
                        {ele["twitter"] && (
                          <a
                            className="mr-2 cursor-pointer"
                            href={"https://twitter.com/" + ele["twitter"]}
                            target="_blank"
                          >
                            <svg
                              xmlns="http://www.w3.org/2000/svg"
                              width="24"
                              height="24"
                              viewBox="0 0 24 24"
                              fill="none"
                            >
                              <g clip-path="url(#clip0_2416_8706)">
                                <mask
                                  id="mask0_2416_8706"
                                  styles="mask-type:luminance"
                                  maskUnits="userSpaceOnUse"
                                  x="0"
                                  y="0"
                                  width="24"
                                  height="24"
                                >
                                  <path d="M24 0H0V24H24V0Z" fill="white" />
                                </mask>
                                <g mask="url(#mask0_2416_8706)">
                                  <path
                                    d="M21.1875 0H2.8125C1.2592 0 0 1.2592 0 2.8125V21.1875C0 22.7408 1.2592 24 2.8125 24H21.1875C22.7408 24 24 22.7408 24 21.1875V2.8125C24 1.2592 22.7408 0 21.1875 0Z"
                                    fill="black"
                                  />
                                  <path
                                    d="M16.6829 4.6875H19.1639L13.7437 10.8825L20.1202 19.3125H15.1274L11.2169 14.1998L6.74241 19.3125H4.25993L10.0574 12.6863L3.94043 4.6875H9.05991L12.5947 9.36075L16.6829 4.6875ZM15.8122 17.8275H17.1869L8.31291 6.0945H6.83766L15.8122 17.8275Z"
                                    fill="white"
                                  />
                                </g>
                              </g>
                              <defs>
                                <clipPath id="clip0_2416_8706">
                                  <rect width="24" height="24" fill="white" />
                                </clipPath>
                              </defs>
                            </svg>
                          </a>
                        )}


                        {ele["telegram"] && (
                          <a
                            className="mr-2 cursor-pointer"
                            href={"https://t.me/" + ele["telegram"]}
                            target="_blank"
                          >
                            <svg
                              xmlns="http://www.w3.org/2000/svg"
                              width="24"
                              height="24"
                              viewBox="0 0 24 24"
                              fill="none"
                            >
                              <g clip-path="url(#clip0_2416_8716)">
                                <path
                                  d="M24 12C24 5.37258 18.6274 0 12 0C5.37258 0 0 5.37258 0 12C0 18.6274 5.37258 24 12 24C18.6274 24 24 18.6274 24 12Z"
                                  fill="#419FD9"
                                />
                                <path
                                  d="M24 12C24 5.37258 18.6274 0 12 0C5.37258 0 0 5.37258 0 12C0 18.6274 5.37258 24 12 24C18.6274 24 24 18.6274 24 12Z"
                                  fill="url(#paint0_linear_2416_8716)"
                                />
                                <path
                                  d="M5.3937 11.7354C8.88335 10.1831 11.2103 9.15973 12.3746 8.66523C15.6989 7.25348 16.3897 7.00823 16.84 7.00008C16.939 6.99838 17.1604 7.02343 17.3038 7.14223C17.4249 7.24258 17.4582 7.37813 17.4742 7.47323C17.4901 7.56838 17.51 7.78508 17.4942 7.95438C17.314 9.88698 16.5346 14.5769 16.138 16.7415C15.9702 17.6574 15.6398 17.9645 15.3199 17.9945C14.6248 18.0598 14.0969 17.5255 13.4236 17.0748C12.37 16.3697 11.7749 15.9307 10.7522 15.2427C9.57035 14.4475 10.3365 14.0104 11.01 13.2961C11.1863 13.1092 14.2491 10.2647 14.3084 10.0067C14.3158 9.97443 14.3227 9.85413 14.2527 9.79063C14.1827 9.72713 14.0794 9.74883 14.0049 9.76608C13.8992 9.79058 12.2161 10.9264 8.95565 13.1736C8.4779 13.5086 8.0452 13.6717 7.6575 13.6632C7.2301 13.6538 6.40795 13.4165 5.79675 13.2136C5.0471 12.9648 4.45127 12.8333 4.50315 12.4107C4.53017 12.1906 4.82701 11.9655 5.3937 11.7354Z"
                                  fill="white"
                                />
                              </g>
                              <defs>
                                <linearGradient
                                  id="paint0_linear_2416_8716"
                                  x1="12"
                                  y1="0"
                                  x2="12"
                                  y2="23.822"
                                  gradientUnits="userSpaceOnUse"
                                >
                                  <stop stop-color="#2AABEE" />
                                  <stop offset="1" stop-color="#229ED9" />
                                </linearGradient>
                                <clipPath id="clip0_2416_8716">
                                  <rect width="24" height="24" fill="white" />
                                </clipPath>
                              </defs>
                            </svg>
                          </a>
                        )}
                        {ele['twitter'] == undefined && ele['telegram'] == undefined && (
                          <div className="">
                            <svg
                              xmlns="http://www.w3.org/2000/svg"
                              width="18"
                              height="18"
                              viewBox="0 0 18 18"
                              fill="none"
                            >
                              <path
                                d="M14.625 5.62494H12.375V3.93744C12.375 3.04233 12.0194 2.18389 11.3865 1.55095C10.7536 0.918018 9.89511 0.562439 9 0.562439C8.10489 0.562439 7.24645 0.918018 6.61351 1.55095C5.98058 2.18389 5.625 3.04233 5.625 3.93744V5.62494H3.375C3.07663 5.62494 2.79048 5.74347 2.5795 5.95444C2.36853 6.16542 2.25 6.45157 2.25 6.74994V14.6249C2.25 14.9233 2.36853 15.2095 2.5795 15.4204C2.79048 15.6314 3.07663 15.7499 3.375 15.7499H14.625C14.9234 15.7499 15.2095 15.6314 15.4205 15.4204C15.6315 15.2095 15.75 14.9233 15.75 14.6249V6.74994C15.75 6.45157 15.6315 6.16542 15.4205 5.95444C15.2095 5.74347 14.9234 5.62494 14.625 5.62494ZM6.75 3.93744C6.75 3.3407 6.98705 2.76841 7.40901 2.34645C7.83097 1.92449 8.40326 1.68744 9 1.68744C9.59674 1.68744 10.169 1.92449 10.591 2.34645C11.0129 2.76841 11.25 3.3407 11.25 3.93744V5.62494H6.75V3.93744ZM14.625 14.6249H3.375V6.74994H14.625V14.6249Z"
                                fill="#646D80"
                              />
                            </svg>

                          </div>
                        )}
                      </a>
                    </div></td>
                </tbody>

              )
            })

            }



          </table>
        </div>

        <div className="flex items-center justify-center absolute bottom-0 left-0 right-0 bg-white text-center p-4 py-5 shadow-xl rounded-2xl border text-black ">
          {/* <span className="bg-gradient-to-r from-purple to-blue text-black">
            Invite more friends to earn more points
          </span> */}
          <p className="font-SatoshiBold text-left max-[1024px]:mt-6 ">
            <span className="bg-gradient-to-r from-purple to-blue text-transparent bg-clip-text">
              Invite more friends to earn more points
            </span>
          </p>
          <div
            onClick={() => {
              if (connected) {
                toast("Link Copied Successfully to your Clipboard")
                navigator.clipboard.writeText("https://waitlist.avex.id/?referralCode=" + user.referral.code)
              } else {
                toast("Connect your wallet")
              }
              // if ((timer === "opened" && state.chest.price <= user.points))
            }}
            className="px-1  py-0.5 rounded-lg  flex items-center justify-center  ml-5 xl:!w-[5rem] !w-full self-end cursor-pointer bg-bgBlue "
          >
            <p className="font-SatoshiMedium text-base text-white">Invite</p>
          </div>
        </div>
      </div>
    </Modal>
  );
}
