import { assets } from "../../constants";

export default function ActivityLogItem({ele}) {

  const months = ["Jan", "Feb", "March", "April", "May", "April", "May", "June", "July", "Aug", "Sept", "Oct", "Nov", "Dec"]

  const date = new Date(ele.transactionTimestamp)

  return (
    <div className="w-full  rounded-md bg-white py-1.5 px-1.5 mt-2">
    

      <div className="flex items-center justify-between">
        <p className="font-SatoshiMedium text-xs 2xl:text-xl text-textGreyLight">
          on {months[date.getMonth()]} {date.getDate()}, {date.getFullYear()} 
        </p>
        <div className="flex items-center mt-1">
          <img className="object-contain h-4 w-4 mr-1" src={assets.blueTicket} />
          <p className="font-SatoshiMedium text-xs 2xl:text-xl text-textGreyLight">
            {ele.ticketsInvested}
          </p>
        </div>
      </div>
    </div>
  );
}
