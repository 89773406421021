import axios from "axios"
import { Aptos, AptosConfig, Network } from "@aptos-labs/ts-sdk"
import { useWallet, InputTransactionData } from "@aptos-labs/wallet-adapter-react";

const aptosConfig = new AptosConfig({
    network: Network.MAINNET,
})
const aptos = new Aptos(aptosConfig);



const NFT_V2_IMAGE_CONSTANTS = {
  "0x8999e0d611705ce336a7592cc18fe2d25c3832388156361f160aa7186080010a" : {
    "name" : "Proud Lions Club #1474",
    "image" : "https://tradeport.mypinata.cloud/ipfs/Qmbc9geseQBpwe8uX2tRHczrbWAchd21tqrtakjPNygqag/1110.png?pinataGatewayToken=sd9Ceh-eJIQ43PRB3JW6QGkHAr8-cxGhhjDF0Agxwd_X7N4_reLPQXZSP_vUethU&img-width=700&img-height=700&img-fit=cover&img-quality=80&img-onerror=redirect&img-fit=pad&img-format=webp" 
},
"0x45dfd8547136bd462344ef311cdfea6cd2436e3b9ded872be721e45d15647006" : {
    "name" : "GUI LIONS #224",
    "image" : "https://ns6ydd5nmmuwogaync6pl3sadqtvrdtmpeiytrjfvixkfxhjyjfa.arweave.net/bL2Bj61jKWcYGGi89e5AHCdYjmx5EYnFJaouotzpwko/224.png" 
},
"0xd9f378253b7184b31367341318c88560dbdf3658cb14abaada17dfd3233872ab" : {
    "name" : "GUI LIONS #358",
    "image" : "https://arweave.net/bL2Bj61jKWcYGGi89e5AHCdYjmx5EYnFJaouotzpwko/358.png" 
},
"0xa07bb8d1a25916e4e98c63c61cb6de4c0d17ee71301714cca0519e693d64cece" : {
    "name" : "GUI LIONS #1825",
    "image" : "https://arweave.net/bL2Bj61jKWcYGGi89e5AHCdYjmx5EYnFJaouotzpwko/1825.png" 
},
"0x1b41c9d1c572333fa64a401d536c6d5e63762cc657a22f112f12ea3c4c82e874" : {
    "name" : "Swimming Swans #591",
    "image" : "https://arweave.net/yiSf_zNqsu7O-sQSg0bqmQayAFoA40UExYdLbIjo5Hc/591.jpeg" 
},
"0x79c906682cc416f0729cd5b1e6963d5e35b4e1892882edc07f51b66cdbbac3ec" : {
    "name" : "Swimming Swans #1171",
    "image" : "https://arweave.net/yiSf_zNqsu7O-sQSg0bqmQayAFoA40UExYdLbIjo5Hc/1171.jpeg"
},
"0x732a0c08d37fff47ff755241c9fd3bb92e8b2f12df7bc3599052535b6f760037" : {
    "name" : "Swimming Swans #346",
    "image" : "https://arweave.net/yiSf_zNqsu7O-sQSg0bqmQayAFoA40UExYdLbIjo5Hc/346.jpeg" 
},
"0x4a21b7ffd90e2a59f2b623a4aff7f8ae5813cc54adb835da5dec1221278ad250" : {
    "name" : "Swimming Swans #24",
    "image" : "https://arweave.net/yiSf_zNqsu7O-sQSg0bqmQayAFoA40UExYdLbIjo5Hc/24.jpeg" 
},
"0x7178c004c3bd27ea80b5b9cb85c51b6f3ed8f58d9fa63e95050162f4a65d988d" : {
    "name" : "LOOM #766",
    "image" : "https://arweave.net/rAz2d6WxOfrrlzSnQHRDH3zs3RQWdFT4O4uzlZ9cxy8/766.jpeg"
},

"0x8f08efa3b35944a071d4628948c883e6a209f4346e10b1dd5cb23b4a39c30ed6" : {
    "name" : "Aptos Bunnies #553",
    "image" : "https://arweave.net/oIazhnL4jHLBooDXW5Kh7kHiTE-o3ZF8nyHPsYacVX0/553.png"
},

"0xb9e09253b336390966c8a21223aebd33749ed88f37df2fc2585439de35de36e6" : {
    "name" : "Creatus #86",
    "image" : "https://arweave.net/xcVkGlMs_Xd8qYDdOkUISgN7SjaPXaeyVAeI-ktYgqs/86.png"
},

"0x7dba0d9244d078dda510528e660b13d2e29f3f06a84c995d4bac6d46d4d18101" : {
    "name" : "Creatus #88",
    "image" : "https://arweave.net/xcVkGlMs_Xd8qYDdOkUISgN7SjaPXaeyVAeI-ktYgqs/88.png"
},

"0xf394fdae1bfa73679edc7ae8e9e6c0fda6a543dc88d5aa2a3626cdc3518401e" : {
    "name" : "HOOTZ #1283",
    "image" : "https://arweave.net/bN62KLnrG0uW-s_iFBVE0ZwUH2KWvKMZ7hg05-1It6I/1283.jpg"
},

"0x461b369df6f0d3ece63f6f82487b97dee1d432f57721ef2bff9a477f1cf51830" : {
    "name" : "HOOTZ #745",
    "image" : "https://arweave.net/bN62KLnrG0uW-s_iFBVE0ZwUH2KWvKMZ7hg05-1It6I/745.jpg"
},

"0x1c0202617b41e42cdb7294c7180de8ad67ae513f8548a807a6103bc02f69432e" : {
    "name" : "LOOM #841",
    "image" : "https://arweave.net/rAz2d6WxOfrrlzSnQHRDH3zs3RQWdFT4O4uzlZ9cxy8/841.jpeg"
},

// round 3

"0x52f3e5f006d037ca45c8a29c4153a919c972250d76cf6df69c722a74a8ad913f" : {
    "name" : "Swimming Swans #425",
    "image" : "https://arweave.net/yiSf_zNqsu7O-sQSg0bqmQayAFoA40UExYdLbIjo5Hc/425.jpeg"
},
"0x44880754ac2f1a746730d3e6ab89354c83fbc47e870dab06eeadf4d90d55eaa3" : {
    "name" : "Swimming Swans #469",
    "image" : "https://arweave.net/yiSf_zNqsu7O-sQSg0bqmQayAFoA40UExYdLbIjo5Hc/469.jpeg"
},
"0xc01f67c00565ff9aa2972111b7450ec1c5ed574961f21a225c7e28ff19d71c7c" : {
    "name" : "Swimming Swans #884",
    "image" : "https://arweave.net/yiSf_zNqsu7O-sQSg0bqmQayAFoA40UExYdLbIjo5Hc/884.jpeg"
},
"0x89799c3b4ea8e294b530ed36769f5fc321d959a1481ff7a681345ad6e8ca96b3" : {
    "name" : "Swimming Swans #918",
    "image" : "https://arweave.net/yiSf_zNqsu7O-sQSg0bqmQayAFoA40UExYdLbIjo5Hc/918.jpeg"
},
"0x60835d45083f50fe0db839fd45fb4450924104bb85c140e2e256feb1255e2067" : {
    "name" : "Ice Blue #2722",
    "image" : "https://tradeport.mypinata.cloud/ipfs/QmUN3iNdc65LDw7LPFnPmSCwdggaXSqkrEKU3pTo7t4QYe?pinataGatewayToken=sd9Ceh-eJIQ43PRB3JW6QGkHAr8-cxGhhjDF0Agxwd_X7N4_reLPQXZSP_vUethU&img-width=700&img-height=700&img-fit=cover&img-quality=80&img-onerror=redirect&img-fit=pad&img-format=webp"
},
"0x3a8930d3249b262ee7d1ed68dd7798ff57c203af1a67a54d60d26e2c53ee1a47" : {
    "name" : "Ice Blue #2082",
    "image" : "https://tradeport.mypinata.cloud/ipfs/QmeUuRnf4nCwMVLupuMB7j4yw6fawjcjkLCtR1qov24Jtb?pinataGatewayToken=sd9Ceh-eJIQ43PRB3JW6QGkHAr8-cxGhhjDF0Agxwd_X7N4_reLPQXZSP_vUethU&img-width=700&img-height=700&img-fit=cover&img-quality=80&img-onerror=redirect&img-fit=pad&img-format=webp"
},

    // round 4

    "0x82943232147c76cec963a740ed437694799b725b3d17dba52883fc4d6c9f26ec" :{
        "name" : "Zaaptos #1000",
        "image" : "https://tradeport.mypinata.cloud/ipfs/QmUY3zsD8eLGqXwGjCr2j5PcbVXdzZKQjZbj9wBBUA8uox?pinataGatewayToken=sd9Ceh-eJIQ43PRB3JW6QGkHAr8-cxGhhjDF0Agxwd_X7N4_reLPQXZSP_vUethU&img-width=700&img-height=700&img-fit=cover&img-quality=80&img-onerror=redirect&img-fit=pad&img-format=webp"
    },
    "0xdbd253f5f67f1d3f1350752a466875fadda2b9b1af2bbd800961e49f53cb8d60" :{
        "name" : "Zaaptos #1259",
        "image" : "https://tradeport.mypinata.cloud/ipfs/QmVaBsV7uwsxvcVf9FdhTz2pporMAJB8b9Z8McEfZH7bjP?pinataGatewayToken=sd9Ceh-eJIQ43PRB3JW6QGkHAr8-cxGhhjDF0Agxwd_X7N4_reLPQXZSP_vUethU&img-width=700&img-height=700&img-fit=cover&img-quality=80&img-onerror=redirect&img-fit=pad&img-format=webp"
    },
    "0x3e1ff9bc06b49c70e547cd124eda0c85d50bf94e913950ce5fbc19a379a89e21" :{
        "name" : "Zaaptos #923",
        "image" : "https://tradeport.mypinata.cloud/ipfs/Qmedqvrvc7oTiair7Ht8iiQ583yAt7iGLMKydbUfxWGRBc?pinataGatewayToken=sd9Ceh-eJIQ43PRB3JW6QGkHAr8-cxGhhjDF0Agxwd_X7N4_reLPQXZSP_vUethU&img-width=700&img-height=700&img-fit=cover&img-quality=80&img-onerror=redirect&img-fit=pad&img-format=webp"
    },

    "0xd40cbfc98d3a19c8b878389cea1f1c4701244f3c6578a29121d38ba3ef382755" :{
        "name" : "Zaaptos #179",
        "image" : "https://tradeport.mypinata.cloud/ipfs/QmRNK5a1vkuWr1W6mDnaEJiH7etFj3nSZc6fXbBah7QR9a?pinataGatewayToken=sd9Ceh-eJIQ43PRB3JW6QGkHAr8-cxGhhjDF0Agxwd_X7N4_reLPQXZSP_vUethU&img-width=700&img-height=700&img-fit=cover&img-quality=80&img-onerror=redirect&img-fit=pad&img-format=webp"
    },

    "0x4fd5d2d7fbf5bddb23a468126130d11d7be8199e6a33276429e680a7271372a8" :{
        "name" : "Eragon Passport #876",
        "image" : "https://gcp-images.monsterra.io/eragon_nft/female/894.webp"
    },
    "0xfb7123e5ea3e91dda9f9cf2a8659a1725392e22db4564097a1b2c633213c0a38" :{
        "name" : "Eragon Passport #825",
        "image" : "https://gcp-images.monsterra.io/eragon_nft/female/894.webp"
    },
    "0x8eab4551a01f91bdde106d129f126738672af89d1fca4df562182191190338cc" :{
        "name" : "Eragon Passport #894",
        "image" : "https://gcp-images.monsterra.io/eragon_nft/female/894.webp"
    },

    "0x254fc915f8c303654b4ce80fba54289fa60f492bda9790dd822b3351ef142cae" :{
        "name" : "NaPpY SaInT PaTriK DaY!",
        "image" : "https://tradeport.mypinata.cloud/ipfs/QmQGUrvysBqvFZCkyvU1R9qkMEFL4xv5Fgvttj1Nb83RE7?pinataGatewayToken=sd9Ceh-eJIQ43PRB3JW6QGkHAr8-cxGhhjDF0Agxwd_X7N4_reLPQXZSP_vUethU&img-width=700&img-height=700&img-fit=cover&img-quality=80&img-onerror=redirect&img-fit=pad&img-format=webp"
    },
    "0x369756e9f9ac255ccdd9e64f542fd085e17efaacdb4ee2df7dd11b188a87030" :{
        "name" : "NaPpY EaSTeR FoOLz!",
        "image" : "https://tradeport.mypinata.cloud/ipfs/QmQnVWWTtWrfaV2fcPCazJKBNoynJQuX82R9dZX6THuXnZ?pinataGatewayToken=sd9Ceh-eJIQ43PRB3JW6QGkHAr8-cxGhhjDF0Agxwd_X7N4_reLPQXZSP_vUethU&img-width=700&img-height=700&img-fit=cover&img-quality=80&img-onerror=redirect&img-fit=pad&img-format=webp"
    },
    "0x1e42676a4b61819688501415867bb97ddf53ac10a74416088f93d22686ce0dea" :{
        "name" : "NaPpy LuNAr Nu YEr!",
        "image" : "https://tradeport.mypinata.cloud/ipfs/QmfLGg7M7uPvZEV6W8Hx56QVS9oS4LQZzCgFvwpFwdKycr?pinataGatewayToken=sd9Ceh-eJIQ43PRB3JW6QGkHAr8-cxGhhjDF0Agxwd_X7N4_reLPQXZSP_vUethU&img-width=700&img-height=700&img-fit=cover&img-quality=80&img-onerror=redirect&img-fit=pad&img-format=webp"
    },
    "0x5e3a1685fb6c845771cc05ab2612f0812035960c34a81a409e599d205517c65c" :{
        "name" : "NaPpY SaInT PaTriK DaY!",
        "image" : "https://tradeport.mypinata.cloud/ipfs/QmQGUrvysBqvFZCkyvU1R9qkMEFL4xv5Fgvttj1Nb83RE7?pinataGatewayToken=sd9Ceh-eJIQ43PRB3JW6QGkHAr8-cxGhhjDF0Agxwd_X7N4_reLPQXZSP_vUethU&img-width=700&img-height=700&img-fit=cover&img-quality=80&img-onerror=redirect&img-fit=pad&img-format=webp"
    },

    // round 5 all

  "0xa5afaaf243de8594f00b2ec666a81e8118a70317cbb39d5e0fe2d70a1a6f0246": {
    "name": "Ice Blue #1331",
    "image": "https://tradeport.mypinata.cloud/ipfs/QmPVd8c9QH3KzxNcq8FqDbxTA2hshDWLhbE3kqRaLZ8vz3?pinataGatewayToken=sd9Ceh-eJIQ43PRB3JW6QGkHAr8-cxGhhjDF0Agxwd_X7N4_reLPQXZSP_vUethU&img-width=700&img-height=700&img-fit=cover&img-quality=80&img-onerror=redirect&img-fit=pad&img-format=webp"
  },
  "0xec7123a26aa0f13a78d933c3070a91a688a7d74ed291660dce3976f8429378d8": {
    "name": "Ice Blue #3663",
    "image": "https://tradeport.mypinata.cloud/ipfs/Qmc1zPZvTQUqELFdzDmdVvjX8AGxC2VqYwSPcEXKGMNg4a?pinataGatewayToken=sd9Ceh-eJIQ43PRB3JW6QGkHAr8-cxGhhjDF0Agxwd_X7N4_reLPQXZSP_vUethU&img-width=700&img-height=700&img-fit=cover&img-quality=80&img-onerror=redirect&img-fit=pad&img-format=webp"
  },
  "0x2d15f1f59b76343644afa03df31d2fddad2a28836c1633dfdb3c25a66954498": {
    "name": "Ice Blue #1111",
    "image": "https://tradeport.mypinata.cloud/ipfs/QmaEbVc8CeHjR6vxfFLgRcYZqsmMnqUiWzUcvPS57r2dmf?pinataGatewayToken=sd9Ceh-eJIQ43PRB3JW6QGkHAr8-cxGhhjDF0Agxwd_X7N4_reLPQXZSP_vUethU&img-width=700&img-height=700&img-fit=cover&img-quality=80&img-onerror=redirect&img-fit=pad&img-format=webp"
  },
  "0xfda92cd831e5199e34e6755a62f99d9055fad362214d9b4a15e3d56f0ad9de19": {
    "name": "Ice Blue #2639",
    "image": "https://tradeport.mypinata.cloud/ipfs/QmafLBgrdXEb32JYXgxmyPuRKFEFoTcfD2WXqViVEjVjgy?pinataGatewayToken=sd9Ceh-eJIQ43PRB3JW6QGkHAr8-cxGhhjDF0Agxwd_X7N4_reLPQXZSP_vUethU&img-width=700&img-height=700&img-fit=cover&img-quality=80&img-onerror=redirect&img-fit=pad&img-format=webp"
  },
  "0xc49a41b07d9e67d7fd9ca8d56a19586aab5e5e81d0990aeb77ccf5f604309062": {
    "name": "Ice Blue #2818",
    "image": "https://tradeport.mypinata.cloud/ipfs/QmUWkM7zgWJLSoL8orqK4NNAdec1CvRo92EXnkUiA8vbW8?pinataGatewayToken=sd9Ceh-eJIQ43PRB3JW6QGkHAr8-cxGhhjDF0Agxwd_X7N4_reLPQXZSP_vUethU&img-width=700&img-height=700&img-fit=cover&img-quality=80&img-onerror=redirect&img-fit=pad&img-format=webp"
  },
  "0xadec1b6867bef1d55a64416fe4f80c829ae0ae4a29df9506c178b6239018130a": {
    "name": "HOOTZ #1339",
    "image": "https://arweave.net/bN62KLnrG0uW-s_iFBVE0ZwUH2KWvKMZ7hg05-1It6I/1339.jpg"
  },
  "0xcd3da830ed489c0879b6e5ae76f81083e40ae0048494a7c0dc0d9e6a954c995f": {
    "name": "Creatus #59",
    "image": "https://arweave.net/xcVkGlMs_Xd8qYDdOkUISgN7SjaPXaeyVAeI-ktYgqs/59.png"
  },
  "0x2b4266da3d1852e4d8e24f05e03a8efeef1418dd66889cfd90e8380eac2c09fc": {
    "name": "Creatus #61",
    "image": "https://arweave.net/xcVkGlMs_Xd8qYDdOkUISgN7SjaPXaeyVAeI-ktYgqs/61.png"
  },
  "0xd9805ae1214f01498bd8186b6d48602c65be762c5144ca97424f2679566d6dd1": {
    "name": "Aptos Bunnies #111",
    "image": "https://arweave.net/oIazhnL4jHLBooDXW5Kh7kHiTE-o3ZF8nyHPsYacVX0/111.png"
  },
  "0x485d1a715640b8d09666f2c20da48a55c2be94cf67b9d342b7316c6f5bbd73fd": {
    "name": "Aptos Bunnies #408",
    "image": "https://arweave.net/oIazhnL4jHLBooDXW5Kh7kHiTE-o3ZF8nyHPsYacVX0/408.png"
  },
  "0xb64fd348016e7591d0f5fce875b51469620bee4dbdb2a800d95e033406b74390": {
    "name": "Swimming Swans #430",
    "image": "https://arweave.net/yiSf_zNqsu7O-sQSg0bqmQayAFoA40UExYdLbIjo5Hc/430.jpeg"
  },
  "0xd5d0a6017c1cb84fcdfe3c54b5e163a1e7ed4e6226e1e4c0c7f65f0cd9fd0f85": {
    "name": "Swimming Swans #11",
    "image": "https://arweave.net/yiSf_zNqsu7O-sQSg0bqmQayAFoA40UExYdLbIjo5Hc/11.jpeg"
  },
  "0xeb9d2a7d4efceecbfde6e1044d74e036b1513baa1a93a8fd38c06db15b967555": {
    "name": "Creatus #37",
    "image": "https://arweave.net/xcVkGlMs_Xd8qYDdOkUISgN7SjaPXaeyVAeI-ktYgqs/37.png"
  },
  "0x3fa0fa29f37c87fc0d6b9cd44b425bd810c6fee0ce3eb39b6be2ba8614e54c9": {
    "name": "HOOTZ #1305",
    "image": "https://arweave.net/bN62KLnrG0uW-s_iFBVE0ZwUH2KWvKMZ7hg05-1It6I/1305.jpg"
  },
  "0xbd2c2c1b02c10674fd6879be54b01f554fbfd7fb10bdc10798141533d24f4f3f": {
    "name": "HOOTZ #21",
    "image": "https://arweave.net/bN62KLnrG0uW-s_iFBVE0ZwUH2KWvKMZ7hg05-1It6I/21.jpg"
  },
  "0x60421acb5c44519efb082e60359d294585f1eb09566fef5fde31dd1bd80293b5": {
    "name": "NaPpY EaSTeR FoOLz!",
    "image": "ipfs://QmQnVWWTtWrfaV2fcPCazJKBNoynJQuX82R9dZX6THuXnZ"
  },
  "0x5d0d6017f0c24015e50d246cd4c7cc427d2ed348aee0329bd6bfccd1858adc62": {
    "name": "Ice Blue #1358",
    "image": "https://tradeport.mypinata.cloud/ipfs/QmStHY1ydpRa1wpGTGAHJQSAScUmEFRwL5bZRXtqLJLZGu?pinataGatewayToken=sd9Ceh-eJIQ43PRB3JW6QGkHAr8-cxGhhjDF0Agxwd_X7N4_reLPQXZSP_vUethU&img-width=700&img-height=700&img-fit=cover&img-quality=80&img-onerror=redirect&img-fit=pad&img-format=webp"
  },
  "0x7f4998d5572ed7e1177570f1dc1803e4f70b4ec6b115b624bf4e78ceed09b405": {
    "name": "HOOTZ #883",
    "image": "https://arweave.net/bN62KLnrG0uW-s_iFBVE0ZwUH2KWvKMZ7hg05-1It6I/883.jpg"
  },
  "0x1b3323a518ad9c196b7b4424531daf70a00281fb13c04095d79665d345a2d1a8": {
    "name": "Creatus #62",
    "image": "https://arweave.net/xcVkGlMs_Xd8qYDdOkUISgN7SjaPXaeyVAeI-ktYgqs/62.png"
  },
  "0xf9737334671cc1cdf2e75076431d72841a464d3bfc08ca69b1b0a6577360e704": {
    "name": "Creatus #32",
    "image": "https://arweave.net/xcVkGlMs_Xd8qYDdOkUISgN7SjaPXaeyVAeI-ktYgqs/32.png"
  },
  "0x347ae9aedb3d90b919d1f687248be03daf1ddb58d4edab423dd230f52df005cf": {
    "name": "Creatus #95",
    "image": "https://arweave.net/xcVkGlMs_Xd8qYDdOkUISgN7SjaPXaeyVAeI-ktYgqs/95.png"
  },
  "0xb0be68477266a46179fc82a429be28e7ce872e45692914ce66c7050b71853a2c": {
    "name": "HOOTZ #1128",
    "image": "https://arweave.net/bN62KLnrG0uW-s_iFBVE0ZwUH2KWvKMZ7hg05-1It6I/1128.jpg"
  },
  "0xb2f9c8a29b2d75375f12dbb6cf8f96d93c37c58bca8ca45a928c015efc730628": {
    "name": "Aptos Bunnies #76",
    "image": "https://arweave.net/oIazhnL4jHLBooDXW5Kh7kHiTE-o3ZF8nyHPsYacVX0/76.png"
  },
  "0x55b3b181a9eced772073a852fc9119ccdc3d38e1565ba7dc6d12d634d7628fe2": {
    "name": "Aptos Bunnies #181",
    "image": "https://arweave.net/oIazhnL4jHLBooDXW5Kh7kHiTE-o3ZF8nyHPsYacVX0/181.png"
  },
  "0x6771ce4d84b6c943d52312e600f018cdf4d8d69fed6a02280ab7175fd95bd5cf": {
    "name": "Aptos Bunnies #421",
    "image": "https://arweave.net/oIazhnL4jHLBooDXW5Kh7kHiTE-o3ZF8nyHPsYacVX0/421.png"
  },
  "0x2c5da39c50af4eb4da60bdd3e7b2a48af6bc655c1d963afe43beb285aaf49eca": {
    "name": "Creatus #39",
    "image": "https://arweave.net/xcVkGlMs_Xd8qYDdOkUISgN7SjaPXaeyVAeI-ktYgqs/39.png"
  },
  "0x48b14fcfcca0f0f29316806c361c6fe0ae3107419d55e838e8a6baa44a299208": {
    "name": "Creatus #54",
    "image": "https://arweave.net/xcVkGlMs_Xd8qYDdOkUISgN7SjaPXaeyVAeI-ktYgqs/54.png"
  },
  "0x375a3a20af350db6df56cf4c3130e637f4f18bf2291e1cf957b18ec40df075a2": {
    "name": "Creatus #98",
    "image": "https://arweave.net/xcVkGlMs_Xd8qYDdOkUISgN7SjaPXaeyVAeI-ktYgqs/98.png"
  },
  "0x516e1716a651ea31902b587b58bebacf6cee9ef849306223499ca67aad890c3": {
    "name": "Creatus #81",
    "image": "https://arweave.net/xcVkGlMs_Xd8qYDdOkUISgN7SjaPXaeyVAeI-ktYgqs/81.png"
  },
  "0xdf71b39cb836934598c8046a2302415fa0f92ae7de70b01b936b5ac58bad6321": {
    "name": "Creatus #53",
    "image": "https://arweave.net/xcVkGlMs_Xd8qYDdOkUISgN7SjaPXaeyVAeI-ktYgqs/53.png"
  },
  "0x36ba06d8f0df6b28c609646de0e86ae4dd3359cde64f332f3ae6ce697e5e3335": {
    "name": "HOOTZ #1120",
    "image": "https://arweave.net/bN62KLnrG0uW-s_iFBVE0ZwUH2KWvKMZ7hg05-1It6I/1120.jpg"
  },
  "0xf7da5f4ebd407e45f1db23b3e55c776e20fec336b6813c4c54c247653cb7af5": {
    "name": "Swimming Swans #7",
    "image": "https://arweave.net/yiSf_zNqsu7O-sQSg0bqmQayAFoA40UExYdLbIjo5Hc/7.jpeg"
  },
  "0x1eb027e9a02c74aa0ae6267414151f084e840801a23ab13ece0f58c39aea980f": {
    "name": "Swimming Swans #14",
    "image": "https://arweave.net/yiSf_zNqsu7O-sQSg0bqmQayAFoA40UExYdLbIjo5Hc/14.jpeg"
  },
  "0x34879aa37381310f1e79b2e97ade682fc72a4af3d801b0f9800fce021b2a26ef": {
    "name": "Swimming Swans #4",
    "image": "https://arweave.net/yiSf_zNqsu7O-sQSg0bqmQayAFoA40UExYdLbIjo5Hc/4.jpeg"
  },
  "0x3efb4b710eb88baa52dce441e3f4998147dc71a8bfd45a2bc5e334e5ef195c4a": {
    "name": "Swimming Swans #412",
    "image": "https://arweave.net/yiSf_zNqsu7O-sQSg0bqmQayAFoA40UExYdLbIjo5Hc/412.jpeg"
  },
  "0xee010b32e053acba252ba3fdcc8cee57a130b0efead96b9f386ec9c7dcb38eda": {
    "name": "Swimming Swans #1",
    "image": "https://arweave.net/yiSf_zNqsu7O-sQSg0bqmQayAFoA40UExYdLbIjo5Hc/1.jpeg"
  },
  "0xfbcacea936eab035a85ce3b49fa9f6452f17e1cd938aa0487e2be529165e91c9": {
    "name": "Creatus #44",
    "image": "https://arweave.net/xcVkGlMs_Xd8qYDdOkUISgN7SjaPXaeyVAeI-ktYgqs/44.png"
  },
  "0xa4aa1e7c9739ce55aa98de033c91afb5bf2bf2547472168ac62bc05bdb72f604": {
    "name": "Creatus #48",
    "image": "https://arweave.net/xcVkGlMs_Xd8qYDdOkUISgN7SjaPXaeyVAeI-ktYgqs/48.png"
  },
  "0xf0f7de72735cea810ff0603d41b6224c29ae586cc862fc7f2c3a8af793765dd0": {
    "name": "GUI LIONS #1333",
    "image": "https://arweave.net/bL2Bj61jKWcYGGi89e5AHCdYjmx5EYnFJaouotzpwko/1333.png"
  },
  "0x8af1316a541fb97e9340b504defc9aeac1d9676d219f2b1679e05570eccb740d": {
    "name": "GUI LIONS #1952",
    "image": "https://arweave.net/bL2Bj61jKWcYGGi89e5AHCdYjmx5EYnFJaouotzpwko/1952.png"
  },
  "0x2eec0eb0c896b2920ac9ff8988010fd6c275da1cf6055f1d10041d141a6430e2": {
    "name": "Aptos Bunnies #440",
    "image": "https://arweave.net/oIazhnL4jHLBooDXW5Kh7kHiTE-o3ZF8nyHPsYacVX0/440.png"
  },
  "0x8e8f12df0a9a6db5a3ab1836fdfd1a8f99963e8bb74128749bf4b49a1f6f9ced": {
    "name": "Aptos Bunnies #209",
    "image": "https://arweave.net/oIazhnL4jHLBooDXW5Kh7kHiTE-o3ZF8nyHPsYacVX0/209.png"
  },
  "0x946c5c19a831398e3fe28cd921d6f6d5324207180b549e1afa9e8eb9f92b64ed": {
    "name": "Aptos Bunnies #164",
    "image": "https://arweave.net/oIazhnL4jHLBooDXW5Kh7kHiTE-o3ZF8nyHPsYacVX0/164.png"
  },
  "0xb99dd81391741add1784570df8b4a2a75e391cd2649e1563d3014045cd09eba3": {
    "name": "Aptos Bunnies #93",
    "image": "https://arweave.net/oIazhnL4jHLBooDXW5Kh7kHiTE-o3ZF8nyHPsYacVX0/93.png"
  },
  "0xab97ec03385a7bc0c512e2d8a97cb5a8667544fee099759c24776f9164c4fa44": {
    "name": "Ice Blue #3853",
    "image": "https://tradeport.mypinata.cloud/ipfs/QmUxnFzcU4RtNQhqzbRJN9rawVB9bS7jkX8T3YmYb9YvQo?pinataGatewayToken=sd9Ceh-eJIQ43PRB3JW6QGkHAr8-cxGhhjDF0Agxwd_X7N4_reLPQXZSP_vUethU&img-width=700&img-height=700&img-fit=cover&img-quality=80&img-onerror=redirect&img-fit=pad&img-format=webp"
  },
  "0xee24c15e1bd66b449435efeb9abd7b84b95a070c3d87b20d1bb64ee78d15129a": {
    "name": "Ice Blue #1753",
    "image": "https://tradeport.mypinata.cloud/ipfs/QmdTbAtJdE6CN2DujEM87snCMBAYjRAFqdpRUrRmRhcQ8S?pinataGatewayToken=sd9Ceh-eJIQ43PRB3JW6QGkHAr8-cxGhhjDF0Agxwd_X7N4_reLPQXZSP_vUethU&img-width=700&img-height=700&img-fit=cover&img-quality=80&img-onerror=redirect&img-fit=pad&img-format=webp"
  },
  "0xce0f0a5332b372f8c96e046ba37a1876eb1d9e3bbe07446ece8fd95e8f25ca56": {
    "name": "Ice Blue #2513",
    "image": "https://tradeport.mypinata.cloud/ipfs/QmPvonnhfC2vVPHR5yDQzP88XFL9hFHHdpZhh7QFeTij2R?pinataGatewayToken=sd9Ceh-eJIQ43PRB3JW6QGkHAr8-cxGhhjDF0Agxwd_X7N4_reLPQXZSP_vUethU&img-width=700&img-height=700&img-fit=cover&img-quality=80&img-onerror=redirect&img-fit=pad&img-format=webp"
  },
  "0x42772e4b5a9a1771af58d9d671fd9be572b19bbf0697f73f3747b702678013fe": {
    "name": "Ice Blue #2216",
    "image": "https://tradeport.mypinata.cloud/ipfs/QmbctKkEMoHnaQGwJFooCfwgyd9TMMJSY8ALHGoGE1akSm?pinataGatewayToken=sd9Ceh-eJIQ43PRB3JW6QGkHAr8-cxGhhjDF0Agxwd_X7N4_reLPQXZSP_vUethU&img-width=700&img-height=700&img-fit=cover&img-quality=80&img-onerror=redirect&img-fit=pad&img-format=webp"
  }

    
}

const NFT_V1_IMAGE_CONSTANTS = {
    "NetzJoy #24" : "https://xrdpeo3zghiz54xrgtirjwhgoze7slj2pnyspyhysvthtwxlzrfa.arweave.net/vEbyO3kx0Z7y8TTRFNjmdkn5LTp7cSfg-JVmedrrzEo/24.jpeg",
    "NetzJoy #70" : "https://xrdpeo3zghiz54xrgtirjwhgoze7slj2pnyspyhysvthtwxlzrfa.arweave.net/vEbyO3kx0Z7y8TTRFNjmdkn5LTp7cSfg-JVmedrrzEo/70.jpeg",
    // round 3  
    "Watcher12" : "https://tradeport.mypinata.cloud/ipfs/bafybeiesne6t427qr2hwgnu7r7j5aiedygytclwh47d6d62bntfmkka7l4/12.jpeg?pinataGatewayToken=sd9Ceh-eJIQ43PRB3JW6QGkHAr8-cxGhhjDF0Agxwd_X7N4_reLPQXZSP_vUethU&img-width=700&img-height=700&img-fit=cover&img-quality=80&img-onerror=redirect&img-fit=pad&img-format=webp",
    "Watcher48" : "https://tradeport.mypinata.cloud/ipfs/bafybeiesne6t427qr2hwgnu7r7j5aiedygytclwh47d6d62bntfmkka7l4/48.jpeg?pinataGatewayToken=sd9Ceh-eJIQ43PRB3JW6QGkHAr8-cxGhhjDF0Agxwd_X7N4_reLPQXZSP_vUethU&img-width=700&img-height=700&img-fit=cover&img-quality=80&img-onerror=redirect&img-fit=pad&img-format=webp",
    "Watcher13" : "https://tradeport.mypinata.cloud/ipfs/bafybeiesne6t427qr2hwgnu7r7j5aiedygytclwh47d6d62bntfmkka7l4/13.jpeg?pinataGatewayToken=sd9Ceh-eJIQ43PRB3JW6QGkHAr8-cxGhhjDF0Agxwd_X7N4_reLPQXZSP_vUethU&img-width=700&img-height=700&img-fit=cover&img-quality=80&img-onerror=redirect&img-fit=pad&img-format=webp",
    "Watcher3270" : "https://tradeport.mypinata.cloud/ipfs/bafybeiesne6t427qr2hwgnu7r7j5aiedygytclwh47d6d62bntfmkka7l4/3270.jpeg?pinataGatewayToken=sd9Ceh-eJIQ43PRB3JW6QGkHAr8-cxGhhjDF0Agxwd_X7N4_reLPQXZSP_vUethU&img-width=700&img-height=700&img-fit=cover&img-quality=80&img-onerror=redirect&img-fit=pad&img-format=webp",
    // round 5 all
    "FAPTOX #12": "https://gateway.pinit.io/ipfs/QmXxhddM5MJV9XaZFwm2vKdxG4idAxGUYLHZs3D7RSJS6q/12",
    "NetzCuty #1514": "https://arweave.net/6gm5Ai6m1jWWO-2uasAjzaV4mRaQjf9p4dRU0kxEmis/1514.jpeg",
    "NetzCuty #1555": "https://arweave.net/6gm5Ai6m1jWWO-2uasAjzaV4mRaQjf9p4dRU0kxEmis/1555.jpeg",
    "Watcher26": "https://tradeport.mypinata.cloud/ipfs/bafybeiesne6t427qr2hwgnu7r7j5aiedygytclwh47d6d62bntfmkka7l4/26.jpeg?pinataGatewayToken=sd9Ceh-eJIQ43PRB3JW6QGkHAr8-cxGhhjDF0Agxwd_X7N4_reLPQXZSP_vUethU&img-width=700&img-height=700&img-fit=cover&img-quality=80&img-onerror=redirect&img-fit=pad&img-format=webp",
    "Watcher30": "https://tradeport.mypinata.cloud/ipfs/bafybeiesne6t427qr2hwgnu7r7j5aiedygytclwh47d6d62bntfmkka7l4/30.jpeg?pinataGatewayToken=sd9Ceh-eJIQ43PRB3JW6QGkHAr8-cxGhhjDF0Agxwd_X7N4_reLPQXZSP_vUethU&img-width=700&img-height=700&img-fit=cover&img-quality=80&img-onerror=redirect&img-fit=pad&img-format=webp",
    "Watcher8": "https://tradeport.mypinata.cloud/ipfs/bafybeiesne6t427qr2hwgnu7r7j5aiedygytclwh47d6d62bntfmkka7l4/8.jpeg?pinataGatewayToken=sd9Ceh-eJIQ43PRB3JW6QGkHAr8-cxGhhjDF0Agxwd_X7N4_reLPQXZSP_vUethU&img-width=700&img-height=700&img-fit=cover&img-quality=80&img-onerror=redirect&img-fit=pad&img-format=webp",
    "Watcher22": "https://tradeport.mypinata.cloud/ipfs/bafybeiesne6t427qr2hwgnu7r7j5aiedygytclwh47d6d62bntfmkka7l4/22.jpeg?pinataGatewayToken=sd9Ceh-eJIQ43PRB3JW6QGkHAr8-cxGhhjDF0Agxwd_X7N4_reLPQXZSP_vUethU&img-width=700&img-height=700&img-fit=cover&img-quality=80&img-onerror=redirect&img-fit=pad&img-format=webp",
    "Watcher10": "https://tradeport.mypinata.cloud/ipfs/bafybeiesne6t427qr2hwgnu7r7j5aiedygytclwh47d6d62bntfmkka7l4/10.jpeg?pinataGatewayToken=sd9Ceh-eJIQ43PRB3JW6QGkHAr8-cxGhhjDF0Agxwd_X7N4_reLPQXZSP_vUethU&img-width=700&img-height=700&img-fit=cover&img-quality=80&img-onerror=redirect&img-fit=pad&img-format=webp",
    "Watcher49": "https://tradeport.mypinata.cloud/ipfs/bafybeiesne6t427qr2hwgnu7r7j5aiedygytclwh47d6d62bntfmkka7l4/49.jpeg?pinataGatewayToken=sd9Ceh-eJIQ43PRB3JW6QGkHAr8-cxGhhjDF0Agxwd_X7N4_reLPQXZSP_vUethU&img-width=700&img-height=700&img-fit=cover&img-quality=80&img-onerror=redirect&img-fit=pad&img-format=webp",
    "Watcher23": "https://tradeport.mypinata.cloud/ipfs/bafybeiesne6t427qr2hwgnu7r7j5aiedygytclwh47d6d62bntfmkka7l4/23.jpeg?pinataGatewayToken=sd9Ceh-eJIQ43PRB3JW6QGkHAr8-cxGhhjDF0Agxwd_X7N4_reLPQXZSP_vUethU&img-width=700&img-height=700&img-fit=cover&img-quality=80&img-onerror=redirect&img-fit=pad&img-format=webp",
    "NetzCuty #1578": "https://arweave.net/6gm5Ai6m1jWWO-2uasAjzaV4mRaQjf9p4dRU0kxEmis/1578.jpeg",
    "NetzJoy #84": "https://arweave.net/vEbyO3kx0Z7y8TTRFNjmdkn5LTp7cSfg-JVmedrrzEo/84.jpeg",
    "NetzJoy #235": "https://arweave.net/vEbyO3kx0Z7y8TTRFNjmdkn5LTp7cSfg-JVmedrrzEo/235.jpeg",
    "Watcher21": "https://tradeport.mypinata.cloud/ipfs/bafybeiesne6t427qr2hwgnu7r7j5aiedygytclwh47d6d62bntfmkka7l4/21.jpeg?pinataGatewayToken=sd9Ceh-eJIQ43PRB3JW6QGkHAr8-cxGhhjDF0Agxwd_X7N4_reLPQXZSP_vUethU&img-width=700&img-height=700&img-fit=cover&img-quality=80&img-onerror=redirect&img-fit=pad&img-format=webp",
    "NetzCuty #1239": "https://arweave.net/6gm5Ai6m1jWWO-2uasAjzaV4mRaQjf9p4dRU0kxEmis/1239.jpeg",
    "Watcher39": "https://tradeport.mypinata.cloud/ipfs/bafybeiesne6t427qr2hwgnu7r7j5aiedygytclwh47d6d62bntfmkka7l4/39.jpeg?pinataGatewayToken=sd9Ceh-eJIQ43PRB3JW6QGkHAr8-cxGhhjDF0Agxwd_X7N4_reLPQXZSP_vUethU&img-width=700&img-height=700&img-fit=cover&img-quality=80&img-onerror=redirect&img-fit=pad&img-format=webp"
    
}


const supportedCoins = [
    {
        "coin_address" : "0xe4ccb6d39136469f376242c31b34d10515c8eaaa38092f804db8e08a8f53c5b2::assets_v1::EchoCoin002",
        "decimals" : 6
    },
 {
        "coin_address" : "0xdcfa079344261bfde45e7f6281df091743b8d3098bf9e26e1c0212fc5b070621::zaaptos_token::ZaaptosCoin",
        "decimals" : 8
    }
 ]

 const supportedAptosCoin = [
  {
      "coin_address" : "0x1::aptos_coin::AptosCoin",
      "decimals" : 8
  },
{
      "coin_address" : "0x1::aptos_coin::AptosCoin",
      "decimals" : 8
  }
]

 const supportedCoinsPlinko = [
    {
        "coin_address" : "0xe4ccb6d39136469f376242c31b34d10515c8eaaa38092f804db8e08a8f53c5b2::assets_v1::EchoCoin002",
        "decimals" : 6
    },
 {
        "coin_address" : "0xdcfa079344261bfde45e7f6281df091743b8d3098bf9e26e1c0212fc5b070621::zaaptos_token::ZaaptosCoin",
        "decimals" : 8
    }
 ]


const fetchTotalParticipants = async (cointype, raffle_type, onchain_raffle_id) => {
  
    const moduleAddress = process.env.REACT_APP_MODULE_ADDR;

    

    const participants = await aptos.view(
        {
            payload: {
                function: `${moduleAddress}::raffle::get_total_participants`,
                typeArguments: [cointype],
                functionArguments: [
                    raffle_type,
                    onchain_raffle_id
                ]
            }
        }
    )

    return participants
}

const enterRaffle = async (cointype, raffle_type, onchain_raffle_id, tickets_to_enter, signAndSubmitTransaction) => {


    const moduleAddress = process.env.REACT_APP_MODULE_ADDR;



    const transaction = {
        data: {
            function: `${moduleAddress}::raffle::enter_raffle`,
            typeArguments: [cointype],
            functionArguments: [
                raffle_type,
                onchain_raffle_id,
                tickets_to_enter
            ]
        }
    }
        const response = await signAndSubmitTransaction(transaction);
        return await aptos.waitForTransaction({ transactionHash: response.hash });
    


}



const fetchNftV1 = async (moduleAddress) => {
    try{
    const nfts = await aptos.view(
        {
            payload: {
                function: `${moduleAddress}::nft_lottery::see_nft_details`
            }
        }
    )

    if (!nfts || nfts[0].length === 0) {
        //console.log("No NFTs found")
        return []
    }

    const nftImages = []

    nfts[0].map( (element) => {
        const tokenId = element.token_name;
        
        if (NFT_V1_IMAGE_CONSTANTS[tokenId]) {
            nftImages.push({
                image: NFT_V1_IMAGE_CONSTANTS[tokenId],
                name: element.token_name,
                store: element.store.inner,
                floorPrice: element.token_floor_price / 10 ** 8,
            });
        } else {
            nftImages.push({
                image: "",
                name: element.token_name,
                store: element.store.inner,
                floorPrice: element.token_floor_price / 10 ** 8,
            });
        }

    })

    return nftImages
}catch{
    return []
}
}

const fetchNftV2 = async (moduleAddress) => {
    try{
    
    const nfts = await aptos.view(
        {
            payload: {
                function: `${moduleAddress}::nft_lottery::see_nft_v2_details`
            }
        }
    )

    if (!nfts || nfts[0].length === 0) {
        //console.log("No NFTs found")
        return []
    }

    const nftImages = [];


    nfts[0].map((element) => {
        const token_address = element?.token_address?.inner;

        if (NFT_V2_IMAGE_CONSTANTS[token_address]) {
            nftImages.push({
                image: NFT_V2_IMAGE_CONSTANTS[token_address].image,
                name: NFT_V2_IMAGE_CONSTANTS[token_address].name,
                store: element.store.inner,
                floorPrice: element.token_floor_price / 10 ** 8,
            });
        } else{
            nftImages.push({
                image: "",
                name: "",
                store: element.store.inner,
                floorPrice: element.token_floor_price / 10 ** 8,
            });
        }  
        
    })

    return nftImages
}catch{
    return []
}
}

const fetchSoldoutNfts = async (moduleAddress) => {
  try{
    const v1NftsInPlay = (await aptos.view({payload: {function: `${moduleAddress}::nft_lottery::see_nft_details`}}))[0]
    const v2NftsInPlay = (await aptos.view({payload: {function: `${moduleAddress}::nft_lottery::see_nft_v2_details`}}))[0]
    
    const allV1Nfts = Object.keys(NFT_V1_IMAGE_CONSTANTS)
    const allV2Nfts = Object.keys(NFT_V2_IMAGE_CONSTANTS)
    const allNfts = []
    for(const nft of v1NftsInPlay){
      if(NFT_V1_IMAGE_CONSTANTS.hasOwnProperty(nft.token_name)){
        const index = allV1Nfts.indexOf(nft.token_name)
        allV1Nfts.splice(index, 1)
      }
    }

    for(const nft of v2NftsInPlay){
      if(NFT_V2_IMAGE_CONSTANTS.hasOwnProperty(nft.token_address.inner)){
        const index = allV2Nfts.indexOf(nft.token_address.inner)
        allV2Nfts.splice(index, 1)
      }
    }

    for (const tokenId of allV1Nfts) {
        allNfts.push({
          name: tokenId,
          image: NFT_V1_IMAGE_CONSTANTS[tokenId],
        })
    }
    for (const tokenId of allV2Nfts) {
        allNfts.push({
          name: NFT_V2_IMAGE_CONSTANTS[tokenId].name,
          image: NFT_V2_IMAGE_CONSTANTS[tokenId].image,
        })
    }
    // console.log(allNfts)
    return allNfts

  } catch(err){
    console.log(err)
    return []
  }
}


const fetchTickets = async (address) => {
    try {

    const tickets = await aptos.getAccountResource(
        {
            accountAddress: process.env.REACT_APP_RESOURCE_ADDR,
            resourceType: `${process.env.REACT_APP_MODULE_ADDR}::raffle::RaffleManager`
        }
    )

    if (!tickets) {
        return "0";
    }


    const handle = tickets.tickets.handle



        const res = await axios.post(`https://fullnode.mainnet.aptoslabs.com/v1/tables/${handle}/item`, {
            "key_type": "address",
            "value_type": "u64",
            "key": address
        })
        return res.data
    } 
    catch{
        return "0";
    }
}

const fetchDiceConfig = async (address) => {
    try {

    const response = await aptos.getAccountResource(
        {
            accountAddress: process.env.REACT_APP_RESOURCE_ADDR,
            resourceType: `${process.env.REACT_APP_MODULE_ADDR}::dice_roll::GameManager<0xe4ccb6d39136469f376242c31b34d10515c8eaaa38092f804db8e08a8f53c5b2::assets_v1::EchoCoin002>`
        }
    )

    if (!response) {
        return {};
    }

    return response
 
    } 
    catch{
        return {};
    }
}

const fetchDiceRewards = async (address) => {
    try {

    const response = await aptos.getAccountResource(
        {
            accountAddress: address,
            resourceType: `${process.env.REACT_APP_MODULE_ADDR}::dice_roll::PlayerRewards<0xe4ccb6d39136469f376242c31b34d10515c8eaaa38092f804db8e08a8f53c5b2::assets_v1::EchoCoin002>`
        }
    )

    if (!response) {
        return {};
    }

    return response
 
    } 
    catch{
        return {};
    }
}


const fetchNftV1ForRewards = async (moduleAddress, address) => {
    const nfts = await aptos.view(
        {
            payload: {
                function: `${moduleAddress}::nft_lottery::see_nft_details_user_reward`,
                functionArguments: [address]
            }
        }
    )

    if (!nfts || nfts[0].length === 0) {
        //console.log("No NFTs found")
        return []
    }

    const nftImages = []


        nfts[0].map((element) => {
            const tokenId = element.token_name;

            if (NFT_V1_IMAGE_CONSTANTS[tokenId]) {
                nftImages.push({
                    image: NFT_V1_IMAGE_CONSTANTS[tokenId],
                    name: element.token_name,
                    store: element.store.inner,
                    floorPrice: element.token_floor_price / 10 ** 8,
                });
            } else {
                nftImages.push({
                    image: "",
                    name: element.token_name,
                    store: element.store.inner,
                    floorPrice: element.token_floor_price / 10 ** 8,
                });
            }
            
        })

    return nftImages
}

const fetchNftV2ForRewards = async (moduleAddress, address) => {
    const nfts = await aptos.view(
        {
            payload: {
                function: `${moduleAddress}::nft_lottery::see_nft_v2_details_user_reward`,
                functionArguments: [address]
            }
        }
    )

    if (!nfts || nfts[0].length === 0) {
        //console.log("No NFTs found")
        return []
    }

    const nftImages = [];

        nfts[0].map( (element) => {
            const token_address = element?.token_address?.inner;

            if (NFT_V2_IMAGE_CONSTANTS[token_address]) {
                nftImages.push({
                    image: NFT_V2_IMAGE_CONSTANTS[token_address].image,
                    name: NFT_V2_IMAGE_CONSTANTS[token_address].name,
                    store: element.store.inner,
                    floorPrice: element.token_floor_price / 10 ** 8,
                });
            } else{
                nftImages.push({
                    image: "",
                    name: "",
                    store: element.store.inner,
                    floorPrice: element.token_floor_price / 10 ** 8,
                });
            }  
        })

    return nftImages
}


const handlePlayDiceRoll = async (values, bet_type, side, Bet_amount_even_odd, signAndSubmitTransaction, chance) => {

    const moduleAddress = process.env.REACT_APP_MODULE_ADDR;
    //console.log(values);

    const transaction = {
        data: {
            function: `${moduleAddress}::dice_roll::play_multiple`,
            typeArguments: [
                "0xe4ccb6d39136469f376242c31b34d10515c8eaaa38092f804db8e08a8f53c5b2::assets_v1::EchoCoin002"
              ],            
              functionArguments: [
                bet_type, //int,  values - 0 for sum selection, 1 for even / odd
                values,
                side, //bool, true -even  / false -odd,
                Bet_amount_even_odd, //int, // bet amount for even / odd play
                chance // no of times user wants to roll the dice
              ],
            
        }
    }
    try {
        const response = await signAndSubmitTransaction(transaction);
        return await aptos.waitForTransaction({ transactionHash: response.hash });
    } catch (error) {
        console.error(error);
    }
}


const handlePlayV1 = async (values, signAndSubmitTransaction) => {

    const moduleAddress = process.env.REACT_APP_MODULE_ADDR;

    const transaction = {
        data: {
            function: `${moduleAddress}::nft_lottery::play_v1_multiple`,
            functionArguments: [values.winning_percentage, values.use_free_spin, values.nft_store, values.number_of_spins]
        }
    }
    try {
        const response = await signAndSubmitTransaction(transaction);
        return await aptos.waitForTransaction({ transactionHash: response.hash });
    } catch (error) {
        console.error(error);
    }
}

const handlePlayV2 = async (values, signAndSubmitTransaction) => {
    const moduleAddress = process.env.REACT_APP_MODULE_ADDR;

    const transaction = {
        data: {
            function: `${moduleAddress}::nft_lottery::play_v2_multiple`,
            functionArguments: [values.winning_percentage, values.use_free_spin, values.nft_v2_store,values.number_of_spins]
        }
    }
    try {
        const response = await signAndSubmitTransaction(transaction);
        return await aptos.waitForTransaction({ transactionHash: response.hash });
    } catch (error) {
        console.error(error);
    }
}



// ------------------ wheel ------------------
const fetchWheelConfig = async (address) => {
    try {
      const response = await aptos.getAccountResource({
        accountAddress: process.env.REACT_APP_RESOURCE_ADDR,
        resourceType: `${process.env.REACT_APP_MODULE_ADDR}::wheel::GameConfig<0xe4ccb6d39136469f376242c31b34d10515c8eaaa38092f804db8e08a8f53c5b2::assets_v1::EchoCoin002>`,
        //   spinFee =
      });
  
      if (!response) {
        return "No response";
      }
      // console.log(response.spin_fee);
      return response;
    } catch {
      return {};
    }
  };
  
  const wheelPlaying = async (num_spins, signAndSubmitTransaction) => {
    const module_address = process.env.REACT_APP_MODULE_ADDR;
    const payload = {
      data: {
        function: `${module_address}::wheel::play_multiple`,
        typeArguments: ["0xe4ccb6d39136469f376242c31b34d10515c8eaaa38092f804db8e08a8f53c5b2::assets_v1::EchoCoin002"],
        functionArguments: [num_spins],
        type: "entry_function_payload",
      },
    };
  
    // console.log("Payload: ", payload);
    try {
      const responseInit = await signAndSubmitTransaction(payload);
      // console.log("Response: ", response.output);
    const response = await aptos.waitForTransaction({ transactionHash: responseInit.hash });
      if (response && response.events) {
        const playEvents = response.events.filter(
          (event) => event.type === `${module_address}::wheel::PlayEvent`
        );
        // console.log("Player: ", playEvents[0].data.player);
  
        const mappeddata = playEvents.map((event) => ({
          player: event.data.player,
          reward_amount: event.data.reward_amount,
          reward_tier: event.data.reward_tier,
          reward_type: event.data.reward_type,
          timestamp: event.data.timestamp,
        }));
        // console.log("Mapped", mappeddata);
        return mappeddata;
      } else {
        // return await aptos.waitForTransaction({ transactionHash: response.hash });
        console.log("No play events found.");
        return [];
      }
    } catch (error) {
      console.log("Error Spinning Wheel", error);
      return [];
    }
  };
  
  const fetchWheelReward = async (address) => {
    try {
      const response = await aptos.getAccountResource({
        accountAddress: address,
        resourceType: `${process.env.REACT_APP_MODULE_ADDR}::wheel::UserCoinRewards<0xe4ccb6d39136469f376242c31b34d10515c8eaaa38092f804db8e08a8f53c5b2::assets_v1::EchoCoin002>`,
      });
  
      // console.log("Response to Rewards", response);
  
      if (!response) {
        return {coin:{value:0}};
      }
  
      return response;
    } catch {
      return {coin:{value:0}};
    }
  };
  
  const fetchPlayerWheelRewards = async (address) => {
    try {
      const response = await aptos.getAccountResource({
        accountAddress: address,
        resourceType: `${process.env.REACT_APP_MODULE_ADDR}::wheel::UserRewards`,
      });
  
      // console.log("Response to Rewards", response);
  
      if (!response) {
        return {
          nft_v1:[],
          nft_v2:[],
          raffle_ticket:"0",
          waitlist_coins:"0"
          };
      }
  
      return response;
    } catch {
      return {
        nft_v1:[],
          nft_v2:[],
          raffle_ticket:"0",
          waitlist_coins:"0"
      };
    }
  };

// ----------------- Coin Flip ----------------------
  const fetchCoinConfig = async (coinType) => {
    let coin1, coin2;
    if(coinType==="meme") {
      coin1 = supportedCoins[0].coin_address;
      coin2 = supportedCoins[1].coin_address;
    } else {
      coin1 = supportedAptosCoin[0].coin_address;
      coin2 = supportedAptosCoin[1].coin_address;
    }
    try {
        const response = await aptos.getAccountResource(
            {
                accountAddress: process.env.REACT_APP_RESOURCE_ADDR,
                resourceType: `${process.env.REACT_APP_MODULE_ADDR}::coin_flip::GameManager<${coin1}, ${coin2}>`
            }
        )

        if (!response) {
            return {};
        }
        // console.log(response)
        return response
 
    } 
    catch{
        return {};
    }
}

const handlePlayCoin = async (coinType, side, amount, numericChance, signAndSubmitTransaction) => {
    const moduleAddress = process.env.REACT_APP_MODULE_ADDR;
    let coin1, coin2;
    if(coinType==="meme") {
      coin1 = supportedCoins[0].coin_address;
      coin2 = supportedCoins[1].coin_address;
    } else {
      coin1 = supportedAptosCoin[0].coin_address;
      coin2 = supportedAptosCoin[1].coin_address;
    }
    // console.log(selectedChance);
    const payload = {
        data:{
            function: `${moduleAddress}::coin_flip::play_multiple`,
            typeArguments: [
              coin1,
              coin2,
            ],
            functionArguments: [
              side, // 0-> heads /gui, 1-> tails / fomo
              amount, // bet amount
              numericChance // chances
            ],
        },
    };
      console.log(payload);
      
    try {
        const responseInit = await signAndSubmitTransaction(payload);
        // console.log("try");
        const response = await aptos.waitForTransaction({ transactionHash: responseInit.hash });
        if (response && response.events) {
            const playEvents = response.events.filter(
              (event) => event.type === `${moduleAddress}::coin_flip::PlayEvent`
            );
            // console.log(playEvents);
            let divider = 0;
            if(coinType==="meme") {
              if(side===0) divider=supportedCoins[0].decimals; 
              else divider=supportedCoins[1].decimals;
            } else {
              divider = supportedAptosCoin[0].decimals;
            }
            const mappeddata = playEvents.map((event) => ({
                amount_won: event.data.amount_won/10**divider,
                bet_amount: event.data.bet_amount,
                bet_multiplier_denominator: event.data.bet_multiplier_denominator,
                bet_multiplier_numerator: event.data.bet_multiplier_numerator,
                defy_coins_won: event.data.defy_coins_won,
                heads_coin: event.data.heads_coin,
                is_winner: event.data.is_winner,
                outcome_side: event.data.outcome_side,
                player: event.data.player,
                selected_side: event.data.selected_side,
                tales_coin: event.data.tales_coin
              }));
              return {
                success: true,
                data: mappeddata
              };
        } else {
            // return await aptos.waitForTransaction({ transactionHash: response.hash });
            console.log("No play events found.");
            return [];
        }
    }
    catch (error) {
        console.error("catch",error);
        return {success: false}
    }
}

const fetchGUIRewards = async (address) => {
    try {
      const response = await aptos.getAccountResource({
        accountAddress: address,
        resourceType: `${process.env.REACT_APP_MODULE_ADDR}::coin_flip::PlayerRewards<${supportedCoins[0].coin_address}>`
      });
  
      // console.log("Response to Rewards", response);
  
      if (!response) {
        return {rewards_balance:{value:0}};
      }
  
      return response;
    } catch {
      return {rewards_balance:{value:0}};
    }
  };
  
  const fetchFOMORewards = async (address) => {
    try {
      const response = await aptos.getAccountResource({
        accountAddress: address,
        resourceType: `${process.env.REACT_APP_MODULE_ADDR}::coin_flip::PlayerRewards<${supportedCoins[1].coin_address}>`
      });
  
      // console.log("Response to Rewards", response);
  
      if (!response) {
        return {rewards_balance:{value:0}};
      }
  
      return response;
    } catch {
      return {rewards_balance:{value:0}};
    }
  };

  const fetchAptosRewards = async (address) => {
    try {
      const response = await aptos.getAccountResource({
        accountAddress: address,
        resourceType: `${process.env.REACT_APP_MODULE_ADDR}::coin_flip::PlayerRewards<${supportedAptosCoin[0].coin_address}>`
      });
  
      // console.log("Response to Rewards", response);
  
      if (!response) {
        return {rewards_balance:{value:0}};
      }
  
      return response;
    } catch {
      return {rewards_balance:{value:0}};
    }
  };

  const fetchDefyRewards = async (address) => {
    try {
      const response = await aptos.getAccountResource({
        accountAddress: address,
        resourceType: `${process.env.REACT_APP_MODULE_ADDR}::coin_flip::PlayerDefyCoinsRewards`
      });
  
      // console.log("Response to Rewards", response);
  
      if (!response) {
        return {rewards_balance:0};
      }
  
      return response;
    } catch {
      return {rewards_balance:0};
    }
  };
 // ------------------ Plinko ------------------
 const fetchPlinkoGameConfig = async (address) => {
    try {
        const response = await aptos.getAccountResource({
          accountAddress: process.env.REACT_APP_RESOURCE_ADDR,
          resourceType: `${process.env.REACT_APP_MODULE_ADDR}::plinko::GameConfig`,
        });
    
        if (!response) {
          return "No response";
        }
        // console.log(response);
        return response;
      } catch {
        return {};
      }
  }

  const fetchPlinkoManagerConfig = async (address) => {
    try {
        const response = await aptos.getAccountResource({
          accountAddress: process.env.REACT_APP_RESOURCE_ADDR,
          resourceType: `${process.env.REACT_APP_MODULE_ADDR}::plinko::GameManager<${supportedCoinsPlinko[0].coin_address}>`,
        });
    
        if (!response) {
          return "No response";
        }
        // console.log(response);
        return response;
      } catch {
        return {};
      }
  }

  const plinkoPlaying = async (totalAmount, selectedBalls, signAndSubmitTransaction) => {
    // console.log(totalAmount)
    const module_address = process.env.REACT_APP_MODULE_ADDR;
    const payload = {
      data: {
                function: `${process.env.REACT_APP_MODULE_ADDR}::plinko::play`,
                typeArguments: [
                    `${supportedCoinsPlinko[0].coin_address}`
                ],
                functionArguments: [
                    totalAmount*10**(supportedCoinsPlinko[0].decimals), /// amount per ball
                    selectedBalls // num of balls
                ],
            }
    };
  
    // console.log("Payload: ", payload);
    try {
        const responseInit = await signAndSubmitTransaction(payload);
    //   console.log("Response: ", responseInit);
        const response = await aptos.waitForTransaction({ transactionHash: responseInit.hash });
        if (response && response.events) {
            const playEvents = response.events.filter(
            (event) => event.type === `${module_address}::plinko::PlayEvent`
            );
            // console.log("Player: ", playEvents[0].data.player);
    
            const mappeddata = playEvents.map((event) => ({
                amount_won: event.data.amount_won,
                ball_path: event.data.ball_path,
                bet_amount: event.data.bet_amount,
                coin_type: event.data.coin_type,
                multiplier: event.data.multiplier,
                player: event.data.player,
                defy_coins_won: event.data.defy_coins_won
            }));
            // console.log("Mapped", mappeddata);
            return mappeddata;
        } else {
        // return await aptos.waitForTransaction({ transactionHash: response.hash });
        console.log("No play events found.");
        return [];
      }
    } catch (error) {
      console.log("Error while playing", error);
      return [];
    }
  };

  const fetchPlinkoReward = async (address) => {
    try {
      const response = await aptos.getAccountResource({
        accountAddress: address,
        resourceType: `${process.env.REACT_APP_MODULE_ADDR}::plinko::PlayerRewards<${supportedCoinsPlinko[0].coin_address}>`,
      });
  
      // console.log("Response to Rewards", response);
  
      if (!response) {
        return {rewards_balance:{value:0}};
      }
  
      return response;
    } catch {
      return {rewards_balance:{value:0}};
    }
  };

  const fetchPlinkoDefyReward = async (address) => {
    try {
      const response = await aptos.getAccountResource({
        accountAddress: address,
        resourceType: `${process.env.REACT_APP_MODULE_ADDR}::plinko::PlayerDefyCoinRewards`,
      });
  
      // console.log("Response to Rewards", response);
  
      if (!response) {
        return {rewards_balance:0};
      }
  
      return response;
    } catch {
      return {rewards_balance:0};
    }
  };
  
  //--------------------- claim chest ----------------------

  const nft_module_address = "0xb7bb33d9d04720618336da01eed6f91e841a922990b522b2869eca07cce152aa";

  const fetchSlotsRemaningMythicChest = async () => {
    try {
      const slotsremaning = (
        await aptos.view({
          payload: {
            function: `${nft_module_address}::defy_insiders_nft_collection::rarity_mints_left`,
            typeArguments: [],
            functionArguments: ["0"],
          },
        })
      )[0];
      
      // console.log(parseInt(slotsremaning))
      return parseInt(slotsremaning);
    } catch (error) {
      console.error("Error fetching remaining slots for Mythic Chest:", error);
      return 0; // Return 0 in case of an error to disable the button
    }
  };

  const fetchUserClaimedOrNotMythicChest = async (userAddress) => {
    try {
      const hasUserClaimed = (
        await aptos.view({
          payload: {
            function: `${nft_module_address}::defy_insiders_nft_collection::has_minted_rarity`,
            typeArguments: [],
            functionArguments: [userAddress, "0"],
          },
        })
      )[0];
  
      return hasUserClaimed;
    } catch (error) {
      console.error("Error fetching user claim status for Mythic Chest:", error);
      return false; // Return false in case of an error to allow claiming
    }
  };
  
  




export { fetchNftV1, fetchNftV2, fetchNftV1ForRewards, fetchNftV2ForRewards, handlePlayV1, fetchSoldoutNfts,
    handlePlayV2, fetchTotalParticipants, enterRaffle, fetchTickets, fetchDiceConfig, 
    fetchDiceRewards, handlePlayDiceRoll, fetchWheelConfig, wheelPlaying, fetchWheelReward, fetchPlayerWheelRewards,
    fetchCoinConfig, handlePlayCoin, fetchDefyRewards, fetchGUIRewards, fetchFOMORewards, fetchAptosRewards,
    fetchPlinkoGameConfig, fetchPlinkoManagerConfig, plinkoPlaying, fetchPlinkoReward, fetchPlinkoDefyReward,
    fetchSlotsRemaningMythicChest, fetchUserClaimedOrNotMythicChest}
