import Modal from "@material-ui/core/Modal";
import soundIcon from "../../../assets/sound.svg";
import { useEffect, useRef, useState } from "react";
import { Menu } from "@headlessui/react";
import { useWallet } from "@aptos-labs/wallet-adapter-react";
import MarqueeItem from "./MarqueeItem";
import { motion } from "framer-motion";
import axios from "axios";
import meltingFace from "../../../assets/MeltingFace.png";
import { assets, CONST } from "../../../constants";
import refresh from "../../../assets/refresh.png";
import clsx from "clsx";
import { fetchTickets } from "../../../utils/indexer";
import _30Cashack from "../../../assets/30 Aptos Cashback.png";
import _defyPoints from "../../../assets/Defy Points.png";
import soundIcon2 from "../../../assets/speaker-simple-slash.svg";
import beat from "../../../assets/Atchi thooku beat.mp3";
import bettingEffect from "../../../assets/betting-effect.mp3";
import {
  fetchNftV1ForRewards,
  fetchNftV2ForRewards,
  handlePlayV1,
  handlePlayV2,
} from "../../../utils/indexer";
import { Swiper as SwiperComponent, SwiperSlide } from "swiper/react";
import "swiper/css";
import "swiper/css/navigation";
import "swiper/css/pagination";
import "swiper/css/scrollbar";
import { Navigation } from "swiper/modules";
import { useContext } from "react";
import { UserContext } from "../../../contexts/UserContent";
import { Aptos, AptosConfig, Network } from "@aptos-labs/ts-sdk";

const aptosConfig = new AptosConfig({
  network: Network.MAINNET,
  // fullnode: "https://aptos-mainnet.blastapi.io/3638ca75-fecc-4003-93bf-2e5c41a54e70/v1",
});
const aptos = new Aptos(aptosConfig);

const DETAILS = [
  {
    potentialUpside: "1000x",
    odds: "0.1%",
    costToPlay: 0.1 / 100,
  },
  {
    potentialUpside: "500x",
    odds: "0.2%",
    costToPlay: 0.2 / 100,
  },
  {
    potentialUpside: "333x",
    odds: "0.3%",
    costToPlay: 0.3 / 100,
  },
  {
    potentialUpside: "250x",
    odds: "0.4%",
    costToPlay: 0.4 / 100,
  },
  {
    potentialUpside: "200x",
    odds: "0.5%",
    costToPlay: 0.5 / 100,
  },
];

const REWARDS = [
  {
    nft: "0.1%",
    freeSpin: "5%",
    "50Cashback": "8%",
    "40Cashback": "15%",
    "30Cashback": "20%",
    cashback: "43%",
    tickets: "20.9%",
    coins: "31.9%",
  },
  {
    nft: "0.2%",
    freeSpin: "5%",
    "50Cashback": "8%",
    "40Cashback": "15%",
    "30Cashback": "20%",
    cashback: "43%",
    tickets: "20.8%",
    coins: "31.8%",
  },
  {
    nft: "0.3%",
    freeSpin: "5%",
    "50Cashback": "8%",
    "40Cashback": "15%",
    "30Cashback": "20%",
    cashback: "43%",
    tickets: "20.7%",
    coins: "31.7%",
  },
  {
    nft: "0.4%",
    freeSpin: "5%",
    "50Cashback": "8%",
    "40Cashback": "15%",
    "30Cashback": "20%",
    cashback: "43%",
    tickets: "20.6%",
    coins: "31.6%",
  },
  {
    nft: "0.5%",
    freeSpin: "5%",
    "50Cashback": "8%",
    "40Cashback": "15%",
    "30Cashback": "20%",
    cashback: "43%",
    tickets: "20.5%",
    coins: "31.5%",
  },
];

export default function NFTSpinModal({ state, open, handleClose }) {
  const { user, setUser, tickets, setTickets } = useContext(UserContext);

  function getUser() {
    const jwt = localStorage.getItem("AVEX_DEFY_JWT_STORE");
    const config = {
      headers: {
        Authorization: jwt,
      },
    };
    if (jwt) {
      axios.get(`${CONST.baseUrl}/users`, config).then((response) => {
        setUser(response.data.data);
      });
    }
  }

  let audio = new Audio();
  let audio2 = new Audio();

  function timeout(delay) {
    return new Promise((res) => setTimeout(res, delay));
  }

  const { connect, account, signAndSubmitTransaction, connected } = useWallet();

  const [displayText, setDisplayText] = useState(
    "Your Favorite NFT at a fraction of the price"
  );
  const [showText, setShowText] = useState(true);

  const [rewards, setRewards] = useState({});
  const [rewardNft, setRewardNft] = useState([]);
  const [rewardNftV2, setRewardNftV2] = useState([]);
  const [showDropdown, setShowDropdown] = useState(false);
  const [hasSelected, setHasSelected] = useState(false);
  const [selectedSpin, setSelectedSpin] = useState(1);
  const [slides, setSlides] = useState(5);

  let run = false;
  let rewardType = false;

  const [betX, setBetX] = useState(0);

  const swiperRef = useRef(null);

  async function handleClick(index = 1) {
    // setInterval(()=>{
    let i = 1;
    let j = 1;
    audio.load();
    audio2.load();
    let shouldBeRunning = true;
    //console.log(shouldBeRunning+"1");
    if (index == 1) {
      //console.log(shouldBeRunning+"11");
      setShowText(false);
      const endTime = Date.now() + 6000;
      audio.play();
      while (shouldBeRunning) {
        if (Date.now() < endTime) {
          //console.log("RUNNING");
          swiper.slideNext(350 - 25 * i);

          await timeout(350 - 20 * i);
          // await timeout(100)
          if (i <= 12) i += 1;
        } else {
          //console.log("ELSE");

          for (j = 1; j <= 10; j++) {
            swiper.slideNext(50 + 25 * j);

            await timeout(50 + 20 * j);
          }

          audio.pause();
          // +0 for mobile view and +2 for windows
          
          const indexAddedValue = (slides !== 1) ? 2 : 0;

          while (
            Object.keys(swiper?.slides[swiper.activeIndex + indexAddedValue]?.attributes || {}).length > 0 &&
            !rewardType.includes(
              swiper?.slides[swiper.activeIndex + indexAddedValue]?.attributes["1"]?.nodeValue
            )
          )  {
            //console.log(swiper.slides);
            swiper.slideNext(50 + 25 * j);
            audio2.play();

            await timeout(50 + 20 * j);
          }

          let expectedSlide = swiper.activeIndex + indexAddedValue;

          const slideHash =
            swiper.slides[expectedSlide].attributes["1"]["nodeValue"];
          
          //console.log(slideHash);

          if (rewardType.includes(slideHash)) {
            // if(false)
            shouldBeRunning = false;
            setShowText(true);
          } else swiper.slideNext(50 + 25 * j);
          audio.play();
          await timeout(50 + 20 * j);
        }

        await timeout(30);
      }
    } else {
      // setShowText(false)
      //console.log(shouldBeRunning+"1111");
      const endTime = Date.now() + 6000;
      audio.play();
      while (shouldBeRunning) {
        if (Date.now() < endTime) {
          swiper.slideNext(350 - 25 * i);

          await timeout(350 - 20 * i);
          if (i <= 12) i += 1;
        } else {
          for (j = 1; j <= 10; j++) {
            swiper.slideNext(50 + 25 * j);

            await timeout(50 + 20 * j);
          }

          audio.pause();

          while (
            swiper?.slides &&
            swiper.slides[swiper.activeIndex + 2]?.attributes["1"]
              ?.nodeValue !== "NFT"
          ) {
            audio2.play();
            swiper.slideNext(50 + 25 * j);
            await timeout(50 + 20 * j);
          }

          shouldBeRunning = false;
          // setShowText(true)
        }

        await timeout(30);
      }
    }

    // },300)
    // swiperRef.current.swiper.slideNext()
  }

  const getRewards = async () => {
    try {
      const rewards = await aptos.getAccountResource({
        accountAddress: process.env.REACT_APP_RESOURCE_ADDR,
        resourceType: `${process.env.REACT_APP_MODULE_ADDR}::nft_lottery::Rewards`,
      });
      if (!rewards) return;
      const handle = rewards.rewards.handle;
      const data = {
        key_type: "address",
        key: account?.address,
        value_type: `${process.env.REACT_APP_MODULE_ADDR}::nft_lottery::Reward`,
      };

      let reward = await aptos.getTableItem({ handle, data });

      setRewards({
        apt: reward?.apt?.value ?? 0,
        free_spin: reward.free_spin,
        raffle_ticket: reward?.raffle_ticket ?? 0,
        web2_stars: reward.waitlist_coins ?? 0,
      });

      const moduleAddress = process.env.REACT_APP_MODULE_ADDR;
      let nfts = await fetchNftV1ForRewards(moduleAddress, account?.address);
      getUser();

      let nftv2 = await fetchNftV2ForRewards(moduleAddress, account?.address);

      setRewardNft(nfts);
      setRewardNftV2(nftv2);
    } catch (e) {
      //console.log("error", e);
    }
  };

  const setSlidesPerview = () => {
    setSlides(window.innerWidth <= 720 ? 1 : window.innerWidth > 720 ? 5 : 3);
  };


  useEffect(() => {
    // Initially set the amount of slides on page load
    setSlidesPerview();
    // Add the event listener on component mount
    window.addEventListener("resize", setSlidesPerview);
    // Remove the listener on unmount
    return () => {
      window.removeEventListener("resize", setSlidesPerview);
    };
  }, []);

  const handleClaim = async () => {
    const moduleAddress = process.env.REACT_APP_MODULE_ADDR;

    const transaction = {
      data: {
        function: `${moduleAddress}::nft_lottery::claim`,
        functionArguments: [],
      },
    };
    try {
      const response = await signAndSubmitTransaction(transaction);
      await aptos.waitForTransaction({ transactionHash: response.hash });
      fetchTickets(account?.address).then((result) => {
        setTickets(result);
      });
      getRewards();
    } catch (error) {
      console.error(error);
    }
  };

  useEffect(() => {
    if (open) getRewards();
  }, [open]);

  const [swiper, setSwiper] = useState();
  const [sound, setSound] = useState(true);

  useEffect(() => {
    if (sound) {
      audio = new Audio(beat);
      audio2 = new Audio(bettingEffect);
    } else {
      audio = new Audio();
      audio2 = new Audio();
    }
  }, [sound]);

  const computeBetAmount = () => {
    if (!selectedSpin || !betX || !state.nft?.floorPrice) return 0;
    return (
      selectedSpin *
      ((DETAILS[betX].costToPlay * state.nft.floorPrice) / 100 +
        0.1 * state.nft.floorPrice * 0.01)
    ).toFixed(2);
  };

  const handleSelectChange = (e) => {
    setSelectedSpin(e.target.value);
    setHasSelected(true); // User has made a selection
    setShowDropdown(false); // Close dropdown after selection
  };

  return (
    state.nft && (
      <Modal
        onClose={handleClose}
        open={open}
        style={{
          backgroundColor: "transparent",
          margin: "auto",
          borderRadius: 8,
          backdropFilter: "none",
          outlineWidth: 0,
          outline: "none",
          color: "white",
        }}
        outline="none"
        className=" max-[1024px]:w-[95vw] w-[80vw] h-[90vh] lg:h-[70vh] "
      >
        <div className="w-[80vw] h-full bg-white px-6 py-6 rounded-lg !outline-none overflow-y-scroll max-[1024px]:w-[95vw] max-[1024px]:mx-auto">
          <div className="flex justify-between items-center">
            <div className="flex items-center">
              <img
                className="rounded h-10 w-10 object-cover 2xl:h-14 2xl:w-14"
                src={
                  state?.nft?.store ==
                  "0x109db6377c978b6920fcd05187c75324228a0fc36936bfa7ccef334e00bcf3b3"
                    ? "https://tradeport.mypinata.cloud/ipfs/Qmbc9geseQBpwe8uX2tRHczrbWAchd21tqrtakjPNygqag/1110.png?pinataGatewayToken=sd9Ceh-eJIQ43PRB3JW6QGkHAr8-cxGhhjDF0Agxwd_X7N4_reLPQXZSP_vUethU&img-width=700&img-height=700&img-fit=cover&img-quality=80&img-onerror=redirect&img-fit=pad&img-format=webp"
                    : state?.nft?.image
                }
              />
              <div className="ml-2">
                <p className="text-left font-SatoshiBold text-base text-black 2xl:text-xl">
                  {state.nft?.name}
                </p>
                <p className="text-left font-SatoshiMedium text-sm text-textGreyDark 2xl:text-lg">
                  FLOOR - {state.nft.floorPrice} APT{" "}
                </p>
              </div>
            </div>

            <div className="flex items-center">
              <div onClick={() => setSound(!sound)} className="cursor-pointer">
                <img
                  className="object-contain w-6 h-6 mr-6"
                  src={sound ? soundIcon : soundIcon2}
                />
              </div>
              <img
                onClick={handleClose}
                className="object-contain w-4 h-4 mr-6 cursor-pointer"
                src={assets.close}
              />
            </div>
          </div>

          <div className="flex justify-between items-start w-full max-[1024px]:flex-col">
            <div
              className="max-[1024px]:max-w-[98%] max-w-[66%]"
              style={{ flex: 3 }}
            >
              <div className="w-full bg-greyVeryLight py-5 mt-4">
                <p
                  style={{
                    color: displayText.includes("Your Favorite NFT")
                      ? "black"
                      : "#58BB47",
                  }}
                  className="text-center font-SatoshiBold text-base 2xl:text-xl"
                >
                  {/* {setDisplayText(`Your Favourite NFT at ${DETAILS[betX]["odds"]} price`)} */}
                  {showText && displayText.includes("Your Favorite NFT")
                    ? `Your Favorite NFT at a fraction of the price`
                    : displayText}
                </p>
                <div className="flex nowrap mt-5 xl:max-w-[90%] mx-auto relative">
                  <SwiperComponent
                    ref={swiperRef}
                    slidesPerView={slides}
                    onSwiper={(swiper) => setSwiper(swiper)}
                    loop
                    spaceBetween={0}
                    modules={[Navigation]}
                    hashNavigation={{
                      watchState: true,
                    }}
                  >
                    <SwiperSlide data-hash="2x APT REWARD">
                      <MarqueeItem
                        nft={{
                          name: "2x APT REWARD",
                          image: "/SwiperAssets/Aptos Coin.png",
                        }}
                      />
                    </SwiperSlide>

                    <SwiperSlide data-hash="30% APT CASHBACK">
                      <MarqueeItem
                        nft={{
                          name: "30% APT CASHBACK",
                          image: _30Cashack,
                        }}
                      />
                    </SwiperSlide>
                    <SwiperSlide data-hash="40% APT CASHBACK">
                      <MarqueeItem
                        nft={{
                          name: "40% APT CASHBACK",
                          image: "/SwiperAssets/40 Aptos Cashback.png",
                        }}
                      />
                    </SwiperSlide>
                    <SwiperSlide data-hash="50% APT CASHBACK">
                      <MarqueeItem
                        nft={{
                          name: "50% APT CASHBACK",
                          image: "/SwiperAssets/50 Aptos Cashback.png",
                        }}
                      />
                    </SwiperSlide>
                    <SwiperSlide data-hash="NFT">
                      <MarqueeItem isNft={true} nft={state.nft} />
                    </SwiperSlide>
                    <SwiperSlide data-hash="Free Spin">
                      <MarqueeItem
                        nft={{
                          name: "Free Spin",
                          image: "/SwiperAssets/Free Spin.png",
                        }}
                      />
                    </SwiperSlide>
                    <SwiperSlide data-hash="Raffle Ticket">
                      <MarqueeItem
                        nft={{
                          name: "Raffle Ticket",
                          image: "/SwiperAssets/Raffle Ticket.png",
                        }}
                      />
                    </SwiperSlide>
                    <SwiperSlide data-hash="Waitlist Coins">
                      <MarqueeItem
                        nft={{
                          name: "Waitlist Coins",
                          image: _defyPoints,
                        }}
                      />
                    </SwiperSlide>
                  </SwiperComponent>
                  <div class="arrow-up absolute bottom-0 left-1/2 transform -translate-x-1/2 z-[100]"></div>
                  <div class="arrow-down absolute top-0 left-1/2 transform -translate-x-1/2 z-[100]"></div>
                  <div className="bg-black w-[2px] h-full absolute top-0 left-1/2 transform -translate-x-1/2 z-[100]"></div>
                </div>

                <div className="flex items-center justify-between mt-14 2xl:mt-[4rem] text-black max-[1024px]:mt-5">
                  <div className="pl-6 xl:flex-[1_1_0]">
                    <b>Set Your Odds</b>
                  </div>
                  {/* Slider */}

                  <div
                    style={{ flex: 2 }}
                    className="relative items-start justify-center mx-5 max-[1024px]:hidden 2xl:mr-10"
                  >
                    <motion.div
                      animate={{
                        left: `${(betX * 25).toString()}%`,
                      }}
                      style={{
                        top: -40,
                      }}
                      className="absolute bg-textBlue py-1.5 px-2.5 rounded-full transform -translate-x-[45%]"
                    >
                      <p className="m-auto font-SatoshiMedium text-[10px] text-white w-16 text-center">
                        {DETAILS[betX].potentialUpside} Upside
                      </p>
                      <div className="arrow-down-marquee absolute bottom-0 left-1/2 transform translate-y-3/4 -translate-x-1/2"></div>
                    </motion.div>
                    <div className="h-1 bg-borderGrey">
                      <motion.div
                        animate={{ width: `${(betX * 25).toString()}%` }}
                        className="h-1 bg-textBlue"
                      ></motion.div>
                      <div
                        onClick={() => setBetX(0)}
                        style={{
                          backgroundColor: betX >= 0 ? "#2071EE" : "#D9D9D9",
                        }}
                        className="h-3 w-3 rounded-full absolute left-0 top-0 transform -translate-y-1/3 cursor-pointer"
                      ></div>
                      <div
                        onClick={() => setBetX(1)}
                        style={{
                          backgroundColor: betX >= 1 ? "#2071EE" : "#D9D9D9",
                        }}
                        className="h-3 w-3 bg-textBlue rounded-full absolute left-1/4 top-0 transform -translate-y-1/3 cursor-pointer"
                      ></div>
                      <div
                        onClick={() => setBetX(2)}
                        style={{
                          backgroundColor: betX >= 2 ? "#2071EE" : "#D9D9D9",
                        }}
                        className="h-3 w-3 bg-textBlue rounded-full absolute left-2/4 top-0 transform -translate-y-1/3 cursor-pointer"
                      ></div>
                      <div
                        onClick={() => setBetX(3)}
                        style={{
                          backgroundColor: betX >= 3 ? "#2071EE" : "#D9D9D9",
                        }}
                        className="h-3 w-3 bg-textBlue rounded-full absolute left-3/4 top-0 transform -translate-y-1/3 cursor-pointer"
                      ></div>
                      <div
                        onClick={() => setBetX(4)}
                        style={{
                          backgroundColor: betX >= 4 ? "#2071EE" : "#D9D9D9",
                        }}
                        className="h-3 w-3 bg-textBlue rounded-full absolute left-full top-0 transform -translate-y-1/3 cursor-pointer"
                      ></div>
                    </div>
                  </div>

                  {/* Slider */}
                  <div className="xl:flex-[1_1_0] mr-3 xl:mr-0 2xl:mr-3">
                    <div className="text-sm opacity-2">No. of Spins</div>
                    <div
                      className="px-4 py-2 bg-white border border-gray-400 rounded w-[120px] shadow leading-tight cursor-pointer flex justify-between items-center"
                      onClick={() => setShowDropdown(!showDropdown)}
                    >
                      {/* {hasSelected ? (
                          <span>{selectedSpin}</span>
                        ) : ( */}
                      <label className="text-gray-600">
                        <input
                          value={selectedSpin}
                          onChange={handleSelectChange}
                          className="w-10 px-2 rounded focus:outline-none"
                          placeholder="1"
                        />
                        {/* Set Spin */}
                      </label>
                      {/* )} */}
                      <svg
                        className="w-4 h-4 text-gray-500"
                        xmlns="http://www.w3.org/2000/svg"
                        fill="none"
                        viewBox="0 0 24 24"
                        stroke="currentColor"
                      >
                        <path
                          strokeLinecap="round"
                          strokeLinejoin="round"
                          strokeWidth={2}
                          d="M19 9l-7 7-7-7"
                        />
                      </svg>
                    </div>
                    {showDropdown && (
                      <select
                        size="5"
                        className="absolute w-24 bg-white border border-gray-400 rounded shadow z-10 cursor-pointer"
                        onChange={handleSelectChange}
                        onBlur={() => setShowDropdown(false)}
                        style={{ overflowY: "scroll" }}
                      >
                        {[1, 3, 5, 7, 10].map((value) => (
                          <option
                            key={value}
                            value={value}
                            style={{ padding: "8px 12px" }}
                          >
                            {value}
                          </option>
                        ))}
                      </select>
                    )}
                  </div>
                </div>

                <div className="relative items-center justify-center mt-14 min-[1024px]:hidden w-2/3 m-auto  ">
                  <motion.div
                    animate={{
                      left: `${(betX * 25).toString()}%`,
                    }}
                    style={{
                      top: -40,
                    }}
                    className="absolute bg-textBlue py-1.5 px-2.5 rounded-full transform -translate-x-[45%]"
                  >
                    <p className="m-auto font-SatoshiMedium text-[10px] text-white w-16 text-center">
                      {(betX + 1) * 100}x Upside
                    </p>
                    <div className="arrow-down-marquee absolute bottom-0 left-1/2 transform translate-y-3/4 -translate-x-1/2"></div>
                  </motion.div>
                  <div className="h-1 bg-borderGrey">
                    <motion.div
                      animate={{ width: `${(betX * 25).toString()}%` }}
                      className="h-1 bg-textBlue"
                    ></motion.div>
                    <div
                      onClick={() => setBetX(0)}
                      style={{
                        backgroundColor: betX >= 0 ? "#2071EE" : "#D9D9D9",
                      }}
                      className="h-3 w-3 rounded-full absolute left-0 top-0 transform -translate-y-1/3 cursor-pointer"
                    ></div>
                    <div
                      onClick={() => setBetX(1)}
                      style={{
                        backgroundColor: betX >= 1 ? "#2071EE" : "#D9D9D9",
                      }}
                      className="h-3 w-3 bg-textBlue rounded-full absolute left-1/4 top-0 transform -translate-y-1/3 cursor-pointer"
                    ></div>
                    <div
                      onClick={() => setBetX(2)}
                      style={{
                        backgroundColor: betX >= 2 ? "#2071EE" : "#D9D9D9",
                      }}
                      className="h-3 w-3 bg-textBlue rounded-full absolute left-2/4 top-0 transform -translate-y-1/3 cursor-pointer"
                    ></div>
                    <div
                      onClick={() => setBetX(3)}
                      style={{
                        backgroundColor: betX >= 3 ? "#2071EE" : "#D9D9D9",
                      }}
                      className="h-3 w-3 bg-textBlue rounded-full absolute left-3/4 top-0 transform -translate-y-1/3 cursor-pointer"
                    ></div>
                    <div
                      onClick={() => setBetX(4)}
                      style={{
                        backgroundColor: betX >= 4 ? "#2071EE" : "#D9D9D9",
                      }}
                      className="h-3 w-3 bg-textBlue rounded-full absolute left-full top-0 transform -translate-y-1/3 cursor-pointer"
                    ></div>
                  </div>
                </div>

                <div className="flex flex-col sm:flex-row gap-2 sm:gap-0 items-center justify-between px-5 mt-4 max-[1024px]:mt-8 2xl:mt-1">
                  <div
                    onClick={() => {
                      if (state.nft.version == 1) {
                        run = true;
                        rewardType = "";
                        setDisplayText("");
                        handlePlayV1(
                          {
                            winning_percentage: betX + 1,
                            use_free_spin: true,
                            nft_store: state.nft.store,
                            number_of_spins: selectedSpin,
                          },
                          signAndSubmitTransaction
                        )
                          .then((response) => {
                            //console.log("workig");

                            if (!response) {
                              run = false;
                              return;
                            }

                            if (response.success) {
                              handleClick();
                              //console.log(response.events);
                              let rewards = [];
                              response.events.map((event) => {
                                const moduleAddress =
                                  process.env.REACT_APP_MODULE_ADDR;

                                if (
                                  event.type ==
                                  `${moduleAddress}::nft_lottery::RewardEventV2`
                                ) {
                                  run = false;

                                  rewardType = event.data.reward_type;
                                  let reward_type = event.data.reward_type;
                                  let amount = event.data.reward_amount;

                                  if (reward_type.includes("APT"))
                                    amount = amount / 100000000;

                                  rewards.push({
                                    reward_type: reward_type,
                                    amount: amount,
                                  });
                                  
                                }
                              });
                                  
                              if(rewards.length>0){
                                let rewardMessages = rewards.map(
                                  (reward) => `${reward.reward_type} (${reward.amount}), `
                                );
                                let displayMessage = rewardMessages.join("\n");

                                setTimeout(() => {
                                  setDisplayText("Hurray! Congratulations you won: "+ displayMessage);
                                  getRewards();
                                  // setTimeout(() => {
                                  //   setDisplayText("Your Favorite NFT at 0.1% price");
                                  // },5000);
                                },10000);
                              }

                                  // setTimeout(() => {
                                  //   setDisplayText(
                                  //     "Hurray! Congratulations you won " +
                                  //       reward_type +
                                  //       " (" +
                                  //       amount +
                                  //       ")"
                                  //   );
                                  //   getRewards();
                                  //   setTimeout(() => {
                                  //     setDisplayText(
                                  //       "Your Favorite NFT at 0.1% price"
                                  //     );
                                  //   }, 5000);
                                  // }, 10000);

                                  return;
                                
                              
                            }
                          })
                          .catch((err) => {
                            //console.log("notworkig");

                            //console.log(err);
                            run = false;
                          });
                      } else if (state.nft.version == 2) {
                        run = true;

                        setDisplayText("");
                        rewardType = "";

                        handlePlayV2(
                          {
                            winning_percentage: betX + 1,
                            use_free_spin: true,
                            nft_v2_store: state.nft.store,
                            number_of_spins: selectedSpin,
                          },
                          signAndSubmitTransaction
                        )
                          .then((response) => {
                            if (!response) {
                              run = false;
                              return;
                            }

                            if (response.success) {
                              handleClick();
                              //console.log(response.events+"2");
                              let rewards = [];
                              response.events.map((event) => {
                                const moduleAddress =
                                  process.env.REACT_APP_MODULE_ADDR;

                                if (
                                  event.type ==
                                  `${moduleAddress}::nft_lottery::RewardEventV2`
                                ) {
                                  run = false;

                                  rewardType = event.data.reward_type;
                                  let reward_type = event.data.reward_type;
                                  let amount = event.data.reward_amount;

                                  if (reward_type.includes("APT"))
                                    amount = amount / 100000000;

                                  rewards.push({
                                    reward_type: reward_type,
                                    amount: amount,
                                  });
                                  
                                }
                              });
                                  
                              if(rewards.length>0){
                                let rewardMessages = rewards.map(
                                  (reward) => `${reward.reward_type} (${reward.amount}), `
                                );
                                let displayMessage = rewardMessages.join("\n");

                                setTimeout(() => {
                                  setDisplayText("Hurray! Congratulations you won: "+ displayMessage);
                                  getRewards();
                                  // setTimeout(() => {
                                  //   setDisplayText("Your Favorite NFT at 0.1% price");
                                  // },5000);
                                },10000);
                              }
                                  return;                             
                            }
                          })
                          .catch((err) => {
                            //console.log(err);
                            run = false;
                          });
                      }
                    }}
                    className="sm:w-2/3 w-full py-3 bg-bgBlue rounded-lg cursor-pointer mx-2"
                  >
                    {/* <div className="text-center text-white text-base font-SatoshiBold m-auto">
                      Place Bet -{" "}
                      {(
                        selectedSpin *
                        (((0.1 * (betX + 1) + 0.1) * state.nft.floorPrice) /
                          100)
                      ).toFixed(2)} APT <img className="w-8 h-8 ml-2" src={assets.aptosLogo} alt="Aptos Logo"/>  
                    </div> */}
                    {/* <div className="text-center text-white text-base font-SatoshiBold m-auto flex items-center justify-center"> */}
                    <div className="flex flex-row text-white items-center justify-center font-SatoshiBold whitespace-nowrap text-sm sm:text-md">
                      Place Bet -{" "}
                      {(
                        selectedSpin *
                        ((0.1 * (betX + 1) * state.nft.floorPrice * 4) /
                          100)
                      ).toFixed(5)}{" "}
                      APT
                      <img
                        className="w-4 h-4 lg:w-6 lg:h-6 ml-2 mr-2"
                        src={assets.aptosLogo}
                        alt="Aptos Logo"
                      />
                    </div>
                  </div>
                  <div
                    onClick={() => handleClick(0)}
                    className="sm:w-1/3 w-full py-3 stroke-borderGrey border rounded-lg cursor-pointer mx-2 bg-white"
                  >
                    <p className="text-center text-textGreyDark text-base font-SatoshiBold m-auto">
                      Try it
                    </p>
                  </div>
                </div>
              </div>

              {/* <div className="flex flex-row text-black max-[1200px]:flex-col bg-[#F4F4F6] rounded-lg mt-4 2xl:mt-4">
                <div className="px-5 py-2 w-2/5 max-[1200px]:w-full">
                  <b className="2xl:text-xl">Detail</b>
                  <table class="table-auto text-sm">
                    <tbody>
                      <tr>
                        <td class="">Potential Upside</td>
                        <td className="pl-4 purple-text">
                          {DETAILS[betX]["potentialUpside"]}
                        </td>
                      </tr>
                      {/* <tr class="">
                        <td class=" ">Odds of Winning</td>
                        <td className="pl-4">{DETAILS[betX]["odds"]}</td>
                      </tr> 
                      <tr>
                        <td>NFT Price</td>
                        <td className="pl-4">{state.nft.floorPrice}</td>
                      </tr>
                      <tr>
                        <td>Cost To Play</td>
                        <td className="pl-4">
                          {(
                            DETAILS[betX]["costToPlay"] * state.nft?.floorPrice
                          ).toPrecision(3)}{" "}
                          APT
                        </td>
                      </tr>
                      <tr>
                        <td>Service Fee</td>
                        <td className="pl-4">
                          {(0.1 * state.nft?.floorPrice * 0.01).toPrecision(3)}{" "}
                          APT
                        </td>
                      </tr>
                      <tr>
                        <td>Total</td>
                        <td className="pl-4">
                          {(
                            0.1 * state.nft?.floorPrice * 0.01 +
                            DETAILS[betX]["costToPlay"] * state.nft?.floorPrice
                          ).toPrecision(3)}{" "}
                          APT
                        </td>
                      </tr>
                    </tbody>
                  </table>
                </div>
                <div className="px-5 py-2 w-3/5 m-2 max-[1200px]:m-0 max-[1200px]:w-full">
                  <b className="2xl:text-xl">Reward Calculation</b>

                  <div className="w-full h-8 mt-1 rounded-[10px] px-0.5 flex items-center justify-between bg-white">
                    <div
                      onClick={() => setBetX(0)}
                      className={clsx(
                        "rounded-[10px] h-6 flex-1 mx-2 flex items-center px-1 cursor-pointer",
                        betX == 0 ? " bg-black text-white" : "text-textGreyDark"
                      )}
                    >
                      <p className=" font-SatoshiBold text-sm m-auto">1x</p>
                    </div>
                    <div
                      onClick={() => setBetX(1)}
                      className={clsx(
                        "rounded-[10px] h-6 flex-1 mx-2 flex items-center px-1 cursor-pointer",
                        betX == 1 ? " bg-black text-white" : "text-textGreyDark"
                      )}
                    >
                      <p className=" font-SatoshiBold text-sm m-auto">2x</p>
                    </div>
                    <div
                      onClick={() => setBetX(2)}
                      className={clsx(
                        "rounded-[10px] h-6 flex-1 mx-2 flex items-center px-1 cursor-pointer",
                        betX == 2 ? " bg-black text-white" : "text-textGreyDark"
                      )}
                    >
                      <p className=" font-SatoshiBold text-sm m-auto">3x</p>
                    </div>
                    <div
                      onClick={() => setBetX(3)}
                      className={clsx(
                        "rounded-[10px] h-6 flex-1 mx-2 flex items-center px-1 cursor-pointer",
                        betX == 3 ? " bg-black text-white" : "text-textGreyDark"
                      )}
                    >
                      <p className=" font-SatoshiBold text-sm m-auto">4x</p>
                    </div>
                    <div
                      onClick={() => setBetX(4)}
                      className={clsx(
                        "rounded-[10px] h-6 flex-1 mx-2 flex items-center px-1 cursor-pointer",
                        betX == 4 ? " bg-black text-white" : "text-textGreyDark"
                      )}
                    >
                      <p className=" font-SatoshiBold text-sm m-auto">5x</p>
                    </div>
                  </div>

                  <div className="w-full rounded-[10px] px-0.5  items-center justify-between bg-white mt-2">
                    <div className="w-full flex items-center justify-center mt-2 px-4">
                      <motion.div
                        animate={{
                          width: REWARDS[betX]["tickets"],
                        }}
                        transition={{
                          type: "spring",
                          stiffness: 500,
                          damping: 30,
                        }}
                        className={clsx(
                          "rounded bg-[#E2EDFF] h-6 p-0.5 mx-0.5 mt-2"
                        )}
                      ></motion.div>
                      <motion.div
                        animate={{
                          width: REWARDS[betX]["cashback"],
                        }}
                        transition={{
                          type: "spring",
                          stiffness: 500,
                          damping: 30,
                        }}
                        className={clsx(
                          "rounded bg-[#BDD5FF] h-6 p-0.5 mx-0.5 mt-2"
                        )}
                      ></motion.div>
                      <motion.div
                        animate={{
                          width: REWARDS[betX]["coins"],
                        }}
                        transition={{
                          type: "spring",
                          stiffness: 500,
                          damping: 30,
                        }}
                        className={clsx(
                          "rounded bg-[#649BFF] h-6 p-0.5 mx-0.5 mt-2"
                        )}
                      ></motion.div>
                      <motion.div
                        animate={{
                          width: REWARDS[betX]["freeSpin"],
                        }}
                        transition={{
                          type: "spring",
                          stiffness: 500,
                          damping: 30,
                        }}
                        className={clsx(
                          "rounded bg-[#5C4FFE] h-6 p-0.5 mx-0.5 mt-2"
                        )}
                      ></motion.div>

                      <motion.div
                        animate={{
                          width: `0.${betX + 1}%`,
                        }}
                        transition={{
                          type: "spring",
                          stiffness: 500,
                          damping: 30,
                        }}
                        className={clsx(
                          "rounded bg-[#A193FA] h-6 p-0.5 mx-0.5 mt-2"
                        )}
                      ></motion.div>

                      <motion.div
                        animate={{
                          width: REWARDS[betX]["nft"],
                        }}
                        transition={{
                          type: "spring",
                          stiffness: 500,
                          damping: 30,
                        }}
                        className={clsx(
                          "rounded bg-[#3A2F83] h-6 p-0.5 mx-0.5 mt-2"
                        )}
                      ></motion.div>
                    </div>

                    <div className="flex items-center justify-between p-4 w-full">
                      <div>
                        <p className="text-[10px]">
                          {REWARDS[betX]["tickets"]}
                        </p>
                        <div className="flex items-center">
                          <div className="h-1.5 w-1.5 rounded-full bg-[#E2EDFF]"></div>
                          <p className="text-textGreyDark text-[10px] ml-1 max-[1200px]:ml-0">
                            Raffle Ticket
                          </p>
                        </div>
                      </div>

                      <div>
                        <p className="text-[10px]">
                          {REWARDS[betX]["cashback"]}
                        </p>
                        <div className="flex items-center">
                          <div className="h-1.5 w-1.5 rounded-full bg-[#BDD5FF]"></div>
                          <p className="text-textGreyDark text-[10px] ml-1 max-[1200px]:ml-0">
                            Cashback
                          </p>
                        </div>
                      </div>

                      <div>
                        <p className="text-[10px]">{REWARDS[betX]["coins"]}</p>
                        <div className="flex items-center">
                          <div className="h-1.5 w-1.5 rounded-full bg-[#649BFF]"></div>
                          <p className="text-textGreyDark text-[10px] ml-1 max-[1200px]:ml-0">
                            Defy Coins
                          </p>
                        </div>
                      </div>

                      <div>
                        <p className="text-[10px]">
                          {REWARDS[betX]["freeSpin"]}
                        </p>
                        <div className="flex items-center">
                          <div className="h-1.5 w-1.5 rounded-full bg-[#5C4FFE]"></div>
                          <p className="text-textGreyDark text-[10px] ml-1 max-[1200px]:ml-0">
                            Freespin
                          </p>
                        </div>
                      </div>

                      <div>
                        <p className="text-[10px]">0.{betX + 1}%</p>
                        <div className="flex items-center">
                          <div className="h-1.5 w-1.5 rounded-full bg-[#A193FA]"></div>
                          <p className="text-textGreyDark text-[10px] ml-1 max-[1200px]:ml-0">
                            2x APT
                          </p>
                        </div>
                      </div>

                      <div>
                        <p className="text-[10px]">{REWARDS[betX]["nft"]}</p>
                        <div className="flex items-center">
                          <div className="h-1.5 w-1.5 rounded-full bg-[#3A2F83]"></div>
                          <p className="text-textGreyDark text-[10px] ml-1 max-[1200px]:ml-0">
                            NFT
                          </p>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div> */}
            </div>

            <div
              className="max-w-[33%] max-[1024px]:max-w-[100%] w-[100%] m-auto min-[1024px]:flex-[2_1_0%] max-[1024px]:mt-5"
              style={{ flex: 2 }}
            >
              <div className="w-full p-5 bg-greyVeryLight min-[1024px]:min-h-[300px]">
                <div className="flex items-center justify-between">
                  <p className="text-left font-SatoshiBold text-base text-black 2xl:text-xl">
                    Your Rewards
                  </p>
                  <div onClick={getRewards} className="cursor-pointer">
                    <img className="object-contain w-5" src={refresh} />
                  </div>
                </div>
                {/* //console.log(rewards.raffle_ticket, rewards.apt) */}
                {(([...rewardNft, ...rewardNftV2].length == 0 &&
                  rewards.raffle_ticket == 0 &&
                  rewards.apt == 0) ||
                  !connected ||
                  ([...rewardNft, ...rewardNftV2].length == 0 &&
                    rewards.raffle_ticket == undefined &&
                    rewards.apt == undefined)) && (
                  <p className="font-SatoshiMedium text-base text-textGreyDark mx-auto text-center mt-[70px] max-[1200px]:mt-[40px]">
                    <img
                      className="h-28 w-28 align-center justify-center mx-auto"
                      src={meltingFace}
                      alt="melting face"
                    />
                    <br />
                    <br />
                    Play to get rewards.
                  </p>
                )}

                <div className="flex">
                  {[...rewardNft, ...rewardNftV2].map((ele) => {
                    return (
                      <div className="w-16 mx-2">
                        <img className="w-full h-16 rounded" src={ele.image} />
                        <p className="font-SatoshiMedium text-[10px] text-black">
                          {ele.name}
                        </p>
                        <p className="font-SatoshiMedium text-[10px] text-textGreyDark"></p>
                      </div>
                    );
                  })}
                </div>

                <div className="flex mt-2.5">
                  {rewards.raffle_ticket != 0 &&
                    rewards.raffle_ticket != undefined &&
                    connected && (
                      <div className="w-16 mx-2">
                        <div className="w-full h-16 rounded custom-shadow flex items-center">
                          <img
                            className="w-8 h-8 object-contain m-auto"
                            src={assets.blueTicket}
                          />
                        </div>
                        <p className="font-SatoshiMedium text-[10px] text-black">
                          Raffle Ticket
                        </p>
                        <p className="font-SatoshiMedium text-[10px] text-textGreyDark">
                          x{rewards.raffle_ticket}
                        </p>
                      </div>
                    )}
                  {rewards.apt != 0 &&
                    rewards.apt != undefined &&
                    connected && (
                      <div className="w-16 mx-2">
                        <div className="w-full h-16 rounded custom-shadow flex items-center">
                          <img
                            className="w-8 h-8 object-contain m-auto"
                            src={assets.aptosLogo}
                          />
                        </div>
                        <p className="font-SatoshiMedium text-[10px] text-black">
                          {rewards.apt / 100000000} APT
                        </p>
                        {/* <p className="font-SatoshiMedium text-[10px] text-textGreyDark">
                        x {rewards.apt / 100000000}
                      </p> */}
                      </div>
                    )}
                </div>
              </div>

              <div className="flex justify-center items-center mt-4">
                <div
                  onClick={() => {
                    if (
                      ([...rewardNft, ...rewardNftV2].length == 0 &&
                        rewards.raffle_ticket == 0 &&
                        rewards.apt == 0) ||
                      !connected
                    )
                      return;
                    handleClaim();
                  }}
                  className={clsx(
                    " px-6  py-2.5 rounded-lg  flex  items-center justify-center  max-[1200px]:w-full",
                    ([...rewardNft, ...rewardNftV2].length == 0 &&
                      rewards.raffle_ticket == 0 &&
                      rewards.apt == 0) ||
                      !connected
                      ? "bg-borderGrey"
                      : "bg-black cursor-pointer"
                  )}
                >
                  <p className="font-SatoshiMedium text-base text-white">
                    Claim Rewards
                  </p>
                </div>
              </div>
              {/* <div className="relative">
                <div className="absolute top-0 left-0 backdrop-blur w-full h-full rounded-lg w-fit h-fit z-[500]">
                  <p className="text-black font-SatoshiMedium text-base top-1/2 left-1/2 -translate-x-1/2 -translate-y-1/2 absolute">
                    Coming Soon
                  </p>
                </div>
                <div className="w-full rounded-tl-lg rounded-tr-lg bg-greyVeryLight flex items-center justify-between px-2 py-3 mt-6">
                  <div
                    style={{ backgroundColor: "#D80202" }}
                    className="px-3.5 py-1 rounded-[20px] flex items-center justify-center"
                  >
                    <div className="w-1.5 h-1.5 rounded-full bg-white" />
                    <p className="text-left text-white text-sm font-SatoshiMedium ml-2">
                      Live feed
                    </p>
                  </div>

                  <div className="flex items-center">
                    <img
                      className="h-5 w-5 object-contain"
                      src={assets.groupProfile}
                    />
                    <p className="ml-2 font-SatoshiMedium text-textGreyLight text-sm">
                      234 Participants
                    </p>
                  </div>
                </div>
                <div className="w-full px-2 py-3 border-b border-l border-r stroke-borderGrey">
                  <div className="flex items-center justify-between">
                    <div>
                      <p className="text-left font-SatoshiMedium text-sm text-textGreyDark">
                        0x9928..2893
                      </p>
                      <p className="text-left font-SatoshiMedium text-xs text-textGreyLight">
                        0x9928..2893
                      </p>
                    </div>

                    <p className="text-right font-SatoshiMedium text-sm text-textGreyDark">
                      Collected 500 <span className="text-black">Coins</span>
                    </p>
                  </div>
                  <div className="flex items-center justify-between mt-5">
                    <div>
                      <p className="text-left font-SatoshiMedium text-sm text-textGreyDark">
                        0x9928..2893
                      </p>
                      <p className="text-left font-SatoshiMedium text-xs text-textGreyLight">
                        0x9928..2893
                      </p>
                    </div>

                    <p className="text-right font-SatoshiMedium text-sm text-textGreyDark">
                      Collected 500 <span className="text-black">Coins</span>
                    </p>
                  </div>
                  {/* <div className="flex items-center justify-between mt-5">
                <div>
                  <p className="text-left font-SatoshiMedium text-sm text-textGreyDark">
                    0x9928..2893
                  </p>
                  <p className="text-left font-SatoshiMedium text-xs text-textGreyLight">
                    0x9928..2893
                  </p>
                </div>

                <p className="text-right font-SatoshiMedium text-sm text-textGreyDark">
                  Collected 500 <span className="text-black">Coins</span>
                </p>
              </div> 
                </div>
              </div> */}
            </div>
          </div>
        </div>
      </Modal>
    )
  );
}