import Modal from "@material-ui/core/Modal";
import blueTicket from "../../../assets/blueTicket.png";
import { useState, useEffect } from "react";
import yellowDiamond from "../../../assets/yellow_diamond.svg";
import axios from "axios";
import CONST from "../../../constants/constants";
import { useAuth } from "../../../providers/AuthProvider";
import { useWallet } from "@aptos-labs/wallet-adapter-react";
import { fetchTickets } from "../../../utils/indexer";
import { UserContext } from "../../../contexts/UserContent";
import { useContext } from "react";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import "../../../utils/toasts.css";
import loader from "../../../assets/loader-email.gif"

export default function TicketModal({ open, handleClose, state }) {
  const { connected, disconnect, account } = useWallet();
  const { isAuthenticated } = useAuth();
  const {user, setUser, tickets, setTickets} = useContext(UserContext)

  const [loading, setLoading] = useState(false);

  function getUser() {
    const jwt = localStorage.getItem("AVEX_DEFY_JWT_STORE");
    const config = {
      headers: {
        Authorization: jwt,
      },
    };
    if (jwt) {
      axios.get(`${CONST.baseUrl}/users`, config).then((response) => {
        setUser(response.data.data);
        setLoading(false)
      }).catch(()=>{
        setLoading(false)
      });
    }
  }


  useEffect(() => {
    if (isAuthenticated && connected){
      // setLoading(true);
      // getUser()
    }
  }, [isAuthenticated])

  const buyTickets = () => {
    const jwt = localStorage.getItem("AVEX_DEFY_JWT_STORE");
    const config = {
      headers: {
        Authorization: jwt,
      },
    };

    if (jwt) {
      setLoading(true);
      axios
        .post(`${CONST.baseUrl}/users/buy-tickets?amount=${counter}`, config)
        .then((response) => {
          fetchTickets(account?.address).then((result) => {
            setTickets(result)
            getUser()
              setLoading(false);
              toast(`${counter} Tickets bought`)
              setCounter(0);
              handleClose()
           

          });
        })
        .catch(() => {
          setCounter(-1);
          setLoading(false);
          toast("Purchase unsuccessful!");
        });
    }
  };

  const [counter, setCounter] = useState(0);

  const handleIncrement = () => {
    setCounter(counter + 1);
  };

  const handleDecrement = () => {
    if (counter > 0) {
      setCounter(counter - 1);
    }
  };

  useEffect(() => {
    if (open) setCounter(0);
  }, [open]);

  return (
    <Modal
      onClose={handleClose}
      open={open}
      style={{
        backgroundColor: "transparent",
        height: 460,
        width: 350,
        margin: "auto",
        color: "white",
        className: "NFTModal",
      }}
    >
      <div className="w-full h-full bg-white p-5 items-center rounded-lg NFTModal">
        <p className="text-xl font-SatoshiBold mx-auto text-black text-center">
          Raffle Ticket
        </p>
        { (
          <>
            <img className="w-1/3 my-8 mx-auto" src={blueTicket} />
            <div className="flex w-2/3 mx-auto px-1.5 stroke-borderGrey border items-center justify-between rounded-[10px]">
              <p className="text-left text-base font-SatoshiMedium text-black">
                Your Have
              </p>
              <div className="px-3 my-1 py-1 rounded-lg bg-greyVeryLight flex items-center">
                <p className="text-base font-SatoshiBold text-black">
                  {tickets ?? 0}
                </p>
                <img className="h-4 ml-2" src={blueTicket} />
              </div>
            </div>
            <p className="text-center text-sm font-SatoshiMedium text-textGreyDark mt-6">
              Raffle tickets are your gateway to token giveaways.
            </p>
            <div className="flex flex-row items-center justify-between mt-4 ml-3">
              <div className="flex flex-row items-center justify-center bg-greyVeryLight py-2 px-3 md:px-5 rounded-[10px]">
                <img className="w-5 h-5 mr-2 object-contain" src={blueTicket} />
                <div>
                  <p className="text-[12px] md:text-base font-bold font-SatoshiBold text-black">
                    Buy Ticket
                  </p>
                </div>
              </div>
              <div className="text-black ">
                <div className="flex items-center space-x-4 font-SatoshiMedium mr-3 rounded-[10px] border-borderGrey border text-textGreyDark  px-3 py-1.5">
                  <button className="" onClick={handleDecrement}>
                    -
                  </button>
                  <span className="w-5 text-center">{counter}</span>
                  <button className="" onClick={handleIncrement}>
                    +
                  </button>
                </div>
              </div>
            </div>
            {<div className="flex justify-center items-center">
              {counter * 500 > user.balance.points || counter <= 0 ? (
                <button
                  disabled={loading}
                  className="bg-[#CFD2D8] text-white font-SatoshiBold text-base rounded-lg w-5/6 mt-8 py-2"
                >
                  {counter !== 0 ? "Not Enough Coins" : "Select Valid Tickets"}
                </button>
              ) : (
                
                  !loading ? 
                <button
                  onClick={()=>{
                    if(!connected){
                      toast("Connect your Wallet!")
                      return
                    }
                    buyTickets()}}
                  disabled={loading}
                  className="bg-black text-white font-SatoshiBold text-base rounded-lg w-5/6 mt-8 py-2"
                >
        
                    <div className="flex items-center justify-center">
                    <img
                      className="object-contain w-5 h-5 mr-2"
                      src={yellowDiamond}
                    />
                    <p>{counter * 500} Coins</p>
                  </div>
                 
                  
                </button>
                : 
                <img className="w-8 h-8 mt-5" src={loader}/>

                
              )}
            </div>}
          </>
        )}
      </div>
    </Modal>
  );
}
