export default {
    color2: '#FFAA05',
    color3: '#9747FF',
    color4: '#F9379B',
    color5: '#00B061',
    color6: '#4A87F2',
    color7: '#6F6F6F',
    color8: '#4A87F2',
    color9: '#00B061',
    color10: '#F9379B',
    color11: '#9747FF',
    color12: '#FFAA05',
}