import React, { useEffect, useState, useRef } from "react";
import Navbar from "../navbar/Navbar";
import { assets } from "../../constants";
import MatterSim from "./MatterSim2";
import {
  fetchPlinkoGameConfig,
  fetchPlinkoManagerConfig,
  plinkoPlaying,
  fetchPlinkoReward,
  fetchPlinkoDefyReward,
} from "../../utils/indexer";
import { toast } from "react-toastify";
import { useWallet } from "@aptos-labs/wallet-adapter-react";
import { Aptos, AptosConfig, Network } from "@aptos-labs/ts-sdk";
import Matter, {
  Engine,
  Render,
  Runner,
  Bodies,
  Composite,
  Body,
  Events,
  Common,
  Vector,
} from "matter-js";
import clsx from "clsx";
import { useIsMobile } from "../../hooks/useIsMobile";

const aptosConfig = new AptosConfig({
  network: Network.TESTNET,
});
const aptos = new Aptos(aptosConfig);

export default function Plinko() {
  const { account, connected, signAndSubmitTransaction } = useWallet();
  const [isPlaying, setPlaying] = useState(false);
  const [guiRewards, setGuiRewards] = useState(0);
  const [defyRewards, setDefyRewards] = useState(0);

  const decimals = 6;

  const worldWidth = 800;
  const startPins = 3;
  const pinLines = 12;
  const pinSize = 12.82;
  const pinGap = 39;
  const ballSize = 7;
  const ballElastity = 0.01;
  const ballFriction = 0.0002;
  const sceneRef = useRef(null);
  let ballsSpawned = 0;

  const bucketColors = [
    "#FF0000", // Red
    "#FF3000", // Reddish-Orange
    "#FF6000", // Orange-Red
    "#FF9000", // Dark Orange
    "#FFC000", // Light Orange
    "#FFD800", // Yellow-Orange
    "#FFFF00", // Yellow (central color)
    "#FFD800", // Yellow-Orange
    "#FFC000", // Light Orange
    "#FF9000", // Dark Orange
    "#FF6000", // Orange-Red
    "#FF3000", // Reddish-Orange
    "#FF0000", // Red
  ];

  const multiplierColors = {
    3000: "gold-gradient text-white",
    600: "text-white gold-gradient",
    300: "text-[#FF7A00] border-[#FF7A00] bg-[#FF7A00]",
    200: "text-[#BD00FF] border-[#BD00FF] bg-[#BD00FF]",
    120: "text-[#00B061] border-[#00B061] bg-[#00B061]",
    30: "text-green border-green bg-green",
    15: "text-[#2071EE] border-[#2071EE] bg-[#2071EE]",
  };

  const ballCount = [
    "x1",
    "x2",
    "x3",
    "x4",
    "x5",
    "x6",
    "x7",
    "x8",
    "x9",
    "x10",
  ];
  const [plinkoGameConfig, setPlinkoGameConfig] = useState({
    active: false,
  });
  const [finishedBalls, setFinishedBalls] = useState(0);
  const [plinkoGameDefValues, setPlinkoGameDefValues] = useState();
  const [selectedBalls, setSelectedBalls] = useState(1);
  const [playerRewards, setPlayerRewards] = useState([]);
  const [predefinedPaths, setBallPath] = useState([]); //[1, 0, 0, 1, 1, 0, 0, 0, 1, 0, 0, 0] 100; [0, 1, 1, 0, 1, 0, 0, 1, 1, 0, 1, 1] 80
  const multiplierPositions = [];
  const multipliers = ['30x', '6x', '3x', '2x', '1.2x', '0.3x', '0.15x', '0.3x', '1.2x', '2x', '3x', '6x', '30x'];
  const [inputValue, setInputValue] = useState(100000);
  const [historyRewards, setHistoryRewards] = useState([]);

  const [isMobile, setIsMobile] = useState(false);

  useEffect(() => {
    console.log(window.innerWidth);
    const handleResize = () => {
      const mobile = window.innerWidth < 768;
      setIsMobile(mobile);
    };
    handleResize();

    window.addEventListener("resize", handleResize);

    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);

  const containerRef = useRef(null);
  const [scale, setScale] = useState(1);
  // const { isMobile } = useIsMobile();

  useEffect(() => {
    const calculateScale = () => {
      const container = containerRef.current;
      if (!container) return;
      const containerWidth = container.offsetWidth;
      const containerHeight = container.offsetHeight;

      const matterSimWidth = 800;
      const matterSimHeight = 600;

      const scaleX = containerWidth / matterSimWidth;
      const scaleY = containerHeight / matterSimHeight;

      let scale = 0;
      if (!!isMobile) {
        scale = 0.8;
      } else {
        scale = 1;
      }
      console.log(scale);
      const newScale = Math.min(scaleX, scaleY) * scale;

      if (newScale > 0) {
        if (newScale + 0.2 > 1) {
          setScale(newScale);
        } else {
          setScale(newScale + 0.2);
        }
      }
    };

    calculateScale();
  }, [isMobile]);

  console.log(scale);

  useEffect(() => {
    fetchPlinkoGameConfig()
      .then((res) => {
        setPlinkoGameDefValues(res);
      })
      .catch(() => {});
  }, [connected]);

  useEffect(() => {
    fetchPlinkoManagerConfig()
      .then((res) => {
        setPlinkoGameConfig(res);
      })
      .catch(() => {});
  }, [connected]);

  // console.log(plinkoGameDefValues)

  const handleBallsChange = (event) => {
    const value = event.target.value.replace("x", "");
    const numericValue = parseInt(value, 10);
    if (!isNaN(numericValue)) {
      setSelectedBalls(numericValue);
    }
  };
  const getMultiplierColor = (multiplier) => {
    return multiplierColors[multiplier] || "text-black";
  };

  const onPlay = async () => {
    if (isPlaying) {
      toast("Let the current game complete first");
      return;
    }
    if (!plinkoGameConfig.active) {
      toast("The Game has not started yet");
      return;
    }

    if (!connected) {
      toast("Connect you Wallet to proceed");
      return;
    }

    if (inputValue > plinkoGameConfig.max_bet_amount / 10 ** decimals) {
      toast(
        "Amount Cannot be more than " +
          plinkoGameConfig.max_bet_amount / 10 ** decimals +
          " $GUI per ball"
      );
      return;
    }

    if (inputValue < plinkoGameConfig.min_bet_amount / 10 ** decimals) {
      toast(
        "Amount Cannot be less than " +
          plinkoGameConfig.min_bet_amount / 10 ** decimals +
          " $GUI per ball"
      );
      return;
    }
    try {
      const totalAmount = inputValue;
      const response = await plinkoPlaying(
        totalAmount,
        selectedBalls,
        signAndSubmitTransaction
      );
      setPlayerRewards(response);
      for (let i = 0; i < response.length; i++) {
        const newBallPath = response[i].ball_path;
        setBallPath((prevPaths) => [...prevPaths, newBallPath]);
      }
      // setTimeout(() => {
      //   setHistoryRewards(prev => [...prev,...response])
      // }, 10000);

      // setBallPath(prevPaths => [...prevPaths, newBallPath]);
      if (response.length > 0) setPlaying(true);
      else toast("Game Rejected");
    } catch (error) {
      console.error("Error during play: ", error);
    }
  };

  // console.log(predefinedPaths)
  const engine = Engine.create();

  // Plinko Game Canvas
  useEffect(() => {
    // Create engine and renderer
    const container = document.getElementById("matter-canvas-container");
    container.innerHTML = "";
    setFinishedBalls(0);

    const engine = Engine.create();
    const render = Render.create({
      element: container,
      engine: engine,
      options: {
        width: worldWidth,
        height: 600,
        background: "transparent",
        showVelocity: true,
        showAngleIndicator: false,
      },
    });

    // Add Initials
    const pins = [];
    const forceTrackers = [];
    const balls = [];
    let currentPathIndex = 0;

    // Function to spawn new balls at intervals
    const asyncCompositeBallAdd = async (balls) => {
      if (
        JSON.stringify(predefinedPaths) ===
        JSON.stringify([[15, 15, 15, 15, 15, 15, 15, 15, 15, 15, 15, 15]])
      ) {
        setPlaying(false);
        return;
      }
      while (balls.length > ballsSpawned) {
        if (document.visibilityState === "visible" && isPlaying) {
          await new Promise((resolve) => setTimeout(resolve, 750));

          const collisionGroup = Common.nextId();
          Body.set(balls[ballsSpawned], {
            collisionFilter: {
              group: collisionGroup,
              category: 2, // Set a unique category for each ball (can be any number)
              mask: 1, // Set the mask to interact with pins (category 1)
            },
          });

          Composite.add(engine.world, [balls[ballsSpawned]]);
          ballsSpawned++;
        } else {
          await new Promise((resolve) => setTimeout(resolve, 1200));
        }
      }
    };

    // Create balls for predefined paths
    for (let i = 0; i < predefinedPaths.length; i++) {
      let dropBallPosition = 0;
      if (predefinedPaths[i][0] === 1) {
        dropBallPosition = worldWidth / 2.095 + 17;
      } else {
        dropBallPosition = worldWidth / 2.095 - 20;
      }

      const ball = Bodies.circle(dropBallPosition, 50, ballSize, {
        restitution: ballElastity,
        friction: ballFriction,
        density: 0.4,
        render: { fillStyle: "#77DD77" },
      });
      balls.push(ball);
      forceTrackers.push({ x: 0, y: 0 });
    }

    asyncCompositeBallAdd(balls);
    engine.gravity = { x: 0, y: 0.06, scale: 0.0018 };

    // Add Pins and Buckets
    for (let l = 0; l < pinLines; l++) {
      const linePins = startPins + l;
      const lineWidth = linePins * pinGap;
      for (let i = 0; i < linePins; i++) {
        const pinX = worldWidth / 2 - lineWidth / 2 + i * pinGap;
        const pinY = 100 + l * pinGap;
        const pin = Bodies.circle(pinX, pinY, pinSize, {
          isStatic: true,
          render: {
            visible: true,
            fillStyle: "#87CEEB",
          },
        });
        pins.push(pin);

        if (l === pinLines - 1 && i < linePins - 1) {
          const bucketX = pinX + pinGap / 2;
          const bucketY = pinY + pinGap; // Position for the bottom of the bucket
          const bucketWidth = pinGap; // Width of the bucket
          const bucketHeight = 30; // Height of the bucket sides

          // Create left side of the bucket
          const leftSide = Bodies.rectangle(
            bucketX - bucketWidth / 2,
            bucketY + bucketHeight / 2,
            5,
            bucketHeight,
            { isStatic: true }
          );
          // Create right side of the bucket
          const rightSide = Bodies.rectangle(
            bucketX + bucketWidth / 2,
            bucketY + bucketHeight / 2,
            5,
            bucketHeight,
            { isStatic: true }
          );
          // Create bottom of the bucket
          const bottom = Bodies.rectangle(
            bucketX,
            bucketY + bucketHeight,
            bucketWidth,
            5,
            { isStatic: true }
          );

          // Assign a color to each bucket area from the array
          const bucketColor = bucketColors[i % bucketColors.length];
          // Create the bottom area of the bucket
          const bottomArea = Bodies.rectangle(
            bucketX,
            bucketY + bucketHeight / 2,
            bucketWidth - 10,
            bucketHeight,
            {
              isStatic: true,
              isSensor: true, // Make it a sensor so it doesn't physically interact
              render: { fillStyle: bucketColor },
            }
          );

          // Store the reference to the bottomArea body and its multiplier
          multiplierPositions[i] = {
            body: bottomArea, // Reference to the bottomArea body
            value: multipliers[i], // Corresponding multiplier value
          };

          // Add bucket parts to the world
          Composite.add(engine.world, [leftSide, rightSide, bottomArea]);
        }
      }
    }
    Composite.add(engine.world, pins);

    // Path Following Logic
    let followingPredefinedPath = Array(predefinedPaths.length).fill(false);
    let currentSteps = Array(predefinedPaths.length).fill(0);

    Events.on(engine, "collisionStart", (event) => {
      event.pairs.forEach((pair) => {
        for (let i = 0; i < predefinedPaths.length; i++) {
          if (
            (pair.bodyA === balls[i] || pair.bodyB === balls[i]) &&
            !followingPredefinedPath[i]
          ) {
            followingPredefinedPath[i] = true;
            currentSteps[i] = 0;
          }
        }

        let ball = null;
        let pin = null;

        if (balls.includes(pair.bodyA)) {
          ball = pair.bodyA;
          pin = pair.bodyB;
        } else if (balls.includes(pair.bodyB)) {
          ball = pair.bodyB;
          pin = pair.bodyA;
        }

        // Detect if ball and pin touch
        if (ball && pin) {
          const collisionPointY = (ball.position.y + pin.position.y) / 2;
          const topOfPin =
            pin.position.y - (pin.circleRadius ? pin.circleRadius / 2 : 0);

          // Detect top of the pin
          if (collisionPointY <= topOfPin && !pin.isSensor)
            pin.render.fillStyle = "#6CA4Bf";

          setTimeout(() => {
            if (pin && !pin.isSensor) {
              pin.render.fillStyle = "#87CEEB"; // Original color
            }
          }, 100);
        }

        // Detect ball and bottom area
        balls.forEach((ball) => {
          if (
            (pair.bodyA === ball && pair.bodyB.isSensor) ||
            (pair.bodyB === ball && pair.bodyA.isSensor)
          ) {
            // Identify the bottomArea in the collision
            const bottomArea = pair.bodyA.isSensor ? pair.bodyA : pair.bodyB;

            // Scale bottomArea temporarily
            Body.scale(bottomArea, 1.2, 1.2);
            setTimeout(() => {
              Body.scale(bottomArea, 1 / 1.2, 1 / 1.2);
            }, 625);

            setFinishedBalls((prev) => prev + 1);
          }
        });
      });
    });

    // Track the current row and last row's Y position
    let currentRows = Array(predefinedPaths.length).fill(0);
    let lastRowYPositions = Array(predefinedPaths.length).fill(100);

    // Force for predefined Path
    Events.on(engine, "beforeUpdate", () => {
      for (let i = 0; i < predefinedPaths.length; i++) {
        if (
          followingPredefinedPath[i] &&
          currentRows[i] < predefinedPaths[i].length - 1
        ) {
          const newRow = Math.floor((balls[i].position.y - 100) / pinGap);

          if (newRow > currentRows[i]) {
            currentRows[i] = newRow;
            lastRowYPositions[i] = 100 + currentRows[i] * pinGap;
          }

          const distanceFromLastRow =
            balls[i].position.y - lastRowYPositions[i];
          const normalizedDistance = Math.min(
            distanceFromLastRow / (pinGap / 2),
            1
          );
          const baseForceMagnitude = 0.006;
          const forceMagnitude =
            baseForceMagnitude * (1 - normalizedDistance ** 2);

          // Adjust the angle of the force
          const angle = (Math.PI / 2) * normalizedDistance - 0.4; // From 0 (horizontal) to PI/2 (vertical)
          const direction =
            predefinedPaths[i][currentRows[i] + 1] === 0 ? -1 : 1;
          const forceX = Math.cos(angle - 6) * direction * forceMagnitude * 2.3;
          const forceY = Math.sin(angle + 1) * forceMagnitude * 1.2;

          const force = Vector.create(forceX, forceY);
          Body.applyForce(balls[i], balls[i].position, force);
          forceTrackers[i] = force;
        }
      }
    });

    Events.on(engine, "collisionEnd", (event) => {
      event.pairs.forEach((pair) => {
        for (let i = 0; i < predefinedPaths.length; i++) {
          if (pair.bodyA === balls[i] || pair.bodyB === balls[i]) {
            if (currentSteps[i] < predefinedPaths[i].length - 2) {
              currentSteps[i]++; // Move to the next step in the path after each collision
            }
          }
        }
      });
    });

    // Draw the multipliers text inside the buckets
    Events.on(render, "afterRender", () => {
      const context = render.context;
      context.font = "14px Arial";
      context.textAlign = "center";
      context.textBaseline = "middle";
      context.fillStyle = "black"; // Use a contrasting color for better visibility

      multiplierPositions.forEach((pos) => {
        const bodyPosition = pos.body.position;
        context.fillText(pos.value, bodyPosition.x, bodyPosition.y);
      });
    });

    const runner = Runner.create();
    engine.timing.timeScale = 2;
    render.options.wireframes = false;
    Runner.run(runner, engine);
    Render.run(render);

    return () => {
      // Clear the runner and stop the render on unmount
      Runner.stop(runner);
      Render.stop(render);
    };
  }, [isPlaying]);

  useEffect(() => {
    // console.log(finishedBalls, predefinedPaths.length)
    if (finishedBalls === predefinedPaths.length) {
      setPlaying(false);
      setBallPath([]);
    }
    if (finishedBalls === 0 && predefinedPaths.length === 0) {
      setHistoryRewards((prev) => [...prev, ...playerRewards]);
    }
  }, [finishedBalls, predefinedPaths.length, setPlaying]);

  const handleInputChange = (event) => {
    setInputValue(event.target.value);
  };

  const handleOnClickPlus = () => {
    if (inputValue >= plinkoGameConfig.max_bet_amount / 10 ** decimals) {
      toast(
        "Amount Cannot be more than " +
          plinkoGameConfig.max_bet_amount / 10 ** decimals +
          " $GUI per ball"
      );
      return;
    }
    setInputValue((prevValue) => {
      const currentValue = parseFloat(prevValue);
      const validValue = isNaN(currentValue) ? 0 : currentValue;
      const newValue = validValue + 10000;
      const roundedValue = Math.round(newValue * 10) / 10;
      return roundedValue;
    });
  };

  const handleOnClickMinus = () => {
    if (inputValue <= plinkoGameConfig.min_bet_amount / 10 ** decimals) {
      toast(
        "Amount Cannot be less than " +
          plinkoGameConfig.min_bet_amount / 10 ** decimals +
          " $GUI per ball"
      );
      return;
    }
    setInputValue((prevValue) => {
      const currentValue = parseFloat(prevValue);
      const validValue = isNaN(currentValue) ? 0 : currentValue;
      const newValue = validValue - 10000;
      const roundedValue = Math.round(newValue * 10) / 10;
      return newValue < 0 ? validValue : roundedValue;
    });
  };

  useEffect(() => {
    fetchPlinkoReward(account?.address)
      .then((res) => {
        setGuiRewards(res.rewards_balance.value / 10 ** decimals);
      })
      .catch((error) => {
        console.log("error", error);
      });
  }, [connected, finishedBalls, account]);

  useEffect(() => {
    fetchPlinkoDefyReward(account?.address)
      .then((res) => {
        setDefyRewards(res.rewards_balance);
      })
      .catch((error) => {
        console.log("Error fetching Defy: ", error);
      });
  }, [connected, finishedBalls, account]);

  const handleClaim = async () => {
    const moduleAddress = process.env.REACT_APP_MODULE_ADDR;
    const transaction = {
      data: {
        function: `${moduleAddress}::plinko::claim`,
        typeArguments: [
          "0xe4ccb6d39136469f376242c31b34d10515c8eaaa38092f804db8e08a8f53c5b2::assets_v1::EchoCoin002",
          "0x1::string::String",
          "0x1::string::String",
          "0x1::string::String",
          "0x1::string::String",
        ],
        functionArguments: [1],
      },
    };
    try {
      const response = await signAndSubmitTransaction(transaction);
      await aptos.waitForTransaction({ transactionHash: response.hash });
      toast("Successfully claimed");
      // fetchPlinkoReward().then(()=>{}).catch(()=>{})
      fetchPlinkoReward(account?.address)
        .then((res) => {
          // setTimeout(()=>{setGuiRewards(res.rewards_balance.value)},6000);
          setGuiRewards(res.rewards_balance.value / 10 ** decimals);
        })
        .catch((error) => {
          console.log("error", error);
        });

      fetchPlinkoDefyReward(account?.address)
        .then((res) => {
          setDefyRewards(res.rewards_balance);
        })
        .catch((error) => {
          console.log("Error fetching Defy: ", error);
        });
    } catch (error) {
      console.error(error);
      toast("Something went wrong 💀");
    }
  };

  // console.log(isPlaying)

  return (
    <div>
      <Navbar></Navbar>
      <div className="pt-[110px]">
        <div className="flex item-start w-full lg:pt-5 xl:items-start px-20 pb-10 mt-[-2.99vh] max-[1024px]:flex-col max-[1024px]:px-4">
          {/* left content */}
          <div style={{ flex: 1 }} className="gap-4">
            <p className="font-SatoshiMedium 2xl:text-5xl text-3xl text-left mt-4">
              Try
              <span className="bg-gradient-to-r from-purple to-blue text-transparent bg-clip-text">
                {" "}
                Plinko
              </span>
            </p>
            {/*Note section*/}
            <p className="sm:w-[290px] 2xl:text-base 2xl:font-normal text-sm mt-4 font-medium font-SatoshiMedium text-textGreyDark text-left max-[1024px]:hidden">
              Get ready for thrilling excitement and the chance to win big! The
              Plinko board is set, and your mission is to predict where the ball
              will land. If your luck is high, you'll win big! If not, no
              worries! you'll still receive Defy Coins. Dive into the action,
              test your luck with Plinko. Play now and experience the
              exhilarating thrill of Plinko! Every drop could be your ticket to
              fortune!{" "}
            </p>
            {/* Reward Claim */}
            <div className="mt-12 flex flex-row lg:w-[320px] 2xl:w-[310px] justify-between p-1 items-center">
              <p className="text-base font-bold font-SatoshiMedium text-black text-left">
                Claimable Rewards
              </p>
              <button
                className={`text-right text-sm text-white font-Satoshi rounded-lg px-6  py-2.5 ${
                  guiRewards == 0 || guiRewards == undefined || !connected
                    ? "bg-neutral-300"
                    : "bg-[#252525]"
                } flex items-center justify-center`}
                onClick={() => {
                  if (
                    guiRewards === 0 ||
                    guiRewards === undefined ||
                    !connected
                  )
                    return;
                  handleClaim();
                }}
              >
                Claim
              </button>
            </div>
            <div className="mt-2 w-full flex flex-col gap-3 2xl:w-[310px] bg-greyVeryLight rounded-xl p-2.5 overflow-y-scroll hover:cursor-pointer">
              <div className="w-full min-h-[60px] bg-[#FFFFFF] rounded-xl flex flex-row items-center transform duration-300 ease-in-out hover:scale-95">
                <div className="h-12 w-12 rounded bg-[#f4f4f6] flex justify-center items-center ml-1.5">
                  <img className="w-10 h-10" src={assets.gui} />
                </div>
                <div className="ml-2 flex flex-col gap-1">
                  <p className="font-satoshi font-medium text-xs text-textGreyDark">
                    You Won!
                  </p>
                  <p
                    className={clsx(
                      "font-satoshi font-medium text-xs",
                      guiRewards > 0 ? "text-green" : ""
                    )}
                  >
                    {guiRewards} GUI
                  </p>
                </div>
              </div>
              <div className="w-full min-h-[60px] bg-[#FFFFFF] rounded-xl flex flex-row items-center transform duration-300 ease-in-out hover:scale-95">
                <div className="h-12 w-12 rounded bg-[#f4f4f6] flex justify-center items-center ml-1.5">
                  <img className="w-10 h-10" src={assets.defy1} />
                </div>
                <div className="ml-2 flex flex-col gap-1">
                  <p className="font-satoshi font-medium text-xs text-textGreyDark">
                    You Won!
                  </p>
                  <p
                    className={clsx(
                      "font-satoshi font-medium text-xs",
                      defyRewards >= "0" ? "text-green" : ""
                    )}
                  >
                    {defyRewards} DEFY Coins
                  </p>
                </div>
              </div>
            </div>
          </div>
          {/* Middle Part */}
          <div
            className="px-10 max-[1024px]:px-2  2xl:mt-5"
            style={{ flex: 2 }}
          >
            {/* PLINKO GAME */}
            <div className="flex justify-center w-full md:h-[600px] h-[350px] ">
              <div ref={containerRef} className="relative max-w-full">
                {/* Existing content */}
                <div
                  className="w-full flex justify-center items-center overflow-hidden "
                  style={{ height: 600 * scale }}
                >
                  <div
                    style={{
                      transform: `scale(${scale}) translateX(22px)`, // Adjust scale based on screen width
                      transformOrigin: "center center",
                      display: "flex",
                      justifyContent: "center",
                      alignItems: "center",
                      width: "100%",
                    }}
                  >
                    {/* GAME CANVAS */}
                    <div id="matter-canvas-container">
                      {/* <MatterSim /> */}
                    </div>
                  </div>
                </div>
              </div>
            </div>
            {/* <div className="flex justify-center my-5 relative"> */}
            {/* BOTTOM SECTION */}
            {/* <div className="flex items-center gap-4 justify-center">
              {multipliers.map((number, index) => (
                <div key={index}>x{number}</div>
              ))}
            </div> */}
            <div className="flex w-full items-center justify-center mt-6">
              Cost per Ball: 100K
              <img className="h-6 w-6 rounded-full ml-1" src={assets.guiIcon} />
            </div>
            <div className=" py-2 flex items-center flex-wrap px-2 justify-evenly rounded-xl mt-2 border-[1px] border-solid shadow-[0_1px_20px_-10px_rgba(0,0,0,0.3)]">
              <div className="min-h-[60px] min-w-[81px] flex flex-col items-start">
                <p className="font-SatoshiMedium text-sm">Balls</p>
                <select
                  className="h-[45px] w-[81px] rounded-md pl-4 text-left bg-greyVeryLight text-textGreyDark focus:outline-none"
                  style={{ fontFamily: "SatoshiMedium" }}
                  onChange={handleBallsChange}
                >
                  {ballCount.map((item) => (
                    <option key={item} value={item}>
                      {item}
                    </option>
                  ))}
                </select>
              </div>

              <div className=" w-fit flex flex-col items-start ml-2 min-w-[100px] min-h-[50px] ">
                <p className="pl-2 text-sm font-SatoshiMedium">Total Bid</p>
                <div className="p-2 text-lg rounded-md items-center justify-center flex gap-2 focus:outline-none w-full text-textGreyDark">
                  {inputValue * selectedBalls}
                  <img className="h-8 w-8 mr-2 rounded-full" src={assets.gui} />
                  {/* <input
                    type="number" 
                    style={{
                      fontFamily: "SatoshiMedium", 
                    }}
                    required
                    autoFocus
                    value={inputValue}
                    onChange={handleInputChange}
                    className="px-2 py-1 rounded-md items-center flex justify-between bg-greyVeryLight focus:outline-none w-full text-textGreyDark"
                    placeholder="Enter Amount"
                    disabled={isPlaying}
                  >
                    
                  </input>
                  <img 
                    className="h-8 w-8 mr-2 rounded-full" 
                    src={assets.gui} 
                  />
                  <div className="flex items-center mr-1">
                          <button
                              className={`px-2 hover:bg-neutral-200 rounded-full`}
                              onClick={handleOnClickPlus}
                              disabled={isPlaying}
                          >
                          +
                          </button>
                          <div className="w-[0.5px] h-6 bg-gray-500 mx-1"></div>
                          <button
                              className={`px-2 hover:bg-neutral-200 rounded-full`}
                              onClick={handleOnClickMinus}
                              disabled={isPlaying}
                          >
                          -
                          </button>
                      </div>           */}
                </div>
              </div>
              <div className=" w-fit flex flex-col items-start min-w-[300px] min-h-[50px] mt-2 md:mt-0 ">
                {/* <p className="font-SatoshiMedium text-sm">Total Bet: {inputValue*selectedBalls} GUI </p> */}
                <div
                  className={`flex-1 h-[45px] w-full button mt-4 flex py-3 ${
                    isPlaying ? "bg-gray-400" : "bg-bgBlue"
                  }  rounded-lg cursor-pointer spin-now-button`}
                  onClick={onPlay}
                  id="spin-button"
                >
                  <p className="text-center mx-auto text-sm text-white lg:text-base font-SatoshiBold m-auto">
                    Play Now
                  </p>
                </div>
              </div>
            </div>
          </div>
          {/* Right Part */}
          <div className="w-full mt-4 md:mt-0" style={{ flex: 1 }}>
            <div>
              <p className="text-base 2xl:text-lg font-bold font-SatoshiMedium text-black max-[1024px]:hidden text-left py-2">
                Session Activity
              </p>
              <div className="flex flex-col gap-3 border border-greyVeryLight rounded-xl">
                <div className="bg-white text-textGreyDark">
                  <div className="text-center h-12 rounded-tr-md text-black bg-greyVeryLight rounded-tl-md justify-center content-center">
                    Current Road
                  </div>
                  <div className="grid grid-cols-3 content-center p-2">
                    <div className="">Bet</div>
                    <div className="text-center">Multiplier</div>
                    <div className="flex justify-end ">Earnings</div>
                  </div>

                  <div className="border border-bg-slate-200 mx-2"></div>
                  <div className="h-[400px] overflow-y-scroll">
                    {/* Rewards History */}
                    {historyRewards.length !== 0 ? (
                      historyRewards
                        .slice()
                        .reverse()
                        .map((item, inx) => (
                          <div
                            key={inx}
                            className="grid grid-cols-3 content-center p-2"
                          >
                            <div className="">
                              {item.bet_amount / 10 ** decimals} GUI
                            </div>
                            <div
                              className={`text-center bg-opacity-20 border-1 rounded-lg ${getMultiplierColor(
                                item.multiplier
                              )}`}
                            >
                              {item.multiplier / 100}x
                            </div>
                            <div className="flex justify-end ">
                              {item.amount_won / 10 ** decimals} GUI
                            </div>
                          </div>
                        ))
                    ) : (
                      <p className="font-SatoshiMedium text-sm text-textGreyDark mx-auto text-center mt-[70px] max-[1200px]:mt-[40px]">
                        <span className="text-[60px]">😢</span>
                        <br />
                        <br />
                        No more Rewards, play to get more
                      </p>
                    )}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
