import Modal from "@material-ui/core/Modal";
import twitterConnectPoster from "../../../assets/twitterConnectPoster.png";
import profilePic from "../../../assets/profilePic.png";
import coinAnim from "../../../assets/coinAnim.gif";
import groupProfile from "../../../assets/groupProfile.svg";
import { useWallet } from "@aptos-labs/wallet-adapter-react";
import axios from "axios";
import CONST from "../../../constants/constants";
import mcqIcon from "../../../assets/mcqIcon.png";
import { useContext, useEffect, useState } from "react";
import {
  discordOauth,
  twitterOauth,
  googleOauth,
} from "../../../services/oauth/oauth";
import book from "../../../assets/book.png";
import leftArrowCircle from "../../../assets/leftArrowCircle.png";
import rightArrowCircle from "../../../assets/rightArrowCircle.png";
import redCross from "../../../assets/redCross.svg";
import redLink from "../../../assets/redLink.svg";
import greenLink from "../../../assets/greenLink.svg";
import greenTick from "../../../assets/greenTick.svg";
import purpleLightning from "../../../assets/purpleLightning.svg";
import { UserContext } from "../../../contexts/UserContent";
import { fetchTickets } from "../../../utils/indexer";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import "../../../utils/toasts.css";
import clsx from "clsx";
import { useNavigate } from "react-router-dom";



const TaskModal = ({ open, handleClose, state, openMCQ, openEmail }) => {
  const { account } = useWallet();

  const [showBtn, setShowBtn] = useState(true);
  const [earnedTillNowGUI, setEarnedTillNowGUI] = useState(0);
  const [showInput, setShowInput] = useState(false);
  const [tweetUrl, setTweetUrl] = useState("");

  const { user, setUser, tickets, setTickets } = useContext(UserContext);

  const [onchainBalance, setOnchainBalance] = useState(0)
  const [participants, setParticipants] = useState(0)
  const navigate = useNavigate();


  // console.log(state.task)
  useEffect(() => {
    if (state.task.taskType == "donk" || state.task.taskType == "onchain" || state.task.taskType == "gui" || state.task.taskType == "balance_check_coin" || state.task.taskType == "balance_check_nft" || state.task.taskType == "wapal_nft_activity_mint" || state.task.taskType == "wapal_nft_activity_buy" || state.task.taskType == "tradeport_nft_activity_buy" || state.task.taskType == "tradeport_nft_activity_mint" || state.task.taskType == "zaaptos" || state.task.taskType == "cellana" || state.task.taskType == "aries_check") {
      axios.get(`${CONST.baseUrl}/tasks/${state.task.taskId}`).then((response) => {
        const total = response.data.data?.events.reduce(function (acc, obj) { return acc + obj?.balanceChange?.points; }, 0);
        setOnchainBalance(total)
        setParticipants(response.data.data.participants)
      }).catch(() => {

      })
    }
  }, [open])

  // console.log(participants)

  

  function getUser() {
    //console.log("Getting User");
    const jwt = localStorage.getItem("AVEX_DEFY_JWT_STORE");
    const config = {
      headers: {
        Authorization: jwt,
      },
    };
    if (jwt) {
      axios.get(`${CONST.baseUrl}/users`, config).then((response) => {
        setUser(response.data.data);
      });
    }
  }

  const verifyTask = async (taskId) => {
    const jwt = localStorage.getItem("AVEX_DEFY_JWT_STORE");
    const config = {
      headers: {
        Authorization: jwt,
      },
    };

    axios
      .post(`${CONST.baseUrl}/tasks/verify?taskId=${taskId}`, config)
      .then((response) => {
        if (response.data.data) {
          getUser();
          toast("Task succesfully verified!");
          fetchTickets(account?.address).then((result) => {
            setTickets(result);
            handleClose()
          });
        } else {
          toast("Task not verified!");
        }
      })
      .catch(() => {
        toast("Task not verified!");
      });
  };

  const verifyRetweetTask = async (taskId) => {
    const jwt = localStorage.getItem("AVEX_DEFY_JWT_STORE");
    //console.log(jwt, "jwwtt");
    const config = {
      headers: {
        Authorization: jwt,
      },
    };

    axios
      .post(`${CONST.baseUrl}/tasks/verify?taskId=${taskId}`, {
        tweetUrl: [tweetUrl],
      }, config)
      .then((response) => {
        if (response.data.data) {
          getUser();

          toast("Task succesfully verified!");

          fetchTickets(account?.address).then((result) => {
            setTickets(result);
            handleClose()
          });
        }
      })
      .catch(() => {
        toast("Task not verified!");
      });
  };

  function handleClick() {

    if (state.task.completed)
      return


    if ((state.task.platform == "twitter" && state.task.taskType !== "connection")) {
      toast("Verification will take upto 24 hours once you complete the task")
      return
    }


    if (state.task.taskType !== "connection" && !user.connections.some((obj) => obj.provider == state.task.platform) && state.task.taskType !== "mcq") {
      toast(`Complete the verification of ${state.task.platform} before proceeding further`)

    }
    else if (state.task.taskType == "mcq") {
      openMCQ();
      handleClose();
    } else if (
      state.task.taskType == "connection" &&
      state.task.platform == "email"
    ) {
      openEmail();
      handleClose();
    } else if (state.task.taskType == "twitterQuoteRetweet") {
      verifyRetweetTask(state.task["taskId"]);
    } else if (
      state.task.taskType == "connection" &&
      state.task.platform == "discord"
    ) {
      discordOauth(state.task["taskId"], account.address);
    } else if (
      state.task.taskType == "connection" &&
      state.task.platform == "twitter"
    ) {
      twitterOauth(state.task["taskId"], account.address);
    } else if (
      state.task.taskType == "connection" &&
      state.task.platform == "google"
    ) {
      googleOauth(state.task["taskId"], account.address);
    } else {
      verifyTask(state.task["taskId"]);
    }
  }

  // console.log(state);

  useEffect(() => {
    if (!open) return;

    setTweetUrl("");

    setShowBtn(true);

    if (state.task.taskType==="oat" || state.task.taskType==="donk" || state.task.taskType === "onchain" || state.task.taskType === "balance_check_coin" || state.task.taskType == "balance_check_nft" || state.task.taskType == "wapal_nft_activity_mint" || state.task.taskType == "wapal_nft_activity_buy" || state.task.taskType == "tradeport_nft_activity_buy" || state.task.taskType == "tradeport_nft_activity_mint" || state.task.taskType == "gui" || state.task.taskType == "zaaptos" || state.task.taskType == "cellana" || state.task.taskType == "aries_check")
      setShowBtn(false)

    if (state.task.taskType !== "connection" && state.task.platform == "connection")
      setShowBtn(false)

    if (state.task.taskType == "twitterQuoteRetweet") setShowInput(true);
    else setShowInput(false);


    // if(state.task.taskType === "twitterFollow" || (state.task.platform == "twitter" && state.task.taskType !== "connection"))
    //     setShowBtn(false)

    if (state.task.taskType == "connection" && state.task.platform == "telegram") {
      setShowBtn(false);

      const loginTele = () => {
        const script = document.createElement("script");
        script.src = "https://telegram.org/js/telegram-widget.js?22";
        script.async = true;
        script.setAttribute("data-telegram-login", "AvexIDBot");
        script.className = "mx-auto"
        script.setAttribute("data-size", "large");
        // TODO: Remove hardcoded wallet address from here
        script.setAttribute(
          "data-auth-url",
          `https://api.avex.id/waitlist/auth/telegram/callback?walletAddress=${account.address}`
        );
        script.setAttribute("data-request-access", "write");
        document.getElementById("buttonGroup").appendChild(script);
      };

      setTimeout(() => {
        if (!state.task.completed)
          loginTele();
      }, 500);
    }
  }, [open]);

  const hasGamesTag = state.task.tags && state.task.tags.includes('games');
  const navigatePath = () => {
    switch (state.task.platform) {
      case "wheel_play":
        return "/Fortune-Wheel";
      case "dice_roll_play":
        return "/dice-roll";
      case "coin_flip_play":
        return "/coin-flip";
      default:
        return "/";
    }
  };

  return (
    <Modal
      onClose={handleClose}
      open={open}
      style={{
        backgroundColor: "transparent",
        margin: "auto",
        color: "white",
      }}
      className="h-[565px] w-[800px] max-[680px]:w-[98vw] max-[767px]:h-[80vh] max-[380px]:!h-[75vh]"
    >
      <div className="w-full h-full bg-white p-5 rounded-lg !outline-none max-[380px]:!h-[75vh] flex-col justify-between flex">
        <div className="h-[70%] max-[767px]:!h-[70%] max-[380px]:!h-[75%]">
          <div className="flex w-full max-[767px]:flex-row space-y-2 md:px-4 justify-between  md:space-y-0 md:items-center md:justify-between">
            <div className="flex items-center justify-start w-full max-[1024px]:max-w-full text-left">
              <img
                className="h-8 w-8 object-contain"
                src={state.task.iconUrl}
              />
              <p className="md:text-xl font-SatoshiBold ml-2 text-black text-left">
                {state.task.title}
              </p>
              {state.task.boost.isBoosted && (
                <div className="p-1 rainbow rounded-[8rem] ml-6 max-[800px]:hidden">
                  <div className="w-[150px] h-[32px] rounded-[8rem] bg-black flex items-center justify-center">
                    <img
                      className="h-4 w-auto object-contain mr-2"
                      src={purpleLightning}
                    />
                    <p className="font-SatoshiMedium text-sm rainbow-text">
                      {state.task.boost.multiplier}x Rewards
                    </p>
                  </div>
                </div>
              )}
            </div>

            <div className="flex items-center  !mt-2 justify-between">
              {state.task.boost.isBoosted && (
                <div className="p-0.5 rainbow rounded-[8rem] min-[800px]:hidden">
                  <div className=" h-[30px] rounded-[8rem] bg-black flex items-center justify-center min-w-[100px]">
                    <img
                      className="h-3 w-auto object-contain mr-2"
                      src={purpleLightning}
                    />
                    <p className="font-SatoshiMedium text-xs rainbow-text">
                      {state.task.boost.multiplier}x Rewards
                    </p>
                  </div>
                </div>
              )}
            </div>
          </div>

          <div
            className={clsx(
              "flex md:p-1 md:flex-row flex-col max-h-[130%] overflow-y-scroll",
              showInput ? "max-[1024px]:h-[100%]" : "max-[1024px]:h-[110%]"
            )}
          >
            <div style={{ flex: 2 }} className="p-4">
              <img
                className="w-full object-contain"
                src={state.task.bannerUrl}
              />
              <p className="font-SatoshiMedium md:text-base text-sm text-textGreyDark text-left mt-5">
                Description:
                <br />
                {state.task.description}
                {state.task.platform === "econia_fill" &&
                  " (3 Coins/ $1 volume)"}
                <a
                  href="https://medium.com/@maharsi"
                  target="_blank"
                  className="text-textBlue"
                >
                  {state.task.taskType === "mcq" && " blog"}
                </a>
              </p>

              {state.task.taskType == "tradeport_nft_activity_mint" && (
                <>
                  <p className="font-SatoshiMedium md:text-base text-sm text-textGreyDark text-left">
                    - Aptos Journey by{" "}
                    <a
                      href="https://www.tradeport.xyz/aptos/collection/0xb57ab4ca6c8d158c4d6073f389dddb757bf29d15cb6a3f0cd4acc5f1bbca223c?tab=mint&mintTokenId=6382e88e-2755-44c9-8d53-2bb2f741cea4&bottomTab=trades"
                      target="_blank"
                      className="text-textBlue"
                    >
                      Alcista
                    </a>
                  </p>
                  <p className="font-SatoshiMedium md:text-base text-sm text-textGreyDark text-left">
                    - Nruh Bers by{" "}
                    <a
                      href="https://www.tradeport.xyz/aptos/collection/nruh-bers?tab=mint&mintTokenId=1032df2f-cbcd-4b13-aa07-4339f428d5d2&bottomTab=trades"
                      target="_blank"
                      className="text-textBlue"
                    >
                      Shamrock
                    </a>
                  </p>
                  <p className="font-SatoshiMedium md:text-base text-sm text-textGreyDark text-left">
                    - To the Meown by{" "}
                    <a
                      href="https://www.tradeport.xyz/aptos/collection/0x69b9188e87aa2744318edb47197f01723ef108d570feb55988c5dcb3c741ad13?tab=mint&mintTokenId=3a317186-1696-493b-a514-ba6876f2ba80&bottomTab=trades"
                      target="_blank"
                      className="text-textBlue"
                    >
                      Serene
                    </a>
                  </p>
                  <p className="font-SatoshiMedium md:text-base text-sm text-textGreyDark text-left">
                    - After Hours by{" "}
                    <a
                      href="https://www.tradeport.xyz/aptos/collection/0x10f6dccef5270bbee962f114bd076d87e137a16e1f8bebf91b02990831265e53?tab=mint&mintTokenId=9d73eed4-62fd-47bf-878f-993d84a3cffd&bottomTab=trades"
                      target="_blank"
                      className="text-textBlue"
                    >
                      Julie
                    </a>
                  </p>
                  <p className="font-SatoshiMedium md:text-base text-sm text-textGreyDark text-left">
                    - Hue by{" "}
                    <a
                      href="https://www.tradeport.xyz/aptos/collection/0x70f3b1f08d957b0ae44e2235ece868d48ad8e01a2717fe2399ca0fa674f7d094?tab=mint&mintTokenId=14ab7f81-b53f-4b03-b4df-7795728b6c65&bottomTab=trades"
                      target="_blank"
                      className="text-textBlue"
                    >
                      Broke Peker
                    </a>
                  </p>
                  <p className="font-SatoshiMedium md:text-base text-sm text-textGreyDark text-left">
                    - Both Sides by{" "}
                    <a
                      href="https://www.tradeport.xyz/aptos/collection/0x10f6dccef5270bbee962f114bd076d87e137a16e1f8bebf91b02990831265e53?tab=mint&bottomTab=trades&mintTokenId=664ea7ed-8f77-445f-82dc-93cd2f472ff6"
                      target="_blank"
                      className="text-textBlue"
                    >
                      Julie
                    </a>
                  </p>
                  <p className="font-SatoshiMedium md:text-base text-sm text-textGreyDark text-left">
                    - Skull Mask Kid by{" "}
                    <a
                      href="https://www.tradeport.xyz/aptos/collection/0x9ca5fabea3899901693390f55c4d445daeeb33dcfd60d16f17500e98a488d14a?tab=mint&bottomTab=trades&mintTokenId=6ac13676-a11b-47e2-b76e-42d481df3d0b"
                      target="_blank"
                      className="text-textBlue"
                    >
                      Mimikyu
                    </a>
                  </p>
                </>
              )}
              {state.task.taskType == "gui" && (
                <>
                  <p className="font-SatoshiMedium md:text-base text-sm text-textGreyDark text-left">
                    - Swaps: 1 Defy coin for swapping to 10,000 GUI.
                  </p>
                  <p className="font-SatoshiMedium md:text-base text-sm text-textGreyDark text-left">
                    - Pools: 1 Defy coin for every 5000 GUI added to pools.
                  </p>
                  <p className="font-SatoshiMedium md:text-base text-sm text-textGreyDark text-left">
                    - Farms: 1 Defy coin for every 5000 GUI added to farms.
                  </p>
                </>
              )}
              {state.task.platform == "vibrantx_leveraged_staking" && (
                <>
                  <p className="mt-4 font-SatoshiMedium md:text-base text-sm text-textGreyDark text-left">
                    You receive more points as you increase the 'Multiplier'. ,
                    <br />
                    Ex: If you enter 1 APT at 5x multiplier, you earn
                    : 25 Defy coins.
                  </p>
                </>
              )}

              {state.task.taskType == "tradeport_nft_activity_buy" && (
                <>
                  <p className="font-SatoshiMedium md:text-base text-sm text-textGreyDark text-left">
                    -{" "}
                    <a
                      href="https://www.tradeport.xyz/aptos/collection/aptomingos?bottomTab=trades&tab=items"
                      target="_blank"
                      className="text-textBlue"
                    >
                      Aptomingos
                    </a>{" "}
                    1000 Coins
                  </p>
                  <p className="font-SatoshiMedium md:text-base text-sm text-textGreyDark text-left">
                    -{" "}
                    <a
                      href="https://www.tradeport.xyz/aptos/collection/aptos-monkeys?bottomTab=trades&tab=items"
                      target="_blank"
                      className="text-textBlue"
                    >
                      Aptos Monkeys
                    </a>{" "}
                    750 Coins
                  </p>
                  <p className="font-SatoshiMedium md:text-base text-sm text-textGreyDark text-left">
                    -{" "}
                    <a
                      href="https://www.tradeport.xyz/aptos/collection/aptorobos-b8a68933?bottomTab=trades&tab=items"
                      target="_blank"
                      className="text-textBlue"
                    >
                      AptosRobos
                    </a>{" "}
                    400 Coins
                  </p>

                  <p className="font-SatoshiMedium md:text-base text-sm text-textGreyDark text-left">
                    -{" "}
                    <a
                      href="https://www.tradeport.xyz/aptos/collection/creatus?bottomTab=trades&tab=items"
                      target="_blank"
                      className="text-textBlue"
                    >
                      Creatus
                    </a>{" "}
                    400 Coins
                  </p>
                  <p className="font-SatoshiMedium md:text-base text-sm text-textGreyDark text-left">
                    -{" "}
                    <a
                      href="https://www.tradeport.xyz/aptos/collection/proud-lions-club?bottomTab=trades&tab=items"
                      target="_blank"
                      className="text-textBlue"
                    >
                      Proud Lions Club
                    </a>{" "}
                    700 Coins
                  </p>
                  <p className="font-SatoshiMedium md:text-base text-sm text-textGreyDark text-left">
                    -{" "}
                    <a
                      href="https://www.tradeport.xyz/aptos/collection/ice-blue?bottomTab=trades&tab=items"
                      target="_blank"
                      className="text-textBlue"
                    >
                      Ice Blue
                    </a>{" "}
                    500 Coins
                  </p>
                  <p className="font-SatoshiMedium md:text-base text-sm text-textGreyDark text-left">
                    -{" "}
                    <a
                      href="https://www.tradeport.xyz/aptos/collection/mavrik?bottomTab=trades&tab=items"
                      target="_blank"
                      className="text-textBlue"
                    >
                      Mavrik
                    </a>{" "}
                    300 Coins
                  </p>
                  <p className="font-SatoshiMedium md:text-base text-sm text-textGreyDark text-left">
                    -{" "}
                    <a
                      href="https://www.tradeport.xyz/aptos/collection/zaaptos?bottomTab=trades&tab=items"
                      target="_blank"
                      className="text-textBlue"
                    >
                      Zaaptos
                    </a>{" "}
                    300 Coins
                  </p>
                  <p className="font-SatoshiMedium md:text-base text-sm text-textGreyDark text-left">
                    -{" "}
                    <a
                      href="https://www.tradeport.xyz/aptos/collection/gui-lions?bottomTab=trades&tab=items"
                      target="_blank"
                      className="text-textBlue"
                    >
                      Gui Lion
                    </a>{" "}
                    500 Coins
                  </p>
                  <p className="font-SatoshiMedium md:text-base text-sm text-textGreyDark text-left">
                    -{" "}
                    <a
                      href="https://www.tradeport.xyz/aptos/collection/netzcuty-d458482d?bottomTab=trades&tab=items"
                      target="_blank"
                      className="text-textBlue"
                    >
                      NetzCuty
                    </a>{" "}
                    250 Coins
                  </p>
                  <p className="font-SatoshiMedium md:text-base text-sm text-textGreyDark text-left">
                    -{" "}
                    <a
                      href="https://www.tradeport.xyz/aptos/collection/0x1eb8b1fe9cdd6de70ded0a59b27780cde4ee9de3a80eb870f9f531f295b4d16?bottomTab=trades&tab=items"
                      target="_blank"
                      className="text-textBlue"
                    >
                      LOOM
                    </a>{" "}
                    200 Coins
                  </p>
                  <p className="font-SatoshiMedium md:text-base text-sm text-textGreyDark text-left">
                    -{" "}
                    <a
                      href="https://www.tradeport.xyz/aptos/collection/watcherz?bottomTab=trades&tab=items"
                      target="_blank"
                      className="text-textBlue"
                    >
                      Watcherz
                    </a>{" "}
                    150 Coins
                  </p>
                  <p className="font-SatoshiMedium md:text-base text-sm text-textGreyDark text-left">
                    -{" "}
                    <a
                      href="https://www.tradeport.xyz/aptos/collection/netzjoy-494084ba?bottomTab=trades&tab=items"
                      target="_blank"
                      className="text-textBlue"
                    >
                      NetzJoy
                    </a>{" "}
                    150 Coins
                  </p>
                  <p className="font-SatoshiMedium md:text-base text-sm text-textGreyDark text-left">
                    -{" "}
                    <a
                      href="https://www.tradeport.xyz/aptos/collection/swimming-swans?bottomTab=trades&tab=items"
                      target="_blank"
                      className="text-textBlue"
                    >
                      Swimming Swans{" "}
                    </a>{" "}
                    100 Coins
                  </p>
                  <p className="font-SatoshiMedium md:text-base text-sm text-textGreyDark text-left">
                    -{" "}
                    <a
                      href="https://www.tradeport.xyz/aptos/collection/hootz?bottomTab=trades&tab=items"
                      target="_blank"
                      className="text-textBlue"
                    >
                      HOOTZ
                    </a>{" "}
                    100 Coins
                  </p>
                  <p className="font-SatoshiMedium md:text-base text-sm text-textGreyDark text-left">
                    -{" "}
                    <a
                      href="https://www.tradeport.xyz/aptos/collection/faptox?bottomTab=trades&tab=items"
                      target="_blank"
                      className="text-textBlue"
                    >
                      FAPTOX
                    </a>{" "}
                    100 Coins
                  </p>
                  <p className="font-SatoshiMedium md:text-base text-sm text-textGreyDark text-left">
                    -{" "}
                    <a
                      href="https://www.tradeport.xyz/aptos/collection/0xbb736f0b7f724787f19bb7c108c3a216e45040eb6e919be872f0dc300d7342ae?bottomTab=trades&tab=items"
                      target="_blank"
                      className="text-textBlue"
                    >
                      Aptos Bunnies
                    </a>{" "}
                    100 Coins
                  </p>
                  <p className="font-SatoshiMedium md:text-base text-sm text-textGreyDark text-left">
                    -{" "}
                    <a
                      href="https://www.tradeport.xyz/aptos/collection/nruh-bers?bottomTab=trades&tab=items"
                      target="_blank"
                      className="text-textBlue"
                    >
                      Nruh BERS
                    </a>{" "}
                    100 Coins
                  </p>
                </>
              )}
              {state.task.platform == "econia_fill" && (
                <>
                  <p className="font-SatoshiMedium md:text-base text-sm text-textGreyDark text-left">
                    -{" "}
                    <a
                      href="https://app.ariesmarkets.xyz/trading?market=7"
                      target="_blank"
                      className="text-textBlue"
                    >
                      Aries Market
                    </a>{" "}
                  </p>
                  <p className="font-SatoshiMedium md:text-base text-sm text-textGreyDark text-left">
                    -{" "}
                    <a
                      href="https://trade.baptswap.com/market/APT-USDC"
                      target="_blank"
                      className="text-textBlue"
                    >
                      Bapt Swap
                    </a>{" "}
                  </p>
                  <p className="font-SatoshiMedium md:text-base text-sm text-textGreyDark text-left">
                    -{" "}
                    <a
                      href="https://trade.echelon.market/market/7"
                      target="_blank"
                      className="text-textBlue"
                    >
                      Echelon
                    </a>{" "}
                  </p>


                  <p className="font-SatoshiMedium md:text-base text-sm text-textGreyDark text-left">
                    -{" "}
                    <a
                      href="https://gator.trade/trade"
                      target="_blank"
                      className="text-textBlue"
                    >
                      Gator by Liquidswap
                    </a>{" "}
                  </p>


                  <p className="font-SatoshiMedium md:text-base text-sm text-textGreyDark text-left">
                    -{" "}
                    <a

                      href="https://tradebook.kanalabs.io/APT-USDC"
                      target="_blank"
                      className="text-textBlue"
                    >
                      Kana Labs
                    </a>{" "}
                  </p>
                  <p className="font-SatoshiMedium md:text-base text-sm text-textGreyDark text-left">
                    -{" "}
                    <a
                      href="https://hippo.space/swap/from/zUSDC/to/APT"
                      target="_blank"
                      className="text-textBlue"
                    >
                      Hippo
                    </a>{" "}
                  </p>
                  <p className="font-SatoshiMedium md:text-base text-sm text-textGreyDark text-left">
                    -{" "}
                    <a
                      href="https://app.panora.exchange/trae"
                      target="_blank"
                      className="text-textBlue"
                    >
                      Panora
                    </a>{" "}
                  </p>
                </>
              )}
              {state.task.taskType == "wapal_nft_activity_buy" && (
                <>
                  <p className="font-SatoshiMedium md:text-base text-sm text-textGreyDark text-left">
                    -{" "}
                    <a
                      href="https://wapal.io/collection/Aptomingos"
                      target="_blank"
                      className="text-textBlue"
                    >
                      Aptomingos
                    </a>{" "}
                    1000 Coins
                  </p>
                  <p className="font-SatoshiMedium md:text-base text-sm text-textGreyDark text-left">
                    -{" "}
                    <a
                      href="https://wapal.io/collection/Aptos-Monkeys"
                      target="_blank"
                      className="text-textBlue"
                    >
                      Aptos Monkeys
                    </a>{" "}
                    750 Coins
                  </p>
                  <p className="font-SatoshiMedium md:text-base text-sm text-textGreyDark text-left">
                    -{" "}
                    <a
                      href="https://wapal.io/collection/AptoRobos"
                      target="_blank"
                      className="text-textBlue"
                    >
                      AptosRobos
                    </a>{" "}
                    400 Coins
                  </p>

                  <p className="font-SatoshiMedium md:text-base text-sm text-textGreyDark text-left">
                    -{" "}
                    <a
                      href="https://wapal.io/collection/creatus"
                      target="_blank"
                      className="text-textBlue"
                    >
                      Creatus
                    </a>{" "}
                    400 Coins
                  </p>
                  <p className="font-SatoshiMedium md:text-base text-sm text-textGreyDark text-left">
                    -{" "}
                    <a
                      href="https://wapal.io/collection/proud-lions-club"
                      target="_blank"
                      className="text-textBlue"
                    >
                      Proud Lions Club
                    </a>{" "}
                    700 Coins
                  </p>
                  <p className="font-SatoshiMedium md:text-base text-sm text-textGreyDark text-left">
                    -{" "}
                    <a
                      href="https://wapal.io/collection/ice-blue"
                      target="_blank"
                      className="text-textBlue"
                    >
                      Ice Blue
                    </a>{" "}
                    300 Coins
                  </p>
                  <p className="font-SatoshiMedium md:text-base text-sm text-textGreyDark text-left">
                    -{" "}
                    <a
                      href="https://wapal.io/collection/MAVRIK"
                      target="_blank"
                      className="text-textBlue"
                    >
                      Mavrik
                    </a>{" "}
                    300 Coins
                  </p>
                  <p className="font-SatoshiMedium md:text-base text-sm text-textGreyDark text-left">
                    -{" "}
                    <a
                      href="https://wapal.io/collection/zaaptos"
                      target="_blank"
                      className="text-textBlue"
                    >
                      Zaaptos
                    </a>{" "}
                    300 Coins
                  </p>
                  <p className="font-SatoshiMedium md:text-base text-sm text-textGreyDark text-left">
                    -{" "}
                    <a
                      href="https://wapal.io/collection/gui-lions"
                      target="_blank"
                      className="text-textBlue"
                    >
                      Gui Lion
                    </a>{" "}
                    500 Coins
                  </p>
                  <p className="font-SatoshiMedium md:text-base text-sm text-textGreyDark text-left">
                    -{" "}
                    <a
                      href="https://wapal.io/collection/NetzCuty"
                      target="_blank"
                      className="text-textBlue"
                    >
                      NetzCuty
                    </a>{" "}
                    250 Coins
                  </p>
                  <p className="font-SatoshiMedium md:text-base text-sm text-textGreyDark text-left">
                    -{" "}
                    <a
                      href="https://wapal.io/collection/loom"
                      target="_blank"
                      className="text-textBlue"
                    >
                      LOOM
                    </a>{" "}
                    200 Coins
                  </p>
                  <p className="font-SatoshiMedium md:text-base text-sm text-textGreyDark text-left">
                    -{" "}
                    <a
                      href="https://wapal.io/collection/watcherz"
                      target="_blank"
                      className="text-textBlue"
                    >
                      Watcherz
                    </a>{" "}
                    150 Coins
                  </p>
                  <p className="font-SatoshiMedium md:text-base text-sm text-textGreyDark text-left">
                    -{" "}
                    <a
                      href="https://wapal.io/collection/netzjoy4"
                      target="_blank"
                      className="text-textBlue"
                    >
                      NetzJoy
                    </a>{" "}
                    150 Coins
                  </p>
                  <p className="font-SatoshiMedium md:text-base text-sm text-textGreyDark text-left">
                    -{" "}
                    <a
                      href="https://wapal.io/collection/swimming-swans"
                      target="_blank"
                      className="text-textBlue"
                    >
                      Swimming Swans{" "}
                    </a>{" "}
                    100 Coins
                  </p>
                  <p className="font-SatoshiMedium md:text-base text-sm text-textGreyDark text-left">
                    -{" "}
                    <a
                      href="https://wapal.io/collection/hootz"
                      target="_blank"
                      className="text-textBlue"
                    >
                      HOOTZ
                    </a>{" "}
                    100 Coins
                  </p>
                  <p className="font-SatoshiMedium md:text-base text-sm text-textGreyDark text-left">
                    -{" "}
                    <a
                      href="https://wapal.io/collection/FAPTOX-c7d480706a7e71fb32da4bd8d2857f0a60c361366eade16b0cbd91fb604d952a"
                      target="_blank"
                      className="text-textBlue"
                    >
                      FAPTOX
                    </a>{" "}
                    100 Coins
                  </p>
                  <p className="font-SatoshiMedium md:text-base text-sm text-textGreyDark text-left">
                    -{" "}
                    <a
                      href="https://wapal.io/collection/aptos-bunnies"
                      target="_blank"
                      className="text-textBlue"
                    >
                      Aptos Bunnies
                    </a>{" "}
                    100 Coins
                  </p>
                  <p className="font-SatoshiMedium md:text-base text-sm text-textGreyDark text-left">
                    -{" "}
                    <a
                      href="https://wapal.io/collection/NRUH-BERS"
                      target="_blank"
                      className="text-textBlue"
                    >
                      Nruh BERS
                    </a>{" "}
                    100 Coins
                  </p>
                </>
              )}
              {state.task.taskType == "balance_check_nft" && (
                <>
                  <p className="font-SatoshiMedium md:text-base text-sm text-textGreyDark text-left">
                    -{" "}
                    <a
                      href="https://www.tradeport.xyz/aptos/collection/aptomingos?bottomTab=trades&tab=items"
                      target="_blank"
                      className="text-textBlue"
                    >
                      Aptomingos
                    </a>{" "}
                    100 Coins
                  </p>
                  <p className="font-SatoshiMedium md:text-base text-sm text-textGreyDark text-left">
                    -{" "}
                    <a
                      href="https://www.tradeport.xyz/aptos/collection/aptos-monkeys?bottomTab=trades&tab=items"
                      target="_blank"
                      className="text-textBlue"
                    >
                      Aptos Monkeys
                    </a>{" "}
                    75 Coins
                  </p>
                  <p className="font-SatoshiMedium md:text-base text-sm text-textGreyDark text-left">
                    -{" "}
                    <a
                      href="https://www.tradeport.xyz/aptos/collection/aptorobos-b8a68933?bottomTab=trades&tab=items"
                      target="_blank"
                      className="text-textBlue"
                    >
                      AptosRobos
                    </a>{" "}
                    40 Coins
                  </p>

                  <p className="font-SatoshiMedium md:text-base text-sm text-textGreyDark text-left">
                    -{" "}
                    <a
                      href="https://www.tradeport.xyz/aptos/collection/creatus?bottomTab=trades&tab=items"
                      target="_blank"
                      className="text-textBlue"
                    >
                      Creatus
                    </a>{" "}
                    40 Coins
                  </p>
                  <p className="font-SatoshiMedium md:text-base text-sm text-textGreyDark text-left">
                    -{" "}
                    <a
                      href="https://www.tradeport.xyz/aptos/collection/proud-lions-club?bottomTab=trades&tab=items"
                      target="_blank"
                      className="text-textBlue"
                    >
                      Proud Lions Club
                    </a>{" "}

                    70 Coins

                  </p>
                  <p className="font-SatoshiMedium md:text-base text-sm text-textGreyDark text-left">
                    -{" "}
                    <a
                      href="https://www.tradeport.xyz/aptos/collection/ice-blue?bottomTab=trades&tab=items"
                      target="_blank"
                      className="text-textBlue"
                    >
                      Ice Blue
                    </a>{" "}
                    50 Coins
                  </p>
                  <p className="font-SatoshiMedium md:text-base text-sm text-textGreyDark text-left">
                    -{" "}
                    <a
                      href="https://www.tradeport.xyz/aptos/collection/mavrik?bottomTab=trades&tab=items"
                      target="_blank"
                      className="text-textBlue"
                    >
                      Mavrik
                    </a>{" "}
                    30 Coins
                  </p>
                  <p className="font-SatoshiMedium md:text-base text-sm text-textGreyDark text-left">
                    -{" "}
                    <a
                      href="https://www.tradeport.xyz/aptos/collection/zaaptos?bottomTab=trades&tab=items"
                      target="_blank"
                      className="text-textBlue"
                    >
                      Zaaptos
                    </a>{" "}
                    30 Coins
                  </p>
                  <p className="font-SatoshiMedium md:text-base text-sm text-textGreyDark text-left">
                    -{" "}
                    <a
                      href="https://www.tradeport.xyz/aptos/collection/gui-lions?bottomTab=trades&tab=items"
                      target="_blank"
                      className="text-textBlue"
                    >
                      Gui Lion
                    </a>{" "}
                    50 Coins
                  </p>
                  <p className="font-SatoshiMedium md:text-base text-sm text-textGreyDark text-left">
                    -{" "}
                    <a
                      href="https://www.tradeport.xyz/aptos/collection/netzcuty-d458482d?bottomTab=trades&tab=items"
                      target="_blank"
                      className="text-textBlue"
                    >
                      NetzCuty
                    </a>{" "}
                    25 Coins
                  </p>
                  <p className="font-SatoshiMedium md:text-base text-sm text-textGreyDark text-left">
                    -{" "}
                    <a
                      href="https://www.tradeport.xyz/aptos/collection/0x1eb8b1fe9cdd6de70ded0a59b27780cde4ee9de3a80eb870f9f531f295b4d16?bottomTab=trades&tab=items"
                      target="_blank"
                      className="text-textBlue"
                    >
                      LOOM
                    </a>{" "}
                    20 Coins
                  </p>
                  <p className="font-SatoshiMedium md:text-base text-sm text-textGreyDark text-left">
                    -{" "}
                    <a
                      href="https://www.tradeport.xyz/aptos/collection/watcherz?bottomTab=trades&tab=items"
                      target="_blank"
                      className="text-textBlue"
                    >
                      Watcherz
                    </a>{" "}
                    15 Coins
                  </p>
                  <p className="font-SatoshiMedium md:text-base text-sm text-textGreyDark text-left">
                    -{" "}
                    <a
                      href="https://www.tradeport.xyz/aptos/collection/netzjoy-494084ba?bottomTab=trades&tab=items"
                      target="_blank"
                      className="text-textBlue"
                    >
                      NetzJoy
                    </a>{" "}
                    15 Coins
                  </p>
                  <p className="font-SatoshiMedium md:text-base text-sm text-textGreyDark text-left">
                    -{" "}
                    <a
                      href="https://www.tradeport.xyz/aptos/collection/swimming-swans?bottomTab=trades&tab=items"
                      target="_blank"
                      className="text-textBlue"
                    >
                      Swimming Swans{" "}
                    </a>{" "}
                    10 Coins
                  </p>
                  <p className="font-SatoshiMedium md:text-base text-sm text-textGreyDark text-left">
                    -{" "}
                    <a
                      href="https://www.tradeport.xyz/aptos/collection/hootz?bottomTab=trades&tab=items"
                      target="_blank"
                      className="text-textBlue"
                    >
                      HOOTZ
                    </a>{" "}
                    10 Coins
                  </p>
                  <p className="font-SatoshiMedium md:text-base text-sm text-textGreyDark text-left">
                    -{" "}
                    <a
                      href="https://www.tradeport.xyz/aptos/collection/faptox?bottomTab=trades&tab=items"
                      target="_blank"
                      className="text-textBlue"
                    >
                      FAPTOX
                    </a>{" "}
                    10 Coins
                  </p>
                  <p className="font-SatoshiMedium md:text-base text-sm text-textGreyDark text-left">
                    -{" "}
                    <a
                      href="https://www.tradeport.xyz/aptos/collection/0xbb736f0b7f724787f19bb7c108c3a216e45040eb6e919be872f0dc300d7342ae?bottomTab=trades&tab=items"
                      target="_blank"
                      className="text-textBlue"
                    >
                      Aptos Bunnies
                    </a>{" "}
                    10 Coins
                  </p>
                  <p className="font-SatoshiMedium md:text-base text-sm text-textGreyDark text-left">
                    -{" "}
                    <a
                      href="https://www.tradeport.xyz/aptos/collection/nruh-bers?bottomTab=trades&tab=items"
                      target="_blank"
                      className="text-textBlue"
                    >
                      Nruh BERS
                    </a>{" "}
                    10 Coins
                  </p>
                </>
              )}
              {state.task.taskType == "cellana" && (
                <>
                  <p className="font-SatoshiMedium md:text-base text-sm text-textGreyDark text-left">
                    - Swaps: 1 Defy coin for swapping to 15 CELL.
                  </p>
                  <p className="font-SatoshiMedium md:text-base text-sm text-textGreyDark text-left">
                    - Lock: 1 Defy coin for every 20 veCELL you hold everyday. 
                  </p>
                  <p className="font-SatoshiMedium md:text-base text-sm text-textGreyDark text-left">
                    - Vote: 1 Defy coin for every 10 veCELL used for vote.
                  </p>
                </>
              )}
              {state.task.taskType == "zaaptos" && (
                <>
                  <p className="font-SatoshiMedium md:text-base text-sm text-textGreyDark text-left">
                    - Swap - 1 Defy coin for every 3000 ZAAP swapped
                  </p>
                </>
              )}
              {state.task.taskType == "donk" && (
                <>
                  <p className="font-SatoshiMedium md:text-base text-sm text-textGreyDark text-left">
                    - Swaps: 1 Defy coin for swapping to 250 DONK.
                  </p>
                  <p className="font-SatoshiMedium md:text-base text-sm text-textGreyDark text-left">
                    - Pools: 1 Defy coin for every 125 DONK added to pools.
                  </p>
                  <p className="font-SatoshiMedium md:text-base text-sm text-textGreyDark text-left">
                    - Farms: 1 Defy coin for every 125 DONK added to farms.
                  </p>
                </>
              )}
              {state.task.taskType == "aries_check" && (
                <>
                  <p className="font-SatoshiMedium md:text-base text-sm text-textGreyDark text-left">
                    - Lend - 1 Defy coin for 1$ worth asset lent
                  </p>
                  <p className="font-SatoshiMedium md:text-base text-sm text-textGreyDark text-left">
                    - Borrow - 3 Defy coins for 1$ worth asset borrowed
                  </p>
                </>
              )}
              <p className="font-SatoshiMedium text-base text-textGreyDark text-left my-3">
                Read the{" "}
                <a href="/faq" target="_blank" className="text-textBlue">
                  FAQ
                </a>{" "}
                for more info.
              </p>
              <p className="font-SatoshiMedium text-base text-textGreyDark text-left mt-2">
                <span className="text-black font-SatoshiBold">Note:</span> It
                might sometimes take multiple hours to verify the quest.
              </p>
              {Math.abs(
                (new Date() -
                  new Date(localStorage.getItem(state.task.taskId))) /
                  36e5 <
                  24
              ) &&
                !state.task.completed && (
                  <p className="font-SatoshiMedium text-base text-textGreyDark text-left mt-2">
                    The Task is under review
                  </p>
                )}
              {showInput && (
                <input
                  className="my-3 p-2  text-black border-2 border-slate-300 rounded-full w-full !outline-none"
                  placeholder="Enter your Defy Quote URL"
                  onChange={(e) => {
                    setTweetUrl(e.target.value);
                  }}
                ></input>
              )}
            </div>

            <div className="flex-1 p-2">
              <div className="size-full h-auto bg-greyVeryLight rounded-xl max-[600px]:hidden">
                <img className="m-auto w-2/3 object-contain" src={coinAnim} />
              </div>

              <div
                className={clsx(
                  "stroke-borderGrey border rounded py-1.5 px-2 mt-3 ",
                  showInput
                    ? "max-[1024px]:mt-[-1rem]"
                    : "max-[1024px]:mt-[-2rem]"
                )}
              >
                <p className="font-SatoshiMedium text-sm text-left text-black">
                  Rewards:
                </p>
                <div className="flex items-center justify-between">
                  <p className="font-SatoshiMedium text-xs text-left text-textGreyDark">
                    You get:
                  </p>
                  {state.task.boost.isBoosted ? (
                    <p className="font-SatoshiMedium text-xs text-right text-black">
                      X{" "}
                      <span className="rainbow-text">
                        {state.task.taskType === "gui" ||
                        state.task.taskType === "wapal_nft_activity_buy" ||
                        state.task.taskType === "tradeport_nft_activity_buy" ||
                        state.task.taskType==="onchain" || state.task.taskType == "donk" || state.task.taskType == "zaaptos" || state.task.taskType == "cellana" || state.task.taskType == "aries_check"
                          ? "Unlimited"
                          : state.task.taskType ===
                            "tradeport_nft_activity_mint"
                          ? `${
                              state.task.reward.points *
                              state.task.boost.multiplier
                            } Coins / NFT`
                          : state.task.taskType === "balance_check_nft"
                          ? "Unlimited / Everyday"
                          : (state.task.taskType === "onchain" ||
                              state.task.taskType === "balance_check_coin") &&
                            (state.task.platform === "merkle_position" ||
                              state.task.platform === "econia_fill")
                          ? `${
                              state.task.reward.points *
                              state.task.boost.multiplier
                            } Coins / USDC`
                          : (state.task.taskType === "onchain" ||
                              state.task.taskType === "balance_check_coin") &&
                            state.task.platform !==
                              "vibrantx_leveraged_staking" &&
                            state.task.platform !== "merkle_position" &&
                            state.task.platform !== "econia_fill"
                          ? `${
                              state.task.reward.points *
                              state.task.boost.multiplier
                            } Coins / APT Everyday`
                          : `${
                              state.task.reward.points *
                              state.task.boost.multiplier
                            } Coins / APT`}
                      </span>
                    </p>
                  ) : (
                    <p className="font-SatoshiMedium text-xs text-right text-black">
                      X{" "}
                      {state.task.taskType === "gui" ||
                      state.task.taskType === "wapal_nft_activity_buy" ||
                      state.task.taskType === "tradeport_nft_activity_buy" ||
                      state.task.taskType === "onchain" || state.task.taskType == "donk" || state.task.taskType == "zaaptos" || state.task.taskType == "cellana" || state.task.taskType == "aries_check"
                        ? "Unlimited"
                        : state.task.taskType === "tradeport_nft_activity_mint"
                        ? `${
                            state.task.reward.points *
                            state.task.boost.multiplier
                          } Coins / NFT`
                        : state.task.taskType === "balance_check_nft"
                        ? "Unlimited / Everyday"
                        : (state.task.taskType === "onchain" ||
                            state.task.taskType === "balance_check_coin") &&
                          (state.task.platform === "merkle_position" ||
                            state.task.platform === "econia_fill")
                        ? `${
                            state.task.reward.points *
                            state.task.boost.multiplier
                          } Coins / USDC`
                        : (state.task.taskType === "onchain" ||
                            state.task.taskType === "balance_check_coin") &&
                          state.task.platform !==
                            "vibrantx_leveraged_staking" &&
                          state.task.platform !== "merkle_position" &&
                          state.task.platform !== "econia_fill"
                        ? `${
                            state.task.reward.points *
                            state.task.boost.multiplier
                          } Coins / APT Everyday`
                        : `${
                            state.task.reward.points *
                            state.task.boost.multiplier
                          } Coins / APT`}
                    </p>
                  )}{" "}
                </div>
              </div>

              <div className="flex items-center mt-10">
                <img className="h-5 w-5 object-contain" src={groupProfile} />
                <p className="ml-2 font-SatoshiMedium text-textGreyLight text-sm">
                  {state.task.taskType == "onchain" ||
                  state.task.taskType == "balance_check_coin" ||
                  state.task.taskType == "balance_check_nft" ||
                  state.task.taskType == "wapal_nft_activity_mint" ||
                  state.task.taskType == "gui" ||
                  state.task.taskType == "wapal_nft_activity_buy" ||
                  state.task.taskType == "tradeport_nft_activity_buy" ||
                  state.task.taskType == "tradeport_nft_activity_mint" || 
                  state.task.taskType == "donk" || state.task.taskType == "zaaptos" || state.task.taskType == "cellana" || state.task.taskType == "aries_check"
                    ? participants
                    : state.task.participants}{" "}
                  Participants
                </p>
              </div>

              {(state.task.taskType == "onchain" ||
                state.task.taskType == "balance_check_coin" ||
                state.task.taskType == "balance_check_nft" ||
                state.task.taskType == "wapal_nft_activity_mint" ||
                state.task.taskType == "wapal_nft_activity_buy" ||
                state.task.taskType == "tradeport_nft_activity_buy" ||
                state.task.taskType == "gui" ||
                state.task.taskType == "tradeport_nft_activity_mint" ||
                state.task.taskType == "donk" || state.task.taskType == "zaaptos" || state.task.taskType == "cellana" || state.task.taskType == "aries_check"
                
              ) && (
                <div className="flex items-center mt-3 p-2 border rounded justify-center border-borderGrey">
                  <p className=" font-SatoshiMedium text-textGreyLight text-sm">
                    Earned Till Now: {onchainBalance}
                  </p>
                </div>
              )}


              {/* <TelegramLoginButton/> */}
            </div>
          </div>
        </div>
        <div
          className="mt-auto flex-col items-end min-[1024px]:pr-3 self-end flex w-full"
          id="buttonGroup"
        >
          {state.task.links && state.task.taskType !== "mcq" && (
            <div
              onClick={
                !state.task.completed
                  ? () => {
                      if (
                        Math.abs(
                          (new Date() -
                            new Date(localStorage.getItem(state.task.taskId))) /
                            36e5 <
                            24
                        )
                      ) {
                        toast("Wait for 24 hours for verficiation");
                        return;
                      }

                      if (state.task.taskType === "twitterFollow") {
                        localStorage.setItem(
                          state.task.taskId,
                          new Date().toLocaleString()
                        );
                        toast(
                          "Twitter Follow Verification will take upto 24 Hours"
                        );
                      } else if (state.task.platform === "twitter") {
                        localStorage.setItem(
                          state.task.taskId,
                          new Date().toLocaleString()
                        );
                        toast("Twitter Verification will take upto 24 Hours");
                      }

                      window.open(state.task.links[0], "_blank", "noopener");
                    }
                  : () => {}
              }
              className={clsx(
                "px-6  button py-2.5 rounded-lg bg-bgBlue flex items-center justify-center mt-3 xl:!w-[15rem] !w-full ml-auto",
                state.task.completed
                  ? "bg-borderGrey"
                  : "cursor-pointer bg-bgBlue"
              )}
            >
              <p className="font-SatoshiMedium text-base text-white">
                {state.task.buttonText}
              </p>
            </div>
          )}

          {showBtn && (
            <div
              onClick={
                !state.task.completed ||
                !(
                  state.task.platform == "twitter" &&
                  state.task.taskType !== "connection"
                )
                  ? handleClick
                  : () => {}
              }
              className={clsx(
                "px-6 button  py-2.5 rounded-lg bg-bgBlue flex items-center justify-center mt-3 xl:!w-[15rem] !w-full ml-auto",
                state.task.completed ||
                  (state.task.platform == "twitter" &&
                    state.task.taskType !== "connection")
                  ? "bg-borderGrey"
                  : "cursor-pointer bg-bgBlue"
              )}
            >
              <p className="font-SatoshiMedium text-base text-white">
                {state.task.taskType == "mcq" ? "Start Quiz" : "Verify"}
              </p>
            </div>
          )}

          {hasGamesTag && (
            <div
              className={clsx(
                "px-6 button py-2.5 rounded-lg bg-bgBlue flex items-center justify-center mt-3 xl:!w-[15rem] !w-full ml-auto",
                "cursor-pointer bg-bgBlue"
              )}
              onClick={() => navigate(navigatePath())}
            >
              <p className="font-SatoshiMedium text-base text-white">
                {state.task.buttonText}
              </p>
            </div>
          )}
        </div>
      </div>
    </Modal>
  );
};


export default TaskModal

