import React, { useEffect, useState, useContext } from "react";
import bgChest from "../../assets/freechestbg.png";
import freechest from "../../assets/freechest.png";
import FreeChestRewardsModel from "../quest/chests/FreeChestRewardsModel";
import axios from "axios";
import { assets, CONST } from "../../constants";
import clsx from "clsx";
import { UserContext } from "../../contexts/UserContent";
import { useWallet } from "@aptos-labs/wallet-adapter-react";
import {  toast } from "react-toastify";
import { Modal } from "@material-ui/core";
import { fetchTickets } from "../../utils/indexer";
import raffleTicket from "../../assets/blueTicket.png";

function DailyRewardsModal({ isOpen, onClose }) {

  const { connected, connect, account } = useWallet()


  const { user, setUser, tickets, setTickets } = useContext(UserContext)
  // //console.log(user);
  const [ChestRewardsModel, setChestRewardsModel] = useState(false);
  const [rewards, setRewards] = useState(connected ? [] : CONST.defaultDailyRewards);
  const [loading, setLoading] = useState(true);
  const [currentStartIndex, setCurrentStartIndex] = useState(0);
  // const [value2, setValue2] = useState(7);
  const [celebrationState, setCelebrationState] = useState({
    image: assets.blueTicket,
    text: "2x Raffle Ticket"
  })

  const [celebration, setCelebration] = useState(false)
  // const [nextIsClaimable, setNextIsClaimable] = useState(1)

  const handleCloseCelebration = () => {
    setCelebration(false);
  };

  const handleOpenCelebration = () => {
    setCelebration(true);
  };

  function getUser() {
    const jwt = localStorage.getItem("AVEX_DEFY_JWT_STORE");
    const config = {
      headers: {
        Authorization: jwt,
      },
    };
    if (jwt) {
      axios.get(`${CONST.baseUrl}/users`, config).then((response) => {
        setUser(response.data.data);
      });
    }
  }

  const getDailyRewards = () => {
    const jwt = localStorage.getItem("AVEX_DEFY_JWT_STORE");
    const config = {
      headers: {
        Authorization: jwt,
      },
    };


    if (jwt) {
      setLoading(true);
      axios.get(`${CONST.baseUrl}/daily-rewards`, config).then((response) => {
        setRewards(response.data.data);
        setLoading(false);
      }).catch(() => { setLoading(false) });
    }
  };
  // console.log(rewards);
  useEffect(() => {
    if (isOpen) getDailyRewards();
  }, [isOpen]);

  function claimReward(rewardId, isFinal, reward) {

    if(!connected){
      toast("Connect your Wallet")
      return
    }


    const jwt = localStorage.getItem("AVEX_DEFY_JWT_STORE");
    const config = {
      headers: {
        Authorization: jwt,
      },
    };

    if (jwt) {
      setLoading(true);
      axios
        .post(
          `${CONST.baseUrl}/daily-rewards/user/claim?rewardId=${rewardId}`,
          config
        )
        .then((response) => {
          getUser();
          setLoading(false);
          fetchTickets(account?.address).then((result) => {
            setTickets(result)
          });
          toast("Daily Rewards succesfully claimed!")
          if (isFinal) {
            if (reward.reward.points !== 0) {
              setCelebrationState({
                image: assets.coinAnim,
                text: `${reward.reward.points} Defy Coins`
              })
            } else if (reward.reward.tickets !== 0) {
              setCelebrationState({
                image: assets.blueTicket,
                text: `${reward.reward.tickets}x Raffle Tickets`
              })
            }
            else if (reward.reward?.fungibleAssets.length !== 0) {
              setCelebrationState({
                image: assets.guiIcon,
                text: `${reward.reward?.fungibleAssets[0]?.value}x ${reward.reward?.fungibleAssets[0]?.name} Tokens`
              })
            }
            handleOpenCelebration()
          }

        })
        .catch(() => {
          setLoading(false);
          toast("Failed to claim rewards!")

        });
    }
  }
  
    let isoDate = user.lastDailyRewardCheckedAt;
    const [remainingTime, setRemainingTime] = useState(0);
  
    useEffect(() => {
      const startDate = new Date(isoDate);
      startDate.setHours(startDate.getHours() + 24);
      // startDate.setMinutes(startDate.getMinutes() + 5);
      
      const intervalId = setInterval(() => {
        const now = new Date().getTime();
        const distance = startDate - now;
        setRemainingTime(distance);
        
        if (distance < 0) {
          clearInterval(intervalId);
          setRemainingTime(0);
        }
      }, 1000);
  
      return () => clearInterval(intervalId);
    }, [isoDate]);
  
    const hours = Math.floor((remainingTime % (1000 * 60 * 60 * 24)) / (1000 * 60 * 60));
    const minutes = Math.floor((remainingTime % (1000 * 60 * 60)) / (1000 * 60));
    const seconds = Math.floor((remainingTime % (1000 * 60)) / 1000);
    // //console.log(user.lastDailyRewardCheckedAt);
    // //console.log(hours+" "+minutes+" "+" "+seconds);

  useEffect(() => {
    if (!isOpen) setCelebration(false)
    if (isOpen && !connected) {
      connect()
      return
    }
  }, [isOpen])

  useEffect(() => {
    if (user && user.nextDailyRewardSequence !== undefined) {
      const index = user.nextDailyRewardSequence - 1;
      // console.log(user.nextDailyRewardSequence);
      // const index = 8;
      const groupNumber = Math.floor(index / 7);
      const startIndex = groupNumber * 7;
      
      setCurrentStartIndex(startIndex);
    }
  }, [user]);

  if (!isOpen) return null;

  return (
    <>
      <Modal
        onClose={onClose}
        open={isOpen}
        className="h-[600px] w-[800px] max-[1024px]:w-[95vw] "

        style={{
          backgroundColor: "transparent",
          margin: "auto",
          overflow: "hidden",
          display: "flex",
          flexDirection: "column",
        }}
      >
        {!celebration ? <>
          <div className=" bg-white xl:min-h-[556px] p-5 rounded-lg sm:scrollable max-[1024px]:w-[95vw] max-[1024px]:h-[100%]">
            <div className="flex items-start justify-between">
              <h1 className="text-lg font-bold mb-2">Daily Rewards</h1>
              <button
                onClick={onClose}
                className="text-3xl align-top text-gray-500"
              >
                &times;
              </button>
            </div>
            <p className="text-sm mb-7">
              Return daily to unlock your exclusive rewards and keep the
              excitement alive!
            </p>

            <div>
              {loading && connected ? (
                <>
                  <img
                    className="w-1/4 m-auto mt-[15%] mb-[15%] self-center"
                    src="/loader.gif"
                    alt="loader animation"
                  />
                </>
              ) : (
                <div >
                  {/* day 7 claimed then i+7 and j+7  */}
                  <div className="grid grid-cols-2 gap-4 sm:grid-cols-3 md:grid-cols-4 overflow-y-scroll max-h-[75vh] max-[1024px]:h-[80%] max-[1024px]:pb-[220px]">
                    {rewards.slice(currentStartIndex,currentStartIndex+7).map((reward, index) => {
                      // const CLAIMED = connected ? user.dailyRewardsClaimed?.some(
                      //   (object) => object.dailyRewardId === reward.rewardId
                      // ) : false;
                      let CLAIMED = (currentStartIndex + index + 1) < user.nextDailyRewardSequence
                      // console.log(index)
                      // if(user.isDailyRewardClaimed && (index + 1) == user.nextDailyRewardSequence) CLAIMED = true
                      // if(index%7!==0 && CLAIMED) 
                      const isClaimable =
                        connected ?
                          (user.nextDailyRewardSequence == reward.sequence) &&
                          !user.isDailyRewardClaimed : false

                      return reward.sequence % 7 !== 0 ? (
                        <div className="" key={index}>
                          <div
                            className={`relative flex flex-col items-center justify-center gap-2 border p-3 rounded-lg bg-gray-200 ${CLAIMED || !isClaimable
                              ? "border-none"
                              : "border-[#F4F4F6] hover:border-[#2071EE]"
                              }`}
                          >
                            {CLAIMED && (
                              <div className="absolute top-0 right-0 border bottom-0 h-full rounded-lg bg-[rgba(0,0,0,0.3)] left-0 flex items-center justify-center">
                                <img
                                  src="/DailyRewardModal/tick.png"
                                  alt="claimed"
                                  className="opacity-100"
                                />
                              </div>
                            )}
                            <div className="text-center">
                              <span className="block text-sm font-semibold">
                                {reward.title}
                              </span>
                            </div>
                            <div
                              style={{
                                opacity: !isClaimable && 0.6,
                              }}
                              className="rounded-lg bg-white py-4 gap-2 flex flex-col w-full items-center justify-center"
                            >
                              {/* <img
                                src={
                                  index == 0 || index == 1 || index == 3 || index == 5 
                                    ? "/DailyRewardModal/Coins.gif"
                                    : assets.blueTicket
                                }
                                className={clsx(
                                  "h-[66px]",
                                  tickets && "p-4"
                                )}
                                alt="Reward Coins"
                              /> */}
                                  {reward.reward.points===0 ? (
                                    <div className="flex justify-center items-center flex-col">
                                      <img src={raffleTicket} alt="Raffle Ticket" className="h-[66px] p-4" />
                                      <span className="block text-md text-black font-medium">x{reward.reward.tickets}</span>
                                    </div>                                    
                                  ) : (
                                    <div className="flex justify-center items-center flex-col">
                                      <img src="/DailyRewardModal/Coins.gif" alt="Reward Coins" className="h-[66px]" /> 
                                      <span className="block text-md text-black font-medium px-4">x{reward.reward.points}</span>
                                    </div>   
                                  )}
                              {/* <span className="block text-md text-black font-medium">
                                x{index == 0 || index == 1 || index == 3 || index == 5 ? reward.reward.points : reward.reward.tickets}
                              </span> */}
                            </div>
                              

                            <div
                              onClick={() => {
                                if (CLAIMED || !isClaimable) return;

                                claimReward(reward.rewardId);
                              }}
                              className={`w-full px-1 py-[0.15rem] font-SatoshiMedium text-center text-white ${CLAIMED || !isClaimable
                                ? "bg-borderGrey"
                                : "bg-[#2071EE] cursor-pointer"
                                } rounded-lg`}
                              disabled={CLAIMED || !isClaimable}
                            >
                              {CLAIMED ? "Claimed" : isClaimable ? `Claim before: ${hours}:${minutes}:${seconds}` : user.isDailyRewardClaimed && currentStartIndex + index === user.nextDailyRewardSequence-1 ? `Claim in: ${hours}:${minutes}:${seconds}` : "Claim"} 
                            </div>
                          </div>
                        </div>
                        // till here
                      ) : (
                        <div className="relative flex flex-col justify-center gap-2 border-none shadow-all p-3 rounded-lg sm:col-span-3 md:col-span-2">
                          <div className="">
                            <span className="block text-sm font-semibold">
                              {reward.title}
                              {/* Day 7 */}
                            </span>
                          </div>
                          <div
                            className={`relative rounded-lg bg-white py-0 gap-2 flex flex-col w-full items-center justify-center border-[#F4F4F6] hover:border-[#2071EE] border`}
                          >
                            <img
                              src={bgChest}
                              className="h-[130px] w-full"
                              alt="Background"
                            />
                            <img
                              src={freechest}
                              className="absolute top-1/2 left-1/2 transform -translate-x-1/2 -translate-y-1/2 w-1/3"
                              alt="Overlay"
                            />
                          </div>
                          <div>
                            <button
                              className={`w-full px-1 py-[0.15rem] text-white bg-[#2071EE] rounded-lg font-SatoshiMedium text-center
                            ${CLAIMED || !isClaimable
                                  ? "bg-borderGrey"
                                  : "bg-[#2071EE] cursor-pointer"
                                }
                            `}
                              disabled={CLAIMED || !isClaimable}
                              onClick={() => {
                                if (CLAIMED || !isClaimable) 
                                  return
                                else {
                                  claimReward(reward.rewardId, true, reward) 
                                }  
                              }}     
                              >
                              {CLAIMED ? "Claimed" : isClaimable ? `Claim before: ${hours}:${minutes}:${seconds}` : user.isDailyRewardClaimed && currentStartIndex + index === user.nextDailyRewardSequence-1 ? `Claim in: ${hours}:${minutes}:${seconds}` : "Claim"}

                            </button>
                          </div>
                        </div>
                      );
                    })}
                  </div>
                </div>
              )}

            </div>

          </div>
          {ChestRewardsModel && (
            <FreeChestRewardsModel
              isOpen={ChestRewardsModel}
              onClose={() => setChestRewardsModel(false)}
            />
          )}
        </>

          :
          <div
            style={{
              backgroundImage: `url(${assets.celebrationBg})`,
              backgroundPosition: "center",
              backgroundSize: "cover",
            }}
            className=" xl:min-h-[556px] p-5 rounded-lg sm:scrollable max-[1024px]:w-[95vw]">
            <div className="w-full h-full  p-5 rounded-lg NFTModal flex flex-col items-center justify-center">
              <p className="text-[32px] font-SatoshiBold mx-auto text-white text-center mt-4">Free Chest Rewards</p>

              <img className='w-1/4 object-contain mx-auto  my-[3rem]' src={celebrationState.image} />
              <p className="text-[32px] font-SatoshiBold mx-auto text-white text-center mt-5">{celebrationState.text}</p>

            </div>

          </div>
        }

      </Modal>

    </>
  );
}

export default DailyRewardsModal;