import React, { useEffect, useRef, useState } from 'react';
import Matter, { Engine, Render, Runner, Bodies, Composite, Body, Events, Common, Vector } from 'matter-js';
import { useWallet } from "@aptos-labs/wallet-adapter-react";
// import {
//     findTheExpectedMultipliers,
//     findTheMultipliers,
//     generateMultiplierText,
//   } from "@/helpers/automatedTests";

const MatterSim2 = () => {
const { account, connected, signAndSubmitTransaction } = useWallet();
  const worldWidth = 800;
  const startPins = 3;
  const pinLines = 12;
  const pinSize = 12.82;
  const pinGap = 39;
  const ballSize = 7;
  const ballElastity = 0.01;
  const ballFriction = 0.0002;
  const sceneRef = useRef(null);
  let ballsSpawned = 0;

  const bucketColors = [
    "#FFAA05", 
    "#FFAA05", 
    "#FFAA05", 
    "#FFD5AE", 
    "#F5CEFF",
    "#ADE6CC", 
    "#E4F4E1", 
    "#D2E3FC", 
    "#E4F4E1", 
    "#ADE6CC",
    "#F5CEFF", 
    "#FFD5AE", 
    "#FFAA05"
  ];

  const multiplierPositions = [];
  const multipliers = [
    9.0, 8.2, 6.5, 3.8, 1.0, 0.6, 0.4, 0.6, 1.0, 3.8, 6.5, 8.2, 9.0,
  ];
  // Define the multipliers for each spot
//   const multipliers = generateMultiplierText(multipliersNumbers);

  const predefinedPaths = [[0, 0, 0, 0, 1, 0, 1, 1, 1, 0, 0, 1]]
  const [isPlaying, setPlaying] = useState(false)
  const [finishedBalls, setFinishedBalls] = useState(0);
//   console.log(isPlaying)

  useEffect(() => {
    // Create engine and renderer
    setFinishedBalls(0);

    const engine = Engine.create();
    const render = Render.create({
      element: sceneRef.current,
      engine: engine,
      options: {
        width: worldWidth,
        height: 700,
        wireframes: false,
        // showVelocity: true, 
        // showAngleIndicator: true,
        // showPerformance: true, // Show FPS (only for testing) TODO : remove it
        background: "transparent"
      },
    });

    // Add Initials
    const pins = [];
    const forceTrackers = [];
    const balls = [];
    let currentPathIndex = 0;

    
    // To spawn new ball every 750 after click
    const asyncCompositeBallAdd = async(balls) => {
        if (
            JSON.stringify(predefinedPaths) ===
            JSON.stringify([[15, 15, 15, 15, 15, 15, 15, 15, 15, 15, 15, 15]])
          ) {
            setPlaying(false);
            return;
          }
          while(balls.length>ballsSpawned) {
            console.log("true")
            if (document.visibilityState === "visible" && isPlaying) {
                await new Promise((resolve) => setTimeout(resolve, 7500));
                
                const collisionGroup = Common.nextId();
                // console.log(collisionGroup)

                Body.set(balls[ballsSpawned], {
                    collisionFilter: {
                      group: collisionGroup,
                      category: 2, // Set a unique category for each ball (can be any number)
                      mask: 1, // Set the mask to interact with pins (category 1)
                    },
                });

                Composite.add(engine.world, [balls[ballsSpawned]]);
                ballsSpawned++; 
                console.log("bs: ",ballsSpawned)    
            } else {
                await new Promise((resolve) => setTimeout(resolve, 1200));
            }
          }
    };

    const handleClick = (e) => {
        setPlaying(true)
        const rect = sceneRef.current.getBoundingClientRect();
        // console.log(predefinedPaths.length)
        
        for(let i=0; i<predefinedPaths.length; i++) {
            let dropBallPosition = 0;
            if (predefinedPaths[i][0] === 1) {
                dropBallPosition = worldWidth / 2.095 + 17;
              } else {
                dropBallPosition = worldWidth / 2.095 - 20;
              }
              const centerPinX = dropBallPosition;
              const ballStartY = 50;
              const ball = Bodies.circle(centerPinX, ballStartY, ballSize, {
                restitution: ballElastity,
                friction: ballFriction,
                density: 0.4,
                render: { fillStyle: '#77DD77' },
              });
            Composite.add(engine.world, ball);  
            balls.push(ball);
            forceTrackers.push({ x: 0, y: 0 });
        }        
      };
      console.log(balls)
      asyncCompositeBallAdd(balls);
    //   engine.gravity = { x: 0, y: 0.06, scale: 0.0018 };

    let pinPositions = [];

    // Add Pins
    for (let l = 0; l < pinLines; l++) {
      const linePins = startPins + l;
      const lineWidth = linePins * pinGap;
      pinPositions[l] = [];
      for (let i = 0; i < linePins; i++) {
        const pinX = worldWidth / 2 - lineWidth / 2 + i * pinGap;
        const pinY = 100 + l * pinGap;
        const pin = Bodies.circle(
          pinX,
          pinY,
          pinSize,
          {
            isStatic: true,
            render: {
                visible: true,
                fillStyle: "#87CEEB",
                // lineWidth: 100,
            },
          }
        );
        pins.push(pin);
        
        if (l === pinLines - 1 && i < linePins - 1) {
          const bucketX = pinX + pinGap / 2;
          const bucketY = pinY + pinGap; // Position for the bottom of the bucket
          const bucketWidth = pinGap; // Width of the bucket
          const bucketHeight = 30; // Height of the bucket sides

          // Create left side of the bucket
          const leftSide = Bodies.rectangle(
            bucketX - bucketWidth / 2,
            bucketY + bucketHeight / 2,
            5,
            bucketHeight,
            {
              isStatic: true,
            }
          );

          // Create right side of the bucket
          const rightSide = Bodies.rectangle(
            bucketX + bucketWidth / 2,
            bucketY + bucketHeight / 2,
            5,
            bucketHeight,
            {
              isStatic: true,
            }
          );

          // Create bottom of the bucket
          const bottom = Bodies.rectangle(
            bucketX,
            bucketY + bucketHeight,
            bucketWidth,
            5,
            {
              isStatic: true,
            }
          );
          // Calculate and store the position for the multiplier text
          const textX = bucketX + bucketWidth / 2;
          const textY = bucketY + bucketHeight + 20; // 20 is an offset, adjust as needed
          multiplierPositions.push({
            x: textX,
            y: textY,
            value: multipliers[i],
          });

          // Assign a color to each bucket area from the array
          const bucketColor = bucketColors[i % bucketColors.length];
          // Create the bottom area of the bucket
          const bottomArea = Bodies.rectangle(
            bucketX,
            bucketY + bucketHeight / 2,
            bucketWidth - 10, // slightly less width than the bucket itself
            bucketHeight,
            {
              isStatic: true,
              isSensor: true, // Make it a sensor so it doesn't physically interact
              render: {
                fillStyle: bucketColor,
              },
            }
            
          );

          // Store the reference to the bottomArea body and its multiplier
          multiplierPositions[i] = {
            body: bottomArea, // Reference to the bottomArea body
            value: multipliers[i], // Corresponding multiplier value
          };

          // Add bucket parts to the world
          Composite.add(engine.world, [leftSide, rightSide, bottomArea]);
        }
      }
    }
    Composite.add(engine.world, pins);


    // Path Following Logic
    let followingPredefinedPath = Array(predefinedPaths.length).fill(false);
    let currentSteps = Array(predefinedPaths.length).fill(0);

    Events.on(engine,"collisionStart", (event) => {
        // console.log("Collision Start")
        event.pairs.forEach((pair) => {
            for(let i=0; i<predefinedPaths.length; i++) {
                if(
                    (pair.bodyA === balls[i] || pair.bodyB === balls[i]) &&
                    !followingPredefinedPath[i]
                ) {
                    followingPredefinedPath[i] = true;
                    currentSteps[i] = 0;
                  }
            }
            // console.log(currentSteps, followingPredefinedPath)
            let ball = null;
            let pin = null;

            if (balls.includes(pair.bodyA)) {
                ball = pair.bodyA;
                pin = pair.bodyB;
              } else if (balls.includes(pair.bodyB)) {
                ball = pair.bodyB;
                pin = pair.bodyA;
              }

            //detect if ball and pin touch
            if(ball && pin) {
                const collisionPointY = (ball.position.y + pin.position.y) / 2;
                const topOfPin = pin.position.y - (pin.circleRadius ? pin.circleRadius / 2 : 0);

                //detect top of the pin
                if(collisionPointY<=topOfPin && !pin.isSensor)
                    pin.render.fillStyle = "#6CA4Bf";

                setTimeout(() => {
                    if (pin && !pin.isSensor) {
                      // Check again to ensure it's not a sensor
                      pin.render.fillStyle = "#87CEEB"; // Original color
                    }
                  }, 100);

                // console.log("ball and Pin",collisionPointY, topOfPin)
            }

            //detect ball and bottom area
            balls.forEach((ball) => {
                if (
                    (pair.bodyA === ball && pair.bodyB.isSensor) ||
                    (pair.bodyB === ball && pair.bodyA.isSensor)
                ) {
                    // Identify the bottomArea in the collision
                    const bottomArea = pair.bodyA.isSensor ? pair.bodyA : pair.bodyB;
        
                    // Get the color of the bottomArea and add it to the history
                    // const color = bottomArea.render.fillStyle;

                    Body.scale(bottomArea, 1.1, 1.1);

                    //Back to normal
                    setTimeout(() => {
                        Body.scale(bottomArea, 1 / 1.1, 1 / 1.1);
                    }, 625);

                    setFinishedBalls((prev) => prev + 1);
                }
            });
                
        });
    });

    // Track the current row and last row's Y position
    let currentRows = Array(predefinedPaths.length).fill(0);
    let lastRowYPositions = Array(predefinedPaths.length).fill(100);

    //Force for predefined Path
    Events.on(engine, "beforeUpdate", ()=> {
        // console.log("beforeUpdate detect")
        for(let i=0; i<predefinedPaths.length; i++) {
            if(
                followingPredefinedPath[i] &&
                currentRows[i] < predefinedPaths[i].length - 1
            ) {
                const newRow = Math.floor((balls[i].position.y - 100) / pinGap);

                if(newRow > currentRows[i]) {
                    currentRows[i] = newRow;
                    lastRowYPositions[i] = 100+currentRows[i]*pinGap; 
                }

                const distanceFromLastRow = balls[i].position.y - lastRowYPositions[i];

                const normalizedDistance = Math.min(
                    distanceFromLastRow / (pinGap / 2),
                    1
                );

                const baseForceMagnitude = 0.006;
                const forceMagnitude = baseForceMagnitude * (1 - normalizedDistance ** 2);
                const angle = (Math.PI / 2) * normalizedDistance - 0.4
                const direction = predefinedPaths[i][currentRows[i] + 1] === 0 ? -1 : 1;
                const forceX = Math.cos(angle - 6) * direction * forceMagnitude * 2.3;
                const forceY = Math.sin(angle + 1) * forceMagnitude * 1.2;

                // console.log("Force ", forceX, forceY)

                const force = Vector.create(forceX, forceY);
                Body.applyForce(balls[i], balls[i].position, force);
                forceTrackers[i] = force;
            }
        }
    });

    Events.on(engine, "collisionEnd", (event) => {
        // console.log("CollisionEnd")
        event.pairs.forEach((pair) => {
            for (let i = 0; i < predefinedPaths.length; i++) {
                if (pair.bodyA === balls[i] || pair.bodyB === balls[i]) {
                    if (currentSteps[i] < predefinedPaths[i].length - 2) {
                        currentSteps[i]++; // Move to the next step in the path after each collision
                    }
                }
            }
        });
    });

    const runner = Runner.create();
    engine.timing.timeScale = 1;
    render.options.wireframes = false;
    Runner.run(runner, engine);
    Render.run(render);
      
    sceneRef.current.addEventListener('click', handleClick);
    // const interval = setInterval(updateBallPath, 1000);


    // Clean up on component unmount
    return () => {
      Render.stop(render);
      Runner.stop(runner);
      Composite.clear(engine.world);
      Engine.clear(engine);
    //   clearInterval(interval);
      sceneRef.current.removeEventListener('click', handleClick);
    };
  }, []);

  useEffect(() => {
    if(finishedBalls === predefinedPaths.length) {
        setPlaying(false)
    }
  },[finishedBalls, predefinedPaths.length, setPlaying])

  return <div ref={sceneRef} />;
};

export default MatterSim2;
