import Modal from "@material-ui/core/Modal";
import { assets, CONST } from "../../../constants";
import { useWallet } from "@aptos-labs/wallet-adapter-react";
import { useState, useEffect } from "react";
import clsx from "clsx";
import axios from "axios";
import { fetchTickets } from "../../../utils/indexer";
import { toast } from "react-toastify";
import { UserContext } from "../../../contexts/UserContent";
import { useContext } from "react";
import chestReward from "../../../assets/chestReward.png";
import raffleTicket from "../../../assets/raffleTicket.png";
// import defyCoins from "../../../assets/DefyCoins.gif";
import coinAnim from "../../../assets/coinAnim.gif"
// import FreeChestRewardsModel from "./FreeChestRewardsModel";
import { fetchSlotsRemaningMythicChest, fetchUserClaimedOrNotMythicChest } from "../../../utils/indexer"

//Congratulations, Mythic NFT has been transferred to your address


export default function ChestModal({
  open,
  handleClose,
  selectedChest,
  state,
  // hasAvexHandle,
  // emailVerified
}) {
  const [loading, setLoading] = useState(false);
  const [showRewardImage, setShowRewardImage] = useState(false);
  // const [isFreeChestOpen, setIsFreeChestOpen] = useState(false);
  const { account } = useWallet();

  const { user, setUser, tickets, setTickets } = useContext(UserContext);


  function getUser() {
    const jwt = localStorage.getItem("AVEX_DEFY_JWT_STORE");
    const config = {
      headers: {
        Authorization: jwt,
      },
    };
    if (jwt) {
      axios.get(`${CONST.baseUrl}/users`, config).then((response) => {
        setUser(response.data.data);
        // if(response.data.data.connections.some(connection => connection.email)) {
        //   setEmailVerified(true);
        // }
      });
    }
  }

  const [emailVerified, setEmailVerified] = useState(false)
  const [hasAvexHandle, setHasAvexHandle] = useState(false)
  const [emailMid, setEmailMid] = useState(false)
  const [emailMid2, setEmailMid2] = useState(false)
  const [avexHandleMid, setAvexHandleMid] = useState(false)
  const [buttonLoading, setButtonLoading] = useState(false);
  const [slotsRemainingState, setSlotsRemainingState] = useState(0);
  const [jwt, setjwt] = useState('')

  const [email, setEmail] = useState('');
  const [otp, setOTP] = useState('');
  const [avex, setAvex] = useState('');

  useEffect(() => {
    const updateSlotsRemaining = async () => {
      try {
        const slots = await fetchSlotsRemaningMythicChest();
        setSlotsRemainingState(slots);
      } catch (error) {
        console.error('Error fetching slots remaining:', error);
      }
    };

    updateSlotsRemaining(); // Initial fetch when component mounts

    const intervalId = setInterval(updateSlotsRemaining, 5000); // Update every 5 seconds

    // Cleanup interval on component unmount
    return () => clearInterval(intervalId);
  }, []); 

  const handleEmailChange = (e) => {
    setEmail(e.target.value);
  };

  const handleEmailOTPChange = (e) => {
    setOTP(e.target.value);
  }

  const handleAvexHandleChange = (e) => {
    setAvex(e.target.value);
  }

  // console.log(user.connections)
  useEffect(() => {
    const jwt = localStorage.getItem("AVEX_DEFY_JWT_STORE");
    const config = {
      headers: {
        Authorization: jwt,
      },
    };
    if (user && user.connections) {
      for(let i=0; i<user.connections.length; i++) {
        if(user.connections[i].provider==="email"){
          
          setEmailVerified(true)
        }
      }
      // const hasEmail = user.connections.some(connection => connection.email);
      // if (hasEmail) {
      //   console.log("Email true")
      //   setEmailVerified(true);
      // }
      axios.get(`${CONST.baseUrl}/users/avex-handle`, config).then((response) => {
        if(response.data.data)
        setHasAvexHandle(true);
      });
    }
    // setHasAvexHandle(false);
    // setEmailVerified(false);
  }, [user, open]);

  function closePopup() {
    setCurrentStep(1);
    // setEmailMid(false);
    // setAvexHandleMid(false);
    // setEmailMid2(false);
    handleClose();
  }

  const [timer, setTimer] = useState("");
  let lastChestSequence = user.lastPaidChestSequence ?? 0;

  useEffect(() => {
    if (!state.chest.chestType.isPaid)
      lastChestSequence = user.lastFreeChestSequence ?? 0;
  }, [open]);

  useEffect(() => {
    if (open) {
      setShowRewardImage(false); // Reset showRewardImage when the modal is opened
    }
  }, [open]);

  const handleShowReward = () => {
    setShowRewardImage(true);
    // setIsFreeChestOpen(true);
  };

  async function claimChest() {
      // console.log("claimChestButton", state.chest)
    //  setShowRewardImage(true);
    if (
      (timer !== "opened" || state.chest.price > user.balance.points) &&
      state.chest.chestType.isPaid
    )
      return;

    if (
      lastChestSequence + 1 !== state.chest.chestType.sequence &&
      state.chest.chestType.isPaid
    )
      return;
    const jwt = localStorage.getItem("AVEX_DEFY_JWT_STORE");
    // setjwt(jwt)
    const config = {
      headers: {
        Authorization: jwt,
      },
    };

    if (jwt) {
      setLoading(true);
      // axios
      //   .post(
      //     `${CONST.baseUrl}/chest/user/claim?chestId=${state.chest.chestId}`,
      //     config
      //   )
      //   .then((response) => {
      //     setLoading(false);
      //     if(!state.chest.chestType.isPaid) {
      //       handleShowReward();
      //       // //console.log("response", response.data);
      //       //console.log("state.chest data:",state.chest);
      //     }
      //     toast("Chest Successfully Claimed!");
      //     fetchTickets(account?.address).then((result) => {
      //       setTickets(result);
      //     });
      //     getUser();
      //   })
      //   .catch((err) => {
      //     //console.log(err.response);
      //     toast("Failed to Claim");
      //     setLoading(false);
      //   });
      // const hasEmail = emailVerified;
      // const hasAvex = hasAvexHandle;
      try {
        const slotsRemaining = await fetchSlotsRemaningMythicChest();
        const hasUserClaimed = await fetchUserClaimedOrNotMythicChest(account?.address);
      
        if (slotsRemaining > 0 && !hasUserClaimed) {
          handleNextStep();
          console.log("Chests available and user has not claimed.");
        } else {
          setButtonLoading(true);
      
          if (slotsRemaining <= 0) {
            toast("No chests left");
            console.log("No chests left.");
          } else {
            toast("You've already claimed this chest");
            console.log("User has already claimed this chest.");
          }
        }
      } catch (error) {
        toast(error);
        console.error("Error checking chests left:", error);
      } finally {
        setLoading(false);
        setButtonLoading(false);
      }      
    }
  }

  const handleVerifyButton = async () => {
    if (!emailVerified) {
      if (!emailMid) {
        setEmailMid(true);
      } else if (!emailMid2) {
        // SEND OTP
        const jwt = localStorage.getItem("AVEX_DEFY_JWT_STORE");
        const config = {
          headers: {
            Authorization: jwt,
          },
        };
        setButtonLoading(true);
        try {
          const response = await axios.post(`${CONST.baseUrl}/users/email`, { email: email }, config);
          if (response.status === 200) {
            setEmailMid2(true);
            toast("Successfully sent OTP");
          } else {
            toast("Error sending OTP!")
            console.error('Error sending OTP');
          }
        } catch (error) {
          toast("Error sending OTP!!")
          console.error('Error sending OTP:', error);
        } finally {
          setButtonLoading(false);
        }
        // setEmailMid2(true); //remove after completion
      } else {
        // VERIFY EMAIL WITH OTP
        const jwt = localStorage.getItem("AVEX_DEFY_JWT_STORE");
        const config = {
          headers: {
            Authorization: jwt,
          },
        };
        setButtonLoading(true);
        try {
          const response = await axios.post(`${CONST.baseUrl}/users/email/verify`, { code: otp }, config);
          if (response.status === 200) {
            // OTP verified successfully
            setEmailMid(false);
            setEmailMid2(false);
            setEmailVerified(true);
            handleNextStep();
            toast("Successfully verified Email");
          } else {
            // Handle error if needed
            toast("Wrong OTP!");
            console.error('Error verifying OTP');
          }
        } catch (error) {
          toast("Wrong OTP!");
          console.error('Error verifying OTP:', error);
        } finally {
          setButtonLoading(false);
        }
        //remove once api issue fixed
        // setEmailMid(false);
        // setEmailMid2(false);
        // setEmailVerified(true);
        // handleNextStep();
      }
    } else if (!hasAvexHandle) {
      setAvexHandleMid(true);
      const jwt = localStorage.getItem("AVEX_DEFY_JWT_STORE");
      const config = {
        headers: {
          Authorization: jwt,
        },
      };
      if(avexHandleMid) {
        //GET AVEX HANDLE 
        setButtonLoading(true);
        try {
          const response = await axios.post(`${CONST.baseUrl}/users/name/claim?name=${avex}`, config);
          if (response.status === 200) {
            setAvexHandleMid(false);
            handleNextStep();
            toast("Successfully claimed");
          } else {
            toast("Something went wrong!");
            console.error("Error while claiming");
          }
        } catch {
          toast("Can't use this avex handle");
          console.error('Error claiming Avex Handle');
        } finally {
          setAvexHandleMid(false);
        }
        // remove after completion
        // setAvexHandleMid(false);
        // handleNextStep();
      }
      
    }
  };
  // console.log("chestID",state.chest.chestId)
  const handleMintChest = async () => {
    setButtonLoading(true)
    const jwt = localStorage.getItem("AVEX_DEFY_JWT_STORE");
    const config = {
      headers: {
        Authorization: jwt,
      },
    };
    if(emailVerified && hasAvexHandle) {
      try {
        const response = await axios.post(`${CONST.baseUrl}/chest/user/claim/special?chestId=o6jb2dg4f`, config);
        if(response.status===200){
          toast("Congratulations, Mythic NFT has been transferred to your address")
        } else {
          toast("Error while minting!");
          console.error("Error while minting!")
        }
      } 
      catch {
        toast("Error while minting!!");
        console.error("Error while minting!")
      } finally {
        setButtonLoading(false)
      }
    } else {
      toast("Please verify your email/avex handle first")
    }
  }
  

  const renderEmailVerifyButtons = () => {
    if (emailMid && !emailMid2) {
      return (
        <button
          onClick={handleVerifyButton}
          className={clsx(
            "px-6 py-2.5 button rounded-lg text-white flex items-center justify-center mt-3 w-full self-end",
            "cursor-pointer ",
            buttonLoading?"bg-gray-400":"bg-bgBlue"
          )}
          disabled={buttonLoading}
        >
          <p>Send OTP</p>
        </button>
      );
    } else if (emailMid && emailMid2) {
      return (
        <button
          onClick={handleVerifyButton}
          className={clsx(
            "px-6 py-2.5 button rounded-lg text-white flex items-center justify-center mt-3 w-full self-end",
            "cursor-pointer bg-bgBlue",
            buttonLoading?"bg-gray-400":"bg-bgBlue"
          )}
          disabled={buttonLoading}
        >
          <p>Verify</p>
        </button>
      );
    } else {
      return (
        <button
          onClick={handleVerifyButton}
          className={clsx(
            "px-6 py-2.5 button rounded-lg text-white flex items-center justify-center mt-3 w-full self-end",
            "cursor-pointer bg-bgBlue",
            buttonLoading?"bg-gray-400":"bg-bgBlue"
          )}
          disabled={buttonLoading}
        >
          <p>Verify Mail</p>
        </button>
      );
    }
  };

  const [currentStep, setCurrentStep] = useState(1);

  // step - 1 claim page
  // step - 2 Email connection
  // step - 3 Avex Handle claim
  // step - 4 Mint Chest
  const steps = [2, 3, 4];

  const handleNextStep = () => {
    setShowRewardImage(true);
    if (currentStep === 1) {
      if (!emailVerified) {
        setCurrentStep(2);
      } else if (!hasAvexHandle) {
        setCurrentStep(3);
      } else {
        setCurrentStep(4);
      }
    } else if (currentStep === 2) {
      if (!hasAvexHandle) {
        setCurrentStep(3);
      } else {
        setCurrentStep(4);
      }
    } else if (currentStep === 3) {
      setCurrentStep(4);
    } else {
      setCurrentStep(1);
    }
    console.log(currentStep)
  };

  useEffect(() => {
    const interval = setInterval(() => {
      const now = new Date();
      const distance = new Date(state.chest.notBeforeAt) - now;
      // const distance = now - now;

      if (distance <= 0) {
        clearInterval(interval);
        setTimer("opened");
        return;
      }

      const days = Math.floor(distance / (1000 * 60 * 60 * 24));
      const hours = Math.floor(
        (distance % (1000 * 60 * 60 * 24)) / (1000 * 60 * 60)
      );
      const minutes = Math.floor((distance % (1000 * 60 * 60)) / (1000 * 60));

      setTimer(`${days}d : ${hours}h : ${minutes}m`);
    }, 1000);

    return () => clearInterval(interval);
  }, [state.chest.notBeforeAt]);


  // console.log(currentStep, emailVerified, hasAvexHandle, emailMid, emailMid2, avexHandleMid)
  // console.log(user, account)



  return (
    <>
      
      
      <Modal
        onClose={closePopup}
        open={open}
        className="h-[600px] w-[800px] max-[768px]:!h-[600px] max-[768px]:w-[95vw] max-[360px]:!h-[600px]"
        style={{
          backgroundColor: "transparent",
          margin: "auto",
          overflow: "hidden",
          display: "flex",
          flexDirection: "column",
          // TODO: ADDED IN MERGE
          color: "white",
        }}
      >
        {showRewardImage ? ( 
            <div
              className="w-full flex flex-col bg-white text-black py-5 px-5 rounded-lg !outline-none font-SatoshiMedium"
              style={{ maxHeight: "600px" }}
            >
              <div className="flex flex-col  ">
                    <div className="flex items-center flex-[2_2_0]">
                      <img
                        className="h-8 w-8 object-contain"
                        src={assets.treasure}
                        alt=""
                      />
                      <p className="text-xl font-SatoshiBold ml-2 text-black text-center">
                        {state.chest.chestName}
                      </p>
                    </div>

                    {state.chest.chestType.isPaid && <div className="flex text-sm flex-row gap-2 items-center justify-between mt-3">
                      <div className="bg-[#F8F8F9] rounded-full px-4 py-3 flex justify-between items-center w-[7rem]">
                        {steps.map((step, index) => (
                          <div
                            key={index}
                            className={`h-2 rounded-full cursor-pointer flex items-center justify-center transition-all duration-300 ${
                              currentStep == step
                                ? 'bg-green w-6'
                                : 'bg-gray-300 w-2'
                            }`}
                          />
                        ))}
                      </div>
                      <div className="flex gap-20 bg-[#F8F8F9] justify-between px-4 py-2 rounded-lg ">
                        <p className="font-SatoshiMedium  text-textGreyDark text-left">
                          SLOTS LEFT: 
                        </p>
                        <p className="font-SatoshiMedium text-black text-right">
                        {state.chest.chestName === "Mythic Chest" ? (
                          slotsRemainingState
                        ) : (
                          state.chest.slotsRemaining
                        )}
                        /
                        {/* Render total slots based on chest name */}
                        {(() => {
                          switch (state.chest.chestName) {
                            case "Mythic Chest":
                              return 100;
                            case "Legendary Chest":
                              return 175;
                            case "Epic Chest":
                              return 375;
                            case "Rare Chest":
                              return 700;
                            default:
                              return state.chest.totalSlots ?? 0;
                          }
                        })()}
                        </p>
                      </div>
                    </div>}
                  </div>

                  {/* <div
                    style={{}}
                    className="rounded-xl w-full h-[130px] flex align-center justify-center min-h-[210px] mt-2"
                  >
                    <img
                      className="w-full object-cover rounded-lg"
                      src={state.chest.bannerUrl}
                    />
                  </div> */}
                  <div className="flex items-center text-md gap-2 mt-2 font-bold font-SatoshiMedium">
                    <img src={assets.checklist} className="h-6 w-6" />
                    <p>Mint Checklist</p>
                  </div>
                  {emailMid ? 
                    <div className="flex flex-col items-start p-4 text-md gap-4 mt-2 font-SatoshiMedium">
                          <input
                          className="bg-[#F8F8F9] rounded-lg px-4 py-2 w-full focus:outline-grey focus:ring-0"
                          placeholder="Enter Mail ID"
                          value={email}
                          onChange={handleEmailChange}
                        />
                      {emailMid2 && <input
                        className="bg-[#F8F8F9] rounded-lg px-4 py-2 w-full focus:outline-grey focus:ring-0"
                        placeholder="Enter OTP"
                        value={otp}
                        onChange={handleEmailOTPChange}
                      />}
                    </div>
                    :
                    avexHandleMid ? 
                    <div className="flex flex-col items-start p-4 text-md gap-4 mt-2 font-SatoshiMedium">
                      <div className="relative w-full">
                        <span className="absolute left-4 top-1/2 transform -translate-y-1/2 text-gray-500">@</span>
                        <input
                          className="bg-[#F8F8F9] rounded-lg pl-10 pr-12 py-2 w-full focus:outline-grey focus:ring-0"
                          placeholder=""
                          value={avex}
                          onChange={handleAvexHandleChange}
                        />
                        <span className="absolute right-4 top-1/2 transform -translate-y-1/2 text-gray-500">.avex</span>
                      </div>
                    </div>
                    :
                    <div className="flex flex-col items-start bg-[#F8F8F9] rounded-[12px] p-4 text-md gap-2 mt-2 font-SatoshiMedium">
                      <div className={`${currentStep>=2?"text-green":"text-black"} flex gap-3 flex-row items-center`}>
                      <div className={`h-5 w-5 flex items-center justify-center ${currentStep >= 2 ? "bg-green" : "bg-grey"} rounded-full text-xs text-white`}>
                        1
                      </div>
                        100000 Defy Coins
                      </div>
                      <div className={`${currentStep>=3?"text-green":"text-black"} flex gap-3 flex-row items-center`}>
                      <div className={`h-5 w-5 flex items-center justify-center ${currentStep >= 3 ? "bg-green" : "bg-grey"} rounded-full text-xs text-white`}>
                        2
                      </div>
                        Verify Mail
                      </div>
                      <div className={`${currentStep>=4?"text-green":"text-black"} flex gap-3 flex-row items-center`}>
                      <div className={`h-5 w-5 flex items-center justify-center ${currentStep >= 4 ? "bg-green" : "bg-grey"} rounded-full text-xs text-white`}>
                        3
                      </div>
                        Claim Avex Handle
                      </div>
                    </div>

                  }

                  <div>
                    <img src={assets.line} className="w-full my-4" />
                  </div>

                  <div>
                    {currentStep === 2 && (
                      <div className="flex flex-col gap-4 font-SatoshiMedium">
                        <div className="flex gap-2 content-center">
                          <img src={assets.emailImage} className="h-14 w-14"/>
                          <div>
                            <h2 className="font-semibold">Verify your email</h2>
                            <p>The wallet you are connecting will be visible to the people on 0xPPL</p>
                          </div>
                        </div>
                        { 
                          // emailMid ?
                          //   <button onClick={handleVerifyButton} className={clsx(
                          //     "px-6  py-2.5 button rounded-lg text-white flex items-center justify-center mt-3 w-full self-end",
                          //     "cursor-pointer bg-bgBlue"
                          //   )}>
                          //     <p>Send OTP</p>
                          //   </button>
                          // : 
                          // emailMid2 ?
                          //   <button onClick={handleVerifyButton} className={clsx(
                          //     "px-6  py-2.5 button rounded-lg text-white flex items-center justify-center mt-3 w-full self-end",
                          //     "cursor-pointer bg-bgBlue"
                          //   )}>
                          //     <p>Verify</p>
                          //   </button>
                          // :
                          //   <button onClick={handleVerifyButton} className={clsx(
                          //     "px-6  py-2.5 button rounded-lg text-white flex items-center justify-center mt-3 w-full self-end",
                          //     "cursor-pointer bg-bgBlue"
                          //   )}>
                          //     <p>Verify Mail</p>
                          //   </button>
                          renderEmailVerifyButtons()
                        }  
                      </div>
                    )}
                    {currentStep === 3 && (
                      <div className="flex flex-col gap-4 font-SatoshiMedium">
                        {avexHandleMid
                        ?
                        <div className="flex gap-2 content-center">
                          {/* <img src={assets.avexChest} className="h-14 w-14"/> */}
                          <div>
                            <h2 className="font-semibold">Choosing Avex handle</h2>
                            <ul className="list-disc pl-5 mt-3 text-textGreyDark">
                              <li>Only letter A-Z, number 0-9, and (_) are allowed</li>
                              <li>Should only start with a letter</li>
                              <li>Should be between 4 - 15 characters long</li>
                            </ul>
                            {/* <p>Avex handles let you ditch long address & effortlessly transfer assets using usernames.</p> */}
                          </div>
                        </div>                      
                        :
                          <div className="flex gap-2 content-center">
                            <img src={assets.avexChest} className="h-14 w-14"/>
                            <div>
                              <h2 className="font-semibold">Claim your .avex handle</h2>
                              <p>Avex handles let you ditch long address & effortlessly transfer assets using usernames.</p>
                            </div>
                          </div>
                        }
                        
                        {avexHandleMid 
                        ? 
                          <button 
                            onClick={handleVerifyButton} 
                            className={clsx(
                              "px-6  py-2.5 button rounded-lg text-white flex items-center justify-center mt-3 w-full self-end",
                              "cursor-pointer bg-bgBlue",
                              buttonLoading?"bg-gray-400":"bg-bgBlue"
                            )}
                            disabled={buttonLoading}
                          >
                            <p>Continue</p>
                          </button>
                        :
                          <button 
                            onClick={handleVerifyButton} 
                            className={clsx(
                              "px-6  py-2.5 button rounded-lg text-white flex items-center justify-center mt-3 w-full self-end",
                              "cursor-pointer bg-bgBlue",
                              buttonLoading?"bg-gray-400":"bg-bgBlue"
                            )}
                            disabled={buttonLoading}
                          >
                            <p>Verify .avex Handle</p>
                          </button>
                        }
                        
                      </div>
                    )}
                    {currentStep === 4 && (
                      <div className="flex flex-col gap-4 font-SatoshiMedium">
                        <div className="flex gap-2 content-center">
                          {/* <img src={assets.avexChest} className="h-14 w-14"/> */}
                          <div>
                            <h2 className="font-semibold">Claim Mythic Chest NFT</h2>
                            <p>Mythic NFT will be later traded in the Defy app for your Insider NFT.</p>
                          </div>
                        </div>
                        <button 
                          onClick={handleMintChest} 
                          className={clsx(
                          "px-6  py-2.5 button rounded-lg text-white flex items-center justify-center mt-3 w-full self-end",
                          "cursor-pointer bg-bgBlue",
                          buttonLoading?"bg-gray-400":"bg-bgBlue"
                        )}>
                          <p>Mint Mythic Chest</p></button>
                      </div>
                    )}  
                  </div>
            </div>         
       ) : ( 
        <div
          className="w-full flex flex-col bg-white py-5 px-5  rounded-lg  !outline-none"
          style={{ maxHeight: "600px" }}
        >
          {loading ? (
            <>
              <img
                className="w-1/4 m-auto mt-[15%] mb-[20%] self-center"
                src="/loader.gif"
                alt="loader animation"
              />
            </>
          ) : (
            <>
              {/* Header section remains fixed size */}
              <div className="flex items-center justify-between ">
                <div className="flex items-center flex-[2_2_0]">
                  <img
                    className="h-8 w-8 object-contain"
                    src={assets.treasure}
                    alt=""
                  />
                  <p className="text-xl font-SatoshiBold ml-2 text-black text-center">
                    {state.chest.chestName}
                  </p>
                </div>
                
               {state.chest.chestType.isPaid && <div className="flex items-center justify-between flex-1 min-[767px]:px-5 mt-2">
                  <p className="font-SatoshiMedium text-xs text-textGreyDark text-left pl-3">
                    SLOTS LEFT:
                  </p>
                  <p className="font-SatoshiMedium text-xs text-black text-right">
                  {state.chest.chestName === "Mythic Chest" ? (
                    slotsRemainingState
                  ) : (
                    state.chest.slotsRemaining
                  )}
                  /
                  {/* Render total slots based on chest name */}
                  {(() => {
                    switch (state.chest.chestName) {
                      case "Mythic Chest":
                        return 100;
                      case "Legendary Chest":
                        return 175;
                      case "Epic Chest":
                        return 375;
                      case "Rare Chest":
                        return 700;
                      default:
                        return state.chest.totalSlots ?? 0;
                    }
                  })()}
                  </p>
                </div>}
              </div>
              <div
                className="flex-grow"
                style={{ overflowY: "auto", maxHeight: "calc(500px - 80px)" }}
              >
                <div className="flex xl:p-2 max-[768px]:flex-col">
                  <div style={{ flex: 2 }} className="p-4">
                    <div
                      style={{}}
                      className="rounded-xl w-full h-[130px] flex align-center justify-center min-h-[210px]"
                    >
                      <img
                        className="w-full object-cover rounded-lg"
                        src={state.chest.bannerUrl}
                      />
                    </div>
                    <p className="font-SatoshiMedium text-base text-textGreyDark text-left mt-2">
                      {state.chest.description}
                    </p>
                    {state.chest.subtexts &&
                      state.chest.subtexts.map((text) => {
                        return (
                          text
                        );
                      })}
                {state.chest.chestType.isPaid &&    <p className="font-SatoshiMedium text-base text-textGreyDark text-left mt-2">
                      Read the{" "}
                      <a
                        href="https://medium.com/@maharsi/the-defy-nft-collections-1bf8ab0c755b"
                        target="_blank"
                        className="text-textBlue"
                      >
                        blog
                      </a>{" "}
                      to know more details.
                    </p>}
                    {state.chest.chestType.isPaid &&    <p className="font-SatoshiMedium text-base text-textGreyDark text-left mt-2">
                      <span className="text-black font-SatoshiBold">Note:</span>{" "}
                      Users can buy only one chest from every tier.
                    </p>}
                  </div>

                  <div className="flex-1 p-2">
                    <div className="size-full h-auto  rounded-xl max-[768px]:hidden">
                      <img
                        className="h-full w-full  rounded-xl object-cover"
                        src={state.chest.chestType.isPaid ? assets.chestModalBannerPaid : assets.chestModalBanner}
                      />
                    </div>

                    <div className="">

                    <div className="stroke-borderGrey border rounded py-1.5 px-2 mt-3 flex items-center justify-end relative">
                      <p className="font-SatoshiMedium text-sm text-left text-black absolute top-[4px] left-[4px]">
                        Rewards:
                      </p>

                      <div>

                      {!state.chest.chestType.isPaid && 
                        <div className="flex items-center justify-end">
                          {/* <p className="font-SatoshiMedium text-xs text-left text-textGreyDark">You get:</p> */}
                        <p className="font-SatoshiMedium text-sm text-right text-black mr-2 w-[10rem]">
                        Defy Coins, Meme Tokens and Raffle Tickets
                          </p>
                        </div>
                      }

                      {state.chest.reward.tickets !== 0 && state.chest.chestType.isPaid &&  <div className="flex items-center justify-end">
                          {/* <p className="font-SatoshiMedium text-xs text-left text-textGreyDark">You get:</p> */}
                        <p className="font-SatoshiMedium text-sm text-left text-black mr-2">
                            {state.chest.reward.tickets ?? 0}x Raffle Ticket
                          </p>
                          <img
                            className="h-[12px] object-contain"
                            src={assets.blueTicket}
                          />
                        </div> }

                        {state.chest.reward.points !== 0 && state.chest.chestType.isPaid &&  <div className="flex items-center justify-end mt-0.5">
                        <p className="font-SatoshiMedium text-sm text-left text-black mr-2">
                            {state.chest.reward.points ?? 0} Defy Coins
                          </p>
                          <img
                            className="h-[12px] object-contain"
                            src={assets.coinAnim}
                          />
                        </div> }

                      {state.chest.reward?.nonFungibleAssets !== undefined && state.chest.chestType.isPaid &&  <div className="flex items-center justify-end mt-0.5">
                          {/* <p className="font-SatoshiMedium text-xs text-left text-textGreyDark">You get:</p> */}
                          <p className="font-SatoshiMedium text-sm text-left text-black mr-2">
                            {state.chest.reward.nonFungibleAssets[0].value}x{" "}
                            {state.chest.reward.nonFungibleAssets[0].name}
                          </p>
                          <img className="h-4 object-contain" src={assets.defyLogo} />
                        </div>}
                      </div>
                    </div>

                    <div className="flex items-center mt-4 justify-between">
                      <p className="ml-2 font-SatoshiMedium text-textGreyDark text-sm">
                        You'll spend
                      </p>
                      <div className="flex items-center py-1.5 px-2 bg-greyVeryLight rounded-full">
                        <p className="text-black font-SatoshiMedium text-base">
                          {state.chest.price}
                        </p>
                        <img className="size-6 ml-2" src={assets.yellowDiamond} />
                      </div>
                    </div>
                    </div>
                  
                  </div>
                  
                </div>
               
              </div>
              
              {state.chest.chestType.isPaid ? (
                
                      <div
                        onClick={() => {
                          if (
                            timer === "opened" &&
                            state.chest.price <= user.balance.points &&
                            state.chest.chestType.sequence == lastChestSequence + 1
                          )
                            claimChest();
                        }}
                        className={clsx(
                          "px-6  py-2.5  rounded-lg  flex items-center justify-center mt-3 xl:!w-[15rem] self-end !w-full",
                          timer === "opened" && state.chest.price <= user.balance.points && state.chest.chestType.sequence  == lastChestSequence + 1
                            ? "cursor-pointer bg-bgBlue button"
                            : "bg-borderGrey"
                        )}
                      >
                        <p className="font-SatoshiMedium text-base text-white">
                          {timer == "opened" && state.chest.price > user.balance.points
                            ? "Insufficient Coins"
                            : state.chest.chestType.sequence  == lastChestSequence + 1 ? "Claim" : state.chest.chestType.sequence  <= lastChestSequence ? "Claimed" : "Claim"}{" "}
                          {timer != "opened" && `in ${timer}`}
                        </p>
                      </div>
                    ) : (
                      <div
                        onClick={() => {
                          // if ((timer === "opened" && state.chest.price <= user.points))
                          claimChest();
                          setShowRewardImage(true);
                        }}
                        className={clsx(
                          "px-6  py-2.5 button rounded-lg  flex items-center justify-center mt-3 xl:!w-[15rem] !w-full self-end",
                          "cursor-pointer bg-bgBlue"
                        )}
                      >
                        <p className="font-SatoshiMedium text-base text-white">
                          Claim
                        </p>
                      </div>
                    )}
            </>
          )}
        </div>
        )}
      </Modal>
      
    </>
  );
}
